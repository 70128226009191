<template>
  <div class="container-fluid">
    <ApolloQuery :query="require('src/graphql/CaMyAffilAdmin.gql')" class="row">
      <template v-slot="{ result: { loading, error, data } }">
        <div v-if="loading" class="loading apollo">Chargement...</div>
        <div v-else-if="error" class="error apollo" style="color:red">An error occurred</div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-coins"></i>
                  </div>
                  <br />
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-chart-bar-32"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p class="card-category">{{ $t("Pages.dashboard.CaDayBefore").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? Math.floor(data.statsCaJ_1G_Lead[0].ca + data.statsCaJ_1G_Click[0].ca).toLocaleString() +' €' : '' }}</h3>
                    <p class="card-category">{{ $t("Pages.dashboard.MarginDayBefore").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? (data.statsCaJ_1G_Lead[0].marge).toFixed(1) +' %' : '' }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-coins"></i>
                  </div>
                  <br />
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-chart-bar-32"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p class="card-category">{{ $t("Pages.dashboard.CaDay").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? Math.floor(data.statsCaJG_Lead[0].ca + data.statsCaJG_Click[0].ca).toLocaleString() +' €' : '' }}</h3>
                    <p class="card-category">{{ $t("Pages.dashboard.MarginDay").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? (data.statsCaJG_Lead[0].marge).toFixed(1) +' %' : '' }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-coins"></i>
                  </div>
                  <br />
                  <div class="info-icon text-center icon-primary">
                    <i class="tim-icons icon-chart-bar-32"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p class="card-category">{{ $t("Pages.dashboard.CaMonth").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? Math.floor(data.statsCaMG_Lead[0].ca + data.statsCaMG_Click[0].ca).toLocaleString() +' €' : '' }}</h3>
                    <p class="card-category">{{ $t("Pages.dashboard.MarginMonth").toUpperCase() }}</p>
                    <base-button v-if="data==null" loading type="primary"></base-button>
                    <h3
                      class="card-title"
                      v-else
                    >{{ (data!=null) ? (data.statsCaMG_Lead[0].marge).toFixed(1) +' %' : '' }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ApolloQuery>

    <div class="row">
      <div class="col-12">
        <div class="card card-chart" data="blue">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2 class="card-title">{{ $t("Pages.dashboard.Statistics").toUpperCase() }}</h2>
                <h5 class="card-category" style="color:#000">
                  <template v-if="StatsWeek">
                    {{ $t("Pages.dashboard.StatsWeek").toUpperCase() }}
                  </template>
                  <template v-if="StatsMonth">
                    {{ $t("Pages.dashboard.StatsMonth").toUpperCase() }}
                  </template>
                </h5>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-12">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons" v-if="StatsWeek">
                      <label
                        style="font-size: 0.75rem"
                        v-for="(option, index) in bigLineChartCategories"
                        :key="option.name"
                        class="btn btn-sm btn-default btn-simple"
                        :class="{ active: bigLineChart.activeIndex === index }"
                        :id="index"
                      >
                        <input
                          type="radio"
                          @click="initBigChart(index)"
                          name="options"
                          autocomplete="off"
                          :checked="bigLineChart.activeIndex === index"
                        />
                        <span class="d-none d-sm-block">{{ option.name }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="option.name"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i :class="option.icon"></i>
                          </el-tooltip>
                        </span>
                      </label>
                    </div>
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons" v-if="StatsMonth">
                      <label
                        style="font-size: 0.75rem"
                        v-for="(option, index) in bigLineChartMonthCategories"
                        :key="option.name"
                        class="btn btn-sm btn-default btn-simple"
                        :class="{ active: bigLineChartMonth.activeIndex === index }"
                        :id="index"
                      >
                        <input
                          type="radio"
                          @click="initBigChartMonth(index)"
                          name="options"
                          autocomplete="off"
                          :checked="bigLineChartMonth.activeIndex === index"
                        />
                        <span class="d-none d-sm-block">{{ option.name }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="option.name"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i :class="option.icon"></i>
                          </el-tooltip>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="btn-group btn-group-toggle pull-right " data-toggle="buttons">
                      <label
                        style="font-size: 0.75rem"
                        class="btn btn-sm btn-primary btn-simple"
                        :class="{ active: StatsWeek === true }"
                        id="0">
                        <input 
                        type="radio" 
                        @click="showStatsWeek"
                        name="options">
                        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnWeek").toUpperCase() }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="$t('buttons.BtnWeek').toUpperCase()"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i class="tim-icons icon-chart-pie-36"></i>
                          </el-tooltip>
                        </span>
                      </label>
                      <label 
                        style="font-size: 0.75rem"
                        class="btn btn-sm btn-primary btn-simple" 
                        :class="{ active: StatsMonth === true }"
                        id="1">
                        <input 
                        type="radio" class="d-none d-sm-none" 
                        @click="showStatsMonth"
                        name="options">
                        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnMonth").toUpperCase() }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="$t('buttons.BtnMonth').toUpperCase()"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i class="tim-icons icon-chart-pie-36"></i>
                          </el-tooltip>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button
              v-if="$apollo.queries.statsGlobales.loading || this.$apollo.queries.statShootOfMonth.loading"
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);"
              loading
              type="default"
            ></base-button>
            <div class="chart-area">
              <line-chart
                v-show="!$apollo.queries.statsGlobales.loading && StatsWeek && !this.$apollo.queries.statShootOfMonth.loading"
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              ></line-chart>
              <line-chart
                v-show="!$apollo.queries.statsGlobales.loading && StatsMonth && !this.$apollo.queries.statShootOfMonth.loading"
                style="height: 100%"
                ref="bigChartMonth"
                :chart-data="bigLineChartMonth.chartData"
                :gradient-colors="bigLineChartMonth.gradientColors"
                :gradient-stops="bigLineChartMonth.gradientStops"
                :extra-options="bigLineChartMonth.extraOptions"
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "src/components/Cards/StatsCard";
import LineChart from "@/components/Charts/LineChart";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
import gql from "graphql-tag";

let bigChartData = [[], [], []];

let bigChartDataMonth = [[], [], []];

let bigChartLabels = ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"];
let bigChartLabelsMonth = [];

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.myAfill,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.myAfill,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: config.colors.myAfill,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
};

export default {
  apollo: {
    statsCaWeek: {
      query: gql`
        query {
          statsCaWeek_Click {
            day
            ca
          }
          statsCaWeek_Lead {
            day
            ca
          }
        }
      `,
      update: data => data
    },
    statShootOfWeek: gql`
      {
        statShootOfWeek {
          day
          shoots
        }
      }
    `,
    statLeadsOfWeek: gql`
      {
        statLeadsOfWeek {
          day
          leads
        }
      }
    `,
    statsGlobales: {
      query: gql`
        query($day: Int, $month: String!, $year: String!) {
          statsGlobales(
            day: $day
            month: $month
            year: $year
            droit: "ad"
          ) {
            numero_jour
            flag
            returnDay
            jour
            leads
            ca
          }
        }
      `,
      fetchPolicy: "no-cache",
      skip() {
        return this.SkipQuery;
      },
      variables() {
        return {
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""
        };
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobales[0].returnDay;
        this.Flag = data.statsGlobales[0].flag;
        this.AllstatsGlobales = [
          ...this.AllstatsGlobales,
          ...data.statsGlobales
        ];
      }
    },
    statShootOfMonth: {
      query: gql`
        query( $month: String!, $year: String!) {
          statShootOfMonth(month: $month,year:$year){
            day
            shoots
          }
        }
      `,
      variables() {
        return {
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""
        };
      },
      result({ data, loader, networkStatus }) {
        let Shootdata = [];
        let label = [];
        for (let index = 0; index < data.statShootOfMonth.length; index++) {
          label.push(data.statShootOfMonth[index].day+"/"+ this.Mounth[new Date().getUTCMonth()])
          Shootdata.push(data.statShootOfMonth[index].shoots)
        }
        bigChartLabelsMonth = label;
        bigChartDataMonth[1] = Shootdata;
      }
    },
  },
  watch: {
    statShootOfWeek: function(val) {
      this.statShootOfWeek.map(function(value, key) {
        bigChartData[1][key] = value.shoots;
      });
    },
    statLeadsOfWeek: function(val) {
      this.statLeadsOfWeek.map(function(value, key) {
        bigChartData[2][key] = (value.leads == 0) ? null : value.leads;
      });
    },
    statsCaWeek: function(val) {
      for (let i = 0; i < this.statsCaWeek.statsCaWeek_Lead.length; i++) {
        if (
          this.statsCaWeek.statsCaWeek_Lead[i].ca == null &&
          this.statsCaWeek.statsCaWeek_Click[i].ca == null
        ) {
          bigChartData[0][i] = null;
        } else
          bigChartData[0][i] = (
            this.statsCaWeek.statsCaWeek_Lead[i].ca +
            this.statsCaWeek.statsCaWeek_Click[i].ca
          ).toFixed(2);
      }
      bigChartLabels = this.setChartLabels()
      this.initBigChart(0);
    }
  },
  components: {
    StatsCard,
    LineChart
  },
  data() {
    return {
      Mounth: this.$t('labels.Mounths'),
      AllstatsGlobales: [],
      returnDay: null,
      StatsWeek: true,
      StatsMonth: false,
      Flag: false,
      SkipQuery: true,
      role: JSON.parse(localStorage.getItem("utilisateur")).law,
      statsCaWeek: null,
      statShootOfWeek: null,
      statLeadsOfWeek: null,
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[0]
            }
          ],
          labels: bigChartLabels
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartData[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 20,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      bigLineChartMonth: {
        activeIndex: 0, 
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartDataMonth[0]
            }
          ],
          labels: bigChartLabelsMonth
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartDataMonth[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartDataMonth[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots" 
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 20,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      }
    };
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: "CA", icon: "tim-icons icon-coins" },
        { name: "SHOOTS", icon: "tim-icons icon-spaceship" },
        { name: "LEADS", icon: "tim-icons icon-badge" }
      ];
    },
    bigLineChartMonthCategories() {
      return [
        { name: "CA", icon: "tim-icons icon-coins" },
        { name: "SHOOTS", icon: "tim-icons icon-spaceship" },
        { name: "LEADS", icon: "tim-icons icon-badge" }
      ];
    }
  },
  methods: {
    setChartLabels() {

      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ this.Mounth[parseInt(day[1]-1)])
      }
      return week
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            ...bigChartDatasetOptions,
            data: bigChartData[index]
          }
        ],
        labels: bigChartLabels
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    initBigChartMonth(index) {
      let chartData = {
        datasets: [
          {
            ...bigChartDatasetOptions,
            data: bigChartDataMonth[index]
          }
        ],
        labels: bigChartLabelsMonth
      };
      this.$refs.bigChartMonth.updateGradients(chartData);
      this.bigLineChartMonth.chartData = chartData;
      this.bigLineChartMonth.activeIndex = index;
    },
    async sendGlobalMonth() {
      this.SkipQuery = false;
      while (!this.Flag) {
        await this.$apollo.queries.statsGlobales.refetch({
          day: this.returnDay,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""
        });
      }
      
      let CAdata = [];
      let Leaddata = [];
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        CAdata.push(
          this.AllstatsGlobales[index].ca != null
            ? this.AllstatsGlobales[index].ca.toFixed(1)
            : this.AllstatsGlobales[index].ca
        );
        Leaddata.push(this.AllstatsGlobales[index].leads);
      }
      
      bigChartDataMonth[0] = CAdata;
      bigChartDataMonth[2] = Leaddata;
      this.initBigChartMonth(0);
      this.SkipQuery = true;
    },
    showStatsWeek() {
      this.initBigChart(0)
      this.StatsWeek = true
      this.StatsMonth = false
    },
    showStatsMonth() {
      this.initBigChartMonth(0)
      this.StatsWeek = false
      this.StatsMonth = true
    }
  },
  created() {
    this.sendGlobalMonth();
  }
};
</script>
<style scoped>
.card-custom {
  overflow: hidden;
  min-height: 350px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
}

.card-custom-img {
  height: 170px;
  min-height: 170px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}
.card-custom-img h6 {
  text-transform: none;
}
.card-custom .card-body .card-title {
  color: #51357c;
  font-weight: bold;
}
#program .card-custom .card-body .card-title {
  min-height: 60px;
}
.card-custom .card-body .card-text {
  color: #000;
}

/* First border-left-width setting is a fallback */
.card-custom-img::after {
  position: absolute;
  content: "";
  top: 147px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}
.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  top: 125px;
  left: 1.25rem;
  width: 50px;
  height: 50px;
}

#program .btn-primary.btn-simple .d-none {
  color: #303030 !important;
}
#program .btn-primary.btn-simple.active .d-none {
  color: #fff !important;
}

.prog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
.tm_photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.tm_photo img {
  width: 100%;
}
</style>
