<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>LISTE DES SUPPORTS</h3>
        <base-button
        size="sm"
          round
          class="btn btn-round btn-just-icon btn-primary pull-right"
          @click.native="prepareSupport()"
        >AJOUTER</base-button>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  <div class="col-md-3">
                    <base-input
                      label="SUPPORT"
                      name="support"
                      v-model="NameSupport"
                    ></base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input
                      label="THEMATHIQUE"
                      name="themathique"
                      v-model="Themathique"
                    ></base-input>
                  </div>

                  <!-- <div class="col-md-3">
                    <base-input
                      label="PAYS"
                      name="pays"
                    ></base-input>
                  </div> -->

                </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-12"><br>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <base-button
                    v-if="$apollo.queries.liste_supports.loading"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >Chargement</base-button>
                  <button
                    @click="resetFilter"
                    v-else
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >RÉINITIALISER</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="queriedData">
                <el-table-column
                  prop="nom_support"
                  label="SUPPORT"
                  class="table-striped"
                  min-width="180"
                  sortable
                  show-overflow-tooltip
                >
                  <template scope="props">{{ props.row.nom_support.toUpperCase() }}</template>
                </el-table-column>

                <el-table-column
                  prop="audience"
                  label="AUDIENCE"
                  class="table-striped"
                  min-width="110"
                  sortable
                  show-overflow-tooltip
                >
                  <template scope="props">{{ formatThounsends(props.row.audience) }}</template>
                </el-table-column>

                <el-table-column
                  prop="thematique"
                  label="THEMATHIQUE"
                  class="table-striped"
                  min-width="120"
                  sortable
                  show-overflow-tooltip
                >
                  <template
                    scope="props"
                  >{{ (!props.row.thematique) ? '-' : props.row.thematique.toUpperCase() }}</template>
                </el-table-column>

                <el-table-column
                  prop="support_types"
                  label="types support"
                  class="table-striped"
                  width="150"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if="scope.row.support_types">
                      <template v-for="type in scope.row.support_types.split(';')">
                        {{ supportTypes.find(c => c.value == type).type }} 
                        <label v-if="scope.row.support_types.split(';').length > 1">,</label>
                      </template>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pays"
                  label="PAYS"
                  class="table-striped"
                  min-width="120"
                  sortable
                  show-overflow-tooltip
                >
                  <template
                    scope="props"
                  >{{ (!props.row.pays) ? '-' : props.row.pays.toUpperCase() }}</template>
                </el-table-column>

                <el-table-column label="Actions" minWidth="130">
                  <div slot-scope="props">
                    <el-tooltip content="MODIFIER" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="prepareSupport(props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-edit"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="SUPPRIMER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="deleteSupport(props.row)"
                        class="edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-delete"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- ADD SUPPORT modal -->
    <modal
      :show.sync="AddSupportShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="AddSupportShow = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
          
        </template>
        <h3 slot="header" class="modal-title">
          AJOUTER UN SUPPORT
        </h3>
        <div class="col-sm-12">
          <base-input label="NOM DE SUPPORT" v-model="support.nom_support" :disabled="nomSupportDisabled" 
            name="support"
            v-validate="{ required: true, supportname: true }"
          :error="getError('support')"></base-input>
        </div>

        <div class="col-sm-12">
          <base-input label="TYPE DE SUPPORTS" :error="getError('type')">
            <el-select multiple class="select-primary"
              placeholder="TYPE DE SUPPORTS"
              filterable
              v-model="support.support_types"
              name="type"
              v-validate="'required'">
              <el-option 
                v-for="option in supportTypes"
                class="select-primary"
                :value="option.value"
                :label="option.type.toUpperCase()"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-sm-12">
          <base-input type="number" label="AUDIENCE" v-model="support.audience"
            name="volume"
            v-validate="'required'"
          :error="getError('volume')"></base-input>
        </div>

        <div class="col-sm-12">
          <base-input label="URL DU MEDIA" placeholder="https://..." v-model="support.url"></base-input>
        </div>

        <div class="col-sm-12">
          <base-input label="PAYS" :error="getError('pays')">
            <el-select filterable class="select-primary"
              placeholder="PAYS"
              clearable
              v-model="support.pays"
              name="pays"
              v-validate="'required'"
              >
              <el-option 
                v-for="option in LangueSelects.LangueList"
                class="select-primary"
                :value="option.name"
                :label="option.name.toUpperCase()"
                :key="option.iso2">
              </el-option>
            </el-select>
          </base-input>
        </div>

        
        
        <div class="col-sm-12">
          <base-input label="APPETENCES" :error="getError('thematique')">
            <el-select
              class="select-primary"
              placeholder="APPETENCES"
              v-model="support.thematique"
              filterable
              clearable
              name="thematique"
              v-validate="'required'"
            >
              <el-option
                v-for="option in ThematiqueSelects.ListThematique"
                class="select-primary"
                :value="option.categorie"
                :label="option.categorie.toUpperCase()"
                :key="option.value"
              ></el-option>
            </el-select>
          </base-input>
        </div>

        

        <div  class="text-center">
          <base-button
            v-if="showUpdateSupport"
            type="info"
            @click.native="updateSupport"
            :disabled='disabledadd'
            >MODIFIER
          </base-button>
          <base-button
            v-if="showAddSupport"
            type="info"
            @click.native="addSupport"
            :disabled='disabledadd'
            >AJOUTER
          </base-button>
        </div>
      </card>
    </modal>
    <!-- ADD SUPPORT modal -->

  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import PieChart from "src/components/Charts/PieChart";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import gql from "graphql-tag";
import swal from "sweetalert2";
import { Modal } from "src/components";
import { Thematiques } from "src/pages/Filters/Thematiques";

let today = new Date();

export default {
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    PieChart,
    Modal
  },
  apollo: {
    liste_supports: {
      query: gql`
        query($id_client: ID) {
          liste_supports(id_client: $id_client, orderBy: [
              {
                field: "id_support"
                order: DESC
              }
          ]) {
            id_support
            id_bases
            id_client
            nom_support
            support_types
            url_media
            pays
            audience
            etat
            thematique
            date_creation
          },
          listPays(iso2:""){
            iso2
            name
          }
        }
      `,
      update: data => data.liste_supports,
      result({ data, loader, networkStatus }) {
        this.tableData = data.liste_supports;
        this.LangueSelects.LangueList = data.listPays
        this.total = data.liste_supports.length;
      },
      variables() {
        return {
          id_client: this.$route.params.id_utilisateur
        };
      }
    }
  },
  data() {
    return {
      nomSupportDisabled: true,
      showUpdateSupport: false,
      showAddSupport: true,
      disabledadd:  false,
      NameSupport: "",
      Themathique: "",
      support: {
        id_support: "",
        nom_support: "",
        audience: "",
        pays: "",
        support_types: [],
        thematique: "",
        url: ""
      },
      AddSupportShow: false,
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchedData: [],
      tableData: [],
      LangueSelects: {
        Langue: '',
        LangueList: []
      },
      supportTypes: [
        {value: '1', type:'APPLI MOBILE'},
        {value: '2', type:'BASE EMAILS'},
        {value: '3', type:'BASE SMS'},
        {value: '4', type:'COMPARATEUR DE PRIX'},
        {value: '5', type:'DISPLAY'},
        {value: '6', type:'EMULATION'},
        {value: '7', type:'FACEBOOK'},
        {value: '8', type:'INSTAGRAM'},
        {value: '9', type:'LINKEDIN'},
        {value: '10', type:'PUSH NOTIFS'},
        {value: '11', type:'RETARGETING'},
        {value: '12', type:'SNAPCHAT'},
        {value: '13', type:'SEO/SEA'},
        {value: '14', type:'TWITTER'},
        {value: '15', type:'YOUTUBE'}
      ],
      ThematiqueSelects: {
        ListThematique: Thematiques
      },
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData.filter(support => {
        if(support.thematique == null)
          support.thematique = "-"
        return support.nom_support.toUpperCase().match(this.NameSupport.toUpperCase()) &&
                support.thematique.toUpperCase().match(this.Themathique.toUpperCase())
      })
      this.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    resetFilter() {
      this.Themathique = ""
      this.NameSupport = ""
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    prepareSupport(row = null) {
      if(row){
        this.nomSupportDisabled = true
        this.showUpdateSupport = true
        this.showAddSupport = false
        this.support.id_support = row.id_support
        this.support.nom_support = row.nom_support
        this.support.audience = row.audience
        this.support.pays = row.pays
        this.support.thematique = row.thematique
        this.support.url = row.url_media
        if(row.support_types){
          this.support.support_types = row.support_types.split(";")
        }else{
          this.support.support_types = []
        }
      }else{
        this.nomSupportDisabled = false
        this.showUpdateSupport = false
        this.showAddSupport = true
        this.support.id_support = ""
        this.support.nom_support = ""
        this.support.audience = ""
        this.support.pays = ""
        this.support.thematique = ""
        this.support.url = ""
        this.support.support_types = []
      }
      this.AddSupportShow = true
    },
    deleteSupport(row) {
      swal.fire({
          // title: 'Êtes-vous sûr?',
          text: "VOUS ÊTES EN TRAIN DE SUPPRIMER CE SUPPORT!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: "#988fc3",
          cancelButtonColor: "#27293D",
          confirmButtonText: "SUPPRIMER",
          cancelButtonText: "ANNULER"
        }).then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                // Query
                mutation: gql`
                  mutation($id_support: Int!, $etat: String) {
                    deleteSupport(
                      id_support: $id_support
                      etat: $etat
                    ) {
                      id_support
                    }
                  }
                `,
                variables: {
                  id_support: row.id_support,
                  etat: "supprime"
                }
              }).then(data => {
                var message = "";
                if (data.data.deleteSupport.id_support) {
                  message = "LE SUPPORT A BIEN ÉTÉ SUPPRIMÉ.";
                }

                this.tableData.forEach((value, index) => {
                  if (row.id_support == value.id_support) {
                    this.tableData.splice(index, 1);
                  }
                });

                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: message,
                  duration: 5000
                });
              }).catch(error => {
                // Error
                console.error(error);
              });
          }
        });
    },
    async addSupport(){
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.disabledadd = true
      this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation(
                $id_utilisateur: Int!
                $audience: Int!
                $pays: String!
                $thematique: String!
                $nom_support: String!
                $support_types: String!
              ) {
              addNewSupport(
                id_utilisateur: $id_utilisateur
                audience: $audience
                pays: $pays
                thematique: $thematique
                nom_support: $nom_support
                support_types: $support_types
              ) {
                id_support
                id_client
                nom_support
                support_types
                url_media
                pays
                audience
                etat
                thematique
                date_creation
              }
            }
          `,
          variables: {
            id_utilisateur: this.$route.params.id_utilisateur,
            audience: this.support.audience,
            pays: this.support.pays,
            thematique: this.support.thematique,
            nom_support: this.support.nom_support,
            support_types: this.support.support_types.join(';')
          }
        }).then(data => {
          var message = "";
          if (data.data.addNewSupport.id_support) {
            this.tableData.unshift(data.data.addNewSupport)
            this.AddSupportShow = false
            message = "LE SUPPORT A BIEN ÉTÉ AJOUTE"
          }

          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: message,
            duration: 5000
          });
          this.disabledadd = false
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors[0].extensions.code === "SUPPORT404_1") {
            this.$notify({type: 'warning', message: "Le support existe déjà dans notre base." })
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          }
          this.disabledadd = false
        });
    },
    async updateSupport() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.disabledadd = true
      this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation($id_support: Int!, $nom_support: String, $audience: Int, $pays: String, $thematique: String, $support_types: String!, $url_media: String) {
              updateSupport(
                id_support: $id_support
                nom_support: $nom_support
                audience: $audience
                pays: $pays
                thematique: $thematique
                support_types: $support_types
                url_media: $url_media
              ) {
                id_support
                id_client
                nom_support
                support_types
                url_media
                pays
                audience
                etat
                thematique
                date_creation
              }
            }
          `,
          variables: {
            id_support: this.support.id_support,
            nom_support: this.support.nom_support,
            audience: this.support.audience,
            pays: this.support.pays,
            thematique: this.support.thematique,
            support_types: this.support.support_types.join(';'),
            url_media: this.support.url
          }
        }).then(data => {
          var message = "";
          if (data.data.updateSupport.id_support) {
            this.tableData.forEach((value, index) => {
              if (this.support.id_support == value.id_support) {
                this.tableData[index].audience = this.support.audience
                this.tableData[index].thematique = this.support.thematique
                this.tableData[index].pays = this.support.pays
                this.tableData[index].support_types = data.data.updateSupport.support_types
                this.tableData[index].url_media = this.support.url
              }
            });
            this.AddSupportShow = false
            message = "LE SUPPORT A BIEN ÉTÉ MODIFIÉ"
          }

          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: message,
            duration: 5000
          });
          this.disabledadd = false
        }).catch(error => {
          // Error
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          this.disabledadd = false
        });
    }
  }
};
</script>