<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-left">
                <h2 class="card-title">{{ $t('titlePages.AdvancedGlobalStatistics') }}</h2>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2"></div>
                  <base-input class="col-md-10">
                    <base-radio inline name="CURRENTDAY" class="mb-3" v-model="Periode">
                      {{ $t('labels.Currentday') }}
                    </base-radio>
                    <base-radio inline name="YESTERDAY" class="mb-3" v-model="Periode">
                      {{ $t('labels.Yesterday') }}
                    </base-radio>
                    <base-radio inline name="CURRENTMONTH" class="mb-3" v-model="Periode">
                      {{ $t('labels.Currentmonth') }}
                    </base-radio>
                    <base-radio inline name="LASTMONTH" class="mb-3" v-model="Periode">
                      {{ $t('labels.Lastmonth') }}
                    </base-radio>
                    <base-radio inline name="ALL" class="mb-3" v-model="Periode">
                      {{ $t('labels.SinceLaunch') }}
                    </base-radio>
                  </base-input>
                </div>
                <div class="row">
                  <div class="col-md-2"></div>               
                  <base-input class="col-md-3" :label="$t('table.columns.Startdate')" :error="getError($t('table.columns.Startdate'))">
                    <el-date-picker 
                      :name="$t('table.columns.Startdate')"
                      v-validate="'required'"
                      v-model="params.dateD" 
                      type="date" 
                      value-format="yyyy/MM/dd" 
                      :placeholder="$t('table.columns.Startdate').toUpperCase()"
                    ></el-date-picker>
                  </base-input>

                  <base-input class="col-md-3" :label="$t('table.columns.Enddate')" :error="getError($t('table.columns.Enddate'))">
                    <el-date-picker 
                      :name="$t('table.columns.Enddate')"
                      v-validate="'required'"
                      v-model="params.dateF" 
                      type="date" 
                      value-format="yyyy/MM/dd" 
                      :placeholder="$t('table.columns.Enddate').toUpperCase()"
                    ></el-date-picker>
                  </base-input>
                </div>
                <div class="row">  
                  <div class="col-md-2"></div>
                  <base-input label="Tags Campagnes" class="col-md-3">
                    <image-upload
                      @change="onFileChange"
                      :select-text="$t('inputs.SelectFile')">
                    </image-upload>
                  </base-input>
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="Loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn, DatePicker, } from 'element-ui';
  import { BaseRadio, ImageUpload } from 'src/components';

  let CurentDate = new Date()

  export default {
    watch: {
      Periode(value) {
        if(value === "CURRENTDAY") {
          this.params.dateD = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(CurentDate.getDate())
          this.params.dateF = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(CurentDate.getDate())
        }
        else if(value === "YESTERDAY") {
          let valueDate = CurentDate;
          valueDate.setDate(valueDate.getDate()-1)
          this.params.dateD = valueDate.getFullYear() +"/"+ this.appendLeadingZeroes(valueDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(valueDate.getDate())
          this.params.dateF = valueDate.getFullYear() +"/"+ this.appendLeadingZeroes(valueDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(valueDate.getDate())
        }
        else if(value === "CURRENTMONTH") {
          this.params.dateD = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ "01"
          this.params.dateF = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(CurentDate.getDate())
        }
        else if(value === "CURRENTMONTH") {
          this.params.dateD = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ "01"
          this.params.dateF = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(CurentDate.getDate())
        }
        else if(value === "LASTMONTH") {
          if (CurentDate.getMonth()+1 == 1) {
            this.params.dateD = (CurentDate.getFullYear() - 1) +"/12/01"
            this.params.dateF = (CurentDate.getFullYear() - 1) +"/12/31"
          }
          else {
            this.params.dateD = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()) +"/"+ "01"
            this.params.dateF = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()) +"/"+ this.appendLeadingZeroes(new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate())
          }
        }
        else if(value === "ALL") {
          this.params.dateD = "2010/01/01"
          this.params.dateF = CurentDate.getFullYear() +"/"+ this.appendLeadingZeroes(CurentDate.getMonth()+1) +"/"+ this.appendLeadingZeroes(CurentDate.getDate())
        }

      }
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      BaseRadio,
      ImageUpload
    },
    data() {
      return {
        Loading: false,
        Periode: 'CURRENTDAY',
        params: {
          dateD: CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate(),
          dateF: CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate(),
          tags_file: null
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        if (!this.params.tags_file) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné les tags de campagnes !" })
          return
        }

        this.Loading = true
        var formData = new FormData();
        formData.append("dateD", this.params.dateD);
        formData.append("dateF", this.params.dateF);
        formData.append("tags_file", this.params.tags_file);
        formData.append("withCaMG", true);
        const url_api = "https://apimyaffil.wee-do-it.net/api/export-emulation-csv"
        let accessToken = localStorage.getItem("token_jwt")
        this.axios.post(url_api, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
          }).then(response => {
            const filename = "GetStatsEmulation.csv";
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.Loading = false
          }).catch(error => {
            console.log("error occured")
            this.Loading = false
          });
      },
      onFileChange(file) {
        // console.log(file)
        this.params.tags_file = file;
      },
      appendLeadingZeroes(n){
        if(n <= 9){
          return "0" + n;
        }
        return n
      },
    },
  }
</script>
