<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-chart" data="blue">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <h2 class="card-title">TOP TRAFFIC MANAGER</h2>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <base-input>
                      <el-select class="select-primary"
                          :disabled="$apollo.queries.statTopManager.loading"
                          :placeholder="$t('table.columns.Period')"
                          v-model="PeriodeSelects.Period">
                          <el-option 
                          v-for="option in PeriodeSelects.ListPeriod"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label.toUpperCase()"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <base-input>
                      <el-select filterable multiple clearable class="select-primary"
                          :disabled="$apollo.queries.statTopManager.loading"
                          :placeholder="$t('table.columns.Announcer').toUpperCase()"
                          v-model="ClientSelects.Client" collapse-tags @change="changeSelect">
                          <el-option 
                            class="select-primary"
                            value="ALL_SELECT"
                            label="TOUT SÉLECTIONNER"
                          >
                          </el-option>
                          <hr/>
                        <el-option 
                          v-for="option in ClientSelects.ListClient"
                          class="select-primary"
                          :value="option.id_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id_client">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <base-input>
                      <el-select class="select-primary"
                          :disabled="$apollo.queries.statTopManager.loading"
                          :placeholder="$t('inputs.Law.Affiliates').toUpperCase()"
                          filterable
                          multiple
                          clearable
                          v-model="EditeursSelects.Editeur" collapse-tags @change="changeEditeurSelect">
                          <el-option 
                            class="select-primary"
                            value="ALL_SELECT"
                            label="TOUT SÉLECTIONNER"
                          >
                          </el-option>
                          <hr/>
                          <el-option 
                          v-for="option in EditeursSelects.ListEditeur"
                          class="select-primary"
                          :value="option.id_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id_client">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <base-input>
                      <el-select class="select-primary"
                          :disabled="$apollo.queries.statTopManager.loading"
                          placeholder="TEAM"
                          clearable
                          v-model="TeamSelects.Team">
                          <el-option 
                          v-for="option in TeamSelects.ListTeam"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label.toUpperCase()"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-4  col-md-4">
                    <base-input>
                        <el-date-picker 
                          :disabled="$apollo.queries.statTopManager.loading"
                          v-model="StartDate" 
                          type="date" 
                          value-format="yyyy-MM-dd" 
                          placeholder="DATE DE DÉBUT"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </base-input>
                  </div>
                  <div class="col-lg-4  col-md-4">
                    <base-input>
                        <el-date-picker
                          :disabled="$apollo.queries.statTopManager.loading"
                          v-model="EndDate" 
                          type="date" 
                          value-format="yyyy-MM-dd" 
                          placeholder="DATE DE FIN"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.statTopManager.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        v-else 
                        class="btn btn-round btn-just-icon btn-primary" 
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
						      </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <bar-chart
              v-if='!$apollo.queries.statTopManager.loading && disblechartTM'
              ref="ChartTopManager"
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
            >
            </bar-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <h2 class="card-title">TRAFFIC MANAGER</h2>					  
              </div>
            </div>
          </div>
          <div class="card-body" data="black">
            <el-table ref="topTM_Table" :data="filterTeam" show-summary :summary-method="getSummaries">
              <el-table-column
                min-width="250"
                sortable
                label="Traffic manager"
                prop="tm.prenom.replace(/\s/g, '')"
              >
                <template scope="scope">
                  {{ scope.row.tm.prenom.toUpperCase()  + " " + scope.row.tm.nom.toUpperCase()}}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                :label="$t('Pages.dashboard.ca')"
                prop="ca.value"
              >
                <template scope="scope">
                  {{ Math.floor(scope.row.ca.value).toLocaleString() + " €" }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                label="%"
                prop="ca.pc"
              >
                <template scope="scope">
                  {{ scope.row.ca.pc.toFixed(2) + " %" }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                label="Leads"
                prop="leads.value"
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="goTodetailLeads(scope.row)">
                    <u>
                      {{ scope.row.leads.value }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                label="%"
                prop="leads.pc"
              >
                <template scope="scope">
                  {{ scope.row.leads.pc.toFixed(2) + " %" }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                :label="$t('table.columns.Margin')"
                prop="marge.value"
              >
                <template scope="scope">
                  {{ scope.row.marge.value.toFixed(2) + " €" }} 
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                sortable
                label="%"
                prop="marge.pc"
              >
                <template scope="scope">
                  {{ scope.row.marge.pc.toFixed(2) + " %" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Option, DatePicker} from 'element-ui'
import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import { Table, TableColumn } from 'element-ui';
import moment from 'moment'

let CurentDate = new Date()

export default {
  apollo: {
    Filter: {
      query : gql`query ($month: String, $year: String) {
        listAnnonceur{
          id_client
          email_client
          societe_client
        }

        Clients_ByStatCampagne(month: $month, year: $year) {
          id_client
          societe_client
        }

      }`,
      update: data => data,
      variables() {
        return {
          month: (((CurentDate.getMonth()+1)+"").length==1) ? "0"+(CurentDate.getMonth()+1) : (CurentDate.getMonth()+1),
          year: CurentDate.getFullYear()
        }
      },
      result({ data, loader, networkStatus }) {
        this.ClientSelects.ListClient = data.listAnnonceur
        this.EditeursSelects.ListEditeur = data.Clients_ByStatCampagne
      },
    },
    statTopManager: {
      query : gql`query (
                          $date_start: Date,
                          $date_end: Date,
                          $id_client: [Int], 
                          $id_editeur: [Int]  
                        ) {
        statTopManager (
                        date_start: $date_start
                        date_end: $date_end
                        id_client: $id_client
                        id_editeur: $id_editeur
                       )
        {
          tm{
            id_client
            email
            nom
            prenom
            team
          }
          ca {
            value
            pc
          }
          leads {
            value
            pc
          }
          marge {
            value
            pc
          }
        }        
      }`,
      skip(){
        return this.SkipQuery
      },
      variables() {
        return {
          date_start: this.StartDate,
          date_end: this.EndDate,
          id_client: this.ClientSelects.Client,
          id_editeur: this.EditeursSelects.Editeur    
        }
      },
      update: data => data,
      result({ data, loader, networkStatus }) {

        this.dateDetails.StartDate = this.StartDate || this.EndDate || CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
        this.dateDetails.EndDate = this.EndDate || this.StartDate || CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()

        let NomTm = []
        let CaTm = []
        this.tableTM = data.statTopManager.sort(function (a, b) {
          if ( a.tm.prenom.replace(/\s/g, '') < b.tm.prenom.replace(/\s/g, '') ){
            return -1;
          }
          if ( a.tm.prenom.replace(/\s/g, '') > b.tm.prenom.replace(/\s/g, '') ){
            return 1;
          }
          return 0;
        })
        let SUMlead = 0
        let SUMca = 0
        let SUMmarge = 0
        let SUMmargePC = 0
        for (let i = 0; i < data.statTopManager.length; i++) {
          SUMlead += data.statTopManager[i].leads.value
          SUMca += data.statTopManager[i].ca.value
          SUMmarge += data.statTopManager[i].marge.value
          SUMmargePC += data.statTopManager[i].marge.pc
          NomTm.push(data.statTopManager[i].tm.prenom.replace(/\s/g, '').toUpperCase() + " " +data.statTopManager[i].tm.nom.toUpperCase().replace(/\s/g, ''))
          CaTm.push(data.statTopManager[i].ca.value.toFixed(0))
        }
        this.SUMlead = SUMlead
        this.SUMca = SUMca
        this.SUMmarge = SUMmarge
        this.SUMmargePC = SUMmargePC / data.statTopManager.length
        this.barChart1.chartData.labels = NomTm
        NomTm.sort()
        this.barChart1.chartData.datasets[0].data = CaTm
        this.SkipQuery = true
        this.RefreshChartTM()
      },
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    BarChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  watch: {
    'PeriodeSelects.Period'(value){
      if(value == "YESTERDAY") {
        this.StartDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
        this.EndDate = ""
      } else if(value == "CURRENTDAY") {
        this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
        this.EndDate = ""
      } else if(value == "CURRENTMONTH") {
        this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ 1
        this.EndDate   = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
      } else if(value == "LASTMONTH") {
        if (CurentDate.getMonth()+1 == 1) {
          this.StartDate = (CurentDate.getFullYear() - 1) +"-"+ 12 +"-"+ 1
          this.EndDate   = (CurentDate.getFullYear() - 1) +"-"+ 12 +"-"+ 31
        }
        else {
          this.StartDate = CurentDate.getFullYear() +"-"+ CurentDate.getMonth() +"-"+ 1
          this.EndDate   = CurentDate.getFullYear() +"-"+ CurentDate.getMonth() +"-"+ new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate()
        }
      }
    },
    filterTeam() {
      let NomTm = []
      let CaTm = []
      let SUMlead = 0
      let SUMca = 0
      let SUMmarge = 0
      let SUMmargePC = 0
      this.filterTeam.forEach(TM => {
        SUMlead += TM.leads.value
        SUMca += TM.ca.value
        SUMmarge += TM.marge.value
        SUMmargePC += TM.marge.pc
        NomTm.push(TM.tm.prenom.replace(/\s/g, '').toUpperCase()  + " " +TM.tm.nom.toUpperCase().replace(/\s/g, ''))
        CaTm.push(TM.ca.value.toFixed(0))
      });
      this.SUMlead = SUMlead
      this.SUMca = SUMca  
      this.SUMmarge = SUMmarge
      this.SUMmargePC = SUMmargePC /  this.filterTeam.length
      this.barChart1.chartData.labels = NomTm
      this.barChart1.chartData.datasets[0].data = CaTm
      this.RefreshChartTM()
      this.setTotalLeadsAction()
    }
  },
  data() {
    return {
      clientallselect: false,
      editeurallselect: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      SUMlead: 0,
      SUMca: 0,
      SUMmarge: 0,
      SUMmargePC: 0,
      SkipQuery: true,
      disblechartTM: true,
      tableTM: [],
      dateDetails:{
        StartDate:new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate(),
        EndDate:new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate(),
      },
      StartDate: "",
      EndDate:  "",
      PeriodeSelects: {
        Period: '',
        ListPeriod: [
          { value: "YESTERDAY" , label: "Hier"},
          { value: "CURRENTDAY" , label: "Aujourd'hui"},	
          { value: "CURRENTMONTH" , label: "Mois en cours"},
          { value: "LASTMONTH" , label: "Mois précédent"}
        ]
      },
      ClientSelects: {
        Client: '',
        ListClient: []
      },
      EditeursSelects: {
        Editeur: '',
        ListEditeur: []
      },
      TeamSelects: {
        Team: '',
        ListTeam: [
          { value: "1" , label: "Casablanca"},
          { value: "2" , label: "Lyon"},
          { value: "3" , label: "Datadeal"}
        ]
      },
      barChart1: {
        chartData: {
          labels: null,
          datasets: [
            {
              fill: true,
              borderColor: config.colors.myAfill,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: null
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                return tooltipItems.yLabel + " €";
              }
            }
          },
          "hover": {
            "animationDuration": 0
          },
          animation: {
            duration : 0,
            onComplete : function() {
              var chartInstance = this.chart,
              ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if(screen.width > 550)
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index].toLocaleString()+" €";
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            }
          },
          scales: {
            yAxes: [
              {
                 ticks: {
                  suggestedMin: 999,
                  suggestedMax: 1000,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value + " €";
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.MyAfillGradient,
        gradientStops: [1, 0]
      }
    }
  },
  methods: {
    changeSelect(value) {
      const allValues = []
      for (const item of this.ClientSelects.ListClient) {
        allValues.push(item.id_client)
      }

      if (value.includes('ALL_SELECT')) {
        if (this.clientallselect = !this.clientallselect)
          this.ClientSelects.Client = allValues;
        else
          this.ClientSelects.Client = []
      }
    },
    changeEditeurSelect(value) {
      const allValues = []
      for (const item of this.EditeursSelects.ListEditeur) {
        allValues.push(item.id_client)
      }

      if (value.includes('ALL_SELECT')) {
        if (this.editeurallselect = !this.editeurallselect)
          this.EditeursSelects.Editeur = allValues;
        else
          this.EditeursSelects.Editeur = []
      }
    },
    send() {
      var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if(Difference_In_Days > 31) {
        this.$notify({type: 'primary', message: "vous n'aviez pas le droit de rechercher sur une période plus que 31 jours"})
      } else {
        this.SkipQuery = false
      }
      
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = Math.floor(this.SUMca).toLocaleString() + " €";
          return;
        }
        else if( index === 3) {
          sums[index] = this.SUMlead.toLocaleString();
          return;
        }
        else if( index === 5) {
          sums[index] = Math.floor(this.SUMmarge).toLocaleString() + " €";
          return;
        }
        else if( index === 6) {
          sums[index] = this.SUMmargePC.toFixed(2) + " %"
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      this.setTotalLeadsAction()
      return sums;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async RefreshChartTM() {
      this.disblechartTM = false
      await this.sleep(100)
      this.disblechartTM = true
    },
    goTodetailLeads(row) {
      localStorage.setItem("Clients",JSON.stringify(this.ClientSelects.Client))
      localStorage.setItem("Editeurs",JSON.stringify(this.EditeursSelects.Editeur))
      let routeData = this.$router.resolve({name: 'stats Detail Leads', params: { date_start: this.dateDetails.StartDate , date_end: this.dateDetails.EndDate,id_client: row.tm.id_client, }});
      window.open(routeData.href, '_blank');
    },
    setTotalLeadsAction() {
      let footter = document.querySelector('.el-table__footer')
      let tds = footter.getElementsByTagName('td');
      const leads = tds[3];
      if (leads) {
        let url = "https://login.weedoit.digital/myaffil/admin/detailLeads/c&"+this.dateDetails.StartDate+"&"+this.dateDetails.EndDate
        // let url = "http://localhost:8080/myaffil/admin/detailLeads/c&"+this.dateDetails.StartDate+"&"+this.dateDetails.EndDate
        leads.innerHTML = "<div class='cell'><u><a style='color: #bfbfbf' href='"+url+"' target='_blank'>"+ this.SUMlead.toLocaleString() +"</a></u></div>" 
      }
    }
  },
  created() {
    this.SkipQuery = false
  },
  computed: {
    filterTeam() {
      return this.tableTM.filter(TM => {
        return (TM.tm.team + "").match(this.TeamSelects.Team)
      });
    }
  }
}
</script>