<template>
  <div>
    <card>
      <h3>TAUX DE TRANSFORMATION DES VISUELS (MÀJ QUOTIDIENNE)</h3>
      <el-table :data="StatsVisuel" show-summary :summary-method="getSummaries">
        <el-table-column label="KIT" prop="name" min-width="150">
          <template scope="scope">
            {{ scope.row.name.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column
          label="impression"
          prop="impression"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.impression, true) }}
          </template>
        </el-table-column>
        <el-table-column label="CLICS" prop="clics" sortable min-width="150">
          <template scope="scope">
            {{ formatThounsends(scope.row.clics, true) }}
          </template>
        </el-table-column>
        <el-table-column
          label="TAUX CLIC"
          prop="taux_clic"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.taux_clic) + " %" }}
          </template>
        </el-table-column>
        <el-table-column label="LEADS" prop="leads" sortable min-width="150">
          <template scope="scope">
            {{ formatThounsends(scope.row.leads, true) }}
          </template>
        </el-table-column>
        <el-table-column
          label="TRANSFO"
          prop="tansformation"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ scope.row.tansformation + " %" }}
          </template>
        </el-table-column>
      </el-table>
      <center>
        <base-button
          v-if="StatsVisuel == null"
          loading
          type="primary"
        ></base-button>
      </center>
    </card>
    <card>
      <div class="row">
        <h3 class="card-title col-md-9">HTML MAILING</h3>

        <base-input class="col-md-3">
          <el-select
            class="select-primary"
            placeholder="GÉNÉRER LE PIXEL DU LEAD"
            v-model="GeneratePixelSelects.GeneratePixel"
            @change="generateLeadPixel"
          >
            <el-option
              v-for="option in GeneratePixelSelects.ListGeneratePixel"
              v-if="option.show"
              class="select-primary"
              :value="option.value"
              :label="option.label.toUpperCase()"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="(visuel, id) in listHtml"
          :key="visuel.id"
        >
          <card>
            <el-tooltip
              :content="$t('buttons.BtnImg.BtnRemove').toUpperCase()"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                id="close"
                @click.native="Removevisuel(visuel.id_visuel)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('buttons.BtnModify').toUpperCase()"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                id="close"
                @click.native="
                  VisuelEdited = visuel;
                  VisuelEditShow();
                "
                class="like btn-link"
                type="success"
                size="sm"
                icon
              >
                <i class="tim-icons icon-pencil"></i>
              </base-button>
            </el-tooltip>
            <h4 class="card-title">
              {{ visuel.taille + " " + visuel.tmp.split(" ")[0] }}
            </h4>
            <center>
              <div class="prog">
                <img
                  v-if="visuel.hostname == ''"
                  style="max-width: 155; max-height: 194; cursor: pointer"
                  :src="
                    'https://www.weedoit.fr/tracking/partenaire/' +
                    visuel.repertoire +
                    '/' +
                    visuel.profil
                  "
                  @click="
                    download(
                      '/apercu_template.html?idf=' +
                        visuel.id_visuel +
                        '&id_visuel=' +
                        visuel.id_visuel +
                        '&campagne=visuel_' +
                        visuel.id_visuel +
                        '&hostname=old'
                    )
                  "
                />
                <img
                  v-if="visuel.hostname == 'plf'"
                  style="width: 155; height: 194; cursor: pointer"
                  :src="
                    'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' +
                    visuel.repertoire.replace('é', 'e') +
                    '/' +
                    visuel.profil
                  "
                  @click="download('', visuel)"
                />
              </div>
            </center>
            <h3></h3>
            <h5>
              <b> Expéditeur : </b>
              <template v-if="true"> {{ visuel.expediteur }} </template>
            </h5>
            <h5><b>Objet :</b> {{ visuel.objet }}</h5>
            <h5><b>Objet :</b> {{ visuel.objet2 }}</h5>
            <h5><b>URL : </b>{{ visuel.lien }}</h5>
          </card>
        </div>
      </div>
    </card>

    <card>
      <h3 class="card-title">BANNIÈRES STANDARDS</h3>
      <center>
        <div class="col-lg-4 col-md-6">
          <card v-for="visuel in listBannier" :key="visuel.id">
            <el-tooltip
              content="SUPPRIMER CE VISUEL"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                id="close"
                @click.native="Removevisuel(visuel.id_visuel)"
                class="like btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              content="MODIFIER CE VISUEL"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                id="close"
                @click.native="
                  VisuelEdited = visuel;
                  VisuelBannEditShow();
                "
                class="like btn-link"
                type="success"
                size="sm"
                icon
              >
                <i class="tim-icons icon-pencil"></i>
              </base-button>
            </el-tooltip>
            <h4 class="card-title">
              {{ visuel.repertoire + ": " + visuel.taille + " image - gif" }}
            </h4>
            <img
              v-if="visuel.hostname == ''"
              :src="
                'https://www.weedoit.fr/tracking/partenaire/' +
                visuel.repertoire +
                '/' +
                visuel.profil
              "
            />
            <img
              v-if="visuel.hostname == 'plf'"
              :src="
                'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' +
                visuel.repertoire.replace('é', 'e') +
                '/' +
                visuel.profil
              "
            />
            <h3></h3>
            <h5><b>URL : </b>{{ visuel.lien }}</h5>
          </card>
        </div>
      </center>
    </card>
    <!-- Modal Edit HMTL VISUEL -->
    <modal
      :show.sync="ShowEditVisuel"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template v-if="ShowEditVisuel">
          <form role="form">
            <base-input label="Expéditeur" v-model="Expediteur"> </base-input>
            <base-input label="Objet" v-model="Objet"> </base-input>
            <base-input label="Objet" v-model="Objet2"> </base-input>
            <base-input label="URL" v-model="lien"> </base-input>
            <div class="text-center">
              <base-button type="info" @click="EditVisuel" class="my-4">{{
                $t("buttons.BtnModify").toUpperCase()
              }}</base-button>
            </div>
          </form>
        </template>
      </card>
    </modal>
    <!-- Modal Edit HMTL VISUEL -->

    <!-- Modal Edit BANNIERE VISUEL -->
    <modal
      :show.sync="ShowEditVisuelBann"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template v-if="ShowEditVisuelBann">
          <form role="form">
            <base-input label="Expéditeur" v-model="Expediteur"> </base-input>
            <base-input label="URL" v-model="lien"> </base-input>
            <div class="text-center">
              <base-button
                type="info"
                @click="EditVisuelBanniere"
                class="my-4"
                >{{ $t("buttons.BtnModify").toUpperCase() }}</base-button
              >
            </div>
          </form>
        </template>
      </card>
    </modal>
    <!-- Modal Edit BANNIERE VISUEL -->
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { Modal } from "src/components";
import swal from "sweetalert2";
import { Select, Option } from "element-ui";

export default {
  apollo: {
    stat_visuel: {
      query: gql`
        query ($id_programme: Int!) {
          stat_visuel(id_programme: $id_programme) {
            name
            impression
            clics
            leads
            taux_clic
            tansformation
          }
        }
      `,
      fetchPolicy: "no-cache",
      skip() {
        return this.SkipQuery2;
      },
      variables() {
        return {
          id_programme: this.PrgVisualiser.id_programme,
        };
      },
      result({ data, loader, networkStatus }) {
        this.StatsVisuel = data.stat_visuel;
        let SUMLimp = 0;
        let SUMclics = 0;
        let SUMlead = 0;
        let SUMtauxclick = 0;
        let SUMtrans = 0;
        this.StatsVisuel.forEach((stats) => {
          SUMLimp += stats.impression;
          SUMclics += stats.clics;
          SUMlead += stats.leads;
          SUMtauxclick += stats.taux_clic;
          SUMtrans += stats.tansformation;
        });
        this.SUMLimp = SUMLimp;
        this.SUMclics = SUMclics;
        this.SUMlead = SUMlead;
        this.SUMtauxclick = SUMLimp == 0 ? 0 : (SUMclics / SUMLimp) * 100;
        this.SUMtrans = SUMclics == 0 ? 0 : (SUMlead / SUMclics) * 100;

        this.SkipQuery2 = true;
      },
    },
    list_visuel: {
      query: gql`
        query ($id_programme: Int!) {
          list_visuel(
            orderBy: [{ field: "id_visuel", order: DESC }]
            id_programme: $id_programme
            etat: "valide"
          ) {
            id_visuel
            taille
            format
            profil
            lien
            expediteur
            url
            repertoire
            hostname
            objet
            objet2
            id_programme
            tmp
          }
        }
      `,
      fetchPolicy: "no-cache",
      skip() {
        return this.SkipQuery;
      },
      variables() {
        return {
          id_programme: this.PrgVisualiser.id_programme,
        };
      },
      result({ data, loader, networkStatus }) {
        this.listHtml = data.list_visuel.filter((visuel) => {
          return visuel.format.match("html");
        });
        this.listBannier = data.list_visuel.filter((visuel) => {
          return visuel.format.match("gif");
        });
        this.SkipQuery = true;
        this.SkipQuery2 = false;
      },
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      ShowEditVisuelBann: false,
      ShowEditVisuel: false,
      VisuelEdited: null,
      PrgVisualiser: null,
      SkipQuery: true,
      SkipQuery2: true,
      listHtml: null,
      listBannier: null,
      StatsVisuel: null,
      Expediteur: null,
      Objet: null,
      Objet2: null,
      lien: null,
      SUMLimp: 0,
      SUMclics: 0,
      SUMlead: 0,
      SUMtauxclick: 0,
      SUMtrans: 0,
      GeneratePixelSelects: {
        GeneratePixel: "",
        ListGeneratePixel: [
          { value: 2, label: "iFrame HTTPS", show: true },
          { value: 4, label: "Image HTTPS", show: true },
          { value: 5, label: "S2S HTTPS", show: true },
        ],
      },
    };
  },
  beforeMount() {
    if (
      (this.PrgVisualiser = JSON.parse(
        localStorage.getItem("ProgrammeVisualiser")
      )) == null
    ) {
      this.$router.push({ name: "liste programmes My Affil Admin" });
    } else {
      this.SkipQuery = false;
      if (this.PrgVisualiser.tracking_securise) {
        this.GeneratePixelSelects.ListGeneratePixel[1].show = true;
        this.GeneratePixelSelects.ListGeneratePixel[3].show = true;
      }
      localStorage.removeItem("ProgrammeVisualiser");
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 1) {
          sums[index] = this.formatThounsends(this.SUMLimp);
          return;
        } else if (index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics);
          return;
        } else if (index === 3) {
          sums[index] =
            this.formatThounsends(this.SUMtauxclick, true, 2) + " %";
          return;
        } else if (index === 4) {
          sums[index] = this.formatThounsends(this.SUMlead);
          return;
        } else if (index === 5) {
          sums[index] = this.formatThounsends(this.SUMtrans, true, 2) + " %";
          return;
        } else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    },
    formatThounsends: function (value, fixe = false, number = 0) {
      value != null ? (value = value) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    VisuelEditShow() {
      this.ShowEditVisuel = true;
      this.Expediteur = this.VisuelEdited.expediteur;
      this.Objet = this.VisuelEdited.objet;
      this.Objet2 = this.VisuelEdited.objet2;
      this.lien = this.VisuelEdited.lien;
    },
    VisuelBannEditShow() {
      this.ShowEditVisuelBann = true;
      this.Expediteur = this.VisuelEdited.expediteur;
      this.lien = this.VisuelEdited.lien;
    },
    async Removevisuel(id_visuel, id_programme) {
      swal
        .fire({
          text: this.$t("questions.deletedvisuel").toUpperCase(),
          width: 400,
          showCancelButton: true,
          confirmButtonColor: "#988fc3",
          cancelButtonColor: "#27293D",
          confirmButtonText: this.$t("buttons.BtnImg.BtnRemove").toUpperCase(),
          cancelButtonText: this.$t("buttons.BtnCancel").toUpperCase(),
        })
        .then((result) => {
          if (result.value) {
            this.$apollo
              .mutate({
                mutation: gql`
                  mutation ($id_visuel: ID!) {
                    deleteVisuel(id_visuel: $id_visuel)
                  }
                `,
                variables: {
                  id_visuel: id_visuel,
                },
              })
              .then((response) => {
                this.SkipQuery = false;
                this.$notify({
                  type: "success",
                  message: this.$t("apiresponse.visueldeleted"),
                });
              })
              .catch((error) => {
                this.$notify({
                  type: "warning",
                  message: this.$t("apiresponse.errordeleting"),
                });
              });
          }
        });
    },
    async EditVisuel() {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $id_programme: Int!
            $id_visuel: ID!
            $expediteur: String
            $objet: String
            $objet2: String
            $lien: String
          ) {
            updateVisuelHtml(
              id_programme: $id_programme
              id_visuel: $id_visuel
              expediteur: $expediteur
              objet: $objet
              objet2: $objet2
              lien: $lien
              action: ""
            ) {
              id_visuel
            }
          }
        `,
        variables: {
          id_programme: this.VisuelEdited.id_programme,
          id_visuel: this.VisuelEdited.id_visuel,
          expediteur: this.Expediteur,
          objet: this.Objet,
          objet2: this.Objet2,
          lien: this.lien,
        },
      });
      (this.VisuelEdited.expediteur = this.Expediteur),
        (this.VisuelEdited.objet = this.Objet),
        (this.VisuelEdited.objet2 = this.Objet2),
        (this.VisuelEdited.lien = this.lien);
      this.$notify({
        type: "success",
        message: this.$t("apiresponse.visuelupdated"),
      });
    },
    async EditVisuelBanniere() {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $id_programme: Int!
            $id_visuel: ID!
            $expediteur: String
            $lien: String
          ) {
            updateVisuelBaniere(
              id_visuel: $id_visuel
              id_programme: $id_programme
              expediteur: $expediteur
              lien: $lien
              action: ""
            ) {
              id_visuel
            }
          }
        `,
        variables: {
          id_programme: this.VisuelEdited.id_programme,
          id_visuel: this.VisuelEdited.id_visuel,
          expediteur: this.Expediteur,
          lien: this.lien,
        },
      });
      (this.VisuelEdited.expediteur = this.Expediteur),
        (this.VisuelEdited.lien = this.lien);
      this.$notify({
        type: "success",
        message: this.$t("apiresponse.visuelupdated"),
      });
    },
    download(url, row) {
      if (row) {
        window.open(
          "/apercu_template.html?idf=" +
            row.id_visuel +
            "&id_visuel=" +
            row.url.split("/")[3] +
            "&campagne=" +
            row.url.split("/")[2] +
            "&hostname=" +
            row.hostname
        );
      } else window.open(url);
    },
    generateLeadPixel(arg) {
      const url_api = "https://apimyaffil.wee-do-it.net/api/generate_LeadPixel";
      let accessToken = localStorage.getItem("token_jwt");

      this.axios
        .post(
          url_api,
          {
            id_programme: this.PrgVisualiser.id_programme,
            type: arg,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(response.data)
          );
          element.setAttribute(
            "download",
            "pixel_lead_multidomaine_pour_programme_" +
              this.PrgVisualiser.Titre +
              ".txt"
          );

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
#close {
  float: right;
}
</style>
<style scoped>
.prog {
  height: auto;
  width: 140px;
  border-radius: none;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
</style>
