<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t('SidebarMenu.CampaignsList').toUpperCase() }}</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  
                  <div class="col-md-3">
                    <base-input
                      label="tag"
                      v-model="Tag"
                    ></base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input
                      label="subid"
                      v-model="id_campagne"
                    ></base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input label="bases">
                      <el-select
                        filterable
                        :disabled="$apollo.queries.campagnes.loading"
                        class="select-primary"
                        v-model="nom_base"
                      >
                        <el-option
                          v-for="option in liste_support"
                          class="select-primary"
                          :value="option.nom_support"
                          :label="option.nom_support.toUpperCase()"
                          :key="option.nom_support"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input :label="$t('table.columns.Campagne')">
                      <el-select
                        filterable
                        :disabled="$apollo.queries.campagnes.loading"
                        class="select-primary"
                        v-model="campagnesListe.campagne"
                      >
                        <el-option class="select-primary" value label></el-option>
                        <el-option
                          v-for="option in campagnesListe.listeCampagnes"
                          class="select-primary"
                          :value="option.id_programme"
                          :label="option.provenance.toUpperCase()"
                          :key="option.id_programme"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-12"><br>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    @click="resetFilter"
                    class="btn btn-round btn-just-icon btn-primary pull-right"
                  >{{ $t('buttons.BtnReset').toUpperCase() }}</button>
                  <base-button
                    v-if="$apollo.queries.campagnes.loading"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary"
                  >{{ $t('buttons.BtnLoad') }}</base-button>
                  <button
                    v-else
                    @click="search"
                    class="btn btn-round btn-just-icon btn-primary"
                  >{{ $t('buttons.BtnSearch').toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="queriedData" class="customtable" @selection-change="handleSelectionChangeEdit">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="nom_campagne"
                  :label="$t('table.columns.Campagne')"
                  class="table-striped"
                  width="450"
                  show-overflow-tooltip
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="id_campagne"
                  label="Subid"
                  class="table-striped"
                  width="95"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="prix_editeur"
                  :label="$t('table.columns.LeadPrice')"
                  class="table-striped"
                  width="120"
                  sortable
                  show-overflow-tooltip
                >
                  <template
                    scope="props"
                  >{{ (props.row.prix_editeur > 0 ? formatThounsends(props.row.prix_editeur) : 0) + ' €' }}</template>
                </el-table-column>
                
                <el-table-column label="" width="55">
                  <div slot-scope="props" v-if="loaderShortLink && this.paramsShortLink.id_campagne == props.row.id_campagne">
                    <el-tooltip content="LOADING..." effect="light" :open-delay="300" placement="top">
                      <base-button
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-loading icon-large"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                  <div slot-scope="props" v-else>
                    <el-tooltip content="SHORT LINK" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="shortlinkfunction(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-mobile"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props">
                    <el-tooltip content="STATISTIQUES" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openStats(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-chart-bar-32"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props">
                    <el-tooltip content="VISUEL" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openVisuel(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-tv-2"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="100">
                  <div slot-scope="props">
                    <el-tooltip content="PIXELS" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openPixels(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-laptop"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="KIT MAIL" effect="light" :open-delay="300" placement="top" v-if="props.row.updt != 2">
                      <base-button
                        @click.native="showPopupKitmail=true;kitmailRowF(props.row);"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-paper-plane"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="KIT MAIL déjà envoyé" effect="light" :open-delay="300" placement="top" v-else>
                      <base-button
                        @click.native="showPopupKitmail=true;kitmailRowF(props.row);"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-paper-plane"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="Actions" width="155">
                  <div slot-scope="props">
                    <el-tooltip
                      v-if="props.row.bloque == 'non'"
                      :content="$t('buttons.BtnModify')"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="editCampagne(props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-edit"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      :content="$t('buttons.BtnImg.BtnRemove')"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="deleteCampagne(props.row)"
                        class="edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-delete"></i>
                      </base-button>
                    </el-tooltip>
                    
                    <el-tooltip
                      v-if="props.row.bloque == 'non'"
                      content="BLOQUER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="bloqueCampagne(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-lock-circle"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      v-else
                      content="DÉBLOQUER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="bloqueCampagne(props.row)"
                        class="edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-lock"></i>
                      </base-button>
                    </el-tooltip>
                    
                    <el-tooltip :content="$t('buttons.Extract')" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click="showExtractForm(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-download"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>

              <modal
                :show.sync="showPopupKitmail"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                <el-tooltip
                  content="Quitter"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                <base-button
                  @click.native="closepopItem"
                  class="btn-link float-right"
                  type="danger"
                  size="sm"
                  icon
                >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">ENVOI BAT</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">TAG DE CAMPAGNE:</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ KitmailRow.nom_campagne }}</span>
                    </div>
                    <form role="form" data-vv-scope="ScopePopMail">
                      <div class="col-md-12">
                        <base-input
                          label="email editeur"
                          name="email"
                          required
                          :placeholder="$t('inputs.Email').toUpperCase()"
                          v-model="KitmailRow.email"
                          v-validate="modelValidations.email"
                          type="email"
                          :error="getError('ScopePopMail.email')"
                          addon-left-icon="tim-icons icon-email-85"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-12">
                        <label class="col-form-label">PRIX EDITEUR : </label>
                        <span
                          class="mb-3"
                          style="color: #988fc3; font-size: 15px;"
                        >  {{ KitmailRow.prix_ed }} €</span>
                      </div>
                      <base-input label="message" class="col-md-12">
                        <textarea
                          class="form-control"
                          style="max-height: 200px !important;"
                          v-model="KitmailRow.description"
                          rows="12">
                        </textarea>
                      </base-input>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="sendKitMail()">ENVOYER</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>

              <modal
                :show.sync="showPopupKitsmail"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                <el-tooltip
                  content="Quitter"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                <base-button
                  @click.native="closepopItem"
                  class="btn-link float-right"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove icon-large"></i>
                </base-button>
                </el-tooltip>
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">ENVOI BAT</h3>
                    </div>
                    <form role="form" data-vv-scope="ScopePopMail">
                      <div class="col-md-12">
                        <base-input
                          label="email editeur"
                          name="email"
                          required
                          :placeholder="$t('inputs.Email').toUpperCase()"
                          v-model="KitmailRow.email"
                          v-validate="modelValidations.email"
                          type="email"
                          :error="getError('ScopePopMail.email')"
                          addon-left-icon="tim-icons icon-email-85"
                        >
                        </base-input>
                      </div>
                      <base-input label="message" class="col-md-12">
                        <textarea
                          class="form-control"
                          style="max-height: 200px !important;"
                          v-model="KitmailRow.description"
                          rows="12">
                        </textarea>
                      </base-input>
                      <div class="text-center text-muted mb-4" style="height: 160px;overflow-y: scroll !important;">
                        <h5 class="modal-title">TAGS CAMPAGNES:</h5>
                        <span 
                          v-for="item in KitsmailRow"
                          :key="item.id_campagne"
                          :label="item.nom_campagne"
                          :value="item.id_campagne"
                          class="mb-3"
                        >
                          <p v-if="item.statu == 'Att'" style="color: #988fc3 !important; font-size: 12px;">{{ item.nom_campagne }} : <i class="fa-solid fa-circle-info"></i></p>
                          <p v-if="item.statu == 'envoi'" style="color: #D4D4D8 !important; font-size: 12px;">{{ item.nom_campagne }} : <i class="fas fa-spinner fa-spin"></i></p>
                          <p v-if="item.statu == 'envoyer'" style="color: #2dce89 !important; font-size: 12px;">{{ item.nom_campagne }} : <i class="fa-solid fa-paper-plane"></i></p>
                          <p v-if="item.statu == 'exception'" style="color: #ffd600 !important; font-size: 12px;">{{ item.nom_campagne }} : <i class="fa-solid fa-triangle-exclamation"></i></p>
                        </span>
                      </div>
                      <div class="text-center">
                        <div v-if="loaderMultiSend">
                          <el-tooltip content="LOADING..." effect="light" :open-delay="300" placement="top">
                            <base-button
                              class="edit btn-link"
                              type="info"
                              size="sm"
                              icon
                            >
                              <i class="el-icon-loading icon-large"></i>
                            </base-button>
                          </el-tooltip>
                        </div>
                        <base-button v-else type="info" class="my-4" @click="sendKitMails()">ENVOYER</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>

              <modal
                :show.sync="showExtractModal"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">EXTRACTION DEPUIS UN TAG</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">TAG DE CAMPAGNE:</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ extractTag.nom_campagne }}</span>
                    </div>
                    <form role="form">
                      <div class="col-md-8">
                        <base-input label="DATE DÉBUT:">
                          <el-date-picker
                            v-model="extractTag.start_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="col-md-8">
                        <base-input label="DATE FIN:">
                          <el-date-picker
                            v-model="extractTag.end_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="extract()">EXTRAIRE</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>
            </div>
            <fab
              v-if='MiltipleRowEdit.length && !$apollo.queries.campagnes.loading'
              @click.native="kitsmailRowF"
              position="bottom-rignt"
              main-tooltip="MULTI KIT MAIL"
              main-icon="send"
              icon-size="small"
              bg-color="#004F84"
            ></fab>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import PieChart from "src/components/Charts/PieChart";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import fab from 'vue-fab'
import gql from "graphql-tag";
import swal from "sweetalert2";
import { Modal } from "src/components";
import { forEach } from 'jszip';

let today = new Date();

export default {
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    PieChart,
    fab,
    Modal
  },
  apollo: {
    Filter: {
      query: gql`
        query {
          programmes_campagnes {
            id_programme
            provenance
          }
        }
      `,
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.campagnesListe.listeCampagnes = data.programmes_campagnes;
      }
    },
    liste_supports: {
      query: gql`
        query($id_client: ID) {
          liste_supports(id_client: $id_client, orderBy: [
            {
              field: "id_support"
              order: DESC
            }
          ]) {
            nom_support
          }
        }
      `,
      update: data => data.liste_supports,
      result({ data, loader, networkStatus }) {
        this.liste_support = data.liste_supports;
      },
      variables() {
        return {
          id_client: this.id_client
        };
      }
    },
    campagnes: {
      query: gql`
        query(
          $id_client: Int!
          $id_programme: Int
          $nom_base: String
          $sub_id: Int
        ) {
          liste_campagnes(
            id_client: $id_client
            id_programme: $id_programme
            nom_base: $nom_base
            sub_id: $sub_id
          ) {
            id_client
            nom_campagne
            id_campagne
            id_programme
            prix_annonceur
            prix_editeur
            identifiant
            bloque
            updt
          }
        }
      `,
      update: data => data.liste_campagnes,
      skip() {
        return this.skiplistMember;
      },
      result({ data, loader, networkStatus }) {
        this.tableData = data.liste_campagnes;
        this.total = data.liste_campagnes.length;
        this.skiplistMember = true;
      },
      variables() {
        return {
          id_client: this.id_client,
          id_programme: this.campagnesListe.campagne,
          nom_base: this.nom_base,
          sub_id: this.id_campagne
        };
      }
    },
    shortLink: {
      query: gql`
        query(
          $id_ann: Int
          $id_campagne: Int
          $identifiant: String
        ) {
          genereteShortLink(
            id_ann: $id_ann
            id_campagne: $id_campagne
            identifiant: $identifiant
          ) {
            short_link
          }
        }
      `,
      fetchPolicy: 'network-only',
      skip() {
        return this.skipQueryShortLink;
      },
      result({ data, loader, networkStatus }) {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = data.genereteShortLink.short_link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.skipQueryShortLink = true;
        this.loaderShortLink = false
        this.$notify({ type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'short link généré et copier dans le presse papier avec success.' });
      },
      variables() {
        return {
          identifiant: this.paramsShortLink.identifiant,
          id_ann: this.paramsShortLink.id_programme,
          id_campagne: this.paramsShortLink.id_campagne,
        };
      }
    },
    get_user_by_plf_id: {
      client: 'AUTH',
      query: gql`
        query(
          $id_client: Int!
          $plf: String!
        ) {
          get_user_by_plf_id(
            id_client: $id_client
            plf: $plf
          ) {
            email
            society
          }
        }
      `,
      fetchPolicy: 'network-only',
      skip() {
        return this.skipQueryGetUserByPlf;
      },
      result({ data }) {
        this.KitmailRow.email = data.get_user_by_plf_id.email
        this.skipQueryGetUserByPlf = true;
      },
      variables() {
        return {
          id_client: this.KitmailRow.id_client,
          plf: "PERF",
        };
      }
    },

  },
  data() {
    return {
      Tag: "",
      extractTag: {
        start_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        end_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        nom_campagne: ""
      },
      paramsShortLink: {
        id_programme: null,
        id_campagne: null,
        identifiant: ""
      },
      modelValidations: {
        email: {
          required: true,
          email: true,
          max: 60,
          min:5
        }
      },
      MiltipleRowEdit: [],
      skipQueryGetUserByPlf:true,
      loaderShortLink:false,
      loaderMultiSend:false,
      showExtractModal: false,
      id_user_connected: null,
      skiplistMember: false,
      skipQueryShortLink:true,
      showPopupKitmail: false,
      showPopupKitsmail: false,
      KitmailRow: {
        id_client: null,
        email:'',
        nom_campagne: '',
        prix_an: null,
        prix_ed: null,
        description: "",
        idf:'',
        id_programme: null
      },
      status_send: [],
      KitsmailRow:[],
      email_editeur: '',
      prix_annonceur: null,
      prix_editeur: null,
      id_client: null, //rm
      id_programme: null,
      nom_base: null,
      id_campagne: null,
      campagnesListe: {
        campagne: "",
        listeCampagnes: []
      },
      liste_support: [],
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      tableData: []
    };
  },
  mounted() {
    (this.id_user_connected = JSON.parse(
      localStorage.getItem("utilisateur")
    ).id),
      (this.campagnesListe.campagne = this.$route.params.id_programme),
      (this.id_programme = this.$route.params.id_programme), //rm
      (this.nom_base = this.$route.params.nom_base),
      (this.sub_id = this.$route.params.id_campagne),
      (this.id_client = this.$route.params.id_client);
  },
  computed: {
    queriedData() {
      let result = this.tableData.filter((campagne) => {
        return campagne.nom_campagne.toUpperCase().match(this.Tag.toUpperCase())
      })
      this.total = result.length
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    handleSelectionChangeEdit(row) {
      if (row.length) {
        this.MiltipleRowEdit = row
      }else {
        this.MiltipleRowEdit = []
      }
    },
    async sendKitMails(){
      let validate
      await this.$validator.validateAll('ScopePopMail').then(isValid => {
        validate = isValid
      })
      if (!validate)
        return false
      
      this.loaderMultiSend = true;
      for (let index = 0; index < this.KitsmailRow.length; index++) {
        const url_api = 'https://apimyaffil.wee-do-it.net/api/generate_visuel_email'
        let accessToken = localStorage.getItem("token_jwt")
        let formData = new FormData()
        this.KitsmailRow[index].statu = 'envoi'
        formData.append("campagne", this.KitsmailRow[index].nom_campagne)
        formData.append("id_client", this.KitsmailRow[index].id_client)
        formData.append("idf", this.KitsmailRow[index].identifiant)
        formData.append("id_programme", this.KitsmailRow[index].id_programme)
        formData.append("email", this.KitmailRow.email)
        formData.append("description", this.KitmailRow.description)
        formData.append("track", 3)
        await this.axios.post(url_api, formData, 
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': `Bearer ${accessToken}`
          }
        })
        .then(response => {
          //this.$notify({type: 'success', message: 'mail envoyé avec succès'})
          //this.showPopupKitmail = false
          this.KitsmailRow[index].statu = 'envoyer'
          this.tableData.filter((campagne) => {
            if(this.KitsmailRow[index].identifiant == campagne.identifiant){
              campagne.updt = 2
            }
          })
        })
        .catch((error) => {
          /*if(error.response.status == 403) {
            this.$notify({type: 'warning', message:  error.response.data})
          } else {
            this.$notify({type: 'warning', message: 'erreur inattendue' })
          }
          this.showPopupKitmail = false*/
          this.KitsmailRow[index].statu = 'exception'
        })
      
      }
      this.loaderMultiSend= false;
    },
    async sendKitMail(){
      let validate
      await this.$validator.validateAll('ScopePopMail').then(isValid => {
        validate = isValid
      })
      if (!validate)
        return false

      const url_api = 'https://apimyaffil.wee-do-it.net/api/generate_visuel_email'
      let accessToken = localStorage.getItem("token_jwt")
      let formData = new FormData()
      formData.append("campagne", this.KitmailRow.nom_campagne)
      formData.append("id_client", this.KitmailRow.id_client)
      formData.append("idf", this.KitmailRow.idf)
      formData.append("id_programme", this.KitmailRow.id_programme)
      formData.append("email", this.KitmailRow.email)
      formData.append("description", this.KitmailRow.description)
      formData.append("track", 3)
      this.axios.post(url_api, formData, 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': `Bearer ${accessToken}`
        }
      })
      .then(response => {
        this.tableData.filter((campagne) => {
            if(this.KitmailRow.idf == campagne.identifiant){
              campagne.updt = 2
            }
        })
        this.$notify({type: 'success', message: 'mail envoyé avec succès'})
        this.showPopupKitmail = false
      })
      .catch((error) => {
        if(error.response.status == 403) {
          this.$notify({type: 'warning', message:  error.response.data})
        } else {
          this.$notify({type: 'warning', message: 'erreur inattendue' })
        }
        this.showPopupKitmail = false
      })
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    resetFilter() {
      (this.campagnesListe.campagne = ""),
        (this.nom_base = ""),
        (this.id_campagne = "");
    },
    closepopItem() {
      this.showPopupKitmail = false
      this.showPopupKitsmail = false
      this.KitsmailRow = []
    },
    async shortlinkfunction(row) {
      this.loaderShortLink = true;
      this.paramsShortLink.id_programme = row.id_programme
      this.paramsShortLink.id_campagne =row.id_campagne
      this.paramsShortLink.identifiant =row.identifiant
      this.skipQueryShortLink = false;
    },
    openStats(row) {
      let routeData = this.$router.resolve({
        name: "stats_shoots",
        params: { id_campagne: row.id_campagne }
      });
      window.open(routeData.href, "_blank");
    },
    openVisuel(row) {
      let routeData = this.$router.resolve({
        name: "visuel",
        params: {
          identifiant: row.identifiant,
          nom_campagne: row.nom_campagne,
          id_ann: row.id_programme
        }
      });
      window.open(routeData.href, "_blank");
    },
    openPixels(row) {
      let routeData = this.$router.resolve({
        name: "pixels",
        params: { identifiant: row.identifiant, nom_campagne: row.nom_campagne }
      });
      window.open(routeData.href, "_blank");
    },
    async search() {
      let validate;
      await this.$validator.validateAll().then(isValid => {
        validate = isValid;
      });
      if (!validate) return;

      this.skiplistMember = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    editCampagne(row){
      let routeData = this.$router.resolve({
        name: "edit_campagne",
        params: { id_campagne: row.id_campagne }
      });
      window.open(routeData.href, "_blank");
    },
    bloqueCampagne(row) {
      swal
        .fire({
          // title: 'Êtes-vous sûr?',
          text: "Vous êtes en train de bloquer cette campagne!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: "#988fc3",
          cancelButtonColor: "#27293D",
          confirmButtonText: "Bloquer",
          cancelButtonText: "Annuler"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                // Query
                mutation: gql`
                  mutation($id_campagne: Int!) {
                    bloque_campagne(id_campagne: $id_campagne) {
                      state
                    }
                  }
                `,
                variables: {
                  id_campagne: row.id_campagne
                }
              })
              .then(data => {
                var message = "";
                if (data.data.bloque_campagne.state) {
                  message = "La campagne est bloqué.";
                } else {
                  message = "La campagne est débloqué.";
                }

                this.tableData.forEach((value, index) => {
                  if (row.id_campagne == value.id_campagne) {
                    this.tableData.splice(index, 1);
                  }
                });

                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: message,
                  duration: 5000
                });
              })
              .catch(error => {
                // Error
                console.error(error);
              });
          }
        });
    },
    deleteCampagne(row) {
      swal
        .fire({
          // title: 'Êtes-vous sûr?',
          text: "Vous êtes en train de supprimer cette campagne!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: "#988fc3",
          cancelButtonColor: "#27293D",
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                // Query
                mutation: gql`
                  mutation($id_campagne: Int!, $id_client: Int!) {
                    delete_campagne(
                      id_campagne: $id_campagne
                      id_client: $id_client
                    ) {
                      state
                    }
                  }
                `,
                variables: {
                  id_campagne: row.id_campagne,
                  id_client: this.id_user_connected
                }
              })
              .then(data => {
                var message = "";
                if (data.data.delete_campagne.state) {
                  message = "La campagne est supprimé.";
                }

                this.tableData.forEach((value, index) => {
                  if (row.id_campagne == value.id_campagne) {
                    this.tableData.splice(index, 1);
                  }
                });

                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: message,
                  duration: 5000
                });
              })
              .catch(error => {
                // Error
                console.error(error);
              });
          }
        });
    },
    showExtractForm(row) {
      this.extractTag.nom_campagne = row.nom_campagne;
      this.showExtractModal = true;
    },
    kitmailRowF(row) {
      this.KitmailRow.nom_campagne = row.nom_campagne;
      this.KitmailRow.prix_an = row.prix_annonceur;
      this.KitmailRow.prix_ed = row.prix_editeur;
      this.KitmailRow.idf = row.identifiant;
      this.KitmailRow.id_programme = row.id_programme;
      this.KitmailRow.id_client = row.id_client;
      this.skipQueryGetUserByPlf = false
    },
    kitsmailRowF() {
      this.KitsmailRow = []
      for (let i = 0; i < this.MiltipleRowEdit.length; i++) {
        this.KitsmailRow.push({
          nom_campagne: this.MiltipleRowEdit[i].nom_campagne,
          id_campagne: this.MiltipleRowEdit[i].id_campagne,
          prix_annonceur: this.MiltipleRowEdit[i].prix_annonceur,
          prix_annonceur: this.MiltipleRowEdit[i].prix_annonceur,
          identifiant: this.MiltipleRowEdit[i].identifiant,
          id_programme: this.MiltipleRowEdit[i].id_programme,
          id_client: this.MiltipleRowEdit[i].id_client,
          statu: 'Att'
        })
      }
      this.KitmailRow.id_client = this.MiltipleRowEdit[0].id_client;
      this.skipQueryGetUserByPlf = false
      this.showPopupKitsmail = true
    },
    extract() {
      //const url_api = "http://weedoitmyaffil.api/api/extract_tag_campagne"; //for local test
      const url_api = 'https://apimyaffil.wee-do-it.net/api/extract_tag_campagne' //for server test
      let accessToken = localStorage.getItem("token_jwt");
      this.axios
        .post(url_api, this.extractTag, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
        })
        .then(response => {
          const filename =
            "Extract_leads_" +
            this.extractTag.nom_campagne +
            "_du_" +
            this.extractTag.start_date +
            "_au_" +
            this.extractTag.end_date +
            ".csv";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => console.log("error occured"));
    }
  },
  created() {
    let tag = localStorage.getItem('tag')
    if(tag) {
      this.Tag = tag
      localStorage.removeItem('tag')
    }
  },
};
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 12px;
  }
</style>