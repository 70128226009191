<template>
  <card>
    <div class="row justify-content-center">
      <div class="col-sm-5">
        <base-input label="Annonceur *" :error="getError('annonceur')">
          <el-select class="select-primary"
                  filterable
                  clearable
                  placeholder="ANNONCEUR"
                  name="annonceur"
                  v-validate="'required'"
                  v-model="AnnonceurSelects.Annonceur">
            <el-option v-for="option in AnnonceurSelects.AnnonceurList"
                    class="select-primary"
                    :value="option.id_client"
                    :label="option.societe_client.toUpperCase()"
                    :key="option.id_client">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Titre *" placeholder="TITRE"
              v-model="Titre"
              v-validate="'required'"
              :error="getError('titre')"
              name="titre"
              type="text">
        </base-input>
      </div>
      <!-- <div class="col-sm-5">
        <base-input label="Sous titre" type="text" placeholder="SOUS TITRE" 
                name="Sous Titre"
                v-model="sous_titre">
        </base-input>
      </div> -->
      <div class="col-sm-5">
        <base-input label="TYPE DE RÉMUNÉRATION *" :error="getError('type de rémunération')">
          <el-select multiple class="select-primary"
                  placeholder="TYPE DE RÉMUNÉRATION"
                  name="type de rémunération"
                  v-validate="'required'"
                  v-model="CampagneSelects.Campagne">
            <el-option v-for="option in CampagneSelects.CampagneList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Statut minimum *" :error="getError('statut minimum')">
          <el-select class="select-primary"
                  placeholder="STATUT MINIMUM"
                  name="statut minimum"
                  v-validate="'required'"
                  v-model="StatutSelects.Statut">
            <el-option v-for="option in StatutSelects.StatutList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <template v-if="CampagneSelects.Campagne.includes('affichage')">
        <!-- <div class="col-sm-5">
          <base-input v-model="prix_mg_annonceur" label="PRIX MG ANNONCEUR RÉFÉRENTIEL (AFFICHAGE)" type="text" placeholder="PRIX MG ANNONCEUR RÉFÉRENTIEL (AFFICHAGE)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_mg_editeur" label="PRIX MG ÉDITEUR RÉFÉRENTIEL (AFFICHAGE)" type="text" placeholder="PRIX MG ÉDITEUR RÉFÉRENTIEL (AFFICHAGE)">
          </base-input>
        </div> -->
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_cpc" label="RÉMUNÉRATION CPL ANNONCEUR (CPC)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPC)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_cpc" label="RÉMUNÉRATION CPL ÉDITEUR (CPC)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPC)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('cpm')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_cpm" label="RÉMUNÉRATION CPL ANNONCEUR (CPM)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPM)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_cpm" label="RÉMUNÉRATION CPL ÉDITEUR (CPM)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPM)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('enrichissement')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_enrichissement" label="RÉMUNÉRATION CPL ANNONCEUR (ENRICHISSEMENT)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (ENRICHISSEMENT)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_enrichissement" label="RÉMUNÉRATION CPL ÉDITEUR (ENRICHISSEMENT)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (ENRICHISSEMENT)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('lead')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur" label="RÉMUNÉRATION CPL ANNONCEUR (CPL)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPL)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur" label="RÉMUNÉRATION CPL ÉDITEUR (CPL)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPL)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('pourcentage des ventes')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_cpa" label="RÉMUNÉRATION CPL ANNONCEUR (CPA)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPA)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_cpa" label="RÉMUNÉRATION CPL ÉDITEUR (CPA)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPA)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('sms')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_sms" label="RÉMUNÉRATION CPL ANNONCEUR (SMS)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (SMS)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_sms" label="RÉMUNÉRATION CPL ÉDITEUR (SMS)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (SMS)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('téléchargement')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_cpd" label="RÉMUNÉRATION CPL ANNONCEUR (CPD)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPD)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_cpd" label="RÉMUNÉRATION CPL ÉDITEUR (CPD)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPD)">
          </base-input>
        </div>
      </template>
      <template v-if="CampagneSelects.Campagne.includes('vente fixe')">
        <div class="col-sm-5">
          <base-input v-model="prix_annonceur_cpa_fixe" label="RÉMUNÉRATION CPL ANNONCEUR (CPA FIXE)" type="number" placeholder="RÉMUNÉRATION CPL ANNONCEUR (CPA FIXE)">
          </base-input>
        </div>
        <div class="col-sm-5">
          <base-input v-model="prix_editeur_cpa_fixe" label="RÉMUNÉRATION CPL ÉDITEUR (CPA FIXE)" type="number" placeholder="RÉMUNÉRATION CPL ÉDITEUR (CPA FIXE)">
          </base-input>
        </div>
      </template>
      <div class="col-sm-5">
        <base-input label="Format">
          <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Mailing">
            MAILING
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Banniere">
            BANNIÈRE
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Flux">
            FLUX XML
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="FormatCheckboxes.Sms">
            SMS
          </base-checkbox>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Retargeting">
          <base-checkbox class="mb-3" v-model="Retargeting">
            RETARGETING
          </base-checkbox>
        </base-input>
      </div>
      <div class="col-sm-10">
        <el-table :data="tableData">
          <el-table-column min-width="100" label="" prop="Autorisation">
          </el-table-column>
          <el-table-column min-width="120" label="E-mailing">
            <base-checkbox class="mb-3" v-model="tableau.emaling">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="120" label="Bannière">
            <base-checkbox class="mb-3" v-model="tableau.Banniere">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="120" label="Pop-under">
            <base-checkbox  class="mb-3" v-model="tableau.Pop_under">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="120" label="Site-under">
            <base-checkbox  class="mb-3" v-model="tableau.Site_under">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="120" label="Incentive clic">
            <base-checkbox  class="mb-3" v-model="tableau.Incentive_clic">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="150" label="Incentive remplissage">
            <base-checkbox  class="mb-3" v-model="tableau.Incentive_remplissage">
            </base-checkbox>
          </el-table-column>
          <el-table-column min-width="120" label="Achat de mots clès">
            <base-checkbox  class="mb-3" v-model="tableau.Achat_mots_cles">
            </base-checkbox>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-5">
        <base-input v-model="URL_Flux_XML" label="URL Flux XML" type="text" placeholder="URL FLUX XML">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input v-model="Cle_items_XML" label="Clé items (Flux XML)" type="text" placeholder="CLÉ ITEMS (FLUX XML)">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input v-model="Description" label="Description" type="text" placeholder="DESCRIPTION">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input disabled v-model="Repertoire" label="Répertoire" type="text" placeholder="RÉPERTOIRE">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input disabled v-model="Provenance" label="Provenance" type="text" placeholder="PROVENANCE">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Catégorie *" :error="getError('categorie')">
          <el-select class="select-primary"
                  placeholder="CATÉGORIE"
                  filterable
                  name="categorie"
                  v-validate="'required'"
                  clearable
                  v-model="CategorieSelects.Categorie">
            <el-option v-for="option in CategorieSelects.CategorieList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.categorie.toUpperCase()"
                    :key="option.name">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Langue *" :error="getError('langue')">
          <el-select class="select-primary"
                  placeholder="LANGUE"
                  filterable
                  name="langue"
                  v-validate="'required'"
                  clearable
                  v-model="LangueSelects.Langue">
            <el-option v-for="option in LangueSelects.LangueList"
                    class="select-primary"
                    :value="option.iso2"
                    :label="option.name.toUpperCase()"
                    :key="option.iso2">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Manager *" :error="getError('manager')">
          <el-select class="select-primary"
                  placeholder="MANAGER"
                  filterable
                  name="manager"
                  v-validate="'required'"
                  v-model="ManagerSelects.Manager">
            <el-option v-for="option in ManagerSelects.ManagerList"
                    class="select-primary"
                    :value="option.email_client + 'SPLITE' + option.id_client"
                    :label="option.prenom_client + ' '+ option.nom_client.toUpperCase()"
                    :key="option.id_client">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="État *" :error="getError('etat')">
          <el-select class="select-primary"
                  placeholder="ÉTAT"
                  name="etat"
                  v-validate="'required'"
                  v-model="EtatSelects.Etat">
            <el-option v-for="option in EtatSelects.EtatList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Visible">
          <el-select class="select-primary"
                  placeholder="VISIBLE"
                  v-model="VisibleSelects.Visible">
            <el-option v-for="option in VisibleSelects.VisibleList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5 text-center">
        <base-input label="Image profil">
          <image-upload
            @change="onImageChange">
          </image-upload>
        </base-input>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-2">
        <base-button type="info" v-if="!loader" @click="addProgram" class="space">{{ $t("buttons.BtnAdd").toUpperCase() }}</base-button>
        <base-button type="info" v-else loading></base-button>
      </div>
    </div>
  </card>
</template>

<script>
import {Select, Option} from 'element-ui'
import {ImageUpload} from 'src/components'
import {Table, TableColumn} from 'element-ui'
import gql from 'graphql-tag'
import { Thematiques } from "src/pages/Filters/Thematiques";

export default {
  created() {
    if(localStorage.getItem('DupliqueProgram')) {
      let Programme = JSON.parse(localStorage.getItem('DupliqueProgram'))
      this.Titre = Programme.Titre
      this.sous_titre = Programme.sous_titre
      this.AnnonceurSelects.Annonceur = "" + Programme.id_client
      this.CampagneSelects.Campagne = Programme.campagne
      this.CategorieSelects.Categorie = Programme.categorie
      this.prix_mg_annonceur = Programme.prix_mg_annonceur
      this.prix_mg_editeur = Programme.prix_mg_editeur
      this.prix_annonceur = Programme.prix_annonceur
      this.prix_editeur = Programme.prix_editeur
      this.EtatSelects.Etat = Programme.etat
      this.ManagerSelects.Manager = Programme.manager
      this.LangueSelects.Langue = Programme.langue
      this.Description = Programme.description
      this.StatutSelects.Statut = Programme.statut
      localStorage.removeItem('DupliqueProgram')
    }
  },
  apollo: {
    listAnnonceur: {
      query: gql`query {
        listAnnonceur
        {    
          id_client    
          email_client   
          societe_client    
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.AnnonceurSelects.AnnonceurList = data.listAnnonceur
      }
    },
    listCategories: {
      query: gql`query {
        listCategories {
          name
        },
        listPays(iso2:""){
          iso2
          name
        },
        listManager(
          bloque:"non",
          etat:"valide",
          droit:["ma","tem"], 
          orderBy: 
          [
            {
              field: "prenom_client"
              order: ASC
            }
            {
              field: "nom_client"
              order: ASC
            }
          ]
          ){
            id_client
            nom_client
            prenom_client
            email_client
        }
      }`,
      result({ data, loader, networkStatus }) {
        //this.CategorieSelects.CategorieList = data.listCategories
        this.LangueSelects.LangueList = data.listPays
        this.ManagerSelects.ManagerList = data.listManager
      },
      update: data => data
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ImageUpload,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      Titre: '',
      sous_titre: '',
      loader: false,
      prix_mg_annonceur: null, 
      prix_mg_editeur: null,   
      prix_annonceur: null, // leads
      prix_editeur: null,   // leads
      prix_annonceur_cpc: null,
      prix_editeur_cpc: null,
      prix_annonceur_cpm: null,
      prix_editeur_cpm: null,
      prix_annonceur_cpa: null,
      prix_editeur_cpa: null,
      prix_annonceur_sms: null,
      prix_editeur_sms: null,
      prix_annonceur_cpd: null,
      prix_editeur_cpd: null,
      prix_annonceur_cpa_fixe: null,
      prix_editeur_cpa_fixe: null,
      prix_annonceur_enrichissement: null,
      prix_editeur_enrichissement: null,
      URL_Flux_XML: null,
      Cle_items_XML: null,
      Description: '',
      Repertoire: '',
      Provenance: '',
      tableau: {
        emaling: true,
        Banniere: false,
        Pop_under: false,
        Site_under: false,
        Incentive_clic: false,
        Incentive_remplissage: false,
        Achat_mots_cles: false,
      },
      Retargeting: false,
      images: {
        profile: null
      },
      AnnonceurSelects: {
        Annonceur: '',
        AnnonceurList: []
      },
      CampagneSelects: {
        Campagne: '',
        CampagneList: [
          {value: 'affichage' , label: 'CPC'},
          {value: 'cpm' , label: 'CPM'},
          {value: 'enrichissement' , label: 'ENRICHISSEMENT'},
          {value: 'lead' , label: 'CPL'},
          {value: 'pourcentage des ventes' , label: 'CPA'},
          {value: 'sms' , label: 'SMS'},
          {value: 'téléchargement' , label: 'CPD'},
          {value: 'vente fixe' , label: 'CPA FIXE'}
        ]
      },
      StatutSelects: {
        Statut: '',
        StatutList: [
          {value: 'AUCUN' , label: 'AUCUN'},
          {value: 'BRONZE' , label: 'BRONZE'},
          {value: 'SILVER' , label: 'SILVER'},
          {value: 'GOLD' , label: 'GOLD'}
        ]
      },
      FormatCheckboxes: {
        Mailing: true,
        Banniere : false,
        Flux: false,
        Sms: false
      },
      CategorieSelects: {
        Categorie: '',
        CategorieList: Thematiques
      },
      LangueSelects: {
        Langue: '',
        LangueList: []
      },
      ManagerSelects: {
        Manager: '',
        ManagerList: []
      },
      EtatSelects: {
        Etat: '',
        EtatList: [
          {value: 'pause' , label: 'Pause'},
          {value: 'supprime' , label: 'Supprimé'},
          {value: 'valide' , label: 'Valide'}
        ]
      },
      VisibleSelects: {
        Visible: '',
        VisibleList: [
          {value: true , label: 'Oui'},
          {value: false , label: 'Non'}
        ]
      },
      tableData: [{
        autorization: "AUTORISÉ"
      }]
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onImageChange(file) {
      this.images.profile = file;
    },
    async addProgram() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      
      this.loader = true
      let Compagne = this.CampagneSelects.Campagne.join(",")
      let autorise = ''
      let no_autorise = ''
      let format = ''
      if (this.FormatCheckboxes.Mailing)
        format += 'mailing,'
      if (this.FormatCheckboxes.Banniere)
        format += 'bannière,'
      if (this.FormatCheckboxes.Flux)
        format += 'flux xml,'
      if (this.FormatCheckboxes.Sms)
        format += 'sms,'


      if(this.tableau.emaling) 
        autorise += 'E-mailing,' 
      else 
        no_autorise += 'E-mailing,'

      if(this.tableau.Banniere) 
        autorise += 'bannière,' 
      else 
        no_autorise += 'bannière,'

      if(this.tableau.Pop_under) 
        autorise += 'pop-under,' 
      else 
        no_autorise += 'pop-under,'
      
      if(this.tableau.Site_under) 
        autorise += 'site-under,' 
      else 
        no_autorise += 'site-under,'

      if(this.tableau.Incentive_clic) 
        autorise += 'Incentive au clic,' 
      else 
        no_autorise += 'Incentive au clic,'
      
      if(this.tableau.Incentive_remplissage) 
        autorise += 'incentive au remplissage,' 
      else 
        no_autorise += 'incentive au remplissage,'

      if(this.tableau.Achat_mots_cles) 
        autorise += 'achat de mots cles,' 
      else 
        no_autorise += 'achat de mots cles,'

      this.$apollo.mutate({
        mutation: gql`mutation (
            $file: Upload,
            $id_client: Int!,
            $Titre: String!,
            $sous_titre: String,
            $campagne: String!,
            $format: String,
            $categorie: String!,
            $langue: String!,
            $etat: String!,
            $manager: String!,
            # $prix_mg_annonceur: Float,
            # $prix_mg_editeur: Float,
            $prix_annonceur: Float,
            $prix_editeur: Float,
            $prix_annonceur_cpc: Float,
            $prix_editeur_cpc: Float,
            $prix_annonceur_cpm: Float,
            $prix_editeur_cpm: Float,
            $prix_annonceur_cpa: Float,
            $prix_editeur_cpa: Float,
            $prix_annonceur_sms: Float,
            $prix_editeur_sms: Float,
            $prix_annonceur_cpd: Float,
            $prix_editeur_cpd: Float,
            $prix_annonceur_cpa_fixe: Float,
            $prix_editeur_cpa_fixe: Float,
            $prix_annonceur_enrichissement: Float,
            $prix_editeur_enrichissement: Float,  
            $description: String,
            $provenance: String,
            $statut: String!,
            $id_manager: Int!,
            $url_flux: String,
            $item_childs: String,
            $autorise: String,
            $no_autorise: String,
            $retargeting: Int,
            $display: Boolean,
            $type: Boolean
          ) {
          createProgramme(
                            file: $file,
                            id_client: $id_client,
                            Titre: $Titre,
                            sous_titre: $sous_titre,
                            campagne: $campagne,
                            format: $format,
                            categorie: $categorie,
                            langue: $langue,
                            etat: $etat,
                            manager: $manager,
                            # prix_mg_annonceur: $prix_mg_annonceur,
                            # prix_mg_editeur: $prix_mg_editeur,
                            prix_editeur: $prix_editeur,
                            prix_annonceur: $prix_annonceur,
                            prix_annonceur_cpc: $prix_annonceur_cpc,
                            prix_editeur_cpc: $prix_editeur_cpc,
                            prix_annonceur_cpm: $prix_annonceur_cpm,
                            prix_editeur_cpm: $prix_editeur_cpm,
                            prix_annonceur_cpa: $prix_annonceur_cpa,
                            prix_editeur_cpa: $prix_editeur_cpa,
                            prix_annonceur_sms: $prix_annonceur_sms,
                            prix_editeur_sms: $prix_editeur_sms,
                            prix_annonceur_cpd: $prix_annonceur_cpd,
                            prix_editeur_cpd: $prix_editeur_cpd,
                            prix_annonceur_cpa_fixe: $prix_annonceur_cpa_fixe,
                            prix_editeur_cpa_fixe: $prix_editeur_cpa_fixe,
                            prix_annonceur_enrichissement: $prix_annonceur_enrichissement,
                            prix_editeur_enrichissement: $prix_editeur_enrichissement,  
                            autorise: $autorise,
                            no_autorise: $no_autorise,
                            description: $description,
                            location: "",
                            pixel: "",
                            provenance: $provenance,
                            type: $type,
                            tracking_securise: true,
                            display: $display,
                            NbrCat: 0,
                            send_mail: false,
                            retargeting: $retargeting,
                            statut: $statut,
                            id_manager: $id_manager,
                            url_flux: $url_flux,
                            item_childs: $item_childs
                          ) 
          {
            id_client
            id_programme
            Titre
            sous_titre
          }
        }`,
        variables: {
          type: !this.VisibleSelects.Visible,
          display: this.VisibleSelects.Visible,
          file: this.images.profile,
          id_client: this.AnnonceurSelects.Annonceur,
          Titre: this.Titre,
          sous_titre: this.sous_titre,
          campagne: Compagne,
          format: format.slice(0,format.length-1),
          lien: this.lien,
          categorie: this.CategorieSelects.Categorie,
          langue: this.LangueSelects.Langue,
          etat: this.EtatSelects.Etat,
          manager: this.ManagerSelects.Manager.split("SPLITE")[0],
          autorise: autorise.slice(0, autorise.length-1),
          no_autorise: no_autorise.slice(0, no_autorise.length-1),
          statut: this.StatutSelects.Statut,
          url_flux: this.URL_Flux_XML,
          item_childs: this.Cle_items_XML,
          id_manager: this.ManagerSelects.Manager.split("SPLITE")[1],
          description: this.Description,
          retargeting: (this.Retargeting) ? 1 : 0,
          // prix_mg_annonceur: this.prix_mg_annonceur,
          // prix_mg_editeur: this.prix_mg_editeur,
          // prix
          prix_annonceur: this.prix_annonceur,
          prix_editeur: this.prix_editeur,
          prix_annonceur_cpc: this.prix_annonceur_cpc,
          prix_editeur_cpc: this.prix_editeur_cpc,
          prix_annonceur_cpm: this.prix_annonceur_cpm,
          prix_editeur_cpm: this.prix_editeur_cpm,
          prix_annonceur_cpa: this.prix_annonceur_cpa,
          prix_editeur_cpa: this.prix_editeur_cpa,
          prix_annonceur_sms: this.prix_annonceur_sms,
          prix_editeur_sms: this.prix_editeur_sms,
          prix_annonceur_cpd: this.prix_annonceur_cpd,
          prix_editeur_cpd: this.prix_editeur_cpd,
          prix_annonceur_cpa_fixe: this.prix_annonceur_cpa_fixe,
          prix_editeur_cpa_fixe: this.prix_editeur_cpa_fixe,
          prix_annonceur_enrichissement: this.prix_annonceur_enrichissement,
          prix_editeur_enrichissement: this.prix_editeur_enrichissement,
        },
      }).then(response => {
        this.loader = false
        this.$notify({type: 'success', message: this.$t("apiresponse.prgramcreated") })
      }).catch(error => {
        this.loader = false
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
      })
    }
  }
}
</script>