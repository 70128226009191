<template>
  <div class="row">
    <div class="col-lg-7">
      <card>
        <form @submit.prevent data-vv-scope="ScopeCampagne">
          <base-input label="SUPPORT *" :error="getError('ScopeCampagne.support_m')">
            <el-select
              filterable
              clearable
              multiple
              v-model="supportsList.multisupport"
              placeholder="CHOISIR"
              class="select-primary"
              name="support_m"
              v-validate="'required'"
              @change="changeSelect"
            >
            <el-option 
                class="select-primary"
                value="ALL_SELECT"
                label="TOUT SÉLECTIONNER"
              >
              </el-option>
              <hr/>
              <el-option
                v-for="support in supportsList.supports"
                class="select-primary"
                :label="support.nom_support.toUpperCase()"
                :value="support.nom_support"
                :key="support.id_support"
              ></el-option>
            </el-select>
            <!-- <a target="_blank" :href="'/myaffil/admin/list_supports/'+id_utilisateur">AJOUTER UN NOUVEAU SUPPORT</a href="#"> -->
            <label style="cursor: pointer;" @click.prevent="AddSupportShow = true">AJOUTER UN NOUVEAU SUPPORT</label>
          </base-input>

          <base-input label="PROGRAMME *" :error="getError('ScopeCampagne.programme')">
            <el-select
              filterable
              clearable
              v-model="programmesList.programme"
              placeholder="CHOISIR"
              class="select-primary"
              name="programme"
              v-validate="'required'"
            >
              <el-option
                v-for="programme in programmesList.programmes"
                class="select-primary"
                :label="programme.provenance.toUpperCase()"
                :value="programme.provenance"
                :key="programme.id_programme"
              ></el-option>
            </el-select>
          </base-input>

          <base-input label="TYPE *" :error="getError('ScopeCampagne.type')">
            <el-select
              v-model="typeVisuelList.visuel"
              placeholder="CHOISIR"
              class="select-primary"
              name="type"
              v-validate="'required'"
            >
              <el-option
                v-for="visuel in typeVisuelList.visuels"
                class="select-primary"
                :label="visuel.toUpperCase()"
                :value="visuel"
                :key="visuel"
              ></el-option>
            </el-select>
          </base-input>

          <base-input label="TYPE DE DIFFUSION *" :error="getError('ScopeCampagne.type de diffusion')">
            <el-select
              v-model="campagnesTypesList.type"
              placeholder="CHOISIR"
              class="select-primary"
              name="type de diffusion"
              v-validate="'required'"
            >
              <el-option
                v-for="type in campagnesTypesList.types"
                class="select-primary"
                :label="type.type_2.toUpperCase()"
                :value="type.id_type_compagne"
                :key="type.id_type_compagne"
              ></el-option>
            </el-select>
          </base-input>
          <template v-if="role == 'ad'">
            <base-input
              v-model="prix_lead"
              label="RÉMUNÉRATION ÉDITEUR"
              type="number"
              placeholder="RÉMUNÉRATION ÉDITEUR"
            ></base-input>
          </template>

          <template v-else>
            <base-input label="RÉMUNÉRATION ÉDITEUR">
              <el-select
                v-model="prix_lead"
                class="select-primary"
                filterable
              >
                <el-option
                  v-for="type in listprix_lead"
                  class="select-primary"
                  :value="type"
                  :key="type"
                ></el-option>
              </el-select>
            </base-input>
          </template>
          

          <base-input
            v-model="prix_annonceur"
            label="RÉMUNÉRATION ANNONCEUR"
            type="number"
            placeholder="RÉMUNÉRATION ANNONCEUR"
          ></base-input>
          <template v-if="check_exp_obj">
            <base-button native-type="submit" v-if="!load" type="primary" @click="addMultiCampagnes()">{{ $t("buttons.BtnCreate").toUpperCase() }}</base-button>
            <base-button type="primary" v-else loading></base-button>
          </template>
          <template v-else>
            <base-button native-type="submit" v-if="!load" type="primary" disabled>{{ $t("buttons.BtnCreate").toUpperCase() }}</base-button>
            <base-button type="primary" v-else loading></base-button>
          </template>  
        </form>
      </card>
    </div>
    <div class="col-lg-5">
      <card type="timeline" class="card-plain">
        <time-line type="simple">
          <time-line-item inverted badge-type="primary" badge-icon="tim-icons el-icon-view">
            <template slot="content" v-if="visuel_img">
              <div class="prog">
                <img
                  slot="image"
                  class="card-img-top"
                  :src="visuel_img"
                  alt="Card image cap"
                />
              </div>
              <br/>
              <center>
                <p>
                  <strong>Expéditeur :</strong>
                  {{ expediteur }}
                  <br />
                  <strong>Objet :</strong>
                  {{ objet }}
                  <br />
                  <br />
                  <label style="text-size: 30px; font-weight: bold; color: red" v-if="check_spam">SPAM MOT</label>
                  <label style="text-size: 30px; font-weight: bold; color: green" v-else>SPAM MOT</label>
                  <br />
                  <strong>kit-mail :</strong>
                  {{ kit_mail_check }}
                  <br />
                  <strong>Objet :</strong>
                  {{ object_check }}
                  <br />
                  <strong>Objet2 :</strong>
                  {{ object2_check }}
                  <br />
                </p>
              </center>
            </template>
          </time-line-item>
        </time-line>
      </card>
    </div>
    <!-- ADD SUPPORT modal -->
    <modal
      :show.sync="AddSupportShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="AddSupportShow = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
          
        </template>
        <h3 slot="header" class="modal-title">
          AJOUTER UN SUPPORT
        </h3>
        <form @submit.prevent="addSupport" data-vv-scope="ScopeSupport">
          <div class="col-sm-12">
            <base-input label="NOM DE SUPPORT" v-model="support.nom_support" 
              name="support"
              v-validate="{ required: true, supportname: true }"
            :error="getError('ScopeSupport.support')"></base-input>
          </div>

          <div class="col-sm-12">
            <base-input label="TYPE DE SUPPORTS" :error="getError('ScopeSupport.type')">
              <el-select multiple class="select-primary"
                placeholder="TYPE DE SUPPORTS"
                filterable
                v-model="support.support_types"
                name="type"
                v-validate="'required'">
                <el-option 
                  v-for="option in supportTypes"
                  class="select-primary"
                  :value="option.value"
                  :label="option.type.toUpperCase()"
                  :key="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-sm-12">
            <base-input type="number" label="AUDIENCE" v-model="support.audience"
              name="volume"
              v-validate="'required'"
            :error="getError('ScopeSupport.volume')"></base-input>
          </div>

          <div class="col-sm-12">
            <base-input label="URL DU MEDIA" placeholder="https://..." v-model="support.url"></base-input>
          </div>

          <div class="col-sm-12">
            <base-input label="PAYS" :error="getError('ScopeSupport.pays')">
              <el-select filterable class="select-primary"
                placeholder="PAYS"
                clearable
                v-model="support.pays"
                name="pays"
                v-validate="'required'"
                >
                <el-option 
                  v-for="option in listPays"
                  class="select-primary"
                  :value="option.name"
                  :label="option.name.toUpperCase()"
                  :key="option.iso2">
                </el-option>
              </el-select>
            </base-input>
          </div>

          
          
          <div class="col-sm-12">
            <base-input label="APPETENCES" :error="getError('ScopeSupport.thematique')">
              <el-select
                class="select-primary"
                placeholder="APPETENCES"
                v-model="support.thematique"
                filterable
                clearable
                name="thematique"
                v-validate="'required'"
              >
                <el-option
                  v-for="option in ListThematique"
                  class="select-primary"
                  :value="option.categorie"
                  :label="option.categorie.toUpperCase()"
                  :key="option.id"
                ></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-info"
              @click.native="addSupport"
              :disabled='disabledaddSupport'
              >AJOUTER
            </button>
          </div>
        </form>
      </card>
    </modal>
    <!-- ADD SUPPORT modal -->
  </div>
</template>

<script>
import gql from "graphql-tag";
import { Select, Option } from "element-ui";
import { Modal } from "src/components";
import { Thematiques } from "src/pages/Filters/Thematiques";
import swal from 'sweetalert2';
import {
  BaseSwitch,
  TimeLine,
  TimeLineItem,
  BaseCheckbox
} from "src/components";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSwitch,
    TimeLine,
    TimeLineItem,
    BaseCheckbox,
    Modal
  },
  apollo: {
    liste_supports: {
      query: gql`
        query($id_utilisateur: ID) {
          liste_supports(id_client: $id_utilisateur) {
            nom_support
            id_support
            support_types
          }
        }
      `,
      update: data => data.liste_supports,
      result({ data, loader, networkStatus }) {
        this.supportsList.supports = data.liste_supports;
      },
      variables() {
        return {
          id_utilisateur: this.id_utilisateur
        };
      }
    },
    showMembreData: {
      query: gql`
        query($id_client: ID) {
          campagnes_programmes(id_editeur: $id_client) {
            provenance
            id_programme
          }
          campagnes_types {
            id_type_compagne
            type_2
          }
        }
      `,
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.programmesList.programmes = data.campagnes_programmes;
        this.campagnesTypesList.types = data.campagnes_types;
      },
      variables() {
        return {
          id_client: this.id_client
        };
      }
    },
    typeVisuel: {
      query: gql`
        query($produit: String, $typevisuel: String, $id_editeur: Int!) {
          type_visuel(produit: $produit, id_editeur: $id_editeur) {
            taille
            prix_editeur
            prix_annonceur
            marge_min
          }
          show_visuel_img(produit: $produit, typevisuel: $typevisuel) {
            src
            expediteur
            objet
          }
          show_spam_word(produit: $produit, typevisuel: $typevisuel) {
            email
            objet
            objet2
          }
        }
      `,
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.one_exc++
        
        this.typeVisuelList.visuels = data.type_visuel.taille;
        if (this.role == 'ad') {
          this.prix_lead = data.type_visuel.prix_editeur;
        }
        this.prix_annonceur = data.type_visuel.prix_annonceur;
        this.visuel_img = data.show_visuel_img.src;
        this.expediteur = data.show_visuel_img.expediteur;
        this.objet = data.show_visuel_img.objet;
        this.kit_mail_check = (data.show_spam_word.email !== undefined && data.show_spam_word.email !== null) ? data.show_spam_word.email : [];
        this.objet_check = (data.show_spam_word.objet !== undefined && data.show_spam_word.objet !== null) ? data.show_spam_word.objet : [];
        this.object2_check = (data.show_spam_word.object2 !== undefined && data.show_spam_word.object2 !== null) ? data.show_spam_word.object2 : [];

        if(this.kit_mail_check.length == 0 && this.objet_check.length == 0 && this.object2_check.length == 0) {
          this.check_spam = false;
        } else { 
          this.check_spam = true;
        }

        if((this.expediteur == '' || this.objet == '') && this.visuel_img != '' && this.one_exc == 1) {
          this.$notify({ type: "warning", message: "Veuillez renseigner l'objet et l'expéditeur pour ce visuel." , duration: 4000 });
          this.check_exp_obj = false
        } else if ((this.expediteur == '' || this.objet == '') && this.visuel_img != '') {
          this.check_exp_obj = false
        }else {
          this.check_exp_obj = true
        }
        
        if(this.one_exc > 1){
          this.one_exc = 0
        }
        if (this.role != 'ad') {
          let is_programme = this.programmesList.programme
          if(this.programmesList.programme.includes('.')) {
            is_programme = this.programmesList.programme.substring(0, this.programmesList.programme.indexOf('.'));
          }
          this.listprix_lead = []
          this.prix_lead = 0
          if(this.sante_arr.includes(is_programme.toUpperCase().replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, ''))){
            let p = 8;
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          } else if(this.animaux_arr.includes(is_programme.toUpperCase().replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, ''))){
            let p = 7;
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          } else if(this.mrh_arr.includes(is_programme.toUpperCase().replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, ''))){
            let p = 7;
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          } else if(this.auto_arr.includes(is_programme.toUpperCase().replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, ''))){
            let p = 8;
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          } else if(this.emprunteur_arr.includes(is_programme.toUpperCase().replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, ''))){
            let p = 15;
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          } else {
            let p = (this.prix_annonceur - (this.prix_annonceur * data.type_visuel.marge_min / 100)).toFixed(2)
            this.listprix_lead.push(parseFloat(p))
            while (p > 0.01) {
              p = (p - 0.01).toFixed(2);
              this.listprix_lead.push(parseFloat(p))
            }
          }
        }
      },
      variables() {
        return {
          produit: this.programmesList.programme,
          typevisuel: this.typeVisuelList.visuel,
          id_editeur: this.id_client
        };
      }
    },
    listPays: {
      query: gql`
        query {
          listPays(iso2:""){
            iso2
            name
          }
        }
      `
    }
  },
  data() {
    return {
      id_auth : JSON.parse(localStorage.getItem('utilisateur')).id,
      AddSupportShow: false,
      disabledaddSupport:  false,
      support: {
        nom_support: "",
        audience: "",
        pays: "",
        support_types: [],
        thematique: "",
        url: ""
      },
      sante_arr : [
        '1MUTUELLE',
        'ALINE ASSURANCES FB',
        'ARBORIA SANTE',
        'ARBORIA SANTE SENIOR',
        'DFS',
        'EASY-MUTUELLE',
        'LM ASSURANCES',
        'MATEVA ASSURANCE',
        'MATEVA ASSURANCE SENIOR',
        'MELIA MUTUELLE',
        'MON GUSTAVE',
        'MON GUSTAVE SANTE',
        'MUTUELLE INTEGRALE',
        'MUTUELLE INTEGRALE SENIOR',
        'MUTUELLEDUNETCOM',
        'SANTEVIES',
        'SERENA SANTE',
        'VIVA MUTUELLE'
      ],
      animaux_arr : [
        'ASSUR ANIMAUX',
        'COSMO ASSURANCES',
        'DOCTANIMO',
        'FELIX ASSURANCE',
        'IDEFIX ASSURANCE',
        'LEANIMO',
        'MON GUSTAVE ANIMAUX',
        'MON GUSTAVE ANIMAUX LANDBOT',
        'MON GUSTAVE ANIMAUX MB',
        'NOS ANIMAUX',
        'ROUXYCARE',
        'SANTENIMO',
        'SANTENIMO2',
        'SO MUTUELLE ANIMAUX',
        'VETOLIA'
      ],
      mrh_arr : [
        'ASSUR LOGEMENT',
        'ASSURHOME',
        'HABITASSUR',
        'MON GUSTAVE HABITATION',
        'MON GUSTAVE MRH',
        'OSIA HABITATION',
        'VESTALIA',
        'VOTRE LOGEMENT',
        'ASSUR HABITATION'
      ],
      emprunteur_arr : [
      'ASSUR EMPRUNT',
      'ASSUR PRET',
      'ASSURANCE DE  PRET',
      'MON GUSTAVE EMPRUNTEUR'
      ],
      auto_arr : [
        'ASSURER MA VOITURE',
        'ASSURTITINE',
        'AUTODRIVE',
        'CARASSUR',
        'MON GUSTAVE AUTO',
        'VIAUTOLM',
        'VOITURANCE',
      ],
      supportTypes: [
        {value: '1', type:'APPLI MOBILE'},
        {value: '2', type:'BASE EMAILS'},
        {value: '3', type:'BASE SMS'},
        {value: '4', type:'COMPARATEUR DE PRIX'},
        {value: '5', type:'DISPLAY'},
        {value: '6', type:'EMULATION'},
        {value: '7', type:'FACEBOOK'},
        {value: '8', type:'INSTAGRAM'},
        {value: '9', type:'LINKEDIN'},
        {value: '10', type:'PUSH NOTIFS'},
        {value: '11', type:'RETARGETING'},
        {value: '12', type:'SNAPCHAT'},
        {value: '13', type:'SEO/SEA'},
        {value: '14', type:'TWITTER'},
        {value: '15', type:'YOUTUBE'}
      ],
      ListThematique: Thematiques,
      role: JSON.parse(localStorage.getItem('utilisateur')).law,
      listprix_lead: [],
      load: false,
      skip: true,
      supportsList: {
        multisupport:[],
        support: "",
        supports: []
      },
      programmesList: {
        programme: "",
        programmes: []
      },
      typeVisuelList: {
        visuel: "",
        visuels: []
      },
      campagnesTypesList: {
        type: 1,
        types: []
      },
      id_utilisateur: null,
      id_client: null,
      error_support_check : '',
      valide_support_check : [],
      error_query_check : '',
      valide_query_check : '',
      prix_lead: "",
      prix_annonceur: "",
      visuel_img: "",
      expediteur: "",
      objet: "",
      check_exp_obj: true,
      one_exc : 0,
      kit_mail_check: [],
      object_check: [],
      object2_check: [],
      check_spam : false
    };
  },
  methods: {
    changeSelect(value) {
      const allValues = []
      for (const item of this.supportsList.supports) {
        allValues.push(item.nom_support)
      }
      if (value.includes('ALL_SELECT')) {
        this.supportsList.multisupport = allValues
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async addMultiCampagnes() {
      this.valide_query_check = ''
      this.error_query_check = ''
      this.error_support_check = ''
      this.valide_support_check= []
      let validate
      await this.$validator.validateAll("ScopeCampagne").then(isValid => {
        validate = isValid
      })
      if (!validate) return
      for (let index = 0; index < this.supportsList.multisupport.length; index++) {
        this.supportsList.support = this.supportsList.multisupport[index]
        
        if(!this.supportsList.supports.find(x => x.nom_support === this.supportsList.support).support_types) {
          this.error_support_check += "<p style='font-size: 15px;'>"+this.supportsList.support+"<p>"
        } else {
          this.valide_support_check.push(this.supportsList.support)
        }
        
        //this.$notify({ type: "warning", message: "les informations de support n'est pas renseigné", duration: 4000 });
          
      }
      console.log(this.error_support_check,this.valide_support_check)
      this.load = true
      for (let index = 0; index < this.valide_support_check.length; index++) {
        this.supportsList.support = this.valide_support_check[index]      
        await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation(
              $id_client: Int!
              $code: String!
              $prix_editeur: Float!
              $id_type_compagne: Int!
              $prix_annonceur: Float!
            ) {
              createCampagneW(
                id_client: $id_client
                code: $code
                prix_editeur: $prix_editeur
                id_type_compagne: $id_type_compagne
                prix_annonceur: $prix_annonceur
              ) {
                id_campagne
                is_eca
              }
            }
          `,
          variables: {
            prix_editeur: this.prix_lead,
            prix_annonceur: this.prix_annonceur,
            id_type_compagne: this.campagnesTypesList.type,
            code:
              this.supportsList.support +
              "_" +
              this.programmesList.programme +
              "_" +
              this.typeVisuelList.visuel,
            id_client: this.id_client
          }
        })
        .then(data => {
          this.valide_query_check += "<p style='font-size: 15px;'>"+this.supportsList.support +"_" +this.programmesList.programme +"_" +this.typeVisuelList.visuel+" : Campagne créée avec succès</p>";

          if (data.data.createCampagneW.is_eca === true) {
            this.logTag();
          }

          
          /*setTimeout(() => {
            this.$router.push({ name:'liste_campagnes', params: {id_client: this.id_client} });
          }, 2000);*/
        
        })
        .catch(error => {
          if (error.graphQLErrors[0].extensions.code === "CAM404_1") {
            this.error_query_check += "<p style='font-size: 15px;'>"+this.supportsList.support +"_" +this.programmesList.programme +"_" +this.typeVisuelList.visuel+" : La campagne existe déjà dans notre base</p>";
            //this.$notify({ type: "warning", message: "La campagne existe déjà dans notre base", duration: 4000 });
          }
          else {
            this.error_query_check += "<p style='font-size: 15px;'>"+this.supportsList.support +"_" +this.programmesList.programme +"_" +this.typeVisuelList.visuel+" : erreur inattendue</p>";
            //this.$notify({ type: "warning", message: this.$t("apiresponse.errorcreation"), duration: 4000 });
          }
        });
        
      }
      this.load = false

      if(this.valide_query_check == '') {
        this.valide_query_check = "<p style='font-size: 15px;'> AUCUNE </p>"
      }
      if(this.error_query_check == '') {
        this.error_query_check = "<p style='font-size: 15px;'> AUCUNE </p>"
      }
      if(this.error_support_check == '') {
        this.error_support_check = "<p style='font-size: 15px;'> AUCUNE </p>"
      }

      let result = await swal({
        background: '#1E1E2B',
        title: 'CRÉATION DES CAMPAGNES',
        html:
          '<div class="form-group">' +
          '<h3 style="color: green">Campagnes créées</h3>' +
          this.valide_query_check +
          "<h3 style='color: red'>Campagnes non créées</h3>" +
           this.error_query_check+
          "<h3 style='color: red'>Supports non renseignés</h3>" +
           this.error_support_check +
          '</div>',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-info',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: 'liste campagnes',
        cancelButtonText: 'fermer',
        buttonsStyling: false
      });
      if (result.value) {
        this.$router.push({ name:'liste_campagnes', params: {id_client: this.id_client} });
      }
    },
    async addSupport(){
      let validate
      await this.$validator.validateAll("ScopeSupport").then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.disabledaddSupport = true
      this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation(
              $id_utilisateur: Int!
              $audience: Int!
              $pays: String!
              $thematique: String!
              $nom_support: String!
              $support_types: String!
            ) {
            addNewSupport(
              id_utilisateur: $id_utilisateur
              audience: $audience
              pays: $pays
              thematique: $thematique
              nom_support: $nom_support
              support_types: $support_types
            ) {
              id_support
              id_client
              nom_support
              support_types
              url_media
              pays
              audience
              etat
              thematique
              date_creation
            }
          }
        `,
        variables: {
          id_utilisateur: this.id_utilisateur,
          audience: this.support.audience,
          pays: this.support.pays,
          thematique: this.support.thematique,
          nom_support: this.support.nom_support,
          support_types: this.support.support_types.join(';')
        }
      }).then(data => {
        this.$notify({ type: "success", message: "LE SUPPORT A BIEN ÉTÉ AJOUTE" });
        this.$apollo.queries.liste_supports.refetch({
          id_utilisateur: this.id_utilisateur
        })
        this.AddSupportShow = false
        this.disabledaddSupport = false
      })
      .catch(error => {
        // Error
        if (error.graphQLErrors[0].extensions.code === "SUPPORT404_1") {
          this.$notify({type: 'warning', message: "Le support existe déjà dans notre base." })
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        }
        this.disabledaddSupport = false
      });
    },
    async logTag(){
      let url = 'https://mutuelle-sante.self-assurance.fr/devis/insertionCampagneWeeDoIt?provenance=' + this.supportsList.support + "_" + this.programmesList.programme + "_" + this.typeVisuelList.visuel;
      let result = "";
     
     await this.axios.get('https://mutuelle-sante.self-assurance.fr/devis/insertionCampagneWeeDoIt?provenance=' + this.supportsList.support + "_" + this.programmesList.programme + "_" + this.typeVisuelList.visuel)
      .then(function (response) {
        // handle success
        result = response.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
      this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation(
              $url: String
              $result: String
            ) {
            addLogsTagSelfAssurance(
              url: $url
              result: $result
            ) {
              message
            }
          }
        `,
        variables: {
          url: url,
          result: result,
        }
      }).then(data => {
        console.log("LE log resulta self assurance A BIEN ÉTÉ AJOUTE");
      })
      .catch(error => {
        // Error
          console.log(this.$t("apiresponse.errorcreation"));
      });
    },
  },
  created(){
    this.id_utilisateur = localStorage.getItem("add_campagne_id_utilisateur");
    this.id_client = localStorage.getItem("add_campagne_id_client");
  }
};
</script>

<style scoped>
.prog {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
</style>