<template >
	<div class="container-fluid">
		<div class="btn-group btn-group-toggle" data-toggle="buttons" v-if="email === 'n.khallouki@weedoit.fr' || email === 'a.gorani@weedoit.fr'">
			<router-link
				class="btn btn-sm btn-info btn-simple"
				:class="$route.name == 'Statistiques Push Notifications' ? 'active' : ''"
				id="0"
				data-toggle="collapse"
				href="#collapseone"
				aria-expanded="false"
				aria-controls="collapseExample"
				:to="{ name: 'Statistiques Push Notifications' }"
			>
          <input type="radio" name="options" checked />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnStatsGlobal").toUpperCase() }}</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-chart-pie-36"></i>
        </span>
			</router-link>
			<router-link
				class="btn btn-sm btn-info btn-simple"
				:class="$router.name == 'Logs Push Notifications' ? 'active' : ''"
				id="1"
				data-toggle="collapse"
				href="#collapsetwo"
				aria-expanded="false"
				aria-controls="collapseExample"
				:to="{ name: 'Logs Push Notifications' }"
			>
				<input type="radio" class="d-none d-sm-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnStatsDetails").toUpperCase() }}</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-chart-pie-36"></i>
        </span>
			</router-link>
		</div>
		<p>&nbsp;</p>
		<zoom-center-transition :duration="200" mode="out-in">
			<router-view></router-view>
		</zoom-center-transition>
	</div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";

export default {
	components: {
		ZoomCenterTransition
	},
  data(){
    return {
      email: ''
    }
  },
  created(){
    this.email = JSON.parse(localStorage.getItem('utilisateur')).email
  }
};
</script>
