<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("buttons.BtnStatsGlobal").toUpperCase() }}</h2>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <base-input>
                      <el-select
                        class="select-primary"
                        placeholder="PÉRIODE"
                        v-model="PeriodeSelects.Periode"
                        :disabled="!hideChart"
                      >
                        <el-option
                          v-for="option in PeriodeSelects.ListPeriode"
                          class="select-primary"
                          v-if='option.annee != 1970 && option.annee != 1999'
                          :value="option.numero_mois+ ' '+option.annee"
                          :label="option.mois.toUpperCase() +' '+ option.annee"
                          :key="option.value"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <!-- <div class="col-md-8">
                    <button
                      class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                      @click="send()"
                      v-if="Flag"
                    >RÉINITIALISER</button>
                    <base-button v-else loading type="primary" class="mb-2 pull-right"></base-button>
                  </div> -->
                </div>
              </div>
              <div class="col-sm-2">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: ChartStatGlobal.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="ChartStatGlobal.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <line-chart
              v-if='hideChart'
              :labels="ChartStatGlobal.labels"
              :chart-data="ChartStatGlobal.chartData"
              :extra-options="ChartStatGlobal.extraOptions"
              :height="200"
            >
            </line-chart>
            <center> 
              <base-button v-if='!hideChart' loading type="primary">{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="AllstatsGlobales" show-summary :summary-method="getSummaries">
              <el-table-column
                label="Jour"
                prop="jour"
                width="235"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    {{ scope.row.jour.toUpperCase() }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="Impressions"
                prop="impressions"
                min-width="140"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Clics"
                prop="clics"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Leads"
                prop="leads"
                min-width="100"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    <u>
                      {{ formatThounsends(scope.row.leads) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="Ventes"
                prop="ventes"
                min-width="105"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ventes) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CA"
                prop="ca"
                min-width="100"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CTR"
                prop="CTR"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ECPC"
                prop="ECPC"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ECPC, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <center>
            <base-button v-if='!Flag' loading type="primary"></base-button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from 'element-ui';
import LineChart from 'src/components/Charts/LineChart'
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.info,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.info,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: '#2380f7',
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']

let bigChartData = [
  [],
  []
]

let Mounth = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
]

export default {
  apollo: {
    listPeriode: {
      query: gql`query {
        listPeriode{
          numero_mois
          mois
          annee
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.PeriodeSelects.ListPeriode = data.listPeriode
      }
    },
    statsGlobalesNewTraking: {
      query : gql`query ($day: Int,$month: String!,$year: String!) {
        statsGlobalesNewTraking (
          day: $day
          month: $month
          year: $year,
          droit: "ad"
        )
        {
          flag
          returnDay
          jour
          impressions
          clics
          leads
          ventes
          ECPC
          CTR
          ca
        }        
      }`,
      fetchPolicy: 'no-cache',
      skip(){
        return this.SkipQuery 
      },
      variables() {
        return {
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""   
        }
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobalesNewTraking[0].returnDay
        this.Flag = data.statsGlobalesNewTraking[0].flag
        this.AllstatsGlobales = [...this.AllstatsGlobales,...data.statsGlobalesNewTraking]       
      },
    }
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LineChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      mounth: [ 
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      AllstatsGlobales: [],
      returnDay: null,
      SkipQuery: true,
      Flag: false,
      hideChart: false,
      SUMimpressions: 0,
      SUMclics: 0,
      SUMlead: 0,
      SUMventes: 0,
      SUMECPC: 0,
      SUMca: 0,
      PeriodeSelects: {
        Periode: "",
        ListPeriode: []
      },
      ChartStatGlobal: {
        activeIndex: 0,
        chartData: {
          labels: bigChartLabels,
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[0]
            }
          ],
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 150,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        }
      },
    };
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value : value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.ChartStatGlobal.chartData = chartData;
      this.ChartStatGlobal.activeIndex = index;
    },
    async send() {
      this.hideChart = false
      this.AllstatsGlobales = []
      this.returnDay = null
      this.SkipQuery = false
      this.Flag = false
      while(!this.Flag){
        await this.$apollo.queries.statsGlobalesNewTraking.refetch({
          day: this.returnDay,
          month: this.PeriodeSelects.Periode.split(" ")[0],
          year: this.PeriodeSelects.Periode.split(" ")[1]
        })
      }
      let label = []
      let datalead = []
      let dataCA = []
      let SUMimpressions = 0
      let SUMclics = 0
      let SUMlead = 0
      let SUMventes = 0
      let SUMECPC = 0
      let SUMca = 0
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        label.push(index+1 + "/" + this.AllstatsGlobales[index].jour.split(" ")[2] )
        datalead.push(this.AllstatsGlobales[index].leads)
        dataCA.push(this.AllstatsGlobales[index].ca)
        SUMimpressions += this.AllstatsGlobales[index].impressions
        SUMlead += this.AllstatsGlobales[index].leads
        SUMventes += this.AllstatsGlobales[index].ventes
        SUMECPC += this.AllstatsGlobales[index].ECPC
        SUMca += this.AllstatsGlobales[index].ca
        SUMclics += this.AllstatsGlobales[index].clics
      }
      this.SUMimpressions = SUMimpressions
      this.SUMclics = SUMclics
      this.SUMlead = SUMlead
      this.SUMventes = SUMventes
      this.SUMECPC = SUMECPC
      this.SUMca = SUMca
      bigChartData[0] = dataCA 
      bigChartData[1] = datalead
      bigChartLabels = label
      this.initBigChart(0)
      this.hideChart = true
      this.SkipQuery = true
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMlead)
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMventes) + " €"
          return;
        }
        else if( index === 7) {
          sums[index] = this.formatThounsends(this.SUMECPC, true, 2) + ' €'
          return;
        }
        else if( index === 6) {
          sums[index] = ((this.SUMclics == 0) ? 0 : this.formatThounsends((this.SUMlead/this.SUMclics)*100, true, 2)) + ' %'
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMca, true) + ' €'
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    Details(row) {
      let Details = {}
      Details.jour = row.jour.split(' ')[3] + '/' + (this.mounth.indexOf(row.jour.split(' ')[2]) + 1) + '/' + row.jour.split(' ')[1]
      Details.droit = "ad"
      Details.id_client = null
      Details.id_programme = null
      localStorage.setItem("Details", JSON.stringify(Details))
      let routeData = this.$router.resolve({name: 'stats Detail Leads day admin'});
      window.open(routeData.href, '_blank');   
    }
  },
  async created() {
    this.SkipQuery = false
    while(!this.Flag){
      await this.$apollo.queries.statsGlobalesNewTraking.refetch({
        day: this.returnDay,
        month: (new Date().getUTCMonth() + 1) + "",
        year: new Date().getUTCFullYear() + "",
        droit: 'ad'
      })
    }
    let label = []
    let datalead = []
    let dataCA = []
    let SUMimpressions = 0
    let SUMclics = 0
    let SUMlead = 0
    let SUMventes = 0
    let SUMECPC = 0
    let SUMca = 0

    for (let index = 0; index < this.AllstatsGlobales.length; index++) {
      label.push(index+1 + "/" + this.AllstatsGlobales[index].jour.split(" ")[2])
      datalead.push(this.AllstatsGlobales[index].leads)
      dataCA.push(this.AllstatsGlobales[index].ca)
      SUMimpressions += this.AllstatsGlobales[index].impressions
      SUMlead += this.AllstatsGlobales[index].leads
      SUMventes += this.AllstatsGlobales[index].ventes
      SUMECPC += this.AllstatsGlobales[index].ECPC
      SUMca += this.AllstatsGlobales[index].ca
      SUMclics += this.AllstatsGlobales[index].clics
    }
    this.SUMimpressions = SUMimpressions
    this.SUMclics = SUMclics
    this.SUMlead = SUMlead
    this.SUMventes = SUMventes
    this.SUMECPC = SUMECPC
    this.SUMca = SUMca
    bigChartData[0] = dataCA 
    bigChartData[1] = datalead
    bigChartLabels = label
    this.initBigChart(0)
    this.hideChart = true
    this.SkipQuery = true
  },
  watch: {
    "PeriodeSelects.Periode"() {
      this.send()
    }
  }
};
</script>