<template>
  <div class="content">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <!-- <div class="col-md-12 text-left">
                <h2 class="card-title">EXTRACT EDITEURS</h2>
              </div> -->
              <div class="col-md-12">
                <div class="row">

                  <base-input :error="getError($t('table.columns.Program'))" class="col-md-4">
                    <el-select
                      filterable
                      clearable
                      class="select-primary"
                      :placeholder="$t('table.columns.Program').toUpperCase()"
                      v-model="Programme"
                      :name="$t('table.columns.Program')"
                      v-validate="'required'"
                      :disabled="$apollo.queries.extractEditeur.loading"
                    >
                      <el-option
                        v-for="option in list_programme_detail"
                        class="select-primary"
                        :value="option.provenance"
                        :label="option.provenance.toUpperCase()"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                  <base-input class="col-md-4">
                    <el-date-picker v-model="StartDate" :disabled="$apollo.queries.extractEditeur.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
                  </base-input>
                  <base-input class="col-md-4">
                    <el-date-picker v-model="EndDate" :disabled="$apollo.queries.extractEditeur.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
                  </base-input>

                  <div class="col-md-12 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.extractEditeur.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="p-2" data="black">
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <!-- hide/show colonne -->
            <base-dropdown menu-classes="dropdown-black">
              <template slot="title-container">
                <button class="btn btn-icon btn-round btn-info">
                  <i class="tim-icons el-icon-view"></i>
                </button>
              </template>
              <template v-for="row in rows">
                <div class="dropdown-header">
                  <base-checkbox v-model="row.show">
                    {{ row.label }}
                  </base-checkbox>
                </div>
              </template>
            </base-dropdown>
            <!-- hide/show colonne -->
            <el-table :data="queriedData" v-loading="$apollo.queries.extractEditeur.loading" row-class-name="booking-table-rows">
              <el-table-column
                :label="$t('table.columns.Society')"
                prop="Societe"
                show-overflow-tooltip
                sortable
                min-width="150"
                v-if="rows[0].show"
              ></el-table-column>
              <el-table-column
                label="Tag"
                prop="Tag"
                show-overflow-tooltip
                sortable
                min-width="200"
                v-if="rows[1].show"
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.SendVolume')"
                prop="Volume"
                show-overflow-tooltip
                sortable
                min-width="160"
                v-if="rows[2].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Volume) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Impressions"
                prop="Impressions"
                show-overflow-tooltip
                sortable
                min-width="150"
                v-if="rows[3].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Displays')"
                prop="Affichages"
                show-overflow-tooltip
                sortable
                min-width="150"
                v-if="rows[4].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Affichages) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.TauxClick')"
                prop="TC"
                show-overflow-tooltip
                min-width="150"
                v-if="rows[5].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.TC) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.OpeningRate')"
                prop="TO"
                show-overflow-tooltip
                min-width="180"
                v-if="rows[6].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.TO) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Leads"
                prop="Leads"
                show-overflow-tooltip
                sortable
                min-width="100"
                v-if="rows[7].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Leads) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Transfo"
                prop="Transfo"
                show-overflow-tooltip
                min-width="115"
                v-if="rows[8].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Transfo) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Rem Editeur"
                prop="Rem"
                show-overflow-tooltip
                min-width="135"
                v-if="rows[9].show"
              >
                <template scope="scope">
                  {{ scope.row.Rem + " €" }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Pages.dashboard.ca')"
                prop="ca"
                show-overflow-tooltip
                min-width="100"
                v-if="rows[10].show"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ECPM"
                prop="ECPM"
                show-overflow-tooltip
                min-width="95"
                v-if="rows[11].show"
              >
                <template scope="scope">
                  {{ scope.row.ECPM + ' %' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.EstimatedDateShoot')"
                prop="date_shoot"
                show-overflow-tooltip
                sortable
                min-width="225"
                v-if="rows[12].show"
              >
              </el-table-column>
              <!--
              <el-table-column
                label="Rem editeur HT"
                prop="reh"
                show-overflow-tooltip
                sortable
                min-width="145"
              >
                <template scope="scope">
                  {{ scope.row.reh + " €" }}
                </template>
              </el-table-column>
              <el-table-column
                label="Depenses"
                prop="Depenses"
                show-overflow-tooltip
                sortable
                min-width="115"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Depenses) + ' €' }}
                </template>
              </el-table-column> -->
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui';
  import { BasePagination, BaseDropdown } from "src/components";
  import gql from 'graphql-tag'

  export default {
    apollo: {
      extractEditeur: {
        query: gql`
          query($campagne: String, $date_start: Date, $date_end: Date) {
            extractEditeur(campagne: $campagne, date_start: $date_start, date_end: $date_end){
              Societe
              Tag
              Volume
              Impressions
              Affichages
              TC
              TO
              Leads
              Transfo
              Rem
              ca
              ECPM
              date_shoot
            }
          }
        `,
        skip() {
          return this.skipQuery
        },
        variables() {
          return {
            campagne: this.Programme,
            date_start: this.StartDate,
            date_end: this.EndDate
          };
        },
        result({ data, loader, networkStatus }) {
          this.skipQuery = true
          // let SommeCa = 0
          // let SommeLead = 0
          // data.statLeadMarket.forEach(e => {
          //   SommeCa += parseFloat(e.Depenses)
          //   SommeLead += parseInt(e.vlc)
          // });
          // this.SommeLead = SommeLead
          // this.SommeCa = SommeCa
        },
        fetchPolicy: 'network-only'
      },
      list_programme_detail: {
        query: gql`query {
          list_programme_detail(flag:"details"){
              id_programme
              Titre
              repertoire
              provenance
          }
        }`,
        result({ data, loader, networkStatus }) {
          let regex = /^[a-zA-Z0-9_ ]*.tous$/;
          this.list_programme_detail = this.list_programme_detail.filter((programme) => {
            return programme.provenance.match(regex)
          })
        },
        fetchPolicy: 'network-only'
      },
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      BasePagination,
      [DatePicker.name]: DatePicker,
      BaseDropdown
    },
    data() {
      return {
        StartDate: '',
        EndDate: '',
        SommeCa: 0,
        SommeLead: 0,
        Programme: "",
        skipQuery: true,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [ 50, 150, 300, 500],
          total: 0
        },
        total: 0,
        extractEditeur: [],
        rows: [
          { label: this.$t('table.columns.Society'), show: true },
          { label: 'TAG', show: true },
          { label: this.$t('table.columns.SendVolume'), show: true },
          { label: 'IMPRESSIONS', show: true },
          { label: this.$t('table.columns.Displays'), show: true },
          { label: this.$t('table.columns.TauxClick'), show: false },
          { label: this.$t('table.columns.OpeningRate'), show: false },
          { label: 'LEADS', show: true },
          { label: 'TRANSFO', show: true },
          { label: 'REM EDITEUR', show: true },
          { label: 'CA', show: true },
          { label: 'ECPM', show: true },
          { label: this.$t('table.columns.EstimatedDateShoot'), show: true },
        ]
      }
    },
    computed: {
      queriedData() {
        this.total = this.extractEditeur.length
        return this.extractEditeur.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        this.skipQuery = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value : value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 5) {
            sums[index] = this.formatThounsends(this.SommeLead);
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(this.SommeCa) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      // CustomSort_TC(a, b) {
      //   try {
      //     return parseFloat(a.TC) - parseFloat(b.TC)
      //   } catch (error) {
      //     return -1
      //   }
      // },
      // CustomSort_TO(a, b) {
      //   try {
      //     return parseFloat(a.TO) - parseFloat(b.TO)
      //   } catch (error) {
      //     return -1
      //   }
      // },
      // CustomSort_Transfo(a, b) {
      //   try {
      //     return parseFloat(a.Transfo) - parseFloat(b.Transfo)
      //   } catch (error) {
      //     return -1
      //   }
      // },
      // CustomSort_Rem(a, b) {
      //   try {
      //     return parseFloat(a.Rem) - parseFloat(b.Rem)
      //   } catch (error) {
      //     return -1
      //   }
      // },
      // CustomSort_Ca(a, b) {
      //   try {
      //     return parseFloat(a.ca) - parseFloat(b.ca)
      //   } catch (error) {
      //     return -1
      //   }
      // },
    },
  }
</script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }
  /* .customtable_statsCampEdit .cell{
    word-break: break-word;
    font-size: 11.5px;
  } */

</style>