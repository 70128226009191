<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">
              <base-input class="col-md-3">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('buttons.BtnSearch').toUpperCase()"
                  v-model="search"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
              <div class="col-md-3">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MANAGER"
                    :disabled="$apollo.queries.demandesEnAttente.loading"
                    v-model="ManagerSelects.Manager"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.email_client"
                      :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <base-button
                    v-if="!Flag"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <button
                    v-else
                    class="btn btn-round btn-just-icon btn-primary pull-right"
                  >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card v-if="!$apollo.queries.listManager.loading">
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterRequest" class="customtable">
              <el-table-column 
                label="Clients" 
                prop="societe_client" 
                class="table-striped" 
                min-width="150"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column
                label="Bases"
                prop="nom_base"
                class="table-striped"
                min-width="150"
                sortable
                show-overflow-tooltip
                >
              </el-table-column>
              <el-table-column 
                :label="$t('table.columns.Product')"
                prop="provenance" 
                class="table-striped"
                min-width="150"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column 
                label="TM" 
                prop="manager" 
                class="table-striped" 
                min-width="120"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column 
                label="type"
                prop="id_type_compagne"  
                min-width="100"
                class="table-striped"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column
                label="Date"  
                prop="tmp" 
                min-width="150"
                class="table-striped" 
                show-overflow-tooltip
                sortable>
                <template scope="scope">
                  {{ scope.row.tmp.split(" ")[0] }}
                </template>
              </el-table-column>
              <el-table-column  
                label="SPAM/Fraude" 
                prop="nbr_plaintes"
                min-width="150"
                class="table-striped" 
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="150">
                <template scope="scope">
                  <el-tooltip
                    content="VALIDER"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="AcceptReq(scope.row)"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-check-2"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                    content="REFUSER"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="RefuseReq(scope.row)"
                      class="like btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BasePagination } from "src/components";
import swal from 'sweetalert2'

export default {
  apollo: {
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
      }
    },
    demandesEnAttente: {
      query: gql`
        query ($page: Int ,$count: Int){
          demandesEnAttente (page: $page, count: $count) {
            id_postule
            id_client
            id_bases
            societe_client
            cat
            tmp
            id_type_compagne
            id_programme
            provenance
            nbr_plaintes
            nom_base
            langue
            manager
          }
        }`,
        fetchPolicy: 'no-cache',
        skip() {
          return this.SkipQuery
        },
        result({ data, loader, networkStatus }) {
          this.Flag = (data.demandesEnAttente.length == 0)
          this.AllRequests  = [...this.AllRequests,...data.demandesEnAttente] 
        },
        variables() {
          return {
            page: 0,
            count: 30
          }
        },
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      search: "",
      AllRequests: [],
      SkipQuery: true,
      Flag: false,
      ManagerSelects: {
        Manager: "",
        ListManager: []
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      total: 0
    }
  },
  computed: {
    filterRequest(){
      let list = this.AllRequests.filter(request => {
        return request.manager.match(this.ManagerSelects.Manager.split("@")[0]) &&
        (
          request.societe_client.toUpperCase().match(this.search.toUpperCase()) ||
          request.nom_base.toUpperCase().match(this.search.toUpperCase()) ||
          request.provenance.toUpperCase().match(this.search.toUpperCase())
        )
      })
      this.total = list.length
      return list.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  async mounted(){
    let page = 0
    this.SkipQuery = false
    while(!this.Flag){
      await this.$apollo.queries.demandesEnAttente.refetch({
        page: page++,
        count: 30
      })
    }
  },
  methods: {
    AcceptReq(row){
      swal.fire({
        text: "VOUS ÊTES EN TRAIN DE VALIDER LA DEMANDE DE " + row.societe_client.toUpperCase(),
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'VALIDER',
        cancelButtonText: 'ANNULER'
      }).then(result =>{
          if(result.value) {
            this.$apollo.mutate({
              mutation: gql`mutation (
                                      $societe_client: String!
                                      $id_bases: Int!
                                      $provenance: String!
                                      $id_type_compagne: String!
                                      $id_programme: Int!
              ) {
                valide_postule(
                  societe_client: $societe_client
                  id_bases: $id_bases
                  provenance: $provenance
                  id_type_compagne: $id_type_compagne
                  id_programme: $id_programme
                )
                {
                  state
                  is_eca
                  tag
                }
              }`,
              variables: {
                societe_client: row.societe_client,
                id_bases: row.id_bases,
                provenance: row.provenance,
                id_type_compagne: row.id_type_compagne,
                id_programme: row.id_programme
              },
            }).then((data) => {
              if(data.data.valide_postule.state == true) {
                this.filterRequest.forEach((value, index) => {
                  if (row == value) {
                    this.filterRequest.splice(index, 1);
                    this.AllRequests.splice(index, 1);
                  }
                });

                if (data.data.valide_postule.is_eca === true) {
                  this.axios.get('https://mutuelle-sante.self-assurance.fr/devis/insertionCampagneWeeDoIt?provenance=' + data.data.valide_postule.tag)
                }
                
                this.$notify({ type: "success", message: "la demande a été validé avec succès."});
              }
            }).catch((error) => {
              console.log(error)
            })
          }
        }
      )
    },
    RefuseReq(row){
      swal.fire({
        text: "VOUS ÊTES EN TRAIN DE SUPPRIMER LA DEMANDE DE " + row.societe_client.toUpperCase(),
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'SUPPRIMER',
        cancelButtonText: 'ANNULER'
      }).then(result =>{
          if(result.value) {
            this.$apollo.mutate({
              mutation: gql`mutation ($id_type_compagne: String, $id_programme: Int!, $id_postule: Int!) {
                refuse_postule(
                  id_type_compagne: $id_type_compagne
                  id_programme: $id_programme
                  id_postule: $id_postule
                )
                {
                  state
                }
              }`,
              variables: {
                id_type_compagne: row.id_type_compagne,
                id_programme: row.id_programme,
                id_postule: row.id_postule
              }
            }).then((data) => {
              if(data.data.refuse_postule.state == true) {
                this.filterRequest.forEach((value, index) => {
                  if (row == value) {
                    this.filterRequest.splice(index, 1);
                    this.AllRequests.splice(index, 1);
                  }
                });

                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: "la demande a été supprimer.",
                  duration: 4000
                });
              }
            }).catch((error) => {
              console.error(error)
            })
          }
        }
      )
    },
  }
};
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 12px;
  }
</style>