<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t("labels.TitlePageShoot").toUpperCase() }}</h3>
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <base-button
                  class="btn btn-round btn-just-icon btn-info"
                  @click.native="toEndPage"
          >{{ $t("buttons.BtnShoot").toUpperCase() }}</base-button>
        </div>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <base-input :label="$t('table.columns.Period')" class="col-md-6">
                    <base-radio inline name="YESTERDAY" class="mb-3" v-model="Periode">
                      {{ $t('labels.Yesterday') }}
                    </base-radio>
                    <base-radio inline name="CURRENTDAY" class="mb-3" v-model="Periode">
                      {{ $t('labels.Currentday') }}
                    </base-radio>
                    <base-radio inline name="TOMORROW" class="mb-3" v-model="Periode">
                      {{ $t('labels.Tomorrow') }}
                    </base-radio>
                    <base-radio inline name="CURRENTMONTH" class="mb-3" v-model="Periode">
                      {{ $t('labels.Currentmonth') }}
                    </base-radio>
                  </base-input>
                  
                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.Startdate').toUpperCase()">
                        <el-date-picker
                                :disabled="$apollo.queries.listshoot.loading"
                                v-model="StartDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="AAAA-MM-JJ"
                                :clearable="false"
                        ></el-date-picker>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.Enddate').toUpperCase()">
                        <el-date-picker
                                :disabled="$apollo.queries.listshoot.loading"
                                v-model="EndDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="AAAA-MM-JJ"
                                :clearable="false"
                        ></el-date-picker>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.AddedDate')">
                        <el-date-picker
                                :disabled="$apollo.queries.listshoot.loading"
                                v-model="Created_at"
                                type="date"
                                placeholder="AAAA-MM-JJ"
                                value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.State')">
                        <el-select
                                class="select-primary"
                                :disabled="$apollo.queries.listshoot.loading"
                                v-model="EtatSelects.Etat"
                                clearable>
                          <el-option
                                  v-for="option in EtatSelects.ListEtat"
                                  class="select-primary"
                                  :value="option.value"
                                  :label="option.label.toUpperCase()"
                                  :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('inputs.Law.Affiliates').toUpperCase()"
                                  :disabled="$apollo.queries.listshoot.loading">
                        <el-select
                                filterable
                                clearable
                                :disabled="$apollo.queries.listshoot.loading"
                                class="select-primary"
                                v-model="EditeursSelects.Editeur">
                          <el-option
                                  v-for="option in EditeursSelects.ListEditeurs"
                                  class="select-primary"
                                  :value="option.id_client"
                                  :label="option.societe_client.toUpperCase()"
                                  :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <base-input label="TRAFFIC MANAGER">
                      <el-select filterable
                                 :disabled="$apollo.queries.listshoot.loading"
                                 class="select-primary"
                                 v-model="ManagerSelects.Manager"
                                 clearable>
                        <el-option
                                v-for="option in ManagerSelects.ListManager"
                                class="select-primary"
                                :value="option.id_client"
                                :label="option.prenom_client.toUpperCase()+' '+option.nom_client.toUpperCase()"
                                :key="option.id_client">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.Program').toUpperCase()">
                        <el-select filterable
                                   :disabled="$apollo.queries.listshoot.loading"
                                   class="select-primary"
                                   v-model="ProgrammeSelects.Programme"
                                   clearable>
                          <el-option
                                  v-for="option in ProgrammeSelects.ListProgramme"
                                  class="select-primary"
                                  :value="option.id_programme"
                                  :label="option.provenance.toUpperCase()"
                                  :key="option.id_programme">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('inputs.DiffusionType').toUpperCase()">
                        <el-select filterable
                                   :disabled="$apollo.queries.listshoot.loading"
                                   class="select-primary"
                                   v-model="TypesShootSelects.Type"
                                   clearable>
                          <el-option
                                  v-for="option in TypesShootSelects.ListType"
                                  class="select-primary"
                                  :value="option.id_type_compagne"
                                  :label="option.type_2.toUpperCase()"
                                  :key="option.id_type_compagne">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xl-6 col-lg-12 col-12">
                <div class="row">
                  
                </div>
              </div> -->
            </div>
            <div class="col-md-12">
              <div class="row mt-4">
                <div class="form-group col-auto mx-auto">
                  <base-button
                          v-if="$apollo.queries.listshoot.loading"
                          loading
                          class="btn btn-just-icon btn-default float-left mr-2 d-block"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <button v-else class="btn btn-just-icon btn-default float-left mr-2 d-block" @click="search">{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  <button class="btn btn-just-icon btn-default d-block float-right" @click="resetFilter">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-xl-1 col-sm-6">
                  <base-input>
                    <el-select
                            class="select-primary mb-3 pagination-select"
                            v-model="pagination.perPage"
                            placeholder="Per page"
                            :disabled="$apollo.queries.listshoot.loading"
                    >
                      <el-option
                              class="select-primary"
                              v-for="item in pagination.perPageOptions"
                              :key="item"
                              :label="item"
                              :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-9 col-sm-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>

                <!-- <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input> -->
                <div class="col-xl-2 col-sm-6">
                  <!-- <button class="btn btn-info" @click="ExportTable()">
                    <i class="tim-icons icon-cloud-download-93"></i> EXPORT
                  </button> -->
                  <button class="btn btn-info" @click="ExportShoots()" v-if="!Loading">
                    <i class="tim-icons icon-cloud-download-93"></i> EXPORT
                  </button>
                  <base-button v-else loading type="info">{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                </div>
              </div>
              <el-table show-summary :data="queriedData" :summary-method="getSummaries" ref="elTable_shoot" class="customtable">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <p><b>MANAGER:</b> {{ props.row.email }} </p>
                  </template>
                </el-table-column>
                <el-table-column
                        prop="societe_client"
                        :label="$t('inputs.Law.Affiliates')"
                        min-width="120"
                        sortable
                        show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                        prop="titre"
                        :label="$t('table.columns.Program')"
                        min-width="139"
                        sortable
                        show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                        prop="nom_campagne"
                        :label="$t('table.columns.Campagne')"
                        min-width="350"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="scope">
                    <div style="cursor: pointer;" @click="openStats(scope.row.id_campagne)">{{ scope.row.nom_campagne }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                        prop="date_insert"
                        label="Date"
                        class="table-striped"
                        min-width="100"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="props">
                    {{ props.row.jour+"-"+props.row.mois+"-"+props.row.annee }}
                  </template>
                </el-table-column>
                <el-table-column
                        prop="volume"
                        label="Volume"
                        min-width="100"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="props">
                    {{ formatThounsends(props.row.volume) }}
                  </template>
                </el-table-column>
                <el-table-column
                        prop="etat"
                        :label="$t('table.columns.State')"
                        min-width="140"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="props">
                    <template v-if="props.row.etat == 'SHOOTENVOYE'">
                      {{ $t('labels.ShootEnvoye') }}
                    </template>
                    <template v-else>
                      <base-input>
                        <el-select
                                class="select-primary"
                                v-model="props.row.etat"
                                @change="StatChanged(props.row)"
                        >
                          <el-option class="select-primary" value="BATOK" label="BAT OK"></el-option>
                          <el-option class="select-primary" value="ABAT" label="ABAT"></el-option>
                          <el-option class="select-primary" value="SHOOTENVOYE" :label="$t('labels.ShootEnvoye')"></el-option>
                        </el-select>
                      </base-input>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                        prop="sous_etat"
                        :label="$t('table.columns.Substate')"
                        min-width="120"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="props">
                    {{ getEtat(props.row.sous_etat) }}
                  </template>
                </el-table-column>

                <el-table-column
                        prop="ecpm"
                        label="ECPM"
                        min-width="90"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ scope.row.ecpm + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                        prop="taux_clique"
                        label="% CLIQUE"
                        min-width="90"
                        sortable
                        show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if="scope.row.etat == 'SHOOTENVOYE' && scope.row.taux_clique >= 1.5">
                      <span style="color:green" >{{ scope.row.taux_clique + ' %' }}</span>
                    </template>
                    <template v-else-if="scope.row.etat == 'SHOOTENVOYE' && scope.row.taux_clique >= 1">
                      <span style="color:orange" >{{ scope.row.taux_clique + ' %' }}</span>
                    </template>
                    <template v-else-if="scope.row.etat == 'SHOOTENVOYE' && scope.row.taux_clique < 1">
                      <span style="color:red" >{{ scope.row.taux_clique + ' %' }}</span>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="Actions" min-width="160">
                  <div slot-scope="props">
                    <base-button
                            :disabled="props.row.etat == 'SHOOTENVOYE'"
                            @click.native="handleEdit(props.$index, props.row)"
                            class="edit btn-link"
                            type="warning"
                            size="sm"
                            icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button
                            @click.native="handleDelete(props.row)"
                            class="edit btn-link"
                            type="danger"
                            size="sm"
                            icon
                    >
                      <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                    <el-tooltip
                            content="Dupliquer"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                    >
                      <base-button
                              @click.native="handleCopy(props.row)"
                              class="btn-link"
                              type="success"
                              size="sm"
                              icon
                      >
                        <i class="tim-icons icon-single-copy-04"></i>
                      </base-button>
                    </el-tooltip>
                    <base-button
                            @click.native="handleStat(props.$index, props.row)"
                            class="like btn-link"
                            type="info"
                            size="sm"
                            icon
                    >
                      <i class="tim-icons icon-chart-bar-32"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ formatThounsends(total) }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                      v-if="!$apollo.queries.listshoot.loading"
                      class="pagination-no-border"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
              >
              </base-pagination>
              <base-button v-else loading round type="primary"></base-button>
            </div>
          </div>
        </card>
        <card v-if="!$apollo.queries.listshoot.loading">
          <div class="card-body">
            <full-calendar
                    id="fullCalendar"
                    v-bind="calendarOptions"
                    :events="events"
                    contentHeight="auto"
                    ref="fullCalendar"
                    @select="onSelect"
            />
          </div>
        </card>
        <h3>{{ $t('labels.AddNewShoot') }}</h3>
        <card>
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-12">
                  <div class="row">
                    <div class="col-md-3">
                      <base-input :label="$t('table.columns.Society').toUpperCase()" :error="getError($t('table.columns.Society'))">
                        <el-select filterable class="select-primary"
                                   :name="$t('table.columns.Society')"
                                   v-validate="'required'"
                                   v-model="SocietesClientShootSelects.Societe">
                          <el-option
                                  v-for="option in SocietesClientShootSelects.ListSocietes"
                                  class="select-primary"
                                  :value="option.id_client"
                                  :label="option.societe_client.toUpperCase()"
                                  :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <base-input :label="$t('table.columns.Program').toUpperCase()" :error="getError($t('table.columns.Program'))">
                        <el-select class="select-primary" filterable
                                   :name="$t('table.columns.Program')"
                                   v-validate="'required'"
                                   v-model="ProgrammeEditShootSelects.Programme">
                          <el-option
                                  v-for="option in ProgrammeEditShootSelects.ListProgramme"
                                  class="select-primary"
                                  :value="option.id_programme"
                                  :label="option.provenance.toUpperCase()"
                                  :key="option.id_programme">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input :label="$t('table.columns.Campagne').toUpperCase()" :error="getError($t('table.columns.Campagne'))">
                        <el-select class="select-primary" filterable
                                   :name="$t('table.columns.Campagne')"
                                   v-validate="'required'"
                                   v-model="CampagneEditShootSelects.Campagne">
                          <el-option
                                  v-for="option in CampagneEditShootSelects.ListCampagne"
                                  class="select-primary"
                                  :value="option.id_campagne"
                                  :label="option.nom_campagne.toUpperCase()"
                                  :key="option.id_campagne">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <!-- <div class="col-md-3">
                      <div>
                        <h5 class="card-title">Date</h5>
                      </div>
                      <div>
                        <base-input type="text" v-model="Editeur"/>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-12">
                  <div class="row">
                    <!-- <div class="col-md-2">
                      <base-input label="DATE D'AJOUT" v-model="DateAjoutEditeShoot" type="text" disabled/>
                    </div> -->
                    <div class="col-md-3">
                      <base-input label="DATE" :error="getError('date')">
                        <el-date-picker
                                name="date"
                                v-validate="'required'"
                                v-model="DateEditeShoot"
                                type="date"
                                placeholder="AAAA-MM-JJ"
                                value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <base-input
                              :error="getError('volume')"
                              name="volume"
                              v-validate="'required'"
                              label="VOLUME"
                              type="number"
                              v-model="VolumeEditeShoot">
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <base-input :label="$t('table.columns.State').toUpperCase()" :error="getError($t('table.columns.State'))">
                        <el-select class="select-primary"
                                   :name="$t('table.columns.State')"
                                   v-validate="'required'"
                                   v-model="EtatEditShootSelects.EtatEditShoot">
                          <el-option
                                  v-for="option in EtatEditShootSelects.ListEtatEditShoot"
                                  class="select-primary"
                                  :value="option.value"
                                  :label="option.label.toUpperCase()"
                                  :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <base-input :label="$t('table.columns.Substate').toUpperCase()">
                        <el-select class="select-primary"
                                   :name="$t('table.columns.Substate')"
                                   v-model="SousEtatEditShootSelects.SousEtatEditShoot">
                          <el-option
                                  v-for="option in SousEtatEditShootSelects.ListSousEtatEditShoot"
                                  class="select-primary"
                                  :value="option.value"
                                  :label="option.label.toUpperCase()"
                                  :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row mt-4">
                <div class="form-group col-auto mx-auto">
                  <button class="btn btn-just-icon btn-info float-left mr-2 d-block" v-on:click="updateOrCreateShoot()">{{ $t("buttons.BtnValidate").toUpperCase() }}</button>
                  <button class="btn btn-just-icon btn-default d-block" @click="reset">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <div id="ENDPAGE"></div>
      </div>
    </div>
    <Modal_selfEvent
      :show.sync="showModalAddShoot"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">
        
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="showModalAddShoot = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove icon-large"></i>
            </base-button>
          </el-tooltip>
        </template>
        <form @submit.prevent data-vv-scope="ScopeMultiplShoot">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <base-input :label="$t('table.columns.Society').toUpperCase()" :error="getError('ScopeMultiplShoot.'+$t('table.columns.Society'))">
                  <el-select filterable class="select-primary"
                            :name="$t('table.columns.Society')"
                            v-validate="'required'"
                            v-model="SocietesClientShootSelects.Societe">
                    <el-option
                            v-for="option in SocietesClientShootSelects.ListSocietes"
                            class="select-primary"
                            :value="option.id_client"
                            :label="option.societe_client.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('table.columns.Program').toUpperCase()" :error="getError('ScopeMultiplShoot.' + $t('table.columns.Program'))">
                  <el-select class="select-primary" filterable
                            :name="$t('table.columns.Program')"
                            v-validate="'required'"
                            v-model="ProgrammeEditShootSelects.Programme">
                    <el-option
                            v-for="option in ProgrammeEditShootSelects.ListProgramme"
                            class="select-primary"
                            :value="option.id_programme"
                            :label="option.provenance.toUpperCase()"
                            :key="option.id_programme">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input :label="$t('table.columns.Campagne').toUpperCase()" :error="getError('ScopeMultiplShoot.' + $t('table.columns.Campagne'))">
                  <el-select class="select-primary" filterable
                            :name="$t('table.columns.Campagne')"
                            v-validate="'required'"
                            v-model="CampagneEditShootSelects.Campagne">
                    <el-option
                            v-for="option in CampagneEditShootSelects.ListCampagne"
                            class="select-primary"
                            :value="option.id_campagne"
                            :label="option.nom_campagne.toUpperCase()"
                            :key="option.id_campagne">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <base-input
                        :error="getError('volume')"
                        name="volume"
                        v-validate="'required'"
                        label="VOLUME"
                        type="number"
                        v-model="VolumeEditeShoot">
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('table.columns.State').toUpperCase()" :error="getError('ScopeMultiplShoot.' + $t('table.columns.State'))">
                  <el-select class="select-primary"
                            :name="$t('table.columns.State')"
                            v-validate="'required'"
                            v-model="EtatEditShootSelects.EtatEditShoot">
                    <el-option
                            v-for="option in EtatEditShootSelects.ListEtatEditShoot"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label.toUpperCase()"
                            :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('table.columns.Substate').toUpperCase()">
                  <el-select class="select-primary"
                            :name="$t('table.columns.Substate')"
                            v-model="SousEtatEditShootSelects.SousEtatEditShoot">
                    <el-option
                            v-for="option in SousEtatEditShootSelects.ListSousEtatEditShoot"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label.toUpperCase()"
                            :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row mt-4">
              <div class="form-group col-auto mx-auto">
                <button class="btn btn-just-icon btn-info float-left mr-2 d-block" native-type="submit" v-on:click="addMultipleShoot()" v-if="!loadingMultiShoot">{{ $t("buttons.BtnValidate").toUpperCase() }}</button>
                <base-button loading v-else>{{ $t("buttons.BtnLoad").toUpperCase() }}</base-button>
                <button :disabled="loadingMultiShoot" class="btn btn-just-icon btn-default d-block" @click="reset">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
              </div>
            </div>
          </div>
        </form>
      </card>
    </Modal_selfEvent>
          
  </div>
</template>

<script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination, BaseRadio } from 'src/components';
  import FullCalendar from '@fullcalendar/vue';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import Fuse from 'fuse.js';
  import gql from 'graphql-tag'
  import { elTableExport } from "el-table-export";
  import Modal_selfEvent from 'src/components/Modal_selfEvent'
  import Swal from 'sweetalert2'
  import moment from 'moment'


  let today = new Date();
  let CurentDate = new Date();

  export default {
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      FullCalendar,
      BaseRadio,
      Modal_selfEvent
    },
    apollo: {
      Filter: {
        query : gql`query {
        getSocieteEditeurs{
          id_client
          societe_client
        }
        listManager(
          bloque: "non"
          etat: "valide"
          droit: ["ma", "tem"]
          orderBy: [
            { field: "prenom_client", order: ASC }
            { field: "nom_client", order: ASC }
          ]
        ) {
          id_client
          nom_client
          prenom_client
        }
        programmes_Shoots {
          id_programme
          provenance
        },
        type_compagnes{
          id_type_compagne
          type_2
        }
      }`,
        update: data => data,
        result({ data, loader, networkStatus }) {
          this.EditeursSelects.ListEditeurs = data.getSocieteEditeurs
          this.ManagerSelects.ListManager = data.listManager
          this.ProgrammeSelects.ListProgramme = data.programmes_Shoots
          this.TypesShootSelects.ListType = data.type_compagnes
          // set manager select
          let manager = this.ManagerSelects.ListManager.find(m => parseInt(m.id_client) === JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil)
          if (manager) {
            this.ManagerSelects.Manager = manager.id_client
          }
        }
      },
      listshoot: {
        query : gql` query (
          $page: Int,
          $count: Int,
          $shoots_type: Int,
          $StartDate: String,
          $EndDate: String,
          $etat: String,
          $editeur: Int,
          $manager: Int,
          $program: Int,
          $date_ajout: String
        )
      {
        liste_Shoots
          (
            page:  $page
            count: $count
            shoots_type: $shoots_type
            startDate: $StartDate
            endDate: $EndDate
            etat: $etat
            editeur: $editeur
            manager: $manager
            programme: $program
            date_ajout: $date_ajout
          )
        {
          total
          shoots {
            email
            societe_client
            nom_base
            id_client
            nom_campagne
            id_type_compagne
            prix_editeur
            prix_annonceur
            prix_mg_editeur
            prix_mg_annonceur
            titre
            id_programme
            type_campagne
            id_shoot
            id_campagne
            jour
            mois
            annee
            etat
            sous_etat
            date_ajout
            volume
            ecpm
            taux_clique
          }
        }
      }`,
        skip() {
          return this.SkipQuery
        },
        update: data => data.liste_Shoots,
        result({ data, loader, networkStatus }) {
          this.SkipQuery = true
          this.tableData = data.liste_Shoots.shoots
          this.total = data.liste_Shoots.total

          this.events = []
          for (let i = 0; i < this.tableData.length; i++) {
            today = new Date(this.tableData[i].annee + '-' + this.tableData[i].mois + '-' + this.tableData[i].jour)
            let event = {
              title: this.tableData[i].nom_campagne + ' (' + this.tableData[i].volume +')',
              start: today,
              className: 'event-green'
            }
            this.events.push(event)
            this.calendarOptions.defaultDate = today
          }
        },
        variables() {
          return {
            page: this.pagination.currentPage,
            count: this.pagination.perPage,
            shoots_type: this.TypesShootSelects.Type,
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            etat: this.EtatSelects.Etat,
            editeur: this.EditeursSelects.Editeur,
            manager: this.ManagerSelects.Manager,
            program: this.ProgrammeSelects.Programme,
            date_ajout: this.Created_at
          }
        }
      },
      editshootProgramm: {
        query : gql`query ($id_client: Int) {
        getProgrammesByEditeur_Shoots(id_client: $id_client) {
          id_programme
          provenance
        }
      }`,
        result({ data, loader, networkStatus }) {
          //this.ProgrammeEditShootSelects.Programme = ''
          this.ProgrammeEditShootSelects.ListProgramme = data.getProgrammesByEditeur_Shoots
        },
        update: data => data.getProgrammesByEditeur_Shoots,
        variables() {
          return {
            id_client: this.SocietesClientShootSelects.Societe
          }
        },
      },
      editshootCampagnes: {
        query : gql`query ($id_client: Int, $id_programme: Int) {
        getCampagnesByProgramme_Shoots(id_client: $id_client, id_programme: $id_programme) {
          id_campagne
          nom_campagne
        }
      }`,
        result({ data, loader, networkStatus }) {
          //this.CampagneEditShootSelects.Campagne = ''
          this.CampagneEditShootSelects.ListCampagne = data.getCampagnesByProgramme_Shoots
        },
        update: data => data.getCampagnesByProgramme_Shoots,
        variables() {
          return {
            id_client: this.SocietesClientShootSelects.Societe,
            id_programme: this.ProgrammeEditShootSelects.Programme
          }
        }
      },
      societeClientShoots: {
        query : gql`query {
        getSocieteEditeurs{
          id_client
          societe_client
        }
      }`,
        result({ data, loader, networkStatus }) {
          this.SocietesClientShootSelects.ListSocietes = data.getSocieteEditeurs
        },
        update: data => data.getSocieteEditeurs
      }
    },
    data() {
      return {
        MultipleShootFrom: '',
        MultipleShootTo: '',
        showModalAddShoot: false,
        loadingMultiShoot: false,
        Loading: false,
        Periode: '',
        id_shoot: null,
        //Keyword: "",
        listshoot: [],
        events: [],
        total: 0,
        Created_at: '',
        StartDate: moment().startOf('month').format('YYYY-MM-DD'),
        EndDate: moment().endOf('month').format('YYYY-MM-DD'),
        EtatSelects: {
          Etat: '',
          ListEtat: [
            {value: 'ABAT', label:'ABAT'},
            {value: 'BATOK', label:'BAT OK'},
            {value: 'SHOOTENVOYE', label:this.$t('labels.ShootEnvoye')}
          ]
        },
        EditeursSelects: {
          Editeur: '',
          ListEditeurs: []
        },
        ManagerSelects: {
          Manager: '',
          ListManager: []
        },
        ProgrammeSelects: {
          Programme: '',
          ListProgramme: []
        },
        TypesShootSelects: {
          Type: '',
          ListType: []
        },
        ProgrammeEditShootSelects: {
          Programme: '',
          ListProgramme: []
        },
        CampagneEditShootSelects: {
          Campagne: '',
          ListCampagne: []
        },
        SocietesClientShootSelects: {
          Societe: '',
          ListSocietes: []
        },
        EtatEditShootSelects: {
          EtatEditShoot: '',
          ListEtatEditShoot: [
            {value: 'ABAT', label:'ABAT'},
            {value: 'BATOK', label:'BAT OK'},
            {value: 'SHOOTENVOYE', label:this.$t('labels.ShootEnvoye')}
          ]
        },
        SousEtatEditShootSelects: {
          SousEtatEditShoot: '',
          ListSousEtatEditShoot: [
            {value: 'VALIDE', label:this.$t('labels.Valid')},
            {value: 'ANNULE', label:this.$t('labels.Canceled')},
            {value: 'REPORTE', label:this.$t('labels.Postponed')}
          ]
        },
        DateEditeShoot: '',
        VolumeEditeShoot: 0,
        DateAjoutEditeShoot: '',
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 75],
          total: 0
        },
        calendarOptions: {
          locale: 'fr',
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          theme: true,
          defaultView: 'dayGridMonth',
          header: {
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
            right: 'prev,next',
          },
          buttonText: {
            month: 'mois',
            week: 'semaine',
            day: 'jour',
          },
          defaultDate: today,
          selectable: true,
          selectHelper: false,
          views: {
            month: {
              // name of view
              titleFormat: {
                month: 'long',
                year: 'numeric'
              }
              // other view-specific options here
            },
            week: {
              titleFormat: {
                month: 'long',
                day: '2-digit',
                year: 'numeric'
              }
            },
            day: {
              titleFormat: {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
              }
            }
          },
          editable: false,
          eventLimit: false, // allow "more" link when too many events

          // color classes: [ event-blue | event-azure | event-green | event-orange | event-red ]
          events: self.events

        },
        searchQuery: '',
        searchedData: [],
        tableData: [],
        SkipQuery: true,
      }
    },
    watch: {
      searchQuery(value) {
        let result = this.tableData
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery)
        }
        this.searchedData = result
      },
      'pagination.currentPage'() {
        this.SkipQuery = false
      },
      'pagination.perPage'() {
        this.SkipQuery = false
      },
      Periode (value) {
        if(value == "YESTERDAY") {
          this.StartDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.EndDate   =  moment().subtract(1, 'day').format('YYYY-MM-DD');
        } else if(value == "CURRENTDAY") {
          this.StartDate = moment().format('YYYY-MM-DD');
          this.EndDate   =  moment().format('YYYY-MM-DD');
        } else if(value == "TOMORROW") {
          this.StartDate = moment().add(1, 'days').format('YYYY-MM-DD');
          this.EndDate   =  moment().add(1, 'days').format('YYYY-MM-DD');
        }else if(value == "CURRENTMONTH") {
          this.StartDate = moment().startOf('month').format('YYYY-MM-DD');
          this.EndDate   =  moment().endOf('month').format('YYYY-MM-DD');
        } else if(value == "LASTMONTH") {
          this.StartDate = CurentDate.getFullYear() +"-"+ this.appendLeadingZeroes(CurentDate.getMonth()) +"-01"
          this.EndDate   = CurentDate.getFullYear() +"-"+ this.appendLeadingZeroes(CurentDate.getMonth()) +"-"+ new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate()
        }
      }
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    mounted() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ['societe_client'],
        threshold: 0.3
      });
    },
    methods: {
      getSummaries(param) {
        const { columns, data } = param;
        let SumVolume = 0
        data.forEach(element => {
          SumVolume += element.volume
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 5) {
            sums[index] = this.formatThounsends(SumVolume);
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      async ExportShoots() {
        this.Loading = true
        var formData = new FormData();
        if(this.StartDate instanceof Date){
          formData.append("startDate", this.StartDate.getFullYear()+"-"+this.appendLeadingZeroes(this.StartDate.getMonth()+1)+"-"+this.appendLeadingZeroes(this.StartDate.getDate()))
        }
        else {
          formData.append("startDate", this.StartDate)
        }
        if(this.EndDate instanceof Date){
          formData.append("endDate", this.EndDate.getFullYear()+"-"+this.appendLeadingZeroes(this.EndDate.getMonth()+1)+"-"+this.appendLeadingZeroes(this.EndDate.getDate()))
        }
        else {
          formData.append("endDate", this.EndDate)
        }
        formData.append("etat", this.EtatSelects.Etat)
        formData.append("editeur", this.EditeursSelects.Editeur)
        formData.append("manager", this.ManagerSelects.Manager)
        formData.append("programme", this.ProgrammeSelects.Programme)
        formData.append("date_ajout", this.Created_at)
        formData.append("shoots_type", this.TypesShootSelects.Type)

        const url_api = "https://apimyaffil.wee-do-it.net/api/export-shoots-csv"
      
        let accessToken = localStorage.getItem("token_jwt")

        await this.axios.post(url_api, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
          }).then(response => {
            const filename = "shoots.csv";
            var fileURL = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.Loading = false
          }).catch(error => {
            console.log("error occured")
            this.Loading = false
          });
      },
      async addMultipleShoot() {

        let validate
        await this.$validator.validateAll("ScopeMultiplShoot").then(isValid => {
          validate = isValid
        })
        if (!validate) return

        let result = await Swal.fire({
          title: 'ÊTES-VOUS SÛR?',
          text: "",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'VALIDER',
          cancelButtonText: 'ANNULER'
        })

        if (result.value) {
          this.loadingMultiShoot = true
          for (var d = this.MultipleShootFrom; d < this.MultipleShootTo; d.setDate(d.getDate() + 1)) {

            await this.$apollo.mutate({
              // Query
              mutation: gql`mutation (
                $id_shoot: Int
                $id_client: Int
                $id_programme: Int
                $id_campagne: Int
                $date: String
                $date_ajout: String
                $volume: Int
                $etat: String
                $sous_etat: String
              ) {
                  updateOrCreate_Shoots(
                    id_shoot: $id_shoot
                    id_programme: $id_programme
                    id_campagne: $id_campagne
                    id_client: $id_client
                    date: $date
                    date_ajout: $date_ajout
                    volume: $volume
                    etat: $etat
                    sous_etat: $sous_etat
                  )
                  {
                    id_shoot
                    id_client
                    id_campagne
                    date_ajout
                    volume
                    etat
                    sous_etat
                  }
            }`,
              variables: {
                id_shoot: null,
                id_programme: this.ProgrammeEditShootSelects.Programme,
                id_campagne: this.CampagneEditShootSelects.Campagne,
                id_client: this.SocietesClientShootSelects.Societe,
                date: d.getFullYear() +"-"+ this.appendLeadingZeroes((d.getMonth()+1)) +"-"+ this.appendLeadingZeroes(d.getDate()),
                date_ajout: '',
                volume: this.VolumeEditeShoot,
                etat: this.EtatEditShootSelects.EtatEditShoot,
                sous_etat: this.SousEtatEditShootSelects.SousEtatEditShoot
              },
            }).then((data) => {
              let event = {
                title: this.CampagneEditShootSelects.ListCampagne.find(x => x.id_campagne === this.CampagneEditShootSelects.Campagne).nom_campagne + ' (' + this.VolumeEditeShoot +')',
                start: d.getFullYear() +"-"+ this.appendLeadingZeroes((d.getMonth()+1)) +"-"+ this.appendLeadingZeroes(d.getDate()),
                className: 'event-green'
              }
              this.events.push(event)
            }).catch((error) => {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
            })
          }
          this.$notify({type: 'success', message: 'Le shoot a été bien dupliqué.'})
          this.loadingMultiShoot = false
        }
      },
      onSelect({ start, end }) {
        this.showModalAddShoot = true
        
        this.MultipleShootFrom = start
        this.MultipleShootTo = end
          
      },
      appendLeadingZeroes(n){
        if(n <= 9){
          return "0" + n;
        }
        return n
      },
      openStats(id_campagne) {
        let routeData = this.$router.resolve({
          name: "stats_shoots",
          params: { id_campagne: id_campagne }
        });
        window.open(routeData.href, "_blank");
      },
      ExportTable() {
        let eteInstance = new elTableExport(this.$refs.elTable_shoot, {
          fileName: "export",
          type: "xls",
          withBOM: false,
        });
        eteInstance.export();
      },
      formatThounsends(value){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getEtat(sous_etat)
      {
        switch (sous_etat) {
          case 'VALIDE':
            return this.$t('labels.Valid');
            break;
          case 'ANNULE':
            return this.$t('labels.Canceled');
            break;
          case 'REPORTE':
            return this.$t('labels.Postponed');
            break;
          default: "";
            break;
        }
      },
      toEndPage() {
        let elmnt = document.getElementById('ENDPAGE');
        elmnt.scrollIntoView(false);
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      //get shoot object
      handleEdit(index, row) {
        this.toEndPage()
        //this.Keyword = row.societe_client
        this.id_shoot = row.id_shoot
        this.SocietesClientShootSelects.Societe = "" + row.id_client
        this.ProgrammeEditShootSelects.Programme = row.id_programme
        this.CampagneEditShootSelects.Campagne = "" + row.id_campagne
        this.DateEditeShoot = row.annee+'-'+row.mois+'-'+row.jour
        this.VolumeEditeShoot = row.volume
        this.DateAjoutEditeShoot = row.date_ajout
        this.EtatEditShootSelects.EtatEditShoot = row.etat
        this.SousEtatEditShootSelects.SousEtatEditShoot = row.sous_etat
      },
      async handleDelete(row) {
        let result = await Swal.fire({
          title: 'ÊTES-VOUS SÛR?',
          text: "",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'VALIDER',
          cancelButtonText: 'ANNULER'
        })
        if (result.value) {
          this.$apollo.mutate({
            // Query
            mutation: gql`mutation (
              $id_shoot: Int
              $id_client: Int
              $id_programme: Int
              $id_campagne: Int
              $date: String
              $date_ajout: String
              $volume: Int
              $etat: String
              $sous_etat: String
            ) {
                updateOrCreate_Shoots(
                  id_shoot: $id_shoot
                  id_programme: $id_programme
                  id_campagne: $id_campagne
                  id_client: $id_client
                  date: $date
                  date_ajout: $date_ajout
                  volume: $volume
                  etat: $etat
                  sous_etat: $sous_etat
                )
                {
                  id_shoot
                  id_client
                  id_campagne
                  date_ajout
                  volume
                  etat
                  sous_etat
                }
          }`,
            variables: {
              id_shoot: row.id_shoot,
              id_programme: row.id_programme,
              id_campagne: row.id_campagne,
              id_client: row.id_client,
              date: row.annee+'-'+row.mois+'-'+row.jour,
              date_ajout: row.date_ajout,
              volume: row.volume,
              etat: "SUPPRIME",
              sous_etat: row.sous_etat
            },
          }).then((data) => {
            this.tableData.forEach((element, index) => {
              if (element.id_shoot == row.id_shoot) {
                this.tableData.splice(index, 1);
              }
            });
            this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: "Le shoot a été bien supprimé."})
          }).catch((error) => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }

      },
      handleCopy(row) {
        this.toEndPage()
        this.id_shoot = null
        this.SocietesClientShootSelects.Societe = "" + row.id_client
        this.ProgrammeEditShootSelects.Programme = row.id_programme
        this.CampagneEditShootSelects.Campagne = "" + row.id_campagne
        this.VolumeEditeShoot = row.volume
        this.EtatEditShootSelects.EtatEditShoot = row.etat
        this.SousEtatEditShootSelects.SousEtatEditShoot = row.sous_etat
      },
      handleStat(index, row) {
        let routeData = this.$router.resolve({ path:'stats_shoots/'+row.id_campagne });
        window.open(routeData.href, '_blank');
      },
      updateOrCreateShoot:async function (message) {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        this.$apollo.mutate({
          // Query
          mutation: gql`mutation (
            $id_shoot: Int
            $id_client: Int
            $id_programme: Int
            $id_campagne: Int
            $date: String
            $date_ajout: String
            $volume: Int
            $etat: String
            $sous_etat: String
          ) {
              updateOrCreate_Shoots(
                id_shoot: $id_shoot
                id_programme: $id_programme
                id_campagne: $id_campagne
                id_client: $id_client
                date: $date
                date_ajout: $date_ajout
                volume: $volume
                etat: $etat
                sous_etat: $sous_etat
              )
              {
                id_shoot
                id_client
                id_campagne
                date_ajout
                volume
                etat
                sous_etat
              }
        }`,
          variables: {
            id_shoot: this.id_shoot,
            id_programme: this.ProgrammeEditShootSelects.Programme,
            id_campagne: this.CampagneEditShootSelects.Campagne,
            id_client: this.SocietesClientShootSelects.Societe,
            date: this.DateEditeShoot,
            date_ajout: this.DateAjoutEditeShoot,
            volume: this.VolumeEditeShoot,
            etat: this.EtatEditShootSelects.EtatEditShoot,
            sous_etat: this.SousEtatEditShootSelects.SousEtatEditShoot
          },
        }).then((data) => {
          // Result

          this.tableData.forEach((value, index) => {
            if(value.id_shoot == this.id_shoot){
              this.tableData[index].date_ajout = data.data.updateOrCreate_Shoots.date_ajout
              this.tableData[index].volume = data.data.updateOrCreate_Shoots.volume
              this.tableData[index].etat = data.data.updateOrCreate_Shoots.etat
              this.tableData[index].sous_etat = data.data.updateOrCreate_Shoots.sous_etat
            }
          });

          var message = ''
          if(this.id_shoot != null){
            message = 'Le shoot a été bien modifié.'
          }else{
            // add shoot to the calendar
            let event = {
              title: this.CampagneEditShootSelects.ListCampagne.find(x => x.id_campagne === this.CampagneEditShootSelects.Campagne).nom_campagne + ' (' + this.VolumeEditeShoot +')',
              start: this.DateEditeShoot,
              className: 'event-green'
            }
            this.calendarOptions.defaultDate = this.DateEditeShoot
            this.events.push(event)
            // add shoot to the calendar
            this.reset()
            message = 'Le shoot a été bien ajouté.'
          }

          this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message})

        }).catch((error) => {
          if(this.id_shoot != null){
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }else{
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          }
        })
      },
      reset(){
        this.id_shoot = null
        this.Keyword = ''
        this.ProgrammeEditShootSelects.Programme = null
        this.SocietesClientShootSelects.Societe = null
        this.CampagneEditShootSelects.Campagne = null
        this.DateEditeShoot = ''
        this.DateAjoutEditeShoot = ''
        this.VolumeEditeShoot = ''
        this.EtatEditShootSelects.EtatEditShoot = ''
        this.SousEtatEditShootSelects.SousEtatEditShoot = ''
      },
      resetFilter(){
        // this.StartDate = ''
        // this.EndDate = ''
        this.Created_at = ''
        this.EditeursSelects.Editeur = ''
        this.ManagerSelects.Manager = ''
        this.ProgrammeSelects.Programme = ''
        this.TypesShootSelects.Type = ''
        this.EtatSelects.Etat = ''
      },
      search() {
        this.SkipQuery = false
      },
      StatChanged(row) {
        this.$apollo.mutate({
          mutation: gql`mutation (
            $id_shoot: Int!
            $etat: String!
          ) {
              ChangeStatShoot_byid(
                id_shoot: $id_shoot
                etat: $etat
              )
              {
                id_shoot
                id_client
                id_campagne
                date_ajout
                volume
                etat
                sous_etat
              }
        }`,
          variables: {
            id_shoot: row.id_shoot,
            etat: row.etat,
          },
        }).then((data) => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
        }).catch((error) => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      }
    }
  }
</script>

<style scoped>
  .customtable .cell{
    word-break: break-word;
    font-size: 12px;
  }
</style>

<style>
  .el-table .caret-wrapper {
    width: auto
  }
</style>