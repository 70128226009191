<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 text-left">
                      <h2 class="card-title">Suivi de l'envoi des notifications</h2>
                    </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <base-input>
                            <el-select class="select-primary"
                                :disabled="$apollo.queries.getTokenByDomain.loading"
                                :placeholder="$t('table.columns.Period')"
                                v-model="PeriodeSelectsForMonth.Period">
                                <el-option
                                v-for="option in PeriodeSelectsForMonth.ListPeriod"
                                class="select-primary"
                                :value="option.value"
                                :label="option.label.toUpperCase()"
                                :key="option.value">
                                </el-option>
                            </el-select>
                            </base-input>
                        </div>
                        <div class="col-lg-4  col-md-4">
                            <base-input>
                                <el-date-picker
                                :disabled="$apollo.queries.getTokenByDomain.loading"
                                v-model="StartDate"
                                type="month"
                                value-format="yyyy-MM-dd"
                                placeholder="DATE DE DÉBUT"
                                :picker-options="pickerOptions"
                                @input="PeriodeSelectsForMonth.Period = ''"
                                ></el-date-picker>
                            </base-input>
                        </div>
                        <div class="col-lg-4  col-md-4">
                            <base-input>
                                <el-date-picker
                                :disabled="$apollo.queries.getTokenByDomain.loading"
                                v-model="EndDate"
                                type="month"
                                value-format="yyyy-MM-dd"
                                placeholder="DATE DE FIN"
                                :picker-options="pickerOptions"
                                @input="PeriodeSelectsForMonth.Period = ''"
                                ></el-date-picker>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 float-right">
                            <div class="btn-group btn-group-toggle float-right" >
                                <base-button v-if="$apollo.queries.getTokenByDomain.loading"
                                    loading
                                    class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...
                                </base-button>
                                <base-button
                                    v-else
                                    class="btn btn-round btn-just-icon btn-primary"
                                    @click="sendByMonth"
                                    >{{ $t("buttons.BtnSearch").toUpperCase() }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="totalToken">
                        <div class="col-12 text-left">
                            <h4 class="card-title">Total des abonnés <b>{{totalToken}}</b> </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <!--row 2 -->
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
            <div class="row">
                <div class="col-12">
                <h3 class="card-header text-uppercase">Abonnés
                    <span v-if="period.length === 1"> de {{period[0]}} </span>
                    <span v-if="period.length === 2"> de {{period[0]}} à {{period[1]}} </span>
                      <b style="white-space: pre;"> :   {{sumToken}}</b>
                </h3>
                </div>
            </div>
          <div class="card-body">
            <bar-chart
            v-if='(!$apollo.queries.getTokenByDomain.loading)'
            :chart-data="barChart3.chartData"
            :extra-options="barChart3.extraOptions"
            :gradient-colors="barChart3.gradientColors"
            :gradient-stops="barChart3.gradientStops"
            >
            </bar-chart>
          </div>
        </div>
      </div>
      </div>
<!--row 2 -->

<!--row 3-->
 <div class="row">
      <div class="col-12">
            <div class="card card-chart" style="height: 600px, max-height: 600px">
                <div class="row p-3 h-100">
                  <div class="col-6  border-right border-primary">
                     <div class="row">
                        <div class="col-8">
                           <h3 class="card-title text-uppercase text-left">Notifications envoyées</h3>
                        </div>
                        <div class="col-4">
                          <base-input>
                            <el-select
                              class="select-primary pagination-select"
                              v-model="pagination2.perPage"
                              placeholder="Per page"
                            >
                              <el-option
                                class="select-primary"
                                v-for="item in pagination2.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
                    <div class="card-body">
                      <el-table ref="SentNotif" :data="filterRequest2" show-summary :summary-method="getSummaries" style="height:350px; overflow-y: auto;" >
                        <el-table-column width="55">
                          <template slot-scope="scope">
                            <input
                              type="radio"
                              name="notifications"
                              v-model="selectedRow"
                              :value="scope.row.id"
                              @click="toggleCheck(scope.row)"
                            />
                          </template>
                      </el-table-column>
                        <el-table-column
                            label="ID"
                            prop="id"
                            width="80"
                          >
                            <template scope="scope">
                            <label style="cursor: pointer;" @click="gotToCliques(scope.row)">
                              <u>
                                {{ scope.row.id }}
                              </u>
                            </label>
                          </template>
                          </el-table-column>
                        <el-table-column
                          label="DOMAIN"
                          prop="domain"
                          width="150"
                          show-overflow-tooltip
                          sortable
                        >
                          <template scope="scope">
                            <div v-if="scope.row.domain"> {{ formatDomain(scope.row.domain)  }} </div>
                            <div v-else > Divers </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="TOTAL"
                          prop="count_tokens"
                          width="120"
                          show-overflow-tooltip
                          align="center"
                          sortable
                        >
                          <template scope="scope"> {{ scope.row.count_tokens }} </template>
                        </el-table-column>
                        <el-table-column
                          label="REUSSIS"
                          prop="count_tokensSuccess"
                          width="120"
                          show-overflow-tooltip
                          align="center"
                          sortable
                        >
                          <template scope="scope"> {{ scope.row.count_tokensSuccess }} </template>
                        </el-table-column>
                        <el-table-column
                          label="ECHOUES"
                          prop="count_tokensFailure"
                          width="120"
                          show-overflow-tooltip
                          align="center"
                          sortable
                        >
                          <template scope="scope"> {{ scope.row.count_tokensFailure }} </template>
                        </el-table-column>
                        <el-table-column
                          label="SUPPRIMES"
                          prop="count_deletedToken"
                          width="130"
                          show-overflow-tooltip
                          sortable
                        >
                        <template scope="scope"> {{ scope.row.count_deletedToken }} </template>
                        </el-table-column>
                        <el-table-column
                          label="A RENVOYES"
                          prop="count_retryToken"
                          width="150"
                          show-overflow-tooltip
                          sortable
                        >
                        <template scope="scope"> {{ scope.row.count_retryToken }} </template>
                        </el-table-column>
                        <el-table-column
                          label="DATE"
                          prop="created_at"
                          width="200"
                          show-overflow-tooltip
                          sortable
                        >
                          <template scope="scope"> {{ formatDate(scope.row.created_at,true) }} </template>
                        </el-table-column>
                      </el-table>
                      <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div>
                            <p class="card-category">{{ $t('labels.Datatable.Showing') }} {{ from2 + 1 }} {{ $t('labels.Datatable.to') }} {{ to2 }} {{ $t('labels.Datatable.of') }} {{ total2 }} {{ $t('labels.Datatable.entries') }}</p>
                        </div>
                        <base-pagination
                          class="pagination-no-border"
                          v-model="pagination2.currentPage"
                          :per-page="pagination2.perPage"
                          :total="total2"
                        ></base-pagination>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <h3 class="col-6 card-title text-uppercase text-left" v-if="switchChart === 'IMPRESSION'" >Impression <b> {{ countReceivedNotif }} </b> </h3>
                      <h3 class="col-6 card-title text-uppercase text-left" v-if="switchChart === 'CLIQUE'" >Clics <b> {{ countCliques }} </b> </h3>
                      <div class="col-6 column-content text-right">
                        <base-button
                            v-if="$apollo.queries.getCountNotif.loading && switchChart === 'IMPRESSION'"
                            loading
                            :class="{ active: switchChart === 'IMPRESSION' }"
                            class="btn btn-sm btn-info btn-simple"
                            >IMPRESSIONS...
                        </base-button>
                        <base-button
                            v-else
                            class="btn btn-sm btn-info btn-simple"
                            :class="{ active: switchChart === 'IMPRESSION' }"
                            @click="switchChartTrack"
                            >IMPRESSIONS
                        </base-button>
                        <base-button
                            v-if="loadingResponse && switchChart === 'CLIQUE'"
                            loading
                            :class="{ active: switchChart === 'CLIQUE' }"
                            class="btn btn-sm btn-info btn-simple"
                            >CLICS...
                        </base-button>
                        <base-button
                            v-else
                            class="btn btn-sm btn-info btn-simple"
                            :class="{ active: switchChart === 'CLIQUE' }"
                            @click="switchChartTrack"
                            >CLICS
                        </base-button>
                      </div>
                    </div>
                    <div class="row h-100" v-if="switchChart === 'IMPRESSION'">
                      <div class="col h-100">
                        <div class="card-body h-100">
                          <bar-chart
                          :chart-data="barChart2.chartData"
                          :extra-options="barChart2.extraOptions"
                          :gradient-colors="barChart2.gradientColors"
                          :gradient-stops="barChart2.gradientStops"
                          >
                          </bar-chart>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="switchChart === 'CLIQUE'">
                      <div class="card-body h-100">
                            <bar-chart
                             v-if='(!loadingResponse)'
                            :chart-data="barChart1.chartData"
                            :extra-options="barChart1.extraOptions"
                            :gradient-colors="barChart1.gradientColors"
                            :gradient-stops="barChart1.gradientStops"
                            >
                            </bar-chart>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
 </div>
    </div>


</template>

<script>
import {Select, Option, DatePicker} from 'element-ui'
import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import { Table, TableColumn } from 'element-ui';
import moment from 'moment';
import { BasePagination } from "src/components";
import fab from 'vue-fab'
import Modal_selfEvent from 'src/components/Modal_selfEvent'




let CurentDate = new Date()

export default {
  apollo: {
    getSentNotif: {
      client: "AUTH",
      query: gql`query ($startDate: Date, $endDate: Date)
            {
              getSentNotif (
                              startDate: $startDate
                              endDate: $endDate
                            )
              {
                id
                domain
                topic
                count_tokens
                count_tokensSuccess
                count_tokensFailure
                count_deletedToken
                count_retryToken
                created_at
                updated_at
              }
            }`,
       fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        let count_tokens = 0
        let count_tokensSuccess = 0
        let count_tokensFailure = 0
        let count_deletedToken = 0
        let count_retryToken = 0
        for (let i = 0; i < data.getSentNotif.length; i++) {
          count_tokens += data.getSentNotif[i].count_tokens
          count_tokensSuccess += data.getSentNotif[i].count_tokensSuccess
          count_tokensFailure += data.getSentNotif[i].count_tokensFailure
          count_deletedToken += data.getSentNotif[i].count_deletedToken
          count_retryToken += data.getSentNotif[i].count_retryToken
        }
        this.count_tokens = count_tokens
        this.count_tokensSuccess = count_tokensSuccess
        this.count_tokensFailure = count_tokensFailure
        this.count_deletedToken = count_deletedToken
        this.count_retryToken = count_retryToken
        this.sentNotif = data.getSentNotif
        this.SkipQueryGetSentNotif = true

      },
      skip() {
        return this.SkipQueryGetSentNotif
      },
      variables() {
        return {
          startDate: this.StartMonth,
          endDate: this.EndMonth,
        }
      }
    },
    getCountNotif: {
      client: "AUTH",
      query: gql`query ($startDate: Date, $endDate: Date)
            {
              getCountNotif (
                              startDate: $startDate
                              endDate: $endDate
                            )
              {
                date
                sumCountSent
                sumCountReceived
              }
            }`,
       fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        let dates = data.getCountNotif.map(obj => {
          return this.getDateInFrench(obj.date)
        });
        let sumCountReceived = data.getCountNotif.map(obj => obj.sumCountReceived);
        let sum = sumCountReceived.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        this.countReceivedNotif = sum

        let chartData = {
          labels: dates,
          datasets: [
              {
                  label: "Notifications reçues",
                  fill: true,
                  fillColor: "blue",
                  borderColor: config.colors.myAfill,
                  borderWidth: 2,
                  borderDash: [],
                  data: sumCountReceived
              }
          ]
        }
        this.barChart2.chartData = chartData;
        this.SkipReceivedCountNotif = true
      },
      skip() {
        return this.SkipReceivedCountNotif
      },
      variables() {
        return {
          startDate: this.StartMonth,
          endDate: this.EndMonth,
        }
      }
    },
    getTokenByDomain: {
      client: "AUTH",
      query: gql`query ($startDate: Date, $endDate: Date)
            {
              getTokenByDomain (
                              startDate: $startDate
                              endDate: $endDate
                            )
              {
                tokenPeriodMap{
                    domain,
                    nb_token
                }
                total_token
              }
            }`,
      fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        let tokenByDomain = data.getTokenByDomain[0].tokenPeriodMap
        let domaines = tokenByDomain.map(obj => {
          if(!obj.domain){
            return "domaine non définie"
          }
          return obj.domain.replace('https://','')
        });
        let tokens = tokenByDomain.map(obj => obj.nb_token);
        this.totalToken = data.getTokenByDomain[0].total_token
        this.sumToken = tokens.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        let chartData = {
          labels: domaines,
          datasets: [
              {
                  label: "Tokens inscrits",
                  fill: true,
                  fillColor: "blue",
                  borderColor: config.colors.myAfill,
                  borderWidth: 2,
                  borderDash: [],
                  data: tokens
              }
          ]
        }
        this.barChart3.chartData = chartData;
        this.SkipQueryGetTokens = true
      },
      skip() {
        return this.SkipQueryGetTokens
      },
      variables() {
        return {
          startDate: this.StartMonth,
          endDate: this.EndMonth,
        }
      }
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    BarChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    fab,
    Modal_selfEvent

  },
  watch: {
    'PeriodeSelectsForMonth.Period'(value){
        if(value == "CURRENTMONTH") {
            this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+ 1) +"-01"
            this.EndMonth = moment(this.StartDate).endOf('month').format('YYYY-MM-DD');
            this.EndDate = ''

        } else if(value == "LASTMONTH") {
            this.StartDate = CurentDate.getFullYear() +"-"+ CurentDate.getMonth() +"-01"
            this.EndMonth = moment(this.StartDate).endOf('month').format('YYYY-MM-DD');
            this.EndDate = ''
        }
    },
  },
  data() {
    return {
      start:'',
      end:'',
      count_tokens:0,
      count_tokensSuccess:0,
      count_tokensFailure:0,
      count_deletedToken:0,
      count_retryToken:0,
      ids:[],
      sumToken:'',
      totalToken:'',
      StartDate: moment().startOf('month').format('YYYY-MM-DD'),
      EndDate:'',
      countReceivedNotif:null,
      countCliques:null,
      loadingResponse:false,
      SkipQueryGetTokens : true,
      SkipQueryGetSentNotif: true,
      SkipReceivedCountNotif:true,
      selectedRow:null,
      switchChart:'IMPRESSION',
      token:'',
      sentNotif:[],
      message:"",
      pagination2: {
        perPage:10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100, 500],
        total: 0
      },
      total2: 0,
      dataLogfcm : {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      StartMonth: null,
      EndMonth:  moment().endOf('month').format('YYYY-MM-DD'),
      PeriodeSelectsForMonth: {
        Period: 'CURRENTMONTH',
        ListPeriod: [
          { value: "CURRENTMONTH" , label: "Mois en cours"},
          { value: "LASTMONTH" , label: "Mois précédent"}
        ]
      },
      barChart2: {
        chartData : {
          labels: null,
          datasets: [
            {
              label: "Notifications reçues",
              fill: true,
              fillColor: "blue",
              borderColor: config.colors.myAfill,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: null
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,

          "hover": {
            "animationDuration": 0
          },
          animation: {
            duration : 0,
            onComplete : function() {
              var chartInstance = this.chart,
              ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if(screen.width > 550)
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index].toLocaleString();
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            }
          },
          scales: {
            yAxes: [
              {
                 ticks: {
                  suggestedMin: 0,
                  suggestedMax: 1,
                  // padding: 30,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value ;
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  // padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.MyAfillGradient,
        gradientStops: [1, 0]
      },
      barChart3: {
        chartData : {
          labels: null,
          datasets: [
            {
              label: "Nombre de cliques",
              fill: true,
              fillColor: "blue",
              borderColor: config.colors.myAfill,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: null
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,

          "hover": {
            "animationDuration": 0
          },
          animation: {
            duration : 0,
            onComplete : function() {
              var chartInstance = this.chart,
              ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if(screen.width > 550)
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index].toLocaleString();
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            }
          },
          scales: {
            yAxes: [
              {
                 ticks: {
                  //suggestedMin: 999,
                  //suggestedMax: 1000,
                  // padding: 30,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value ;
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  // padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.MyAfillGradient,
        gradientStops: [1, 0]
      },
      barChart1: {
        chartData : {
          labels: null,
          datasets: [
            {
              label: "Nombre de cliques",
              fill: true,
              fillColor: "blue",
              borderColor: config.colors.myAfill,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: null
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,

          "hover": {
            "animationDuration": 0
          },
          animation: {
            duration : 0,
            onComplete : function() {
              var chartInstance = this.chart,
              ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if(screen.width > 550)
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index].toLocaleString();
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            }
          },
          scales: {
            yAxes: [
              {
                 ticks: {
                  //suggestedMin: 999,
                  //suggestedMax: 1000,
                  //stepSize: 1,
                  precision: 0,
                  padding: 30,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value ;
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.MyAfillGradient,
        gradientStops: [1, 0]
      }
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 3) {
          sums[index] = this.count_tokens.toLocaleString();
          return;
        }
        else if( index === 4) {
          sums[index] = this.count_tokensSuccess;
          return;
        }
        else if( index === 5) {
          sums[index] = this.count_tokensFailure
          return;
        }
        else if( index === 6) {
          sums[index] = this.count_deletedToken
          return;
        }
        else if( index === 7) {
          sums[index] = this.count_retryToken
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    toggleCheck(row) {
        if(this.selectedRow === row.id){
            this.StartMonth = this.start;
            this.EndMonth = this.end;
        }else{
            this.customizeDate(row)
        }
        this.SkipReceivedCountNotif = false
        this.getFCMCliques()
        this.selectedRow = null


    },
    gotToCliques(row){
      let routeData = this.$router.resolve({
        name: "stats_shoots",
        params: { id_campagne: row.id_campagne }
      });
      window.open(routeData.href, "_blank");
    },
    customizeDate(row) {
      let startDate = ''
      let endDate = ''
      const currentIndex = this.filterRequest2.findIndex(item => item.id === row.id);
        if (currentIndex !== -1) {
            startDate = moment(row.created_at).format('YYYY-MM-DD');

        const data = (this.sentNotif.length == this.filterRequest2.length) ? this.filterRequest2 : this.sentNotif
        //const nextRow = this.filterRequest2.find(item => item.id > row.id);
        const nextRow = data.find(item => item.id > row.id);

        if (nextRow) {
            let dateNexRow = moment(nextRow.created_at).format('YYYY-MM-DD')
          if(startDate == dateNexRow){
            endDate = dateNexRow
          }else{
            endDate = moment(nextRow.created_at).subtract(1, 'day').format('YYYY-MM-DD');
          }

        } else {
          endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
        }
        this.StartMonth = startDate;
        this.EndMonth = endDate;
      }
    },
    switchChartTrack(){
      this.switchChart = this.switchChart === 'IMPRESSION' ? 'CLIQUE' : 'IMPRESSION';
    },
    getFCMCliques(){
      this.loadingResponse = true
      this.axios
         .get("https://apimyaffil.wee-do-it.net/api/count-fcm-cliques",{
                    params: {
              "startDate" : this.StartMonth,
               "endDate" : this.EndMonth
            },
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            this.loadingResponse = false
            let data = response.data.response.result
              let label = []
              let dataSet = []
              let objData = []
              data.forEach((element) => {
                let date = this.getDateInFrench(element.ANNEE+"-"+element.MOIS+"-"+ element.JOUR)
                label.push(date)
                dataSet.push(Number(element.STAT1))
                objData.push({'id' : element.id_campagne, 'date' : element.ANNEE+"-"+element.MOIS+"-"+ element.JOUR})

              });
              this.ids = objData

              let sum = dataSet.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              this.countCliques = sum
              let chartData = {
                labels: label,
                datasets: [
                    {
                        label: "Nombre de cliques",
                        fill: true,
                        fillColor: "blue",
                        borderColor: config.colors.myAfill,
                        borderWidth: 2,
                        borderDash: [],
                        data: dataSet
                    }
                ]
              }
            this.barChart1.chartData = chartData;
            /* const maxValue = Math.max(...dataSet)
            if(maxValue > 1){
              this.barChart1.extraOptions.scales.yAxes[0].ticks.suggestedMax = maxValue+1;
            } */
          })
          .catch(error => {
            this.loadingResponse = false
            console.log('error', error)
          });

    },
    formatDate(dateString, dateTime=false) {
      if(dateTime){
        return moment(dateString).format('DD-MM-YYYY HH:mm:ss');
      }
      return moment(dateString).format('dddd LL').toUpperCase();
    },
    formatDomain(value){
      return value.replace("https://",'')
    },
    sendByMonth() {
        if(!this.StartDate){
            this.StartDate = moment().startOf('month').format('YYYY-MM-DD')
        }
        this.StartMonth = this.StartDate
        this.EndMonth = (!this.EndDate) ? moment(this.StartMonth).endOf('month').format('YYYY-MM-DD') : moment(this.EndDate).endOf('month').format('YYYY-MM-DD');
        if(this.diffDates()<=0) {
            this.$notify({type: 'primary', message: "Période invalide"})
        }

        this.SkipReceivedCountNotif = false
        this.SkipQueryGetTokens = false
        this.SkipQueryGetSentNotif = false
        this.getFCMCliques()
        this.selectedRow = ''
        this.start = this.StartMonth
        this.end = this.EndMonth
    },
    diffDates(){
        var Difference_In_Time = new Date(this.EndMonth).getTime() - new Date(this.StartMonth).getTime();
        return Difference_In_Time / (1000 * 3600 * 24);
    },
    /* reset(){
        this.sentNotif = []
        this.barChart1.chartData.labels = []
        this.barChart1.chartData.datasets = []
        this.sumToken = 0;
        this.barChart2.chartData.labels = []
        this.barChart2.chartData.datasets = []
        this.barChart3.chartData.labels = []
        this.barChart3.chartData.datasets = []
        this.countReceivedNotif = 0;
        this.countCliques = 0;
    }, */
    getDateInFrench(dateToConvert){
      moment.locale('fr');
      let date = moment(dateToConvert);
      return date.format('dddd D MMMM YYYY').toUpperCase();

    },
  },
  created() {
    this.token = localStorage.getItem("token_jwt")
    this.StartMonth = this.StartDate;
    this.SkipQueryGetTokens = false
    this.SkipQueryGetSentNotif= false
    this.SkipReceivedCountNotif = false
    this.getFCMCliques()
    this.start = this.StartMonth
    this.end = this.EndMonth
  },

  computed: {
      filterRequest2(){
      this.sentNotif.forEach(obj2 => {
        const matchingDateObj = this.ids.find(obj1 => {
          const datePartArray2 = obj2.created_at.split(' ')[0];
          return obj1.date === datePartArray2;
        });
        if(matchingDateObj){
          obj2.id_campagne = matchingDateObj.id
        }
      });
      let list = this.sentNotif;
      this.total2 = list.length;
      return list.slice(this.from2, this.to2)
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    period(){
        moment.locale('fr');
        const start = moment(this.start);
        const end = moment(this.end);
        if(start.format('MMMM YYYY') === end.format('MMMM YYYY')){
            return [start.format('MMMM YYYY')]
        }
        return [start.format('MMMM YYYY'), end.format('MMMM YYYY')]
    }
  }
}
</script>
