<template>
  <card>
    <!-- <base-input label="PIXEL CLICK" class="col-8">
      <textarea 
        class="form-control"
        v-model="pixel_click"
        rows="3">
      </textarea>
    </base-input>
    <base-input label="PIXEL LEAD" class="col-8">
      <textarea 
        class="form-control"
        v-model="pixel_lead"
        rows="5">
      </textarea>
    </base-input> -->
    <base-input label="VOTRE TRACKINGPIXEL" class="col-8">
      <textarea 
        class="form-control"
        v-model="trackingpixel"
        rows="5">
      </textarea>
    </base-input>
    <card>
      <p>#ORDERID# Renvoie l'identifiant de la commande (toujours disponible)</p>
      <p>#SubID# Renvoie le SubID (toujours disponible)</p>
      <p>#xtra# Renvoie le TC (si entré)</p>
      <p>#trans# Renvoie le montant de la transaction</p>
      <p><u>EXEMPLE:</u></p>
      <p><strong>LANDING PAGE URL : </strong> http://www.weedoit.fr/?tc=12</p>
      <p><strong>VOTRE TRACKINGPIXEL:</strong> http://www.monsite.fr/?tc=#TC#</p>
      <br>
      <base-button type="default" @click="envoyer">ENVOYER</base-button>
    </card>
  </card>
</template>

<script>
import gql from "graphql-tag";

export default {
	data() {
		return {
      //pixel_click: '',
      //pixel_lead: '',
      trackingpixel: '',
      identifiant: '',
      nom_campagne: ''
    }
	},
	apollo: {
		show_pixels: {
			query: gql` query ($identifiant: String)
			{
				show_pixels(identifiant: $identifiant)
				{
          pixel
				}
			}`,
			update: data => data,
			result({ data, loader, networkStatus }) {
        this.trackingpixel = data.show_pixels.pixel
			},
			variables() {
				return {
					identifiant: this.$route.params.identifiant,
				}
			}
		}
  },
  mounted() {
    this.identifiant = this.$route.params.identifiant
    this.nom_campagne = this.$route.params.nom_campagne
    //this.pixel_click = "<img src='http://www.weedoit.fr/tracking/aff.php?id="+this.identifiant+"' width='1' height='1'>"
    //this.pixel_lead = "<img src='http://www.weedoit.fr/tracking/lead.php?campagne="+this.nom_campagne+"'&email=[] width='1' height='1'>"
  },
  methods: {
    envoyer(){
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $identifiant: String
            $trackingpixel: String
          ) {
              update_pixel(
                identifiant: $identifiant
                trackingpixel: $trackingpixel
              )
              {
                state
              }
        }`,
         variables: {
          identifiant: this.identifiant,
          trackingpixel: this.trackingpixel
        },
      }).then(data => {
        if(data.data.update_pixel.state)
        {
          this.$notify({type: "success", verticalAlign: 'top', horizontalAlign: 'right', message: 'Le pixel a été bien modifié.', duration: 5000})
        }
      }).catch((error) => {
        // Error
        console.error(error)
      })
    }
  }
}
</script>