<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t("labels.TitlePageObjectif").toUpperCase() }}</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.Announcer').toUpperCase()">
                        <el-select filterable
                          :disabled="$apollo.queries.listeObjectifs.loading"
                          class="select-primary"
                          placeholder="Choisissez"
                          v-model="annonceurs.annonceur">
                          <el-option
                            class="select-primary"
                            value=""
                            label="">
                          </el-option>
                          <el-option
                            v-for="option in annonceurs.listeAnnonceurs"
                            class="select-primary"
                            :value="option.id_client"
                            :label="option.societe_client.toUpperCase()"
                            :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input :label="$t('table.columns.Campagne').toUpperCase()">
                        <el-select filterable
                          :disabled="$apollo.queries.listeObjectifs.loading"
                          class="select-primary"
                          placeholder="Choisissez"
                          v-model="campagnes.campagne">
                          <el-option
                            class="select-primary"
                            value=""
                            label="">
                          </el-option>
                          <el-option 
                            v-for="option in campagnes.listeCampagnes"
                            class="select-primary"
                            :value="option.id_programme"
                            :label="option.Titre.toUpperCase()"
                            :key="option.id_programme">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input label="TRAFFIC MANAGERS">
                        <el-select filterable
                          :disabled="$apollo.queries.listeObjectifs.loading"
                          class="select-primary"
                          placeholder="Choisissez"
                          v-model="tm_objectifs.tm_objectif">
                          <el-option
                            class="select-primary"
                            value=""
                            label="">
                          </el-option>
                          <el-option 
                            v-for="option in tm_objectifs.listeTM_objectifs"
                            class="select-primary booking-table-rows"
                            :value="option.email_client"
                            :label="option.prenom_client +' '+ option.nom_client"
                            :key="option.email_client">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <base-input label="DATES">
                        <el-select 
                          :disabled="$apollo.queries.listeObjectifs.loading"
                          class="select-primary"
                          placeholder="Choisissez"
                          v-model="dates_objectifs.date">
                          <el-option 
                            v-for="option in dates_objectifs.listeDates_objectifs"
                            class="select-primary"
                            :value="option"
                            :label="option.toUpperCase()"
                            :key="option">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                </div>
              </div>
              <div class="mt-4">
                <div class="form-group col-auto mx-auto">
                  <base-button 
                    v-if="$apollo.queries.listeObjectifs.loading" 
                    loading 
                    class="btn btn-just-icon btn-default float-left"
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <base-button 
                    v-else 
                    class="btn btn-just-icon btn-default float-left mr-2 d-block" 
                    @click="search"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                  <!-- <base-button 
                    class="btn btn-just-icon btn-default d-block float-right" 
                    @click="resetFilter"
                    >{{ $t("buttons.BtnReset").toUpperCase() }}</base-button> -->
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <collapse :multiple-active="false" :active-index="0">
            <collapse-item :title="$t('labels.ObjectiveSummary')">
              <center v-if="$apollo.queries.SyntheseObjectif.loading">
                <base-button 
                  loading
                  class="btn btn-just-icon btn-default" 
                ></base-button>
              </center>
              <div class="row" v-else>
                <card class="col-lg-3 col-md-6 col-sm-12" v-for="(obj, i) in GroupedObjectif" :key="i">
                    <label class="text-uppercase">
                      {{ (tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === obj.manager)) ? tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === obj.manager).prenom_client +' '+ tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === obj.manager).nom_client : ""  }}
                    </label>
                    <div
                      style="width: 100%; font-weight: bold; height: 40px; position: absolute; top: 50%; left: 0; margin-top: -10px; line-height:19px; text-align: center; z-index: 999999999999999"
                      :style="CheckObjectif(obj.ca, obj.ca_objectif) ? 'color: green' : 'color: red'"
                      >
                      {{ calculatePRC(obj.ca, obj.ca_objectif) }}
                    </div>
                    <pie-chart
                      :chart-data="setdataCA(obj.ca_objectif, obj.ca)"
                      :extra-options="pieChart1.extraOptionsCA"
                    >
                    </pie-chart>
                </card>
              </div>
            </collapse-item>
          </collapse>
        </card>
        <card>
        <div class="card-body">
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>

                <!-- <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input> -->
              </div>
              <el-table :data="queriedData" show-summary :summary-method="getSummaries" row-class-name="booking-table-rows">
                <el-table-column
                  prop="Titre"
                  :label="$t('table.columns.Campagne')"
                  class="table-striped"
                  min-width="130"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="societe_client"
                  :label="$t('table.columns.Announcer')"
                  class="table-striped"
                  min-width="140"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="manager"
                  label="tm"
                  class="table-striped"
                  min-width="130"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === scope.row.manager)) ? tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === scope.row.manager).prenom_client +' '+ tm_objectifs.listeTM_objectifs.find(tm => tm.email_client === scope.row.manager).nom_client : ""  }}
                  </template>
                </el-table-column>
                
                <!-- <el-table-column
                  style="font-size: 10px;"
                    prop="prenom_client"
                    label="TM"
                    class="table-striped"
                    sortable
                  >
                  <template scope="props">
                    {{ props.row.prenom_client }}
                  </template>
                </el-table-column> -->

                <el-table-column
                    prop="leads_objectif"
                    label="VOLUME"
                    class="table-striped"
                    min-width="160"
                    sortable
                  >
                  <template scope="props">
                    <base-input 
                      type="text"
                      @blur="updateObjectifLeads($event, props.row)"
                      placeholder="Volume des leads" :value="props.row.leads_objectif > 0 ? formatThounsends(props.row.leads_objectif) : 0 "/>
                  </template>
                </el-table-column>

                <el-table-column
                    prop=""
                    :label="'Obj '+ $t('Pages.dashboard.ca')"
                    class="table-striped"
                    min-width="130"
                    sortable
                    show-overflow-tooltip
                    :sort-method="CustomSort_CaObj"
                  >
                  <template scope="props">
                    <template v-if="props.row.campagne == 'affichage'">
                      {{ formatThounsends(props.row.prix_annonceur_cpc * props.row.leads_objectif, true) + ' €' }}
                    </template>
                    <template v-else>
                      {{ formatThounsends(props.row.prix_annonceur * props.row.leads_objectif, true) + ' €' }}
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                    prop="leads"
                    label="Leads"
                    class="table-striped"
                    min-width="100"
                    sortable
                    show-overflow-tooltip
                  >
                  <template scope="props">
                    {{ props.row.leads > 0 ? formatThounsends(props.row.leads) : 0 }}
                  </template>
                </el-table-column>

                <el-table-column
                    prop="click"
                    :label="$t('table.columns.clicks')"
                    class="table-striped"
                    min-width="100"
                    sortable
                    show-overflow-tooltip
                  >
                  <template scope="props">
                    {{ props.row.click > 0 ? formatThounsends(props.row.click) : 0 }}
                  </template>
                </el-table-column>
                <el-table-column
                    prop="ca"
                    :label="$t('Pages.dashboard.ca')"
                    class="table-striped"
                    min-width="100"
                    sortable
                    show-overflow-tooltip
                  >
                  <template scope="props">
                    {{ formatThounsends(props.row.ca, true) + ' €'}}
                  </template>
                </el-table-column>

                <el-table-column
                  label="Shoots Att/Env"
                  class="table-striped"
                  min-width="140"
                  show-overflow-tooltip
                >
                  <template scope="props">
                    {{ props.row.shootsAttente + '/' + props.row.shootsEnvoye }}
                  </template>
                </el-table-column>
                
                <el-table-column
                    prop="prc_obj"
                    label="% Objectif"
                    class="table-striped"
                    min-width="150"
                    sortable
                    :sort-method="CustomSort_prcObj"
                  >
                  <template scope="props">
                      
                      <div style="width: 100px; height: 80px; float: left; position: relative;">
                        <div
                          style="width: 100%; font-weight: bold; height: 40px; position: absolute; top: 50%; left: 0; margin-top: -10px; line-height:19px; text-align: center; z-index: 999999999999999"
                          :style="CheckObjectif(props.row.leads, props.row.leads_objectif) ? 'color: green' : 'color: red'"
                          >
                          {{ calculatePRC(props.row.leads, props.row.leads_objectif) }}
                        </div>
                        <pie-chart
                          style="height: 100%"
                          :chart-data="setdata(props.row.leads_objectif,props.row.leads)"
                          :extra-options="pieChart1.extraOptions"
                          :height="55">
                        </pie-chart>
                      </div>
                    </template>
                    
                </el-table-column>

                <el-table-column
                  label="Extract"
                  min-width="100px"
                >
                  <template scope="scope">
                    <el-tooltip
                      content="Extract TM"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="DownloadCSV(scope.row, 'tm')"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                        :disabled="Loading"
                      >
                        <i class="fas fa-file-download icon-large"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Extract global"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="DownloadCSV(scope.row, 'global')"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                        :disabled="Loading"
                      >
                        <i class="fas fa-file-download icon-large"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import PieChart from 'src/components/Charts/PieChart'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, Collapse, CollapseItem } from 'src/components'
import Fuse from 'fuse.js'
import gql from 'graphql-tag'
import swal from 'sweetalert2'

let today = new Date();

export default {
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    PieChart,
    Collapse,
    CollapseItem
  },
  apollo: {
    Filter: {
      query : gql`query {
        annonceurs{
          id_client
          societe_client
        },
        programmes_objectifs{
          id_programme
          Titre
        },
        listManager(
          bloque: "non"
          etat: "valide"
          droit: ["ma", "tem"]
          orderBy: [
            { field: "prenom_client", order: ASC }
            { field: "nom_client", order: ASC }
          ]
        ) {
          id_client
          nom_client
          email_client
          prenom_client
        },
        yearAndMonths{
          mois_annee
        }
      }`,
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.dates_objectifs.date = data.yearAndMonths[0].mois_annee[1] //get latest month and year as default date
        this.dates_objectifs.listeDates_objectifs = data.yearAndMonths[0].mois_annee
        this.annonceurs.listeAnnonceurs = data.annonceurs
        this.campagnes.listeCampagnes = data.programmes_objectifs
        this.tm_objectifs.listeTM_objectifs = data.listManager
        // set manager select
        let manager = this.tm_objectifs.listeTM_objectifs.find(m => parseInt(m.id_client) === JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil)
        if (manager) {
          this.tm_objectifs.tm_objectif = manager.email_client
        }
      }
    },
    SyntheseObjectif: {
      query : gql` query ($mois_annee: String) {
        Syntheseliste_objectifs (mois_annee: $mois_annee) {
          campagne
          id_programme
          id_objectif_campagne
          Titre
          societe_client
          leads_objectif
          ca_obj
          leads
          ca
          shootsAttente
          shootsEnvoye
          prc_obj
          manager
          prix_annonceur
          provenance
          click
          prix_annonceur_cpc
        }
      }`,
      variables() {
        return {
          mois_annee: this.dates_objectifs.date
        }
      },
      result({ data, loader, networkStatus }) {
        let grouped = alasql('SELECT manager, SUM(CASE WHEN campagne = "lead" THEN (leads_objectif * prix_annonceur) WHEN campagne = "cpm" THEN (leads_objectif * prix_annonceur) ELSE (leads_objectif * prix_annonceur_cpc) END) AS ca_objectif,sum(CASE WHEN campagne = "lead" THEN (leads * prix_annonceur) WHEN campagne = "cpm" THEN (leads_objectif * prix_annonceur) ELSE (leads * prix_annonceur_cpc) END) AS ca FROM ? GROUP BY manager',[data.Syntheseliste_objectifs])
        this.GroupedObjectif = grouped.sort((a,b) => (((b.ca * 100) / b.ca_objectif) - (a.ca * 100) / a.ca_objectif)); 
      },
      fetchPolicy: 'network-only',
      update: data => data.Syntheseliste_objectifs,
    },
    listeObjectifs: {
      query : gql` query 
      (
          $id_client: Int,
          $id_programme: Int,
          $tm: String
          $mois_annee: String
        ) 
      {
        liste_objectifs
          (
            id_client: $id_client,
            id_programme: $id_programme,
            tm: $tm,
            mois_annee: $mois_annee
          )
        {
          campagne
          id_programme
          id_objectif_campagne
          Titre
          societe_client
          leads_objectif
          ca_obj
          leads
          ca
          shootsAttente
          shootsEnvoye
          prc_obj
          manager
          prix_annonceur
          provenance
          click
          prix_annonceur_cpc
          idcpart
        }
      }`,
      skip() {
        return this.SkipQuery
      },
      update: data => data.liste_objectifs,
      result({ data, loader, networkStatus }) {
        this.tableData = data.liste_objectifs
        this.total = data.liste_objectifs.length
        let SUMVol = 0
        let SUMObjca = 0
        let SUMLeadreel = 0
        let SUMca = 0
        let ShootsAtt = 0
        let ShootsEnv = 0
        let SUMclics = 0
        data.liste_objectifs.forEach(objectif => {
          SUMVol += objectif.leads_objectif
          SUMObjca += (objectif.campagne == 'affichage') ? (objectif.prix_annonceur_cpc * objectif.leads_objectif) : (objectif.prix_annonceur * objectif.leads_objectif)
          SUMLeadreel += objectif.leads
          SUMca += objectif.ca
          ShootsAtt += objectif.shootsAttente
          ShootsEnv += objectif.shootsEnvoye
          SUMclics += objectif.click
        });
        this.SUMVol = SUMVol
        this.SUMObjca = SUMObjca
        this.SUMLeadreel = SUMLeadreel
        this.SUMca = SUMca
        this.ShootsAtt = ShootsAtt
        this.ShootsEnv = ShootsEnv
        this.SUMclics = SUMclics
        this.SkipQuery = true
      },
      variables() {
        return {
          id_client: this.annonceurs.annonceur,
          id_programme: this.campagnes.campagne,
          tm: this.tm_objectifs.tm_objectif,
          mois_annee: this.dates_objectifs.date
          
        }
      },
      fetchPolicy: 'network-only',
    }
  },
  data() {
    return {
      GroupedObjectif: [],
      Loading: false,
      SkipQuery: true,
      SUMVol:0,
      SUMObjca:0,
      SUMLeadreel:0,
      SUMclics:0,
      SUMca:0,
      ShootsAtt:0,
      ShootsEnv:0,
      annonceurs: {
        annonceur: '',
        listeAnnonceurs: []
      },
      campagnes: {
        campagne: '',
        listeCampagnes: []
      },
      tm_objectifs: {
        tm_objectif: '',
        listeTM_objectifs: []
      },
      dates_objectifs: {
        date: '',
        listeDates_objectifs: []
      },
      total: 0,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      searchedData: [],
      tableData: [],
      pieChart1: {
        chartData: {
          labels: ["Leads réels", "Reste"],
          datasets: [{
            label: 'Emails',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#00c09d', '#e2e2e2'],
            borderWidth: 0,
            data: [60,40]
          }]
        },
        extraOptions: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          responsive: true,
          cutoutPercentage: 70,
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest'
          },
          scales: {
            yAxes: [{
              display: 0,
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'transparent',
                color: 'rgba(255,255,255,0.05)'
              }
            }],
            xAxes: [
              {
                display: 0,
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(255,255,255,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  display: false
                }
              }
            ]
          }
        },
        extraOptionsCA: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          responsive: true,
          cutoutPercentage: 70,
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
              },
              label: function(tooltipItem, data) {
                return data['datasets'][0]['data'][tooltipItem['index']].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";
              }
              // afterLabel: function(tooltipItem, data) {
              //   var dataset = data['datasets'][0];
                
              //   var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
              //   return '(' + percent + '%)';
              // }
            },
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest'
          },
          scales: {
            yAxes: [{
              display: 0,
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'transparent',
                color: 'rgba(255,255,255,0.05)'
              }
            }],
            xAxes: [
              {
                display: 0,
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(255,255,255,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  display: false
                }
              }
            ]
          }
        }
      }
    }
  },
  watch: {
    listeObjectifs(value) {}
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['societe_client'],
      threshold: 0.3
    });
  },
  methods: {
    CustomSort_prcObj (a, b) {
      let a1 = (a.leads * 100) / a.leads_objectif;
      let b1 = (b.leads * 100) / b.leads_objectif;

      if (!a.leads_objectif) {
        a1 = 0
      }
      
      if (!b.leads_objectif) {
        b1 = 0
      }

      return a1 - b1
    },
    CustomSort_CaObj(a, b) {
      return (a.prix_annonceur * a.leads_objectif) - (b.prix_annonceur * b.leads_objectif)
    },
    CheckObjectif(leads, leads_objectif) {
      let date = this.dates_objectifs.date.split("/")
      let dayofMounth = new Date(today.getFullYear(), (today.getMonth()+1), 0).getDate()
      let leadofday = leads_objectif
      if (parseInt(date[0]) == (today.getMonth()+1) && parseInt(date[1]) == today.getFullYear() ) {
        leadofday = (leads_objectif / dayofMounth) * today.getDate()
      }      

      return leadofday <= leads 
    },
    calculatePRC(leads, leads_objectif) {
      if (!leads_objectif)
        return ""
      if (!leads)
        return "0 %"
      
      return this.formatThounsends((leads * 100) / leads_objectif, true, 0) + " %"
    },
    formatThounsends: function(value, fixe = false, number = 0){
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    resetFilter(){
      this.annonceurs.annonceur = ''
      this.campagnes.campagne = ''
      this.tm_objectifs.tm_objectif = ''
      // this.dates_objectifs.date = ''
    },
    setdata(leads_volume,leads_completed) {
      leads_volume = (leads_volume === null) ? 0 : leads_volume
      leads_completed = (leads_completed === null) ? 0 : leads_completed
      var leads_reste = leads_volume - leads_completed

      if(leads_reste < 0){
        leads_reste = 0
      }
      
      var chartdata = {
        labels: ["Leads réels", "Reste"],
        datasets: [
          {
            label: 'Leads',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#00c09d', '#ff4d4d'],
            borderWidth: 0,
            data: [leads_completed,leads_reste]
          }
        ]
      }
      return chartdata
    },
    setdataCA(leads_volume,leads_completed) {
      leads_volume = (leads_volume === null) ? 0 : leads_volume
      leads_completed = (leads_completed === null) ? 0 : leads_completed
      var leads_reste = leads_volume - leads_completed

      if(leads_reste < 0){
        leads_reste = 0
      }
      
      var chartdata = {
        labels: ["CA réels", "Reste"],
        datasets: [
          {
            label: 'Leads',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#00c09d', '#ff4d4d'],
            borderWidth: 0,
            data: [leads_completed,leads_reste]
          }
        ]
      }
      return chartdata
    },
    updateObjectifLeads(e, row){

      var newVolume = e.target.value;
      var id_programme = row.id_programme
      var id_objectif_campagne = row.id_objectif_campagne

      if((parseInt(newVolume) || 0) == (parseInt(row.leads_objectif) || 0))
        return;

      swal.fire({
        // title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier le volume de cet objectif!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
          // Query
          mutation: gql`mutation (
              $id_objectif_campagne: Int
              $volume: Int
              $id_annonceur: Int
              $mois_annee: String
            ) {
                updateObjectifLeads(
                  id_objectif_campagne: $id_objectif_campagne
                  id_annonceur: $id_annonceur
                  volume: $volume
                  mois_annee: $mois_annee
                )
                {
                  id_objectif_campagne
                  id_programme
                  nombre_leads
                }
            }`,
            variables: {
              id_objectif_campagne: id_objectif_campagne,
              volume: newVolume,
              id_annonceur: id_programme,
              mois_annee: this.dates_objectifs.date
            },
          }).then((data) => {
            this.tableData.forEach((value, index) => {
              if(value.id_programme == data.data.updateObjectifLeads.id_programme){
                this.tableData[index].leads_objectif = data.data.updateObjectifLeads.nombre_leads
              }
            });
            // Result
            this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: 'L\'objectif a été bien modifié.'});
            if (row.id_objectif_campagne == null) {
              row.id_objectif_campagne = data.data.updateObjectifLeads.id_objectif_campagne
            }
          }).catch((error) => {
            // Error
            console.error(error)
          })
        }
      })
    },
    search() {
      this.SkipQuery = false
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
         
        if (index === 0) {
          sums[index] = ''
          return
        }
        if (index === 3) {
          sums[index] = this.formatThounsends(this.SUMVol)
          return
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMObjca, true) + " €"
          return
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMLeadreel)
          return
        }
        else if( index === 6) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return
        }
        else if( index === 7) {
          sums[index] = this.formatThounsends(this.SUMca, true) + " €"
          return
        }
        else if( index === 8) {
          sums[index] = this.ShootsAtt+'/'+this.ShootsEnv
          return
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    async DownloadCSV(row, option) {
      this.Loading = true
      var formData = new FormData();
      let id_programme = 0
      if (option == 'tm') {
        id_programme = row.id_programme
      }
      else {
        if (row.idcpart != 0) {
          id_programme = row.idcpart
        }
        else {
          id_programme = row.id_programme
        }
      }
      formData.append("compagne", id_programme);
      formData.append("action", option);
      formData.append("mois", this.dates_objectifs.date.split('/')[0]);
      formData.append("annee", this.dates_objectifs.date.split('/')[1]);
      const url_api = "https://apimyaffil.wee-do-it.net/api/export-leadsObjectif-csv"
      let accessToken = localStorage.getItem("token_jwt")
      await this.axios.post(url_api, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${accessToken}`
        },
        responseType: "blob"
        }).then(response => {
          const filename = "rapport_statistiques.csv";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.Loading = false
        }).catch(error => {
          console.log("error occured")
          this.Loading = false
        });
    }
  }
}
</script>
<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>