<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-left">
                <h2 class="card-title">{{ $t('titlePages.WMGlobalstatistics') }}</h2>
              </div>
              <div class="col-md-12">
                <div class="row">

                  <base-input class="col-md-3" :label="$t('table.columns.Startdate')" :error="getError($t('table.columns.Startdate'))">
                    <el-date-picker 
                      :name="$t('table.columns.Startdate')"
                      v-validate="'required'"
                      v-model="StartDate" 
                      type="date" 
                      value-format="dd/MM/yyyy" 
                      :placeholder="$t('table.columns.Startdate').toUpperCase()"
                    ></el-date-picker>
                  </base-input>

                  <base-input class="col-md-3" :label="$t('table.columns.Enddate')" :error="getError($t('table.columns.Enddate'))">
                    <el-date-picker 
                      :name="$t('table.columns.Enddate')"
                      v-validate="'required'"
                      v-model="EndDate" 
                      type="date" 
                      value-format="dd/MM/yyyy" 
                      :placeholder="$t('table.columns.Enddate').toUpperCase()"
                    ></el-date-picker>
                  </base-input>

                  <div class="col-md-12 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.statLeadMarket.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="p-2" data="black">
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-9 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
              <div class="col-xl-2 col-sm-6">
                <!-- <button class="btn btn-info" @click="ExportTable()">
                  <i class="tim-icons icon-cloud-download-93"></i> EXPORT
                </button> -->
                <base-dropdown
                  title-classes="dropdown-toggle btn btn-primary btn-block btn-info"
                  title="EXPORT"
                >
                  <!-- <a class="dropdown-item" @click="ExportTable('xlsx')">XLSX</a> -->
                  <a class="dropdown-item" @click="ExportTable('csv')">CSV</a>
                </base-dropdown>
              </div>
            </div>
            <!-- show-summary :summary-method="getSummaries"  class="customtable_statsCampEdit"-->
            <el-table :data="queriedData" v-loading="$apollo.queries.statLeadMarket.loading" show-summary :summary-method="getSummaries"  row-class-name="booking-table-rows">
              <el-table-column
                :label="$t('table.columns.Product')"
                prop="Produit"
                show-overflow-tooltip
                sortable
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="Editeur"
                prop="Editeur"
                show-overflow-tooltip
                sortable
                min-width="100"
              ></el-table-column>
              <el-table-column
                :label="$t('table.columns.Campagne')"
                prop="Campagne"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
              </el-table-column>
              <el-table-column
                label="TM"
                prop="TM"
                show-overflow-tooltip
                sortable
                min-width="110"
              >
              </el-table-column>
              <el-table-column
                label="Tag"
                prop="Tag"
                show-overflow-tooltip
                sortable
                min-width="150"
              >
              </el-table-column>
              <el-table-column
                label="Date"
                prop="Date"
                show-overflow-tooltip
                sortable
                min-width="95"
              >
              </el-table-column>
              <el-table-column
                label="leads"
                prop="vlc"
                show-overflow-tooltip
                sortable
                min-width="90"
                :sort-method="CustomSort_leads"
              >
              </el-table-column>
              <el-table-column
                label="clic"
                prop="clic"
                show-overflow-tooltip
                sortable
                min-width="90"
              >
              </el-table-column>
              <el-table-column
                label="Rem editeur HT"
                prop="reh"
                show-overflow-tooltip
                sortable
                min-width="145"
              >
                <template scope="scope">
                  {{ scope.row.reh + " €" }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Expenses')"
                prop="Depenses"
                show-overflow-tooltip
                sortable
                min-width="115"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Depenses) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ca réel"
                prop="ca_wm_and_mg"
                show-overflow-tooltip
                sortable
                min-width="115"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca_wm_and_mg, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn, DatePicker, Select, Option } from 'element-ui';
  import { BasePagination } from "src/components";
  import gql from 'graphql-tag'
  import XLSX from 'xlsx'
  import moment from 'moment'

  export default {
    apollo: {
      statLeadMarket: {
        query: gql`
          query($date_debut: String, $date_fin: String) {
            statLeadMarket (date_debut: $date_debut, date_fin: $date_fin){
              Produit
              Editeur
              Campagne
              TM
              Tag
              Date
              vlc
              reh
              Depenses
              clic
              ca_wm_and_mg
            }
            campagneWeedoitCA (date_debut: $date_debut, date_fin: $date_fin) {
              nom_campagne
              ca_wm
              ca_mg
              date
            }
          }
        `,
        udpate: data => data.statLeadMarket,
        skip() {
          return this.skipQuery
        },
        variables() {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndDate
          };
        },
        result({ data, loader, networkStatus }) {
          this.skipQuery = true
          data.campagneWeedoitCA.forEach(element => {
            let find = data.statLeadMarket.find(x => element.nom_campagne === x.Tag && moment(x.Date, "DD/MM/YYYY").isSame(element.date))
            if(find) {
              find.ca_wm_and_mg = Number(element.ca_wm) + Number(element.ca_mg)
            }
          });

          let SommeCa = 0
          let SommeLead = 0
          let SommeClic = 0
          let SommeCaReel = 0
          data.statLeadMarket.forEach(e => {
            SommeCa += parseFloat(e.Depenses)
            SommeLead += parseInt(e.vlc)
            SommeClic += parseInt(e.clic)
            SommeCaReel += Number(e.ca_wm_and_mg)
          });
          this.SommeLead = SommeLead
          this.SommeCa = SommeCa
          this.SommeClic = SommeClic
          this.SommeCaReel = SommeCaReel
        },
        fetchPolicy: 'network-only'
      }
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      BasePagination
    },
    data() {
      return {
        SommeCa: 0,
        SommeLead: 0,
        SommeClic: 0,
        SommeCaReel: 0,
        skipQuery: true,
        EndDate: moment().format('DD/MM/YYYY'),
        StartDate: moment().format('DD/MM/YYYY'),
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [ 50, 150, 300, 500],
          total: 0
        },
        total: 0,
        statLeadMarket: []
      }
    },
    computed: {
      queriedData() {
        this.total = this.statLeadMarket.length
        return this.statLeadMarket.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      ExportTable(format) {
        if (this.statLeadMarket.length) {
          let statLeadMarketArray = JSON.parse(JSON.stringify(this.statLeadMarket));
          statLeadMarketArray.forEach(function(v){
            v.ca_wm_and_mg = Number(v.ca_wm_and_mg).toFixed(2).replace(".", ",")
            delete v.__typename 
          });
          const fileName = 'Lead_LM_'+this.StartDate+"-"+this.EndDate+'.'+format
          var statLeadMarket = XLSX.utils.json_to_sheet(statLeadMarketArray) 
          if (format === 'csv') {
            statLeadMarket = XLSX.utils.sheet_to_csv(statLeadMarket, {FS:";"});
            statLeadMarket = statLeadMarket.replace("vlc","Volume de leads collectes")
            statLeadMarket = statLeadMarket.replace("reh","Rem editeur HT")
            statLeadMarket = statLeadMarket.replace("ca_wm_and_mg","CA reel")
            const url = window.URL.createObjectURL(new Blob([statLeadMarket]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
          else if (format === 'xlsx') {
            var wb = XLSX.utils.book_new() 
            XLSX.utils.book_append_sheet(wb, statLeadMarket, 'WEEDOMARKET') 
            wb.Sheets.WEEDOMARKET.F1.v = "Volume de leads collectes"
            wb.Sheets.WEEDOMARKET.G1.v = "Rem editeur HT"
            XLSX.writeFile(wb, fileName)
          }
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        this.skipQuery = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value : value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 6) {
            sums[index] = this.formatThounsends(this.SommeLead);
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(this.SommeClic);
            return;
          } else if (index === 9) {
            sums[index] = this.formatThounsends(this.SommeCa) + " €";
            return;
          } else if (index === 10) {
            sums[index] = this.formatThounsends(this.SommeCaReel, true, 2) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      CustomSort_leads(a, b) {
        try {
          return parseInt(a) < parseInt(b)
        } catch (error) {
          return false
        }
      }
    },
  }
</script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }
  /* .customtable_statsCampEdit .cell{
    word-break: break-word;
    font-size: 11.5px;
  } */

</style>