<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("labels.LeadsDay") }}</h2>
              </div>
            </div>
            <template v-if="Info">
              <template v-if="Info.manager"><label> MANAGER: {{ Info.manager }}</label><br></template>
              <template v-if="Info.title_programme"><label> {{ $t('table.columns.Program').toUpperCase() }} : {{ Info.title_programme }}</label><br></template>
              <label v-if="Info.editeur"> {{ $t('inputs.Law.Affiliates').toUpperCase() }} : {{ Info.editeur }}</label>
            </template>
            <center>
              <base-button
                @click.native="Daybefore"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-double-left"></i>
              </base-button>

              <label> {{ $t("Pages.dashboard.Statistics") }}  {{ new Date(Start_Date).toLocaleDateString() || '' }}</label>

              <base-button
                @click.native="Dayafter"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-double-right"></i>
              </base-button>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <div class="table-responsive">
              <el-table :data="tableCampagne" :default-sort = "{prop: 'ca', order: 'descending'}" class="customtable" show-summary :summary-method="getSummaries">
                <el-table-column
                  width="420"
                  sortable
                  :label="$t('table.columns.Campagne')"
                  prop="campagne"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  width="88"
                  sortable
                  label="TYPE"
                  prop="type"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if="scope.row.type === 'lead'">
                      CPL
                    </template>
                    <template v-else-if="scope.row.type === 'affichage'">
                      CPC
                    </template>
                    <template v-else-if="scope.row.type === 'cpm'">
                      CPM
                    </template>
                    <template v-else-if="scope.row.type === 'cpa'">
                      CPA
                    </template>
                    <template v-else-if="scope.row.type === 'sms'">
                      SMS
                    </template>
                    <template v-else-if="scope.row.type === 'téléchargement'">
                      CPD
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  width="85"
                  sortable
                  label="IMP"
                  prop="impression"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.impression) }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="94"
                  sortable
                  :label="$t('table.columns.clicks')"
                  prop="clics"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.clics) }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="96"
                  sortable
                  label="LEADS"
                  prop="leads"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.leads) }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="104"
                  sortable
                  :label="$t('table.columns.Sales')"
                  prop="ventes"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ventes) }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  sortable
                  :label="$t('Pages.dashboard.ca')"
                  prop="ca"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ca,true,2) + ' €' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="91"
                  sortable
                  label="CTR"
                  prop="CTR"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.CTR,true,2) + ' %' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="93"
                  sortable
                  label="ECPC"
                  prop="ECPC"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ECPC,true,2) + ' €' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <center v-if="$apollo.queries.detailLeadsEditeur.loading">
            <base-button
              loading 
              class="btn btn-round btn-just-icon btn-default mt-0 d-block"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import gql from 'graphql-tag'
  import {Modal} from 'src/components'
  import { Table, TableColumn } from 'element-ui';
  import moment from 'moment'

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
        return {
          Info: {
            manager: "",
            title_programme: "",
            editeur: ""
          },
          Flag: false,
          SkipQuery: true,
          tableCampagne: [],
          totales: 0,
          totale: {
            impression: 0,
            clics: 0,
            leads: 0,
            ventes: 0,
            ECPM: 0,
            ECPC: 0,
            CA: 0,
            caVente: 0,
          },
          Start_Date: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).jour : '',
          End_Date: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).jour : '',
          Droit: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).droit : null,
          Id_client: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).id_client : null,
          Id_programme: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).id_programme : null,
          title_programme: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).title_programme : null,
          id_annonceur: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).id_annonceur : null
        };
    },
    apollo: {
      detailLeadsEditeur: {
        query : gql` query (
          $date_start: Date!, 
          $date_end: Date!,
          $droit: String!,
          $id_client: Int,
          $id_programme: Int,
          $title_programme: String,
          $StartFrom: Int!,
          $LimitRows: Int!,
          $id_annonceur: Int
        ) 
        {
          detailLeadsEditeur
          (
            date_start:$date_start,
            date_end:$date_end,
            droit:$droit
            id_client:$id_client
            id_programme: $id_programme
            title_programme: $title_programme,
            StartFrom: $StartFrom,
            LimitRows: $LimitRows,
            id_annonceur: $id_annonceur
          )
          {
            caVente
            campagne
            type
            impression
            clics
            leads
            ventes
            ECPC
            CTR
            ca
          }
        }`,
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) 
        {
          this.tableCampagne = [...this.tableCampagne,...data.detailLeadsEditeur]
          this.Flag = data.detailLeadsEditeur.length != 500
          if (!this.Flag) {
            this.totales += 500 
          } else {
            let impression = 0
            let clics = 0
            let leads = 0
            let ventes = 0
            let CA = 0
            let caVente = 0
            let ECPC =  0
            this.tableCampagne.forEach(element => {
              impression += element.impression,
              clics += element.clics,
              leads += element.leads,
              ventes += element.ventes,
              CA += element.ca
              caVente += element.caVente
              ECPC +=  element.ECPC
            });
            this.totale.impression = impression
            this.totale.clics = clics
            this.totale.leads = leads
            this.totale.ventes = ventes
            this.totale.CA = CA
            this.totale.caVente = caVente
            this.totale.ECPC = ECPC
          }
        },
        skip() {
          return JSON.parse(localStorage.getItem('Details')) == null;
        },
        variables() {
          return {
            date_start: moment(this.Start_Date).format('YYYY-MM-DD'), 
            date_end: moment(this.Start_Date).format('YYYY-MM-DD'),
            droit: this.Droit,
            id_client: this.Id_client,
            id_programme: this.Id_programme,
            title_programme: this.title_programme,
            StartFrom: this.totales,
            LimitRows: 500,
            id_annonceur: this.id_annonceur
          }
        }
      }
    },
    methods: {
      Daybefore() {
        this.tableCampagne = []
        this.totale.clics = 0
        this.totale.leads = 0
        this.totale.ventes = 0
        this.totale.CA = 0
        this.totale.caVente = 0
        this.totale.ECPC =  0
        var myDate = new Date(this.Start_Date);
        myDate.setDate(myDate.getDate() - 1)
        this.Start_Date = myDate.getFullYear()+'/'+(myDate.getMonth()+1)+'/'+myDate.getDate();
        this.totales = 0
      },
      Dayafter() {
        this.tableCampagne = []
        this.totale.clics = 0
        this.totale.leads = 0
        this.totale.ventes = 0
        this.totale.CA = 0
        this.totale.caVente = 0
        this.totale.ECPC =  0
        var myDate = new Date(this.Start_Date);
        myDate.setDate(myDate.getDate() + 1)
        this.Start_Date  = myDate.getFullYear()+'/'+(myDate.getMonth()+1)+'/'+myDate.getDate();
        this.totales = 0
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(this.totale.impression);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(this.totale.clics);
            return;
          }
          else if( index === 4) {
            sums[index] = this.formatThounsends(this.totale.leads);
            return;
          }
          else if( index === 5) {
            sums[index] = this.formatThounsends(this.totale.ventes);
            return;
          }
          else if( index === 8) {
            sums[index] = this.formatThounsends(this.totale.ECPC,true,2) + ' €';
            return;
          }
          else if( index === 6) {
            sums[index] = this.formatThounsends(this.totale.CA,true,0) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
    mounted() {
      this.Info = JSON.parse(localStorage.getItem('Details'))
      if(this.Info == null)
        this.$router.push({ name: "Stats Globales" })
      localStorage.removeItem("Details")

      this.$root.$on('Details', (Details) => {
        this.Info =  Commande_Object
        if(this.Info == null) 
          this.$router.push({ name: "Stats Globales" })
      })

    },
  };
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 12px;
  }
</style>