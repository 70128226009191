<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("labels.LeadsMonth") }}</h2>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <base-input>
                      <el-select
                        :disabled="$apollo.queries.shootStatMonth.loading"
                        class="select-primary"
                        v-model="filterDates.date"
                      >
                        <el-option
                          v-for="option in filterDates.listeDates"
                          class="select-primary"
                          :value="option.mois_annee"
                          :label="option.label"
                          :key="option.mois_annee"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-8">
                    <base-button
                      v-if="$apollo.queries.shootStatMonth.loading"
                      loading
                      class="btn btn-just-icon btn-default mt-0 mr-3 d-block"
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}</base-button>
                    <button v-else class="btn btn-just-icon btn-default mt-0 d-block">{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <p class="card-title" v-if="hideExtract">
                  <strong> {{ $t("table.columns.Campagne").toUpperCase() }} :</strong>
                  <span style="color: #988fc3; font-size: 15px;"> {{ extractTag.nom_campagne }}</span>
                </p>
                <p class="card-title" v-else>
                  <strong> {{ $t("inputs.Law.Affiliates").toUpperCase() }} :</strong>
                  <span style="color: #988fc3; font-size: 15px;"> {{ societeClient }}</span>
                </p>
              </div>
            </div>
            <div>
              <base-button
                type="info"
                class="mb-3 pull-right"
                @click="showExtractModal = true"
                v-if="hideExtract"
              >{{ $t("buttons.Extract").toUpperCase() }}</base-button>

              <modal
                :show.sync="showExtractModal"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">{{ $t("labels.ExtractionFromTag") }}</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">{{ $t("table.columns.Campagne").toUpperCase() }}</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ extractTag.nom_campagne }}</span>
                    </div>
                    <form role="form">
                      <div class="col-md-8">
                        <base-input :label="$t('table.columns.Startdate')">
                          <el-date-picker
                            v-model="extractTag.start_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="col-md-8">
                        <base-input :label="$t('table.columns.Enddate')">
                          <el-date-picker
                            v-model="extractTag.end_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="extract()">{{ $t("buttons.Extract").toUpperCase() }}</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <!-- <h2 class="card-title">Globales</h2> -->
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="tableLeads" show-summary :summary-method="getSummaries">
              <el-table-column :label="$t('table.columns.Day')" prop="date" min-width="260">
                <template scope="scope">
                  <label v-if="hideExtract">{{ moment(scope.row.annee+"/"+scope.row.mois+"/"+ scope.row.jour).format('dddd LL').toUpperCase() }}</label>
                  <label v-else style="cursor: pointer;" @click="Details(scope.row)">
                    {{ moment(scope.row.annee+"-"+scope.row.mois+"-"+ scope.row.jour).format('dddd LL').toUpperCase() }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column label="Impressions" prop="impressions" min-width="150">
                <template scope="scope">{{ formatThounsends(scope.row.impressions) }}</template>
              </el-table-column>
              <el-table-column :label="$t('table.columns.clicks')" prop="STAT1" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.STAT1) }}</template>
              </el-table-column>
              <el-table-column label="Leads" prop="STAT2" min-width="100">
                <template scope="scope">
                  <label v-if="hideExtract">{{ scope.row.STAT2 }}</label>
                  <label v-else style="cursor: pointer;" @click="Details(scope.row)">
                    <u>
                      {{ formatThounsends(scope.row.STAT2) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column :label="$t('table.columns.Sales')" prop="vente" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.vente) + ' €' }}</template>
              </el-table-column>
              <el-table-column :label="$t('Pages.dashboard.ca')" prop="ca" min-width="100">
                <template scope="scope">
                  <strong>
                    {{ formatThounsends(scope.row.ca, true, 1) + ' €' }}
                  </strong>
                </template>
              </el-table-column>
              <el-table-column label="CTR" prop="leads" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.ctr, true, 2) + ' %' }}</template>
              </el-table-column>
              <el-table-column label="ECPC" prop="ecpc" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.ecpc, true, 2) + ' €' }}</template>
              </el-table-column>
              <el-table-column label="MER" prop="lm_mer" min-width="90" v-if="hideExtract">
                <template scope="scope">{{ formatThounsends(scope.row.lm_mer) }}</template>
              </el-table-column>
              <el-table-column label="% MER" prop="STAT2" min-width="100" v-if="hideExtract">
                <template scope="scope">
                  <label v-if="scope.row.lm_client === 'WM'" :style="((scope.row.lm_mer / scope.row.STAT2) * 100 < 30 || scope.row.STAT2==0 ) ? 'color: red' : 'color: green'">
                    {{  (scope.row.STAT2 != 0) ? (( scope.row.lm_mer / scope.row.STAT2) * 100).toFixed(2) : (0).toFixed(2) }} %
                  </label>
                  <template v-else>NC</template>
                </template>
              </el-table-column>
              <el-table-column label="CA MG" prop="ca_mg" min-width="110" v-if="this.hideExtract">
                <template scope="scope">
                  <template v-if="scope.row.lm_client === 'WM'">
                    {{ formatThounsends(scope.row.ca_mg, true, 2) + ' €' }}
                  </template>
                  <template v-else>NC</template>
                </template>
              </el-table-column>
              <el-table-column label="CA WM" prop="ca_wm" min-width="110" v-if="this.hideExtract">
                <template scope="scope">
                  <template v-if="scope.row.lm_client === 'WM'">
                    {{ formatThounsends(scope.row.ca_wm, true, 2) + ' €' }}
                  </template>
                  <template v-else>NC</template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('table.columns.Margin')" prop="ca_wm" min-width="110" v-if="this.hideExtract">
                <template scope="scope">
                  <template v-if="scope.row.lm_client === 'WM'">
                    {{ formatThounsends((scope.row.ca_mg+scope.row.ca_wm) - scope.row.cae, true, 2) + ' €' }}
                  </template>
                  <template v-else>NC</template>
                </template>
              </el-table-column>
              <el-table-column :label="'% ' + $t('table.columns.Margin')" prop="ca_mg" min-width="115" v-if="this.hideExtract">
                <template scope="scope">
                  <label v-if="scope.row.lm_client === 'WM'" :style="color_marge(scope.row)">
                    {{ scope.row.cae != 0 ? formatThounsends((((scope.row.ca_mg+scope.row.ca_wm) - scope.row.cae)*100) / scope.row.cae , true, 2) : (0).toFixed(2) }} %
                  </label>
                  <template v-else>NC</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <card>
      <bar-chart style="height: 100%"
                v-if="showBarChart && !$apollo.queries.shootStatMonth.loading && !hideExtract"
                :chart-data="blueBarChart.chartData"
                :gradient-color="blueBarChart.gradientColors"
                :gradient-stops="blueBarChart.gradientStops"
                :extra-options="blueBarChart.extraOptions">
      </bar-chart>
    </card>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import gql from "graphql-tag";
import { Modal } from "src/components";
import { Table, TableColumn } from "element-ui";
import BarChart from 'src/components/Charts/BarChart'
import config from '@/config';
import moment from 'moment'
moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BarChart
  },
  watch: {
    tableLeads(value) {
      let grouped = [];

      value.forEach(function (o) {
        if (!this[o.categorie]) {
          this[o.categorie] = { categorie: o.categorie, ca: 0 };
          grouped.push(this[o.categorie]);
        }
        this[o.categorie].ca += o.ca;
      }, Object.create(null));
      this.blueBarChart.chartData.labels = grouped.map(a => a.categorie.toUpperCase())
      this.blueBarChart.chartData.datasets[0].data = grouped.map(a => a.ca.toFixed(2))
      this.showBarChart = true
    }
  },
  data() {
    return {
      showBarChart: false,
      SkipQuery_caByTag: false,
      date_start: null,
      date_end: null,
      mounth: [ 
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      extractTag: {
        start_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        end_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        nom_campagne: ""
      },
      showExtractModal: false,
      firstEntryToPage: true,
      filterDates: {
        date: "",
        listeDates: []
      },
      tableLeads: [],
      tableLeads_tmp: [],
      leadsTotals: [],
      societeClient: "",
      hideExtract: true,
      blueBarChart: {
        extraOptions: {
          title: {
            display: true,
            text: 'THÉMATIQUE CA'
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          responsive: true,
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest",
            callbacks: {
              label: function(tooltipItems, data) {
                return tooltipItems.yLabel + " €";
              }
            }
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false,
                color: 'rgba(29,140,248,0.1)',
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 60,
                suggestedMax: 120,
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }],
            xAxes: [{
              gridLines: {
                drawBorder: false,
                color: 'rgba(29,140,248,0.1)',
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }]
          }
        },
        chartData: {
          labels: [],
          datasets: [{
            label: "CA",
            fill: true,
            borderColor: config.colors.myAfill,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [],
          }]
        },
        gradientColors: config.colors.MyAfillGradient,
        gradientStops: [1, 0]
      },
    };
  },
  apollo: {
    shootStatMonth: {
      query: gql`
        query($date: String, $id_campagne: Int, $id_client: Int) {
          shootStatOfMonth(
            date: $date
            id_campagne: $id_campagne
            id_client: $id_client
          ) {
            filter_dates {
              mois_annee
            }
            leadsTotals {
              impressions
              clicks
              leads
              ventes
              ecpc
              ca
            }
            leads {
              id_campagne
              id_type_compagne
              nom_campagne
              STAT1
              STAT2
              STAT3
              vente
              prix_annonceur
              date
              annee
              mois
              jour
              ca
              caan
              cae
              ecpc
              ctr
              campagne
              impressions
              categorie
              lm_mer
              lm_client
              ca_mg
            }
          }
        }
      `,
      update: data => data.shootStatOfMonth,
      result({ data, loader, networkStatus }) {
        if (this.firstEntryToPage) {
          this.filterDates.date =
            data.shootStatOfMonth[0].filter_dates[0].mois_annee;
          this.firstEntryToPage = false;
        }
        this.filterDates.listeDates = data.shootStatOfMonth[0].filter_dates;
        this.tableLeads_tmp = data.shootStatOfMonth[0].leads;
        this.leadsTotals = data.shootStatOfMonth[0].leadsTotals;
        this.extractTag.nom_campagne =
          data.shootStatOfMonth[0].leads[0].nom_campagne;
        
        var firstItem = this.tableLeads_tmp[0];
        var lastItem = this.tableLeads_tmp[this.tableLeads_tmp.length-1];
        if (firstItem) {
          this.date_start = firstItem.annee+"-"+firstItem.mois+"-"+firstItem.jour
        }
        if (lastItem) {
          this.date_end = lastItem.annee+"-"+lastItem.mois+"-"+lastItem.jour
        }
        if(!this.hideExtract) {
          this.tableLeads = this.tableLeads_tmp
        } else {
          this.SkipQuery_caByTag = false
        }
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          date: this.filterDates.date,
          id_campagne: this.$route.params.id_campagne || null,
          id_client: this.$route.params.id_client || null
        };
      }
    },
    caByTag: {
      client: "MARKET",
      query : gql` query ($tags: [String]!,$date_debut: String!,$date_fin: String!,$groupByDate: Boolean) {
        caByTag(tags: $tags, date_debut: $date_debut, date_fin: $date_fin, groupByDate: $groupByDate) {
          ca
          tag
          date
        }
      }`,
      skip() {
        return this.SkipQuery_caByTag;
      },
      variables() {
        return {
          date_debut: this.date_start, 
          date_fin: this.date_end,
          tags: [this.extractTag.nom_campagne],
          groupByDate: true
        }
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        this.tableLeads_tmp.forEach(t => {
          let tag = data.caByTag.find(element => t.annee+"-"+t.mois+"-"+t.jour === element.date)
          if (tag) {
            t.ca_wm = tag.ca;
          }
          else {
            t.ca_wm = 0;
          }
        })
        this.tableLeads = this.tableLeads_tmp
        this.SkipQuery_caByTag = true
      }
    }
  },
  methods: {
    Details(row) {
      let tmp = row.jour
      row.jour = row.date.split(' ')[3] + '/' + (this.mounth.indexOf(row.date.split(' ')[2]) + 1) + '/' + row.date.split(' ')[1]
      row.droit = "af"
      row.id_client = this.$route.params.id_client 
      row.id_programme = null
      row.editeur = this.societeClient || null
      localStorage.setItem("Details", JSON.stringify(row))
      row.jour = tmp
      this.$router.push({ name: "stats Detail Leads day"})
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      value != null ? (value = parseFloat(value)) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    extract() {
      //const url_api = 'http://weedoitmyaffil.api/api/extract_tag_campagne' //for local test
      const url_api =
        "https://apimyaffil.wee-do-it.net/api/extract_tag_campagne"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      this.axios
        .post(url_api, this.extractTag, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
        })
        .then(response => {
          const filename =
            "Extract_leads_" +
            this.extractTag.nom_campagne +
            "_du_" +
            this.extractTag.start_date +
            "_au_" +
            this.extractTag.end_date +
            ".csv";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => console.log("error occured"));
    },
    checkIfNull(value) {
      return value == null ? 0 : value;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let sum_mer = 0
      let sum_ca_mg = 0
      let sum_ca_wm = 0
      let sum_cae = 0
      data.forEach(element => {
        if (element.lm_client === 'WM') {
          sum_mer += element.lm_mer
          sum_ca_mg += element.ca_mg
          sum_ca_wm += element.ca_wm
          sum_cae += element.cae
        }
      });
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 1) {
          sums[index] = this.formatThounsends(this.leadsTotals["impressions"]);
          return;
        } else if (index === 2) {
          sums[index] = this.formatThounsends(this.leadsTotals["clicks"]);
          return;
        } else if (index === 3) {
          sums[index] = this.formatThounsends(this.leadsTotals["leads"]);
          return;
        } else if (index === 4) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ventes"]) + " €";
          return;
        } else if (index === 7) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ecpc"], true, 2) + " €";
          return;
        } else if (index === 6) {
          sums[index] =
            (this.leadsTotals["clicks"] == 0) ? 0 + " %" :
            this.formatThounsends(
              (this.leadsTotals["leads"] / this.leadsTotals["clicks"]) * 100,
              true,
              2
            ) + " %";
          return;
        } else if (index === 5) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ca"], true, 1) + " €";
          return;
        } 
        else if (index === 8 && this.hideExtract) {
          sums[index] =
            this.formatThounsends(sum_mer);
          return;
        } 
        else if (index === 9 && this.hideExtract) {
          sums[index] =
            (this.leadsTotals["leads"] != 0) ? (( sum_mer / this.leadsTotals["leads"]) * 100).toFixed(2)+' %' : (0).toFixed(2) +' %'
          return;
        } 
        else if (index === 10 && this.hideExtract) {
          sums[index] =
            this.formatThounsends(sum_ca_mg, true) + " €";
          return;
        }
        else if (index === 11 && this.hideExtract) {
          sums[index] =
            this.formatThounsends(sum_ca_wm, true) + " €";
          return;
        }
        else if (index === 12 && this.hideExtract) {
          sums[index] =
            this.formatThounsends((sum_ca_mg+sum_ca_wm)-sum_cae, true) + " €";
          return;
        }
        else if (index === 13 && this.hideExtract) {
          sums[index] =
            (sum_cae != 0 ? this.formatThounsends((((sum_ca_mg+sum_ca_wm)-sum_cae)*100)/sum_cae, true, 2) : 0) + " %";
          return;
        }
        
        else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    },
    color_marge(row) {
      let marge = row.cae !=0 ? (((row.ca_mg+row.ca_wm) - row.cae) * 100) / row.cae : row.cae
      if (marge >= 30) {
        return 'color: green'
      }
      else if (marge >= 20) {
        return 'color: rgb(252, 255, 62)'
      }
      else if (marge >= 0) {
        return 'color: orange'
      }
      else {
        return 'color: red'
      }
    },
    moment(date) {
      return moment(date);
    }
  },
  mounted() {
    if (this.$route.params.id_client != null) {
      this.hideExtract = false;
      this.societeClient = this.$route.params.society;
    }
  }
};
</script>