<template>
  <card>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <base-input>
        <el-input
          type="search"
          class="mb-3 search-input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="RECHERCHER"
          v-model="search"
          aria-controls="datatables"
        >
        </el-input>
      </base-input>
    </div>
    <el-table :data="FilterProgramme">
      <el-table-column 
        prop="Titre" 
        label="Programme"
        min-width="150"
        sortable
      >
        <template scope="scope">
          {{ scope.row.Titre.toUpperCase() }}
        </template>
      </el-table-column>
      <el-table-column 
        prop="provenance" 
        label="Provenance"
        min-width="150"
        sortable
      >
        <template scope="scope">
          {{ scope.row.provenance.toUpperCase() }}
        </template>
      </el-table-column>
      <el-table-column 
        prop="manager" 
        label="Traffic Manager"
        min-width="200"
        sortable
      >
      </el-table-column>
      <el-table-column
        header-align="right"
        align="right"
        min-width="150"
        label="Dupliquer"
      >
        <div slot-scope="props">
          <el-tooltip
            content="DUPLIQUER"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              v-if="props.row.idcpart == 0"
              @click.native="handleDuplicate(props.$index, props.row)"
              class="edit btn-link"
              type="success"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip
            content="SUPPRIMER"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              v-if="props.row.idcpart != 0"
              @click.native="handleRemove(props.row.id_programme,props.row)"
              class="like btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <center>
      <base-button v-if='$apollo.queries.list_programme_detail.loading' loading type="primary"></base-button>
    </center>
    <modal
      :show.sync="DupplicateShow"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">
          <div class="form-row">
            <base-input 
              label="ANNONCEUR"
              type="text"
              v-model="ProgramSelected.id_client"
              class="col-md-6"
              disabled
            >
            </base-input>
            <base-input 
              label="TITRE"
              type="text"
              v-model="ProgramSelected.Titre"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <base-input 
            label="CAMPAGNE"
            type="text"
            v-model="ProgramSelected.campagne"
            disabled
          >
          </base-input>
          <!-- Price By compagne -->
          <template v-if="ProgramSelected.campagne.includes('lead')">
            <div class="form-row">
              <base-input
                label="PRIX ANNONCEUR (CPL)"
                type="text"
                v-model="ProgramSelected.prix_annonceur"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPL)"
                type="text"
                v-model="ProgramSelected.prix_editeur"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('affichage')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (CPC)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_cpc"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPC)"
                type="text"
                v-model="ProgramSelected.prix_editeur_cpc"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('cpm')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (CPM)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_cpm"
                class="col-md-6"              
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPM)"
                type="text"
                v-model="ProgramSelected.prix_editeur_cpm"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('enrichissement')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (ENRICHISSEMENT)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_cpa"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (ENRICHISSEMENT)"
                type="text"
                v-model="ProgramSelected.prix_editeur_cpa"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('pourcentage des ventes')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (CPA)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_sms"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPA)"
                type="text"
                v-model="ProgramSelected.prix_editeur_sms"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('sms')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (SMS)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_cpd"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (SMS)"
                type="text"
                v-model="ProgramSelected.prix_editeur_cpd"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('téléchargement')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (CPD)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_cpa_fixe"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPD)"
                type="text"
                v-model="ProgramSelected.prix_editeur_cpa_fixe"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <template v-if="ProgramSelected.campagne.includes('vente fixe')">
            <div class="form-row">
              <base-input 
                label="PRIX ANNONCEUR (CPA FIXE)"
                type="text"
                v-model="ProgramSelected.prix_annonceur_enrichissement"
                class="col-md-6"
                disabled
              >
              </base-input>
              <base-input 
                label="PRIX ÉDITEUR (CPA FIXE)"
                type="text"
                v-model="ProgramSelected.prix_editeur_enrichissement"
                class="col-md-6"
                disabled
              >
              </base-input>
            </div>
          </template>
          <!-- Price By compagne -->
          <div class="form-row">
            <base-input 
              label="FORMAT"
              type="text"
              v-model="ProgramSelected.format"
              class="col-md-6"
              disabled
            >
            </base-input>
            <base-input 
              label="AUTORISÉ"
              type="text"
              v-model="ProgramSelected.autorise"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <div class="form-row">
            <base-input 
              label="DESCRIPTION"
              type="text"
              v-model="ProgramSelected.description"
              class="col-md-6"
              disabled
            >
            </base-input>
            <base-input 
              label="RÉPERTOIRE"
              type="text"
              v-model="ProgramSelected.repertoire"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <div class="form-row">
            <base-input 
              label="CATÉGORIE"
              type="text"
              v-model="ProgramSelected.categorie"
              class="col-md-6"
              disabled
            >
            </base-input>
            <base-input 
              label="LANGUE"
              type="text"
              v-model="ProgramSelected.langue"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <div class="form-row">
            <base-input label="MANAGER *" class="col-md-6" :error="getError('manager')">
              <el-select
                class="select-primary"
                placeholder="MANAGER"
                name="manager"
                v-validate="'required'"
                v-model="ManagerSelects.Manager"
                filterable
                clearable
              >
                <el-option
                  v-for="option in ManagerSelects.ListManager"
                  class="select-primary"
                  :value="option.email_client+'SPLITE'+option.prenom_client+'SPLITE'+option.id_client"
                  :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                  :key="option.id_client"
                ></el-option>
              </el-select>
            </base-input>
            <base-input 
              label="ÉTAT"
              type="text"
              v-model="ProgramSelected.etat"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <div class="form-row">
            <base-input 
              label="VISIBLE"
              type="text"
              :value="(ProgramSelected.display)?'OUI':'NON'"
              class="col-md-6"
              disabled
            >
            </base-input>
          </div>
          <div class="text-center">
            <base-button  @click="AjouterPrograme" type="info">AJOUTER</base-button>
          </div>
        </card>
    </modal>
  </card>  
</template>

<script>
import { Select, Option, Table, TableColumn } from 'element-ui';
import {Modal} from 'src/components'
import gql from "graphql-tag"
import swal from 'sweetalert2'

export default {
  apollo: {
    list_programme_detail: {
      query: gql`query {
        list_programme_detail {
          id_programme
          id_client
          societe_client
          Titre
          campagne
          prix_annonceur
          prix_editeur
          prix_annonceur_cpc
          prix_editeur_cpc
          prix_annonceur_cpm
          prix_editeur_cpm
          prix_annonceur_cpa
          prix_editeur_cpa
          prix_annonceur_sms
          prix_editeur_sms
          prix_annonceur_cpd
          prix_editeur_cpd
          prix_annonceur_cpa_fixe
          prix_editeur_cpa_fixe
          prix_annonceur_enrichissement
          prix_editeur_enrichissement
          format
          autorise
          description
          repertoire
          categorie
          langue
          etat
          display
          provenance
          manager
          idcpart
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.tableData = data.list_programme_detail
      },
      update: data => data.list_programme_detail
    },
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
      }
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal
  },
  data() {
    return {
      DupplicateShow: false,
      tableData: [],
      search: "",
      ProgramSelected: {
        campagne: ''
      },
      ManagerSelects: {
        Manager: "",
        ListManager: []
      }
    }
  },
  computed: {
    FilterProgramme() {
      return this.tableData.filter(program => {
        return (
          program.Titre.toUpperCase().match(this.search.toUpperCase()) ||
          program.manager.toUpperCase().match(this.search.toUpperCase())
        )
      })
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    handleDuplicate(index, row) {
      this.ProgramSelected = row
      this.DupplicateShow = true
    },
    async handleRemove(idprogramme,row) {
      swal.fire({
        // title: 'Êtes-vous sûr?',
        text: this.$t("questions.deleteduplicateprgram").toUpperCase(),
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnImg.BtnRemove").toUpperCase(),
        cancelButtonText: this.$t("buttons.BtnCancel").toUpperCase()
      }).then(result =>{
        if(result.value) {
          this.$apollo.mutate({
            mutation: gql`mutation (
                $id_programme: ID!
              ) 
            {
              deleteProgramme
              (
                id_programme : $id_programme
              )
            }`,
            variables: {
              id_programme: idprogramme
            },
          }).then(response => {
            this.tableData.forEach((value, index) => {
              if(row == value){
                this.tableData.splice(index, 1)
                this.$notify({type: 'success', message: this.$t("apiresponse.duplicateprgramdeleted")})
              }
            });
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errordeleting")})
          })
        }
      })      
    },
    async AjouterPrograme() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.$apollo.mutate({
        mutation: gql`mutation (
            $id_programme: ID!
            $manager: String!
            $prenom_manager: String!
            $id_manager: Int!
          ) {
          dupliquerProgramme
          (
            id_programme: $id_programme,
            manager: $manager,
            prenom_manager: $prenom_manager,
            id_manager: $id_manager
          ) 
          {
            id_programme
          }
        }`,
        variables: {
          id_programme: this.ProgramSelected.id_programme,
          manager: this.ManagerSelects.Manager.split("SPLITE")[0],
          prenom_manager: this.ManagerSelects.Manager.split("SPLITE")[1],
          id_manager: this.ManagerSelects.Manager.split("SPLITE")[2]
        },
      }).then(response => {
        this.$notify({type: 'success', message: this.$t("apiresponse.duplicateprgram")})
        this.DupplicateShow = false
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorduplication")})
        this.DupplicateShow = false
      })
    }
  }
}
</script>