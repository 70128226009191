<template>
  <div class="container-fluid">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($route.name == 'Stats Globales') ? 'active' : ''"
        id="0"
        data-toggle="collapse"
        href="#collapseone"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{name: 'Stats Globales'}"
      >
        <input type="radio" name="options" checked />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnStatsGlobal").toUpperCase() }}</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-chart-pie-36"></i>
        </span>
      </router-link>
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($router.name == 'Stats détaillées') ? 'active' : ''"
        id="1"
        data-toggle="collapse"
        href="#collapsetwo"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{ name: 'Stats détaillées' }"
      >
        <input type="radio" class="d-none d-sm-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnStatsDetails").toUpperCase() }}</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-chart-pie-36"></i>
        </span>
      </router-link>
      <!-- <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($route.name == 'Stats Campagnes') ? 'active' : ''"
        id="2"
        data-toggle="collapse"
        href="#collapsethree"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{name: 'Stats Campagnes'}"
      >
        <input type="radio" class="d-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">STATISTIQUES CAMPAGNES</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-wallet-43"></i>
        </span>
      </router-link>
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($router.name == 'Stats CA par editeur') ? 'active' : ''"
        id="3"
        data-toggle="collapse"
        href="#collapsefour"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{name: 'Stats CA par editeur'}"
      >
        <input type="radio" class="d-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          STATISTIQUES CA PAR
          ÉDITEUR
        </span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-pencil"></i>
        </span>
      </router-link>
      <label
        class="btn btn-sm btn-info btn-simple"
        id="4"
        data-toggle="collapse"
        href="#collapsefive"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <input type="radio" class="d-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          STATISTIQUES ÉDITEUR PAR
          BASE
        </span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-chart-pie-36"></i>
        </span>
      </label>
      <router-link
        class="btn btn-sm btn-info btn-simple"
        id="5"
        :class="($router.name == 'Stats CA par programme') ? 'active' : ''"
        data-toggle="collapse"
        href="#collapsesix"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{ name: 'Stats CA par programme'}"
      >
        <input type="radio" class="d-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          STATISTIQUES CA PAR
          PROGRAMME
        </span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-settings-gear-63"></i>
        </span>
      </router-link> -->
    </div>
    <p>&nbsp;</p>
    <zoom-center-transition :duration="200" mode="out-in">
      <router-view></router-view>
    </zoom-center-transition>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    ZoomCenterTransition
  }
};
</script>
