<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t('SidebarMenu.CampaignsList').toUpperCase() }}</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">

                  <div class="col-md-3">
                    <base-input :label="$t('table.columns.CreationDate').toUpperCase()">
                      <el-date-picker
                        :disabled="$apollo.queries.campagnes.loading"
                        v-model="Create_at" 
                        type="date" 
                        value-format="yyyy-MM-dd" 
                        :placeholder="$t('table.columns.CreationDate').toUpperCase()"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input :label="$t('inputs.Themes').toUpperCase()">
                      <el-select
                        class="select-primary"
                        v-model="ThematiqueSelects.Thematique"
                        :disabled="$apollo.queries.campagnes.loading"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="option in ThematiqueSelects.ListThematique"
                          class="select-primary"
                          :value="option.value"
                          :label="option.categorie.toUpperCase()"
                          :key="option.name"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input :label="$t('inputs.Law.Affiliates').toUpperCase()">
                      <el-select
                        filterable
                        clearable
                        :disabled="$apollo.queries.campagnes.loading"
                        class="select-primary"
                        v-model="EditeursSelects.Editeur"
                      >
                        <el-option class="select-primary" value label></el-option>
                        <el-option
                          v-for="option in EditeursSelects.ListEditeur"
                          class="select-primary"
                          :value="option.id_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id_client"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input :label="$t('table.columns.Program').toUpperCase()">
                      <el-select
                        filterable
                        clearable
                        :disabled="$apollo.queries.campagnes.loading"
                        class="select-primary"
                        v-model="campagnesListe.campagne"
                      >
                        <el-option class="select-primary" value label></el-option>
                        <el-option
                          v-for="option in campagnesListe.listeCampagnes"
                          class="select-primary"
                          :value="option.id_programme"
                          :label="option.provenance.toUpperCase()"
                          :key="option.id_programme"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>

                </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-12"><br>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    @click="resetFilter"
                    class="btn btn-round btn-just-icon btn-primary pull-right"
                  >{{ $t('buttons.BtnReset').toUpperCase() }}</button>
                  <base-button
                    v-if="$apollo.queries.campagnes.loading"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary"
                  >{{ $t('buttons.BtnLoad') }}</base-button>
                  <button
                    v-else
                    @click="search"
                    class="btn btn-round btn-just-icon btn-primary"
                  >{{ $t('buttons.BtnSearch').toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="queriedData" class="customtable">
                <el-table-column
                  prop="nom_campagne"
                  :label="$t('table.columns.Campagne')"
                  class="table-striped"
                  width="450"
                  sortable
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ scope.row.nom_campagne.toUpperCase() }}
                  </template>
                </el-table-column>

                <el-table-column
                  label="TM"
                  width="60"
                >
                  <template scope="scope">
                    <ApolloQuery
                      clientId="AUTH"
                      :query="gql => gql`
                        query ($email: String!) {
                          userByEmail (email: $email)  {
                            image
                          }
                        }
                      `"
                      :variables="{ 
                        email: scope.row.programme.manager 
                      }"
                    >
                      <template v-slot="{ result: { loading, error, data } }">
                        <div v-if="data" class="tm_photo mr-2 float-left">
                          <img 
                            class="img-fluid"
                            :src=" (data.userByEmail) ? 'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+data.userByEmail.image : '/img/placeholder.jpg'">
                          </img>
                        </div>
                        <div v-else-if="error" class="error apollo"></div>
                        
                      </template>
                    </ApolloQuery>
                  </template>
                </el-table-column>
                
                <el-table-column
                  prop="support.support_types"
                  :label="$t('table.columns.SupportTypes')"
                  class="table-striped"
                  width="150"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if='scope.row.support'>
                      <template v-if="scope.row.support.support_types">
                        <template v-for="type in scope.row.support.support_types.split(';')">
                          {{ supportTypes.find(c => c.value == type).type }} 
                          <label v-if="scope.row.support.support_types.split(';').length > 1">,</label>
                        </template>
                      </template>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="datecreation_campagne"
                  :label="$t('table.columns.Creationdate')"
                  class="table-striped"
                  width="160"
                  sortable
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ scope.row.datecreation_campagne.split(" ")[0] }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="id_campagne"
                  label="Subid"
                  class="table-striped"
                  width="95"
                  sortable
                >
                  <template scope="props">{{ props.row.id_campagne }}</template>
                </el-table-column>

                <el-table-column
                  prop="prix_editeur"
                  :label="$t('table.columns.LeadPrice')"
                  class="table-striped"
                  width="120"
                  sortable
                >
                  <template
                    scope="props"
                  >{{ (props.row.prix_editeur > 0 ? formatThounsends(props.row.prix_editeur) : 0) + ' €' }}</template>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props" v-if="loaderShortLink && this.paramsShortLink.id_campagne == props.row.id_campagne">
                    <el-tooltip content="LOADING..." effect="light" :open-delay="300" placement="top">
                      <base-button
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-loading icon-large"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                  <div slot-scope="props" v-else>
                    <el-tooltip content="SHORT LINK" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="shortlinkfunction(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-mobile"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props">
                    <el-tooltip content="STATISTIQUES" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openStats(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-chart-bar-32"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props">
                    <el-tooltip content="VISUEL" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openVisuel(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-tv-2"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="" width="55">
                  <div slot-scope="props">
                    <el-tooltip content="PIXELS" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openPixels(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-laptop"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="Actions" width="155">
                  <div slot-scope="props">
                    <el-tooltip
                      v-if="props.row.bloque == 'non'"
                      :content="$t('buttons.BtnModify')"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="editCampagne(props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-edit"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      :content="$t('buttons.BtnImg.BtnRemove')"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="deleteCampagne(props.row)"
                        class="edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-delete"></i>
                      </base-button>
                    </el-tooltip>
                    
                    <el-tooltip
                      v-if="props.row.bloque == 'non'"
                      content="BLOQUER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="bloqueCampagne(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-lock-circle"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      v-else
                      content="DÉBLOQUER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="bloqueCampagne(props.row)"
                        class="edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-lock"></i>
                      </base-button>
                    </el-tooltip>
                    
                    <el-tooltip :content="$t('buttons.Extract')" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click="showExtractForm(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-download"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>

              <modal
                :show.sync="showExtractModal"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">EXTRACTION DEPUIS UN TAG</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">TAG DE CAMPAGNE:</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ extractTag.nom_campagne }}</span>
                    </div>
                    <form role="form">
                      <div class="col-md-8">
                        <base-input label="DATE DÉBUT:">
                          <el-date-picker
                            v-model="extractTag.start_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="col-md-8">
                        <base-input label="DATE FIN:">
                          <el-date-picker
                            v-model="extractTag.end_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="extract()">EXTRAIRE</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { Select, Option, DatePicker } from "element-ui";
  import PieChart from "src/components/Charts/PieChart";
  import { Table, TableColumn } from "element-ui";
  import { BasePagination } from "src/components";
  import gql from "graphql-tag";
  import swal from "sweetalert2";
  import { Modal } from "src/components";
  
  let today = new Date();
  
  export default {
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      PieChart,
      Modal
    },
    apollo: {
      Filter: {
        query: gql`
          query ($month: String, $year: String) {
            programmes_campagnes {
              id_programme
              provenance
            }

            Clients_ByStatCampagne(month: $month, year: $year) {
              id_client
              societe_client
            }
          }
        `,
        update: data => data,
        result({ data, loader, networkStatus }) {
          this.campagnesListe.listeCampagnes = data.programmes_campagnes;
          this.EditeursSelects.ListEditeur = data.Clients_ByStatCampagne
        },
        variables() {
        return {
          month: (((today.getMonth()+1)+"").length==1) ? "0"+(today.getMonth()+1) : (today.getMonth()+1),
          year: today.getFullYear()
        }
      },
      },
      campagnes: {
        query: gql`
          query(
            $id_client: Int
            $id_programme: Int
            $thematique: String
            $page: Int
            $date_creation: String
          ) {
            all_listeCampagnes(
              id_client: $id_client
              id_programme: $id_programme
              thematique: $thematique
              count: 10000
              page: $page
              date_creation: $date_creation
            ) {
              id_client
              nom_campagne
              id_campagne
              id_programme
              prix_editeur
              identifiant
              bloque
              datecreation_campagne
              programme {
                manager
              }
              support {
                nom_support
                support_types
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
        update: data => data.all_listeCampagnes,
        skip() {
          return this.skipQuery;
        },
        result({ data, loader, networkStatus }) {
          
          if (data.all_listeCampagnes.length != 0) {
            this.tableData = [...this.tableData,...data.all_listeCampagnes] 
            this.page++
          } else {
            this.skipQuery = true;
            this.page = 1
          }

        },
        variables() {
          return {
            id_client: this.EditeursSelects.Editeur,
            id_programme: this.campagnesListe.campagne,
            thematique: this.ThematiqueSelects.Thematique,
            page: this.page,
            date_creation: this.Create_at
          };
        }
      },
      shortLink: {
        query: gql`
          query(
            $id_ann: Int
            $id_campagne: Int
            $identifiant: String
          ) {
            genereteShortLink(
              id_ann: $id_ann
              id_campagne: $id_campagne
              identifiant: $identifiant
            ) {
              short_link
            }
          }
        `,
        fetchPolicy: 'network-only',
        skip() {
          return this.skipQueryShortLink;
        },
        result({ data, loader, networkStatus }) {
          let dummy = document.createElement("textarea");
          document.body.appendChild(dummy);
          dummy.value = data.genereteShortLink.short_link;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
          console.log(data.genereteShortLink.short_link);
          this.skipQueryShortLink = true;
          this.loaderShortLink = false
          this.$notify({ type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'short link généré et copier dans le presse papier avec success.' });
        },
        variables() {
          return {
            identifiant: this.paramsShortLink.identifiant,
            id_ann: this.paramsShortLink.id_programme,
            id_campagne: this.paramsShortLink.id_campagne,
          };
        }
      }
    },
    data() {
      return {
        EditeursSelects: {
          Editeur: '',
          ListEditeur: []
        },
        ThematiqueSelects: {
          Thematique: "",
          ListThematique: this.$t("Database.Thematiques")
        },
        paramsShortLink: {
          id_programme: null,
          id_campagne: null,
          identifiant: ""
        },
        loaderShortLink:false,
        Create_at: null,
        page: 1,
        Tag: "",
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        extractTag: {
          start_date: new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"),
          end_date: new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"),
          nom_campagne: ""
        },
        showExtractModal: false,
        id_user_connected: null,
        skipQuery: true,
        skipQueryShortLink:true,
        id_client: null, //rm
        id_programme: null,
        nom_base: null,
        campagnesListe: {
          campagne: "",
          listeCampagnes: []
        },
        total: 0,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: "",
        searchedData: [],
        tableData: [],
        supportTypes: [
          {value: '1', type:'APPLI MOBILE'},
          {value: '2', type:'BASE EMAILS'},
          {value: '3', type:'BASE SMS'},
          {value: '4', type:'COMPARATEUR DE PRIX'},
          {value: '5', type:'DISPLAY'},
          {value: '6', type:'EMULATION'},
          {value: '7', type:'FACEBOOK'},
          {value: '8', type:'INSTAGRAM'},
          {value: '9', type:'LINKEDIN'},
          {value: '10', type:'PUSH NOTIFS'},
          {value: '11', type:'RETARGETING'},
          {value: '12', type:'SNAPCHAT'},
          {value: '13', type:'SEO/SEA'},
          {value: '14', type:'TWITTER'},
          {value: '15', type:'YOUTUBE'}
        ],
      };
    },
    mounted() {
      (this.id_user_connected = JSON.parse(localStorage.getItem("utilisateur")).id),
        (this.campagnesListe.campagne = this.$route.params.id_programme),
        (this.id_programme = this.$route.params.id_programme), //rm
        (this.nom_base = this.$route.params.nom_base),
        (this.sub_id = this.$route.params.id_campagne),
        (this.id_client = this.$route.params.id_client);
    },
    computed: {
      queriedData() {
        let result = this.tableData.filter((campagne) => {
          return campagne.nom_campagne.toUpperCase().match(this.Tag.toUpperCase())
        })
        this.total = result.length
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      resetFilter() {
        (this.campagnesListe.campagne = ""),
          (this.nom_base = ""),
          (this.id_campagne = "");
      },
      openStats(row) {
        let routeData = this.$router.resolve({
          name: "stats_shoots",
          params: { id_campagne: row.id_campagne }
        });
        window.open(routeData.href, "_blank");
      },
      openVisuel(row) {
        let routeData = this.$router.resolve({
          name: "visuel",
          params: {
            identifiant: row.identifiant,
            nom_campagne: row.nom_campagne,
            id_ann: row.id_programme
          }
        });
        window.open(routeData.href, "_blank");
      },
      openPixels(row) {
        let routeData = this.$router.resolve({
          name: "pixels",
          params: { identifiant: row.identifiant, nom_campagne: row.nom_campagne }
        });
        window.open(routeData.href, "_blank");
      },
      search() {
        this.tableData = []
        this.skipQuery = false;
      },
      async shortlinkfunction(row) {
        this.loaderShortLink = true;
        this.paramsShortLink.id_programme = row.id_programme
        this.paramsShortLink.id_campagne =row.id_campagne
        this.paramsShortLink.identifiant =row.identifiant
        this.skipQueryShortLink = false;
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      editCampagne(row){
        let routeData = this.$router.resolve({
          name: "edit_campagne",
          params: { id_campagne: row.id_campagne }
        });
        window.open(routeData.href, "_blank");
      },
      bloqueCampagne(row) {
        swal
          .fire({
            // title: 'Êtes-vous sûr?',
            text: "Vous êtes en train de bloquer cette campagne!",
            width: 400,
            showCancelButton: true,
            confirmButtonColor: "#988fc3",
            cancelButtonColor: "#27293D",
            confirmButtonText: "Bloquer",
            cancelButtonText: "Annuler"
          })
          .then(result => {
            if (result.value) {
              this.$apollo
                .mutate({
                  // Query
                  mutation: gql`
                    mutation($id_campagne: Int!) {
                      bloque_campagne(id_campagne: $id_campagne) {
                        state
                      }
                    }
                  `,
                  variables: {
                    id_campagne: row.id_campagne
                  }
                })
                .then(data => {
                  var message = "";
                  if (data.data.bloque_campagne.state) {
                    message = "La campagne est bloqué.";
                  } else {
                    message = "La campagne est débloqué.";
                  }
  
                  this.tableData.forEach((value, index) => {
                    if (row.id_campagne == value.id_campagne) {
                      this.tableData.splice(index, 1);
                    }
                  });
  
                  this.$notify({
                    type: "success",
                    verticalAlign: "top",
                    horizontalAlign: "right",
                    message: message,
                    duration: 5000
                  });
                })
                .catch(error => {
                  // Error
                  console.error(error);
                });
            }
          });
      },
      deleteCampagne(row) {
        swal
          .fire({
            // title: 'Êtes-vous sûr?',
            text: "Vous êtes en train de supprimer cette campagne!",
            width: 400,
            showCancelButton: true,
            confirmButtonColor: "#988fc3",
            cancelButtonColor: "#27293D",
            confirmButtonText: "Supprimer",
            cancelButtonText: "Annuler"
          })
          .then(result => {
            if (result.value) {
              this.$apollo
                .mutate({
                  // Query
                  mutation: gql`
                    mutation($id_campagne: Int!, $id_client: Int!) {
                      delete_campagne(
                        id_campagne: $id_campagne
                        id_client: $id_client
                      ) {
                        state
                      }
                    }
                  `,
                  variables: {
                    id_campagne: row.id_campagne,
                    id_client: this.id_user_connected
                  }
                })
                .then(data => {
                  var message = "";
                  if (data.data.delete_campagne.state) {
                    message = "La campagne est supprimé.";
                  }
  
                  this.tableData.forEach((value, index) => {
                    if (row.id_campagne == value.id_campagne) {
                      this.tableData.splice(index, 1);
                    }
                  });
  
                  this.$notify({
                    type: "success",
                    verticalAlign: "top",
                    horizontalAlign: "right",
                    message: message,
                    duration: 5000
                  });
                })
                .catch(error => {
                  // Error
                  console.error(error);
                });
            }
          });
      },
      showExtractForm(row) {
        this.extractTag.nom_campagne = row.nom_campagne;
        this.showExtractModal = true;
      },
      extract() {
        //const url_api = "http://weedoitmyaffil.api/api/extract_tag_campagne"; //for local test
        const url_api = 'https://apimyaffil.wee-do-it.net/api/extract_tag_campagne' //for server test
        let accessToken = localStorage.getItem("token_jwt");
        this.axios
          .post(url_api, this.extractTag, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              authorization: `Bearer ${accessToken}`
            },
            responseType: "blob"
          })
          .then(response => {
            const filename =
              "Extract_leads_" +
              this.extractTag.nom_campagne +
              "_du_" +
              this.extractTag.start_date +
              "_au_" +
              this.extractTag.end_date +
              ".csv";
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(() => console.log("error occured"));
      }
    },
    created() {
      this.skipQuery = false;
    },
  };
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 12px;
  }
  .tm_photo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .tm_photo img {
    width: 100%;
  }
</style>