<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t('labels.CaStatsCampaign') }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <!-- <div class="card-header"> -->
            <!-- <div class="row"> -->
              <!-- <div class="col-12 text-left"> -->
                <button class="btn btn-info float-right" @click="ExportTable()">
                  <i class="tim-icons icon-cloud-download-93"></i> EXPORT
                </button>
              <!-- </div> -->
            <!-- </div> -->
          <!-- </div> -->

          <base-dropdown menu-classes="dropdown-black" class="col-6">
            <template slot="title-container">
              <button class="btn btn-icon btn-round btn-info">
                <i class="tim-icons el-icon-view"></i>
              </button>
            </template>
              <div class="dropdown-header">
                <base-checkbox v-model="selectallrows">
                  {{ $t("labels.SelectAll") }}
                </base-checkbox>
              </div>
              <template v-for="row in rows">
                <div class="dropdown-header">
                  <base-checkbox v-model="row.show">
                    {{ row.label }}
                  </base-checkbox>
                </div>
              </template>
          </base-dropdown>

          <el-table :data="tableCampagne" :default-sort = "{prop: 'ca', order: 'descending'}" show-summary :summary-method="getSummaries" v-loading="Loading_Table" class="customtable">
            <el-table-column
              width="350"
              sortable
              :label="$t('table.columns.Campagne')"
              prop="campagne"
              show-overflow-tooltip
              v-if="rows[0].show"
            >
              <template scope="scope">
                <label style="cursor: pointer;" @click="openStatsCampagne(scope.row)"> {{ scope.row.campagne }} </label>
              </template>
            </el-table-column>
            <el-table-column
              width="88"
              sortable
              label="TYPE"
              prop="type"
              show-overflow-tooltip
              v-if="rows[1].show"
            >
              <template scope="scope">
                <template v-if="scope.row.type === 'lead'">
                  CPL
                </template>
                <template v-else-if="scope.row.type === 'affichage'">
                  CPC
                </template>
                <template v-else-if="scope.row.type === 'cpm'">
                  CPM
                </template>
                <template v-else-if="scope.row.type === 'cpa'">
                  CPA
                </template>
                <template v-else-if="scope.row.type === 'sms'">
                  SMS
                </template>
                <template v-else-if="scope.row.type === 'téléchargement'">
                  CPD
                </template>
              </template>
            </el-table-column>
            <el-table-column
              width="85"
              sortable
              label="IMP"
              prop="impression"
              show-overflow-tooltip
              v-if="rows[2].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.impression) }}
              </template>
            </el-table-column>
            <el-table-column
              width="94"
              sortable
              :label="$t('table.columns.clicks')"
              prop="clics"
              show-overflow-tooltip
              v-if="rows[3].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.clics) }}
              </template>
            </el-table-column>
            <el-table-column
              width="96"
              sortable
              label="LEADS"
              prop="leads"
              show-overflow-tooltip
              v-if="rows[4].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads) }}
              </template>
            </el-table-column>
            <el-table-column
              width="104"
              sortable
              :label="$t('table.columns.Sales')"
              prop="ventes"
              show-overflow-tooltip
              v-if="rows[5].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ventes) }}
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              sortable
              :label="$t('Pages.dashboard.ca')"
              prop="ca"
              show-overflow-tooltip
              v-if="rows[6].show"
            >
              <template scope="scope">
                <strong>
                  {{ formatThounsends(scope.row.ca,true,2) + ' €' }}
                </strong>
              </template>
            </el-table-column>
            <el-table-column
              width="90"
              sortable
              label="CTR"
              prop="CTR"
              show-overflow-tooltip
              v-if="rows[7].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.CTR,true,2) + ' %' }}
              </template>
            </el-table-column>
            <el-table-column
              width="91"
              sortable
              label="ECPC"
              prop="ECPC"
              show-overflow-tooltip
              v-if="rows[8].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ECPC,true,2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              width="93"
              sortable
              label="ECPM"
              prop="ECPM"
              show-overflow-tooltip
              v-if="rows[9].show"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ECPM,true,2) + ' €' }}
              </template>
            </el-table-column>

            <el-table-column label="MER" prop="lm_mer" width="90" sortable v-if="rows[10].show">
              <template scope="scope">{{ formatThounsends(scope.row.lm_mer) }}</template>
            </el-table-column>
            <el-table-column label="% MER" min-width="100" sortable :sort-method="CustomSort_mer" v-if="rows[11].show">
              <template scope="scope">
                <label v-if="scope.row.lm_client === 'WM'" :style="((scope.row.lm_mer / scope.row.leads) * 100 < 30 || scope.row.leads==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.leads != 0) ? (( scope.row.lm_mer / scope.row.leads) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                <template v-else>NC</template>
              </template>
            </el-table-column>
            <el-table-column label="CA MG" prop="ca_mg" min-width="110" sortable v-if="rows[12].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ formatThounsends(scope.row.ca_mg, true,2) + ' €' }}
                </template>
                <template v-else>NC</template>
              </template>
            </el-table-column>
            <el-table-column label="CA WM" prop="ca_wm" min-width="110" sortable v-if="rows[13].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ formatThounsends(scope.row.ca_wm, true,2) + ' €' }}
                </template>
                <template v-else>NC</template>
              </template>

            </el-table-column>
            <el-table-column :label="$t('table.columns.Margin')" prop="ca_wm" min-width="110" sortable :sort-method="CustomSort_Marge" v-if="rows[14].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ formatThounsends((scope.row.ca_mg+scope.row.ca_wm) - scope.row.depenses, true,2) + ' €' }}
                </template>
                <template v-else>NC</template>
              </template>

            </el-table-column>
            <el-table-column :label="'% '+$t('table.columns.Margin') " prop="ca_mg" min-width="110" sortable :sort-method="CustomSort_MargeMg" v-if="rows[15].show">
              <template scope="scope">
                <label v-if="scope.row.lm_client === 'WM'" :style="color_marge(scope.row)">
                  {{ scope.row.depenses !=0 ? formatThounsends((((scope.row.ca_mg+scope.row.ca_wm) - scope.row.depenses)*100)/scope.row.depenses , true, 2) : (0).toFixed(2)  }} %
                </label>
                <template v-else>NC</template>
              </template>
            </el-table-column>

            <el-table-column label="volume" prop="volume" min-width="110" sortable v-if="rows[16].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ formatThounsends(scope.row.volume, true,0) }}
                </template>
                <template v-else>NC</template>
              </template>
            </el-table-column>

            <el-table-column label="depence sms" prop="depenses" min-width="120" sortable v-if="rows[17].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ formatThounsends(scope.row.depenses, true,2) + ' €' }}
                </template>
                <template v-else>NC</template>
              </template>
            </el-table-column>

            <el-table-column :label="$t('table.columns.SendingDate')" prop="creation_date" min-width="130" show-overflow-tooltip :sort-method="CustomSort_date" v-if="rows[18].show">
              <template scope="scope">
                <template v-if="scope.row.lm_client === 'WM'">
                  {{ scope.row.creation_date }}
                </template>
                <template v-else>NC</template>
              </template>
            </el-table-column>


          </el-table>
            
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import gql from 'graphql-tag'
import { Modal, BaseDropdown } from 'src/components'
import { Table, TableColumn } from 'element-ui';
import XLSX from 'xlsx'
import moment, { locale } from 'moment';

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseDropdown
  },
  watch: {
    selectallrows(value) {
      this.rows.forEach(element => {
        element.show = value
      });
    },
  },
  data() {
      return {
        Clients: null,
        Editeurs: null,
        SkipQuery: true,
        SkipQuery_caByTag: true,
        SkipQuery_caByTag_MG: true,
        Loading_Table: true,
        listTags: [],
        listIds_annonceur: [],
        tableCampagne: [],
        tableCampagne_tmp: [],
        totale: {
          impression: 0,
          clics: 0,
          leads: 0,
          ventes: 0,
          ECPM: 0,
          ECPC: 0,
          CA: 0,
          caVente: 0,
          CA_editeur: 0
        },
        selectallrows: false,
        rows: [
          { label: this.$t('table.columns.Campagne'), show: true },
          { label: 'Type', show: false },
          { label: 'Impression', show: false },
          { label: this.$t('table.columns.clicks'), show: true },
          { label: 'Leads', show: true },
          { label: this.$t('table.columns.Sales'), show: true },
          { label: this.$t('Pages.dashboard.ca'), show: true },
          { label: 'CTR', show: true },
          { label: 'ECPC', show: false },
          { label: 'ECPM', show: false },
          { label: 'MER', show: true },
          { label: '% MER', show: true },
          { label: 'CA MG', show: true },
          { label: 'CA WM', show: true },
          { label: this.$t('table.columns.Margin'), show: true },
          { label: '% '+ this.$t('table.columns.Margin'), show: true },
          { label: 'Volume', show: true },
          { label: 'Depence sms', show: true },
          { label: this.$t('table.columns.SendingDate'), show: true }
        ]
      };
  },
  apollo: {
    detailLeadsTopManager: {
      query : gql` query (
        $date_start: Date!, 
        $date_end: Date!,
        $droit: String!,
        $id_client: Int,
        $id_annonceurs: [Int],
        $id_editeurs: [Int]
      ) 
      {
        detailLeadsTopManager
        (
          date_start:$date_start,
          date_end:$date_end,
          droit:$droit
          id_client:$id_client
          id_annonceurs: $id_annonceurs
          id_editeurs: $id_editeurs
        )
        {
          campagne
          type
          impression
          clics
          leads
          ventes
          ca
          CTR
          ECPC
          ECPM
          caVente
          lm_mer
          lm_client
          ca_mg
          ca_editeur
          volume
          depenses
          creation_date
          id_campagne
          id_annonceur
        }
      }`,
      skip() {
        return this.SkipQuery;
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) 
      {
        this.tableCampagne_tmp = data.detailLeadsTopManager
        this.tableCampagne_tmp.forEach(element => {
          this.totale.impression += element.impression,
          this.totale.clics += element.clics,
          this.totale.leads += element.leads,
          this.totale.ventes += element.ventes,
          this.totale.CA += element.ca
          this.totale.CA_editeur += element.depenses
          this.totale.caVente += element.caVente
          element.ca_wm = 0
        });
        this.totale.ECPM = (this.totale.impression != 0) ? ((this.totale.CA + this.totale.caVente) / this.totale.impression) * 1000 : 0
        this.totale.ECPC = (this.totale.clics != 0 ) ? ((this.totale.CA + this.totale.caVente) / this.totale.clics) * 100 : 0
        this.listTags = this.tableCampagne_tmp.map(a => a.campagne)
        this.listIds_annonceur = this.tableCampagne_tmp.map(a => a.id_annonceur)
        this.SkipQuery_caByTag_MG = false
        this.SkipQuery = true
      },
      variables() {
        return {
          date_start: this.$route.params.date_start, 
          date_end: this.$route.params.date_end,
          droit: 'ma',
          id_client: (this.$route.params.id_client === 'c') ? null : this.$route.params.id_client,
          id_annonceurs: this.Clients,
          id_editeurs: this.Editeurs
        }
      }
    },
    caByTag_MG: {
      client: "MARKET",
      query : gql` query ($tags: [String]!, $annonceur_id: [Int]!, $date_debut: String!, $date_fin: String!) {
        caByTag_MG(tags: $tags,annonceur_id: $annonceur_id, date_debut: $date_debut, date_fin: $date_fin) {
          lm_client
          lm_mer
          ca_mg
          tag
        }
      }`,
      skip() {
        return this.SkipQuery_caByTag_MG;
      },
      variables() {
        return {
          date_debut: this.$route.params.date_start, 
          date_fin: this.$route.params.date_end,
          tags: this.listTags,
          annonceur_id: this.listIds_annonceur
        }
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        data.caByTag_MG.forEach(element => {
          let tag = this.tableCampagne_tmp.find(t => t.campagne === element.tag)
          if (tag) {
            tag.lm_mer = element.lm_mer;
            tag.lm_client = element.lm_client;
            tag.ca_mg = element.ca_mg;
          }
        });
        this.tableCampagne_tmp = this.tableCampagne_tmp
        this.SkipQuery_caByTag_MG = true
        this.SkipQuery_caByTag = false
      }
    },
    caByTag: {
      client: "MARKET",
      query : gql` query ($tags: [String]!,$date_debut: String!,$date_fin: String!) {
        caByTag(tags: $tags, date_debut: $date_debut, date_fin: $date_fin) {
          ca
          tag
        }
      }`,
      skip() {
        return this.SkipQuery_caByTag;
      },
      variables() {
        return {
          date_debut: this.$route.params.date_start, 
          date_fin: this.$route.params.date_end,
          tags: this.listTags,
        }
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        data.caByTag.forEach(element => {
          let tag = this.tableCampagne_tmp.find(t => t.campagne === element.tag)
          if (tag) {
            tag.ca_wm = element.ca;
          }
        });
        this.tableCampagne = this.tableCampagne_tmp
        this.SkipQuery_caByTag = true
        this.Loading_Table = false
      }
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let sum_mer = 0
      let sum_ca_mg = 0
      let sum_ca_wm = 0
      let sum_volume = 0
      let sum_depenses = 0
      data.forEach(element => {
        if (element.lm_client === 'WM') {
          sum_mer += element.lm_mer
          sum_ca_mg += element.ca_mg
          sum_ca_wm += element.ca_wm
          sum_volume += element.volume
          sum_depenses += element.depenses
        }
      });
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        // else if( index === 2 && this.rows[2].show) {
        //   sums[index] = this.formatThounsends(this.totale.impression);
        //   return;
        // }
        else if( index === 1 && this.rows[3].show) {
          sums[index] = this.formatThounsends(this.totale.clics);
          return;
        }
        else if( index === 2 && this.rows[3].show) {
          sums[index] = this.formatThounsends(this.totale.leads);
          return;
        }
        else if( index === 3 && this.rows[5].show) {
          sums[index] = this.formatThounsends(this.totale.ventes);
          return;
        }
        // else if( index === 9 && this.rows[9].show) {
        //   sums[index] = this.formatThounsends(this.totale.ECPM,true,0) + ' €';
        //   return;
        // }
        // else if( index === 8 && this.rows[8].show) {
        //   sums[index] = this.formatThounsends(this.totale.ECPC,true,0) + ' €';
        //   return;
        // }
        else if( index === 4 && this.rows[6].show) {
          sums[index] = this.formatThounsends(this.totale.CA,true,0) + ' €';
          return;
        }
        else if( index === 6 && this.rows[10].show) {
          sums[index] = this.formatThounsends(sum_mer);;
          return;
        }
        else if( index === 7 && this.rows[11].show) {
          sums[index] = (this.totale.leads != 0) ? (( sum_mer / this.totale.leads) * 100).toFixed(2)+' %' : (0).toFixed(2) +' %';
          return;
        }
        else if (index === 8 && this.rows[12].show) {
          sums[index] =
            this.formatThounsends(sum_ca_mg, true) + " €";
          return;
        }
        else if (index === 9 && this.rows[13].show) {
          sums[index] =
            this.formatThounsends(sum_ca_wm, true) + " €";
          return;
        }
        else if (index === 10 && this.rows[14].show) {
          sums[index] =
            this.formatThounsends((sum_ca_mg+sum_ca_wm)-this.totale.CA_editeur, true) + " €";
          return;
        }
        else if (index === 11 && this.rows[15].show) {
          sums[index] =
            (this.totale.CA_editeur != 0 ? this.formatThounsends((((sum_ca_mg+sum_ca_wm)-this.totale.CA_editeur)*100)/this.totale.CA_editeur, true, 2) : 0) + " %";
          return;
        }
        else if (index === 12 && this.rows[16].show) {
          sums[index] = this.formatThounsends(sum_volume, true);
          return;
        }
        else if (index === 13 && this.rows[17].show) {
          sums[index] = this.formatThounsends(sum_depenses, true, 2) + " €";
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    CustomSort_date(a, b) {
      let date1 = moment(a.creation_date)
      let date2 = moment(b.creation_date)
      return date1.diff(date2, 'days');
      // if (date1.diff(date2, 'days') < 0) {
      //   return 1
      // }
      // else if(date1.diff(date2, 'days') > 0) {
      //   return -1
      // }
      // return 0
    },
    CustomSort_mer(a, b){
      let tmer1 = 0
      let tmer2 = 0
      if (a.leads == 0) {
        tmer1 = 0
      }
      else {
        tmer1 = (a.lm_mer / a.leads) * 100
      }
      if (b.leads == 0) {
        tmer2 = 0
      }
      else {
        tmer2 = (b.lm_mer / b.leads) * 100
      }
      return tmer1 - tmer2
    },
    CustomSort_MargeMg(a, b){
      let tmer1 = 0
      let tmer2 = 0
      if (a.depenses == 0) {
        tmer1 = 0
      }
      else {
        tmer1 = (((a.ca_mg+a.ca_wm) - a.depenses)/a.depenses) * 100
      }
      if (b.depenses == 0) {
        tmer2 = 0
      }
      else {
        tmer2 = (((b.ca_mg + b.ca_wm) - b.depenses)/b.depenses) * 100
      }
      return tmer1 - tmer2
    },
    CustomSort_Marge(a, b){
      let marge1 = (a.ca_mg+a.ca_wm) - a.depenses
      let marge2 = (b.ca_mg+b.ca_wm) - b.depenses
      return marge1 - marge2
    },
    ExportTable() {
      if (this.tableCampagne.length) {
        let CampagneArray = JSON.parse(JSON.stringify(this.tableCampagne));
        CampagneArray.forEach(function(data){ 
          delete data.__typename
          delete data.caVente
          delete data.id_campagne
          if(data.lm_client === 'WM') {
            data.lm_client =  ((data.leads != 0) ? (( data.lm_mer / data.leads) * 100).toFixed(2) : (0).toFixed(2)).replace(".", ",")
            data.MARGE = ((data.ca_mg+data.ca_wm) - data.depenses).toFixed(2).replace(".", ",")
            data.marge_prc = (data.depenses !=0 ? (((data.ca_mg+data.ca_wm) - data.depenses)*100)/data.depenses : 0).toFixed(2).replace(".", ",")
            data.depenses = data.depenses.toFixed(2).replace(".", ",")
            data.ca_mg = data.ca_mg.toFixed(2).replace(".", ",")
            data.ca_wm = data.ca_wm.toFixed(2).replace(".", ",")
          }
          else {
            data.lm_client = 'NC'
            data.MARGE = 'NC'
            data.marge_prc = 'NC'
            data.depenses = 'NC'
          }
          data.ca = data.ca.toFixed(2).replace(".", ",")
          data.CTR = data.CTR.toFixed(2).replace(".", ",")
          data.ECPC = data.ECPC.toFixed(2).replace(".", ",")
          data.ECPM = data.ECPM.toFixed(2).replace(".", ",")

          delete data.ca_editeur

          if (data.type === 'lead') {
            data.type = "CPL"
          }
          else if (data.type === 'affichage') {
            data.type = "CPC"
          }
          else if (data.type === 'cpm') {
            data.type = "CPM"
          }
          else if (data.type === 'cpa') {
            data.type = "CPA"
          }
          else if (data.type === 'sms') {
            data.type = "SMS"
          }
          else if (data.type === 'téléchargement') {
            data.type = "CPD"
          }

        });
        const fileName = 'CA_CAMPAGNES.csv'
        var Stats = XLSX.utils.json_to_sheet(CampagneArray) 
        Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

        Stats = Stats.replace("lm_mer","MER")
        Stats = Stats.replace("lm_client","% MER")

        Stats = Stats.replace("ca_mg","CA MG")
        Stats = Stats.replace("ca_wm","CA WM")
        Stats = Stats.replace("marge_prc","% MARGE")
        Stats = Stats.replace("creation_date","date d envoi")

        var blob = new Blob(["\ufeff", Stats]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    color_marge(row) {
      let marge = row.depenses !=0 ? (((row.ca_mg+row.ca_wm) - row.depenses) * 100) / row.depenses : row.depenses
      if (marge >= 30) {
        return 'color: green'
      }
      else if (marge >= 20) {
        return 'color: rgb(252, 255, 62)'
      }
      else if (marge >= 0) {
        return 'color: orange'
      }
      else {
        return 'color: red'
      }
    },
    openStatsCampagne(row) {
      let routeData = this.$router.resolve({
        name: "stats_shoots",
        params: { id_campagne: row.id_campagne }
      });
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    if(JSON.parse(localStorage.getItem("Clients"))){
      this.Clients = JSON.parse(localStorage.getItem("Clients"))
      localStorage.removeItem("Clients")
    }

    if(JSON.parse(localStorage.getItem("Editeurs"))){
      this.Editeurs = JSON.parse(localStorage.getItem("Editeurs"))
      localStorage.removeItem("Editeurs")
    }
    
    this.SkipQuery = false
  },
};
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 11.5px;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>