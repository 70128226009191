<template>
  <div class="row">
    <div class="col-lg-7">
      <card>
        <p class="card-title">
        <strong>CAMPAGNE:</strong>
        <span style="color: #988fc3; font-size: 15px;" v-if="nom_campagne"> {{ nom_campagne }}</span>
        </p>

        <form @submit.prevent>
          <base-input
            :disabled="role!='ad'"
            :error="getError('prix lead')"
            name="prix lead"
            v-validate="'required'"
            v-model="prix_lead"
            label="RÉMUNÉRATION ÉDITEUR"
            type="number"
            step=".01"
            placeholder="RÉMUNÉRATION ÉDITEUR"
          ></base-input>
          <base-input
            :disabled="role!='ad'"
            :error="getError('prix annonceur')"
            name="prix annonceur"
            v-validate="'required'"
            v-model="prix_annonceur"
            label="RÉMUNÉRATION ANNONCEUR"
            type="number"
            step=".01"
            placeholder="RÉMUNÉRATION ANNONCEUR"
          ></base-input>
          <base-button native-type="submit"  :disabled="role!='ad'" type="primary" @click="EditCampagne()">{{ $t("buttons.BtnModify").toUpperCase() }}</base-button>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { Select, Option } from "element-ui";
import {
  BaseSwitch,
  TimeLine,
  TimeLineItem,
  BaseCheckbox
} from "src/components";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSwitch,
    TimeLine,
    TimeLineItem,
    BaseCheckbox
  },
  apollo: {
    showMembreData: {
      query: gql`
        query($id_campagne: ID) {
          compagneW(id_campagne: $id_campagne) 
          {
            prix_editeur
            prix_annonceur
            nom_campagne
          }
        }
      `,
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.prix_lead = data.compagneW.prix_editeur;
        this.prix_annonceur = data.compagneW.prix_annonceur;
        this.nom_campagne = data.compagneW.nom_campagne;
},
      variables() {
        return {
          id_campagne: this.$route.params.id_campagne
        };
      }
    }
  },
  data() {
    return {
      skip: true,
      nom_campagne: "",
      prix_lead: "",
      prix_annonceur: "",
      role: JSON.parse(localStorage.getItem('utilisateur')).law
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async EditCampagne() {

      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation(
            $id_campagne: Int!
            $prix_editeur: Float
            $prix_annonceur: Float
          ) {
            updateCampagneW(
              id_campagne: $id_campagne
              prix_editeur: $prix_editeur
              prix_annonceur: $prix_annonceur
            ) {
              id_campagne
            }
          }
        `,
        variables: {
          prix_editeur: this.prix_lead,
          prix_annonceur: this.prix_annonceur,
          id_campagne: this.$route.params.id_campagne
        }
      })
      .then(data => {
        this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "La campagne a été bien modifié.",
            duration: 5000
        });
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
};
</script>