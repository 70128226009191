<template>
  <card>
    <div class="row justify-content-center">
       <div class="col-sm-10">
        <base-input label="marque blanche">
          <base-checkbox class="mb-3" v-model="Marque">
            MARQUE BLANCHE
          </base-checkbox>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Programme *" :error="getError('programme')">
          <el-select class="select-primary"
                  placeholder="PROGRAMME"
                  filterable
                  clearable
                  name="programme"
                  v-validate="'required'"
                  v-model="ProgrammeSelects.Programme">
            <el-option v-for="option in ProgrammeSelects.ProgrammeList"
                    class="select-primary"
                    :value="option.id_programme + 'SPLITE' + option.repertoire"
                    :label="(option.Titre=='')?' ':option.Titre"
                    :key="option.id_programme">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Format">
          <base-radio inline name="html" class="mb-3" v-model="Format">
            VISUEL
          </base-radio>
          <base-radio inline name="gif" class="mb-3" v-model="Format">
            BANNIÈRE
          </base-radio>
        </base-input>
      </div>
      <div class="col-sm-5" v-if='Format == "html"'>
        <base-input label="Liens de redirection">
          <base-radio inline name="non" class="mb-3" v-model="Link_redirect">
            NON
          </base-radio>
          <base-radio inline name="oui" class="mb-3" v-model="Link_redirect">
            OUI
          </base-radio>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Elements" :error="getError('elements')">
          <el-select class="select-primary"
                  placeholder="ELEMENTS"
                  name="elements"
                  v-validate="'required'"
                  @change="Elementschange($event)"
                  v-model="ElementsSelects.Elements">
            <optgroup label="Nouveau">
            </optgroup>
            <el-option value="new" label="Créer">
            </el-option>
            <optgroup label="Existants">
            </optgroup>
            <el-option v-for="option in ElementsSelects.ElementsList"
                    class="select-primary"
                    :value="option.id_visuel + 'SPLITE' + option.repertoire + 'SPLITE' + option.profil"
                    :label="option.taille"
                    :key="option.id_visuel">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5" v-if='Format == "html"'>
        <base-input label="Profile" placeholder="PROFILE"
              v-model="Profile"
              disabled
              type="text">
        </base-input>
      </div>
      <div class="col-sm-5" v-if='Format == "html"'>
        <base-input label="Objet" placeholder="OBJET"
              v-model="Objet"
              v-validate="'required'"
              :error="getError('objet')"
              name="objet"
              type="text">
        </base-input>
      </div>
      <div class="col-sm-5" v-if='Format == "html"'>
        <base-input label="Objet2" placeholder="OBJET2"
              v-model="Objet2"
              v-validate="'required'"
              :error="getError('objet2')"
              name="objet2"
              type="text">
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input label="Expéditeur" placeholder="EXPÉDITEUR"
              v-model="Expediteur"
              v-validate="'required'"
              :error="getError('expediteur')"
              name="expediteur"
              type="text">
        </base-input>
      </div>
      <div class="col-sm-5" :class="{ 'text-center': Format === 'gif' }">
        <base-input label="Fichier visuel">
          <image-upload
            @change="onImageVisuelChange"
            select-text="SÉLECTIONNER FICHIER VISUEL">
          </image-upload>
        </base-input>
      </div>
      <div class="col-sm-5" v-if='Format == "html"'>
        <base-input label="Image de capture (gif)">
          <image-upload
            @change="onImageCaptureChange">
          </image-upload>
        </base-input>
      </div>
      <!-- <div class="col-sm-5">
        <img v-if="visual_img" style="width:133px;height:127px" :src="visual_img" />
      </div> -->
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-2" v-if="!loading">
        <base-button type="info" v-if="Format=='html'" @click="addVisuelHtml" class="space">{{ $t("buttons.BtnAdd").toUpperCase() }}</base-button>
        <base-button type="info" v-if="Format=='gif'" @click="addVisuelBanniere" class="space">{{ $t("buttons.BtnAdd").toUpperCase() }}</base-button>
      </div>
      <div class="col-sm-2" v-else>
        <base-button type="info" loading></base-button>
      </div>
    </div>
  </card>
</template>

<script>
import {Select, Option} from 'element-ui'
import {ImageUpload} from 'src/components'
import { BaseCheckbox, BaseRadio } from 'src/components/index'
import gql from 'graphql-tag'

export default {
  apollo: {
    list_programme: {
      query: gql`query {
        list_programme(Titre:"",idcpart:0,etat:"valide",orderBy: [
          {
            field: "Titre"
            order: ASC
          }
          ]){
            id_programme
            Titre
            repertoire
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ProgrammeSelects.ProgrammeList = data.list_programme
      },
      update: data => data.list_programme
    },
    list_visuel: {
      query: gql`query ($id_programme: Int!, $format: String!){
        list_visuel (id_programme: $id_programme, etat: "valide", format: $format){
          id_visuel
          taille
          profil
          repertoire
        }
      }`,
      variables() {
        return {
          id_programme: this.ProgrammeSelects.Programme.split("SPLITE")[0],
          format: this.Format
        }
      },
      skip(){
        return (this.ProgrammeSelects.Programme=='')
      },
      result({ data, loader, networkStatus }) {
        this.ElementsSelects.ElementsList = data.list_visuel
      },
      update: data => data.list_visuel
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ImageUpload,
    BaseRadio,
    BaseCheckbox
  },
  data() {
    return {
      visual_img: null,
      Format: 'html',
      Link_redirect: 'non',
      Profile: '',
      Objet: '',
      Objet2: '',
      Expediteur: '',
      loading: false,
      Marque:false,
      // Pre_remplissage: {
      //   CIVILITE: false,
      //   NOM: false,
      //   PRENOM: false,
      //   EMAIL: false,
      //   SOCIETE: false,
      //   TEL: false,
      //   CODE_POSTAL: false,
      //   VILLE: false,
      //   PAYS: false,
      //   ADRESSE_POSTALE: false,
      //   DATE_DE_NAISSANCE: false
      // },
      images: {
        visuel: null,
        capture: null
      },
      ProgrammeSelects: {
        Programme: '',
        ProgrammeList: []
      },
      ElementsSelects: {
        Elements: '',
        ElementsList: []
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onImageVisuelChange(file) {
      this.images.visuel = file;
    },
    onImageCaptureChange(file) {
      this.images.capture = file;
    },
    async addVisuelHtml() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.loading = true
      if (this.ElementsSelects.Elements == 'new'){
        this.$apollo.mutate({
          mutation: gql`mutation (
              $id_programme:Int!
              $expediteur:String
              $objet:String
              $objet2:String
              $repertoire:String
              $hasRedirectLinks: String
              $redirect:Int
              $file: Upload
              $file_capt: Upload
            ) {
            createVisuelHtml (
                              id_programme: $id_programme,
                              expediteur: $expediteur,
                              objet: $objet,
                              objet2: $objet2,
                              hasRedirectLinks: $hasRedirectLinks,
                              redirect: $redirect,
                              file: $file,
                              file_capt: $file_capt,
                              action: "create",
                              repertoire: $repertoire,
                             ) 
            {
              id_visuel
              format
            }
          }`,
          variables: {
            id_programme: this.ProgrammeSelects.Programme.split("SPLITE")[0],
            expediteur: this.Expediteur,
            objet: this.Objet,
            objet2: this.Objet2,
            redirect: (this.Link_redirect == 'oui') ? 1 : 0,
            hasRedirectLinks: (this.Marque) ? 'oui' : 'non',
            file: this.images.visuel,
            file_capt: this.images.capture,
            repertoire : this.ProgrammeSelects.Programme.split("SPLITE")[1]
          },
        }).then(response => {
          this.loading = false
          this.$notify({type: 'success', message: this.$t("apiresponse.visuelcreated") })
        }).catch(error => {
          this.loading = false
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      } else {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
              $id_visuel: ID!
              $id_programme:Int!
              $expediteur:String
              $objet:String
              $objet2:String
              $repertoire:String
              $hasRedirectLinks: String
              $redirect:Int
              $file: Upload
              $file_capt: Upload
            ) {
            updateVisuelHtml (
                              id_visuel: $id_visuel,
                              id_programme: $id_programme,
                              expediteur: $expediteur,
                              repertoire: $repertoire,
                              hasRedirectLinks: $hasRedirectLinks,
                              objet: $objet,
                              objet2: $objet2,
                              redirect: $redirect,
                              file: $file,
                              file_capt: $file_capt,
                              action: "create"
                             )
            {
              id_visuel
              format
            }
          }`,
          variables: {
            id_visuel: this.ElementsSelects.Elements.split("SPLITE")[0],
            id_programme: this.ProgrammeSelects.Programme.split("SPLITE")[0],
            repertoire: this.ProgrammeSelects.Programme.split("SPLITE")[1],
            hasRedirectLinks: (this.Marque) ? 'oui' : 'non',
            expediteur: this.Expediteur,
            objet: this.Objet,
            objet2: this.Objet2,
            redirect: (this.Link_redirect == 'oui') ? 1 : 0,
            file: this.images.visuel,
            file_capt: this.images.capture
          },
        }).then(response => {
          this.loading = false
          this.$notify({type: 'success', message: this.$t("apiresponse.visuelupdated") })
        }).catch(error => {
          this.loading = false
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      }
    },
    async addVisuelBanniere(){
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.loading = true
      if(this.ElementsSelects.Elements == 'new') {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
              $id_programme:Int!
              $redirect:Int
              $file: Upload
              $repertoire:String
            ) {
            createVisuelBaniere (
                                  id_programme: $id_programme,
                                  redirect: $redirect,
                                  file: $file,
                                  repertoire: $repertoire,
                                  action: "create"
                                ) 
            {
              id_visuel
            }
          }`,
          variables: {
            id_programme: this.ProgrammeSelects.Programme.split("SPLITE")[0],
            redirect: (this.Link_redirect == 'oui') ? 1 : 0,
            file: this.images.visuel,
            repertoire : this.ProgrammeSelects.Programme.split("SPLITE")[1]
          },
        }).then(response => {
          this.loading = false
          this.$notify({type: 'success', message: this.$t("apiresponse.visuelcreated") })
        }).catch(error => {
          this.loading = false
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      } else{
        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
              id_visuel: ID!
              id_programme:Int!
              lien:String
              format:String
              expediteur:String
              repertoire:String
              url:String
              etat:String
              redirect:Int
              tmp:DateTime
              ip:String
              hostname:String
              file: Upload
            ) {
            updateVisuelBaniere ( 
                                  id_visuel: $id_visuel,
                                  id_programme: $id_programme,
                                  expediteur: $expediteur,
                                  file: $file
                                ) 
            {
            }
          }`,
          variables: {
            id_visuel: this.ElementsSelects.Elements,
            id_programme: this.ProgrammeSelects.Programme,
            expediteur: this.Expediteur,
            file: this.images.visuel
          },
        }).then(response => {
          this.loading = false
          this.$notify({type: 'success', message: this.$t("apiresponse.visuelupdated") })
        }).catch(error => {
          this.loading = false
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      }
      
    },
    Elementschange($event){
      if($event == 'new') {
        this.visual_img = null
      } else {
        this.visual_img = "http://www.weedoit.fr/tracking/partenaire/" + $event.split("SPLITE")[1] + "/" + $event.split("SPLITE")[2]
      }
    },
    reset() {
      this.Profile = ''
      this.Objet = ''
      this.Objet2 = ''
      this.Expediteur = ''
      this.images.visuel = null
      this.images.capture = null
    }
  }
}
</script>