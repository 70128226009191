<template>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-chart">
            <div class="card-header">
              <div class="row">
                <div class="col-md-4 text-left">
                  <h2 class="card-title">{{ $t("buttons.BtnStatsDetails").toUpperCase() }}</h2>
                </div>
                <div class="col-md-8">
                  <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                    <button
                      @click="reset"
                      class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                    >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                    <button 
                      v-if="!$apollo.queries.statsDetailleesNewTraking.loading" 
                      @click="send" 
                      class="btn btn-round btn-just-icon btn-primary mb-2"
                      >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                    <base-button 
                      v-else 
                      loading 
                      round 
                      type="primary" 
                      class="btn btn-round btn-just-icon btn-primary mb-2"
                      >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-2 col-sm-6">
                  <base-input :error="getError('date de début')">
                    <el-date-picker
                      v-model="StartDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="DATE DE DÉBUT"
                      name="date de début"
                      v-validate="'required'"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </base-input>
                </div>
                <div class="col-md-2 col-sm-6">
                  <base-input :error="getError('date de fin')">
                    <el-date-picker
                      v-model="EndDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="DATE DE FIN"
                      name="date de fin"
                      v-validate="'required'"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </base-input>
                </div>
                <div class="col-md-2 col-sm-6" >
                 <base-input :error="getError('manager')">
                    <el-select
                      class="select-primary"
                      placeholder="MANAGER"
                      v-validate="managerRequired"
                      name="manager"
                      v-model="ManagerSelects.Manager"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="option in ManagerSelects.ListManager"
                        class="select-primary"
                        :value="option.email_client+'SPLITE'+option.id_client+'SPLITE'+option.prenom_client +' '+ option.nom_client.toUpperCase()"
                        :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                        :key="option.id_client"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-2 col-sm-6">
                  <base-input :error="getError('programme')">
                    <el-select
                      filterable
                      clearable
                      class="select-primary"
                      placeholder="PROGRAMME"
                      v-model="AnnonceurSelects.Annonceur"
                      name="programme"
                      v-validate="prgRequired"
                    >
                      <el-option
                        v-for="option in AnnonceurSelects.ListAnnonceur"
                        class="select-primary"
                        :value="option.id_programme+'SPLITE'+option.provenance"
                        :label="option.provenance.toUpperCase()"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-2 col-sm-6">
                  <base-input>
                    <el-select class="select-primary" 
                    placeholder="TYPE"
                    clearable
                    v-model="TypeSelects.Type">
                      <el-option
                        v-for="option in TypeSelects.ListType"
                        class="select-primary"
                        :value="option.taille"
                        :label="option.taille.toUpperCase()"
                        :key="option.id_visuel"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="p-2">
              <!-- <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label
                  v-for="(option, index) in bigLineChartCategories"
                  :key="option.name"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: bigLineChart.activeIndex === index }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initBigChart(index)"
                    name="options"
                    autocomplete="off"
                    :checked="bigLineChart.activeIndex === index"
                  />
                  <span class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div> -->
              <div class="chart-area" v-if="!$apollo.queries.statsDetailleesNewTraking.loading">
                <line-chart
                  :labels="lineChart2.labels"
                  :chart-data="lineChart2.chartData"
                  :extra-options="lineChart2.extraOptions"
                  :height="200"
                >
                </line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!$apollo.queries.statsDetailleesNewTraking.loading">
        <div class="col-12">
          <div class="card card-chart">
            <div class="card-header">
              <div class="row">
                <div class="col-12 text-left">
                </div>
              </div>
            </div>
            <div class="p-2" data="black">
              <el-table :data="statsDetailleesNewTraking" show-summary :summary-method="getSummaries">
                <el-table-column
                  min-width="250"
                  label="Jour"
                  prop="jour"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <label style="cursor: pointer;" @click="Details(scope.row)">
                      {{ scope.row.jour.toUpperCase() }}
                    </label>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150"
                  label="Impressions"
                  prop="impressions"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.impressions) }}
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="100"
                  label="Clics"
                  prop="clics"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.clics) }}
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="100"
                  label="Leads"
                  prop="leads"
                  sortable
                >
                  <template scope="scope">
                    <label style="cursor: pointer;" @click="Details(scope.row)">
                      <u>
                        {{ formatThounsends(scope.row.leads) }}
                      </u>
                    </label>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150"
                  label="Leads client"
                  prop="Leads_client"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.Leads_client) }}
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="180"
                  label="Taux affichage"
                  prop="taux_affichage"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.taux_affichage, true, 2) + ' %' }}
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  min-width="100"
                  label="CTR"
                  prop="CTR"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                  </template>
                </el-table-column> -->
                <el-table-column
                  min-width="100"
                  label="CA"
                  prop="ca"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ca, true) + ' €' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Select, Option, DatePicker } from "element-ui";
  import LineChart from "@/components/Charts/LineChart";
  import * as chartConfigs from "@/components/Charts/config";
  import config from "@/config";
  import gql from 'graphql-tag'
  import { Table, TableColumn } from 'element-ui';
  
  let thisday = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
  export default {
    apollo: {
      listManager: {
        query: gql`
          query {
            listManager(
              bloque: "non"
              etat: "valide"
              droit: ["ma", "tem"]
              orderBy: [
                { field: "prenom_client", order: ASC }
                { field: "nom_client", order: ASC }
              ]
            ) {
              id_client
              nom_client
              prenom_client
              email_client
            }
          }
        `,
        result({ data, loader, networkStatus }) {
          this.ManagerSelects.ListManager = data.listManager;
        }
      },
      list_programme_detail: {
        query: gql`query {
          list_programme_detail(flag:"details"){
              id_programme
              Titre
              repertoire
              provenance
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.AnnonceurSelects.ListAnnonceur = data.list_programme_detail
        },
        update: data => data.list_programme_detail
      },
      list_visuel: {
        query: gql`query ($id_programme: Int!){
          list_visuel (id_programme: $id_programme, etat: "valide"){
            taille
            format
          }
        }`,
        variables() {
          return {
            id_programme: this.AnnonceurSelects.Annonceur.split("SPLITE")[0]
          }
        },
        skip(){
          return (this.AnnonceurSelects.Annonceur=='')
        },
        result({ data, loader, networkStatus }) {
          let Visuel = []
          let hasBanniere = false
          for (let index = 0; index < data.list_visuel.length; index++) {
            if(data.list_visuel[index].format == 'gif') {
              if (hasBanniere == false)
                Visuel.push({taille: "Banniere",format: "Banniere"})
              hasBanniere = true
            } else {
              Visuel.push({taille: data.list_visuel[index].taille,format: data.list_visuel[index].format})
            }
          }
          this.TypeSelects.ListType = Visuel
          
        },
        update: data => data.list_visuel
      },
      statsDetailleesNewTraking: {
        query: gql`query (
            $annonceur: String,
            $v_type: String
            $manager: String
            $date_start: Date!,
            $date_end: Date!
          ){
          statsDetailleesNewTraking (
              v_type: $v_type,
              annonceur: $annonceur,
              date_start: $date_start,
              date_end: $date_end
              manager: $manager
            ){
              jour
              impressions
              clics
              leads
              Leads_client
              taux_affichage
              ca
          }
        }`,
        skip(){
          return this.SkipQuery
        },
        fetchPolicy: 'network-only',
        variables() {
          return {
            annonceur: this.AnnonceurSelects.Annonceur.split("SPLITE")[1],
            v_type: this.TypeSelects.Type,
            date_start: this.StartDate,
            date_end: this.EndDate,
            manager: this.ManagerSelects.Manager.split("SPLITE")[0]
          }
        },
        result({ data, loader, networkStatus }) {
          let chartdataLead = []
          let chartdataCA = []
          let labels = []
          let SUMimpressions = 0
          let SUMclics = 0
          let SUMleads = 0
          let SUMLeads_client = 0
          let SUMca = 0
          
          for (let index = 0; index < data.statsDetailleesNewTraking.length; index++) {
            (data.statsDetailleesNewTraking[index].leads == null) ? chartdataLead.push(0) : chartdataLead.push(data.statsDetailleesNewTraking[index].leads)
            SUMimpressions += data.statsDetailleesNewTraking[index].impressions
            SUMleads += data.statsDetailleesNewTraking[index].leads
            SUMLeads_client += data.statsDetailleesNewTraking[index].Leads_client
            SUMca += data.statsDetailleesNewTraking[index].ca
            SUMclics += data.statsDetailleesNewTraking[index].clics
            labels.push(data.statsDetailleesNewTraking[index].jour.split(" ")[1]+"/"+data.statsDetailleesNewTraking[index].jour.split(" ")[2]);
            (data.statsDetailleesNewTraking[index].ca == null) ? chartdataCA.push(0) : chartdataCA.push(data.statsDetailleesNewTraking[index].ca)
          }
          this.SUMimpressions = SUMimpressions
          this.SUMclics = SUMclics
          this.SUMleads = SUMleads
          this.SUMLeads_client = SUMLeads_client
          this.SUMca = SUMca
          this.lineChart2.chartData.datasets[0].data = chartdataLead
          this.lineChart2.chartData.datasets[1].data = chartdataCA
          this.lineChart2.chartData.labels = labels
          this.SkipQuery = true
        }
      },
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      LineChart,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        mounth: [ 
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre"
        ],
        managerRequired: 'required',
        prgRequired: 'required',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        SkipQuery: true,
        StartDate: thisday,
        EndDate: thisday,
        SUMimpressions: 0,
        SUMclics: 0,
        SUMleads: 0,
        SUMLeads_client: 0,
        SUMca: 0,
        AnnonceurSelects: {
          Annonceur: "",
          ListAnnonceur: []
        },
        TypeSelects: {
          Type: "",
          ListType: []
        },
        ManagerSelects: {
          Manager: "",
          ListManager: []
        },
        lineChart2: {
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'LEADS',
                fill: true,
                borderColor: config.colors.info,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.info,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: '#2380f7',
                pointBorderWidth: 20,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: []
              },
              {
                label: 'CA',
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: '#2380f7',
                pointBorderWidth: 20,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: []
              }
            ]
          },
          extraOptions: {
            ...chartConfigs.lineChartOptionsBlue,
            legend: {
              display: true
            },
          }
        },
      };
    },
    computed: {
      bigLineChartCategories() {
        return [
          { name: "Aujourd'hui", icon: "tim-icons icon-single-02" },
          { name: "Hier", icon: "tim-icons icon-gift-2" },
          { name: "Mois courant", icon: "tim-icons icon-tap-02" },
          { name: "Mois dernier", icon: "tim-icons icon-tap-02" },
          { name: "Depuis lancement", icon: "tim-icons icon-tap-02" }
        ];
      }
    },
    methods: {
      Details(row) {
        let Details = {}
        Details.jour = row.jour.split(' ')[3] + '/' + (this.mounth.indexOf(row.jour.split(' ')[2]) + 1) + '/' + row.jour.split(' ')[1]
        Details.droit = (this.ManagerSelects.Manager == '') ? "ad" : "tm"
        Details.id_client = this.ManagerSelects.Manager.split("SPLITE")[1] || null
        Details.id_programme = this.AnnonceurSelects.Annonceur.split("SPLITE")[0] || null
        Details.title_programme = this.AnnonceurSelects.Annonceur.split("SPLITE")[1] || null
        Details.manager = this.ManagerSelects.Manager.split("SPLITE")[2] || null
        localStorage.setItem("Details", JSON.stringify(Details))
        
        let routeData = this.$router.resolve({name: 'stats Detail Leads by new traking'});
        window.open(routeData.href, '_blank');   
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value :value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime(); 
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if(Difference_In_Days+1 > 31) {
          this.$notify({type: 'primary', message: "vous n'aviez pas le droit de rechercher sur une période plus que 31 jours"})
        } else if (Difference_In_Days < 0){
          this.EndDate = this.StartDate
          this.SkipQuery = false
        } else {
          this.SkipQuery = false
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      reset() {
        this.TypeSelects.Type = "";
        this.StartDate = thisday;
        this.EndDate = thisday;
        this.AnnonceurSelects.Annonceur = "";
        this.ManagerSelects.Manager = ""
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(this.SUMimpressions)
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(this.SUMclics)
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(this.SUMleads)
            return;
          }
          else if( index === 4) {
            sums[index] = this.formatThounsends(this.SUMLeads_client)
            return;
          }
          else if( index === 5) {
            if(this.SUMclics == 0)
              sums[index] = 0 + ' %'
            else
              sums[index] = this.formatThounsends((this.SUMleads/this.SUMclics)*100, true, 2) + ' %'
            return;
          }
          // else if( index === 6) {
          //   sums[index] = this.formatThounsends((this.SUMleads/this.SUMimpressions)*100, true, 2) + ' %'
          //   return;
          // }
          else if( index === 6) {
            sums[index] = this.formatThounsends(this.SUMca, true) + ' €'
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
    },
    watch: {
      'AnnonceurSelects.Annonceur'() {
        this.TypeSelects.Type = ""
        if(this.AnnonceurSelects.Annonceur == "") {
          this.managerRequired = 'required'
        }
        else {
          this.managerRequired = ''
        }
      },
      'ManagerSelects.Manager'() {
        if(this.ManagerSelects.Manager == "") {
          this.prgRequired = 'required'
        }
        else {
          this.prgRequired = ''
        }
      }
    }
  };
  </script>