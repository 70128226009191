<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("buttons.BtnStatsDetails").toUpperCase() }}</h2>
              </div>
              <div class="col-md-8">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    @click="reset"
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                  <button 
                    v-if="!$apollo.queries.statsDetaillees.loading" 
                    @click="send" 
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  <base-button 
                    v-else 
                    loading 
                    round 
                    type="primary" 
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.StartDate'))">
                  <el-date-picker
                    v-model="StartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('table.columns.StartDate').toUpperCase()"
                    :name="$t('table.columns.StartDate')"
                    v-validate="'required'"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.EndDate'))">
                  <el-date-picker
                    v-model="EndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('table.columns.EndDate').toUpperCase()"
                    :name="$t('table.columns.EndDate')"
                    v-validate="'required'"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6" >
               <base-input :error="getError('manager')">
                  <el-select
                    class="select-primary"
                    placeholder="MANAGER"
                    v-validate="managerRequired"
                    name="manager"
                    v-model="ManagerSelects.Manager"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.email_client+'SPLITE'+option.id_client+'SPLITE'+option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.Announcer').toUpperCase())">
                  <el-select
                    filterable
                    clearable
                    class="select-primary"
                    :placeholder="$t('table.columns.Announcer').toUpperCase()"
                    v-model="client"
                    :name="$t('table.columns.Announcer').toUpperCase()"
                    v-validate="clientRequired"
                  >
                    <el-option
                      v-for="option in list_clients"
                      class="select-primary"
                      :value="option.id_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.Program'))">
                  <el-select
                    filterable
                    clearable
                    class="select-primary"
                    :placeholder="$t('table.columns.Program').toUpperCase()"
                    v-model="AnnonceurSelects.Annonceur"
                    :name="$t('table.columns.Program')"
                    v-validate="prgRequired"
                  >
                    <el-option
                      v-for="option in AnnonceurSelects.ListAnnonceur"
                      class="select-primary"
                      v-if="(client) ? option.id_client+'' === client : true"
                      :value="option.id_programme+'SPLITE'+option.provenance"
                      :label="option.provenance.toUpperCase()"
                      :key="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input>
                  <el-select class="select-primary" 
                  placeholder="TYPE"
                  clearable
                  v-model="TypeSelects.Type">
                    <el-option
                      v-for="option in TypeSelects.ListType"
                      class="select-primary"
                      :value="option.taille"
                      :label="option.taille.toUpperCase()"
                      :key="option.id_visuel"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="p-2">
            <!-- <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
              <label
                v-for="(option, index) in bigLineChartCategories"
                :key="option.name"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: bigLineChart.activeIndex === index }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="initBigChart(index)"
                  name="options"
                  autocomplete="off"
                  :checked="bigLineChart.activeIndex === index"
                />
                <span class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                  <i :class="option.icon"></i>
                </span>
              </label>
            </div> -->
            <div class="chart-area" v-if="!$apollo.queries.statsDetaillees.loading">
              <line-chart
                :labels="lineChart2.labels"
                :chart-data="lineChart2.chartData"
                :extra-options="lineChart2.extraOptions"
                :height="200"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 offset-md-8">
            <div class="btn-group float-right mb-3">
              <button simple size="sm" class="btn btn-sm btn-info btn-simple"  :class="{ active: this.switchTrack === 'Lead' }" @click="switchGraphTrack()">Leads</button>
              <button simple size="sm" class="btn btn-sm btn-info btn-simple" :class="{ active: this.switchTrack === 'Clique' }" @click="switchGraphTrack()">{{ $t('table.columns.clicks') }}</button>
            </div>
          </div>
        </div>
        <span v-show='this.switchTrack === "Lead"'>
        <div class="row">
          
          <div class="col-md-4">
          <div class="card">
            <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
              {{ $t('table.columns.SourceMachine') }}
            </span>
            <br/>
            <pie-chart
              :chart-data="dataChartAffichage_by_machine_lead"
              :extra-options="pieChart.extraOptions"
              :height="200"
              v-if="renderChart_Affichage_by_machine && !$apollo.queries.statsDetaillees.loading"
            >
            </pie-chart>
          </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceOs') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_os_lead"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_os && !$apollo.queries.statsDetaillees.loading"
              >
              </pie-chart>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceBrowser') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_nav_lead"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_nav && !$apollo.queries.statsDetaillees.loading"
              >
              </pie-chart>
            </div>
          </div>

        </div>
        
      </span>
      <span v-show='this.switchTrack === "Clique"'>
        <div class="row">
          
          <div class="col-md-4">
          <div class="card">
            <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
              {{ $t('table.columns.SourceMachine') }}
            </span>
            <br/>
            <pie-chart
              :chart-data="dataChartAffichage_by_machine_clique"
              :extra-options="pieChart.extraOptions"
              :height="200"
              v-if="renderChart_Affichage_by_machine && !$apollo.queries.statsDetaillees.loading"
            >
            </pie-chart>
          </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceOs') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_os_clique"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_os && !$apollo.queries.statsDetaillees.loading"
              >
              </pie-chart>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceBrowser') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_nav_clique"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_nav && !$apollo.queries.statsDetaillees.loading"
              >
              </pie-chart>
            </div>
          </div>

        </div>
      </span>
      </div>
    <div class="row" v-if="!$apollo.queries.statsDetaillees.loading">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="statsDetaillees" show-summary :summary-method="getSummaries">
              <el-table-column
                min-width="250"
                :label="$t('table.columns.Day')"
                prop="jour"
                show-overflow-tooltip
                :sort-method="CustomSort_Periode"
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    {{ moment(scope.row.year+"-"+scope.row.month+"-"+scope.row.day).format('dddd LL').toUpperCase() }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                label="Impressions"
                prop="impressions"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="100"
                :label="$t('table.columns.clicks')"
                prop="clics"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="100"
                label="Leads"
                prop="leads"
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    <u>
                      {{ formatThounsends(scope.row.leads) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                label="Leads client"
                prop="Leads_client"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Leads_client) }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="180"
                :label="$t('table.columns.DisplayRate')"
                prop="taux_affichage"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.taux_affichage, true, 2) + ' %' }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                min-width="100"
                label="CTR"
                prop="CTR"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                </template>
              </el-table-column> -->
              <el-table-column
                min-width="100"
                :label="$t('Pages.dashboard.ca')"
                prop="ca"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import config from "@/config";
import gql from 'graphql-tag'
import PieChart from 'src/components/Charts/PieChart';
import { Table, TableColumn } from 'element-ui';
import moment from 'moment'
moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr");

let thisday = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
export default {
  apollo: {
    statsTrackingDetailsDetaillees: {
        query: gql `query (
          $annonceur: String,
          $v_type: String
          $manager: String
          $date_start: Date!,
          $date_end: Date!) {
            statsTrackingDetailsDetaillees(
              v_type: $v_type,
              annonceur: $annonceur,
              date_start: $date_start,
              date_end: $date_end
              manager: $manager) {
              clique {
                Mobile
                Desctop
                Tab
                os {
                    Android
                    Other
                    iOS
                    Linux
                    Windows
                    MacOS
                }
                navigateur {
                    Chrome
                    Other
                    Edg
                    Safari
                    Firefox
                    MSIE
                    Opera
                }
              }
              lead {
                Mobile
                Desctop
                Tab
                os {
                    Android
                    Other
                    iOS
                    Linux
                    Windows
                    MacOS
                }
                navigateur {
                    Chrome
                    Other
                    Edg
                    Safari
                    Firefox
                    MSIE
                    Opera
                }
              }
            }
          }`,
        variables () {
          return {
            annonceur: this.AnnonceurSelects.Annonceur.split("SPLITE")[1],
            v_type: this.TypeSelects.Type,
            date_start: this.StartDate,
            date_end: this.EndDate,
            manager: this.ManagerSelects.Manager.split("SPLITE")[0]
          }
        },
        fetchPolicy: 'network-only',
        skip() {
          return this.SkipQueryStatsTrackingDetails
        },
        result({ data, loader, networkStatus }) {
          let AllLeadsOld = this.SUMleads - (data.statsTrackingDetailsDetaillees.lead.Mobile + data.statsTrackingDetailsDetaillees.lead.Desctop + data.statsTrackingDetailsDetaillees.lead.Tab)
          if(AllLeadsOld < 0){
            AllLeadsOld = 0;
          }
          let AllCliqueOld = this.SUMclics - (data.statsTrackingDetailsDetaillees.clique.Mobile + data.statsTrackingDetailsDetaillees.clique.Desctop + data.statsTrackingDetailsDetaillees.clique.Tab)
          if(AllCliqueOld < 0){
            AllCliqueOld = 0;
          }
          this.dataChartAffichage_by_machine_lead = {
            labels: ['Mobile','Desctop','Tab','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#00B7C3', '#1B61F7','#FFCD46', '#C626F7'],
                borderWidth: 0,
                data: [data.statsTrackingDetailsDetaillees.lead.Mobile, data.statsTrackingDetailsDetaillees.lead.Desctop, data.statsTrackingDetailsDetaillees.lead.Tab,AllLeadsOld]
              }
            ]
          }
          this.dataChartAffichage_by_machine_clique = {
            labels: ['Mobile','Desctop','Tab','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#00B7C3', '#1B61F7','#FFCD46', '#C626F7'],
                borderWidth: 0,
                data: [data.statsTrackingDetailsDetaillees.clique.Mobile, data.statsTrackingDetailsDetaillees.clique.Desctop, data.statsTrackingDetailsDetaillees.clique.Tab,AllCliqueOld]
              }
            ]
          }
          this.renderChart_Affichage_by_machine = true 

          this.dataChartAffichage_by_os_lead = {
            labels: ['Android','Linux','MacOS','Windows','iOS','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#a4c639', '#3F51B5', '#A3AAAE', '#01A6F0', '#5FC9F8', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetailsDetaillees.lead.os.Android, 
                  data.statsTrackingDetailsDetaillees.lead.os.Linux,
                  data.statsTrackingDetailsDetaillees.lead.os.MacOS,
                  data.statsTrackingDetailsDetaillees.lead.os.Windows,
                  data.statsTrackingDetailsDetaillees.lead.os.iOS,
                  data.statsTrackingDetailsDetaillees.lead.os.Other,
                  AllLeadsOld
                  ]
              }
            ]
          }
          this.dataChartAffichage_by_os_clique = {
            labels: ['Android','Linux','MacOS','Windows','iOS','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#a4c639', '#3F51B5', '#A3AAAE', '#01A6F0', '#5FC9F8', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetailsDetaillees.clique.os.Android, 
                  data.statsTrackingDetailsDetaillees.clique.os.Linux,
                  data.statsTrackingDetailsDetaillees.clique.os.MacOS,
                  data.statsTrackingDetailsDetaillees.clique.os.Windows,
                  data.statsTrackingDetailsDetaillees.clique.os.iOS,
                  data.statsTrackingDetailsDetaillees.clique.os.Other,
                  AllCliqueOld
                  ]
              }
            ]
          }
          this.renderChart_Affichage_by_os = true 

          this.dataChartAffichage_by_nav_lead = {
            labels: ['Chrome','Edg','Firefox','MSIE','Opera','Safari','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#FFCD46', '#0078d7', '#FF6611', '#009999', '#FF1B2D', '#006CFF', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Chrome, 
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Edg,
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Firefox,
                  data.statsTrackingDetailsDetaillees.lead.navigateur.MSIE,
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Opera,
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Safari,
                  data.statsTrackingDetailsDetaillees.lead.navigateur.Other,
                  AllLeadsOld
                  ]
              }
            ]
          }
          this.dataChartAffichage_by_nav_clique = {
            labels: ['Chrome','Edg','Firefox','MSIE','Opera','Safari','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#FFCD46', '#0078d7', '#FF6611', '#009999', '#FF1B2D', '#006CFF', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Chrome, 
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Edg,
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Firefox,
                  data.statsTrackingDetailsDetaillees.clique.navigateur.MSIE,
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Opera,
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Safari,
                  data.statsTrackingDetailsDetaillees.clique.navigateur.Other,
                  AllCliqueOld
                  ]
              }
            ]
          }
          this.renderChart_Affichage_by_nav = true
          this.SkipQueryStatsTrackingDetails = true
        },
      },
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
      }
    },
    list_programme_detail: {
      query: gql`query {
        list_programme_detail(flag:"details"){
            id_programme
            Titre
            repertoire
            provenance
            id_client
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.AnnonceurSelects.ListAnnonceur = data.list_programme_detail
      },
      update: data => data.list_programme_detail
    },
    list_visuel: {
      query: gql`query ($id_programme: Int!){
        list_visuel (id_programme: $id_programme, etat: "valide"){
          taille
          format
        }
      }`,
      variables() {
        return {
          id_programme: this.AnnonceurSelects.Annonceur.split("SPLITE")[0]
        }
      },
      skip(){
        return (this.AnnonceurSelects.Annonceur=='')
      },
      result({ data, loader, networkStatus }) {
        let Visuel = []
        let hasBanniere = false
        for (let index = 0; index < data.list_visuel.length; index++) {
          if(data.list_visuel[index].format == 'gif') {
            if (hasBanniere == false)
              Visuel.push({taille: "Banniere",format: "Banniere"})
            hasBanniere = true
          } else {
            Visuel.push({taille: data.list_visuel[index].taille,format: data.list_visuel[index].format})
          }
        }
        this.TypeSelects.ListType = Visuel
        
      },
      update: data => data.list_visuel
    },
    statsDetaillees: {
      query: gql`query (
          $annonceur: String,
          $v_type: String
          $manager: String
          $date_start: Date!,
          $date_end: Date!,
          $id_client: Int
        ){
        statsDetaillees (
            v_type: $v_type,
            annonceur: $annonceur,
            date_start: $date_start,
            date_end: $date_end,
            manager: $manager,
            id_client: $id_client
          ){
            jour
            impressions
            clics
            leads
            Leads_client
            taux_affichage
            ca
            day
            month
            year
        }
      }`,
      skip(){
        return this.SkipQuery
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          annonceur: this.AnnonceurSelects.Annonceur.split("SPLITE")[1],
          v_type: this.TypeSelects.Type,
          date_start: this.StartDate,
          date_end: this.EndDate,
          manager: this.ManagerSelects.Manager.split("SPLITE")[0],
          id_client: this.client
        }
      },
      result({ data, loader, networkStatus }) {
        let chartdataLead = []
        let chartdataCA = []
        let labels = []
        let SUMimpressions = 0
        let SUMclics = 0
        let SUMleads = 0
        let SUMLeads_client = 0
        let SUMca = 0
        
        for (let index = 0; index < data.statsDetaillees.length; index++) {
          (data.statsDetaillees[index].leads == null) ? chartdataLead.push(0) : chartdataLead.push(data.statsDetaillees[index].leads)
          SUMimpressions += data.statsDetaillees[index].impressions
          SUMleads += data.statsDetaillees[index].leads
          SUMLeads_client += data.statsDetaillees[index].Leads_client
          SUMca += data.statsDetaillees[index].ca
          SUMclics += data.statsDetaillees[index].clics
          labels.push(data.statsDetaillees[index].jour.split(" ")[1]+"/"+this.mounth[data.statsDetaillees[index].month-1]);
          (data.statsDetaillees[index].ca == null) ? chartdataCA.push(0) : chartdataCA.push(data.statsDetaillees[index].ca)
        }
        this.SUMimpressions = SUMimpressions
        this.SUMclics = SUMclics
        this.SUMleads = SUMleads
        this.SUMLeads_client = SUMLeads_client
        this.SUMca = SUMca
        this.lineChart2.chartData.datasets[0].data = chartdataLead
        this.lineChart2.chartData.datasets[1].data = chartdataCA
        this.lineChart2.chartData.labels = labels
        this.SkipQuery = true
        this.SkipQueryStatsTrackingDetails = false
      }
    },
    list_clients: {
      query: gql`
        query {
          listAnnonceur {
            id_client
            societe_client
          }
        }
      `,
      update: data => data.listAnnonceur,
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    LineChart,
    PieChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      client: "",
      mounth: this.$t('labels.Mounths'),
      managerRequired: 'required',
      prgRequired: 'required',
      clientRequired: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      SkipQuery: true,
      SkipQueryStatsTrackingDetails: true,
      renderChart_Affichage_by_machine: false,
      renderChart_Affichage_by_os: false,
      renderChart_Affichage_by_nav: false,
      dataChartAffichage_by_machine_lead: null,
      dataChartAffichage_by_machine_clique: null,
      dataChartAffichage_by_os_lead: null,
      dataChartAffichage_by_nav_lead: null,
      dataChartAffichage_by_os_clique: null,
      dataChartAffichage_by_nav_clique: null,
      switchTrack : 'Lead',
      StartDate: thisday,
      EndDate: thisday,
      SUMimpressions: 0,
      SUMclics: 0,
      SUMleads: 0,
      SUMLeads_client: 0,
      SUMca: 0,
      pieChart: {
        chartData: {},
        extraOptions: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 15,
              fontSize: 12
            }
          },
          cutoutPercentage: 60,
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
              },
              label: function(tooltipItem, data) {
                return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              },
              afterLabel: function(tooltipItem, data) {
                var dataset = data['datasets'][0];
                
                var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
                return '(' + percent + '%)';
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 16,
            titleFontColor: '#0000',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: false
          },
        }
      },
      AnnonceurSelects: {
        Annonceur: "",
        ListAnnonceur: []
      },
      TypeSelects: {
        Type: "",
        ListType: []
      },
      ManagerSelects: {
        Manager: "",
        ListManager: []
      },
      lineChart2: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'LEADS',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 2,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            },
            {
              label: this.$t('Pages.dashboard.ca').toUpperCase(),
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 2,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          legend: {
            display: true
          },
        }
      },
    };
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: "Aujourd'hui", icon: "tim-icons icon-single-02" },
        { name: "Hier", icon: "tim-icons icon-gift-2" },
        { name: "Mois courant", icon: "tim-icons icon-tap-02" },
        { name: "Mois dernier", icon: "tim-icons icon-tap-02" },
        { name: "Depuis lancement", icon: "tim-icons icon-tap-02" }
      ];
    }
  },
  methods: {
    switchGraphTrack(){
      if(this.switchTrack === 'Lead'){
        this.switchTrack = "Clique"
      } else {
        this.switchTrack = 'Lead'
      }
    },
    Details(row) {
      let Details = {}
      Details.jour = row.year + '/' + row.month + '/' + row.day
      Details.droit = (this.ManagerSelects.Manager == '') ? "ad" : "tm"
      Details.id_client = this.ManagerSelects.Manager.split("SPLITE")[1] || null
      Details.id_programme = this.AnnonceurSelects.Annonceur.split("SPLITE")[0] || null
      Details.title_programme = this.AnnonceurSelects.Annonceur.split("SPLITE")[1] || null
      Details.manager = this.ManagerSelects.Manager.split("SPLITE")[2] || null
      Details.id_annonceur = this.client || null
      localStorage.setItem("Details", JSON.stringify(Details))
      
      let routeData = this.$router.resolve({name: 'stats Detail Leads day'});
      window.open(routeData.href, '_blank');   
    },
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value :value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    async send() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days < 0){
        this.EndDate = this.StartDate
        this.SkipQuery = false
      } else {
        this.SkipQuery = false
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    reset() {
      this.TypeSelects.Type = "";
      this.StartDate = thisday;
      this.EndDate = thisday;
      this.AnnonceurSelects.Annonceur = "";
      this.ManagerSelects.Manager = ""
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMleads)
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMLeads_client)
          return;
        }
        else if( index === 5) {
          if(this.SUMclics == 0)
            sums[index] = 0 + ' %'
          else
            sums[index] = this.formatThounsends((this.SUMleads/this.SUMclics)*100, true, 2) + ' %'
          return;
        }
        // else if( index === 6) {
        //   sums[index] = this.formatThounsends((this.SUMleads/this.SUMimpressions)*100, true, 2) + ' %'
        //   return;
        // }
        else if( index === 6) {
          sums[index] = this.formatThounsends(this.SUMca, true) + ' €'
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    CustomSort_Periode(a, b) {
      let d1 = a.jour.split(" ")[1]
      let m1 = a.jour.split(" ")[2]
      let a1 = a.jour.split(" ")[3]

      let d2 = b.jour.split(" ")[1]
      let m2 = b.jour.split(" ")[2]
      let a2 = b.jour.split(" ")[3]

      let date1 = moment(d1+" "+m1+" "+a1, 'DD MMM YYYY', 'fr')
      let date2 = moment(d2+" "+m2+" "+a2, 'DD MMM YYYY', 'fr')
      
      return date1.diff(date2)
    },
    moment(date) {
      return moment(date);
    }
  },
  watch: {
    'AnnonceurSelects.Annonceur'() {
      this.TypeSelects.Type = ""
      if(this.AnnonceurSelects.Annonceur == "") {
        this.managerRequired = 'required'
      }
      else {
        this.managerRequired = ''
      }
    },
    'ManagerSelects.Manager'() {
      if(this.ManagerSelects.Manager == "") {
        this.prgRequired = 'required'
      }
      else {
        this.prgRequired = ''
      }
    },
    client(value) {
      this.AnnonceurSelects.Annonceur = ""
      if (value) {
        this.prgRequired = ''
        this.managerRequired = ''
      }
      else {
        this.managerRequired = 'required'
        this.prgRequired = 'required'
      }
    }
  }
};
</script>