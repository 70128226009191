<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-left">
                <h2 class="card-title">STATISTIQUES CA ANNONCEUR/EDITEUR/TM</h2>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-6">
                    <base-radio inline name="af" class="mb-3" v-model="law">
                      EDITEURS
                    </base-radio>
                    <base-radio inline name="ma" class="mb-3" v-model="law">
                      TM
                    </base-radio>
                    <base-radio inline name="an" class="mb-3" v-model="law" disabled>
                      ANNONCEURS
                    </base-radio>
                  </div>
                  <div class="col-md-12 float">
                    <base-input class="col-md-3 float-left">
                      <el-select filterable class="select-primary"
                        clearable
                        placeholder="NOM SOCIÉTÉ"
                        v-model="societySelected"
                        >
                        <el-option 
                          v-for="option in ListClient"
                          class="select-primary"
                          :value="option.societe_client"
                          :label="(option.societe_client.length > 15 ) ? option.societe_client.slice(0,15).toUpperCase()+'...' : option.societe_client.toUpperCase()"
                          :key="option.societe_client">
                        </el-option>
                      </el-select>
                    </base-input>
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.getTopCaAnnonceurOrEditeur.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                    <div class="col-12 float-left">
                      <button class="btn btn-round btn-info float-right" @click="ExportTable()">
                        <i class="tim-icons icon-cloud-download-93"></i> EXPORT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="p-2" data="black">
            <!-- show-summary :summary-method="getSummaries"  class="customtable_statsCampEdit"-->
            <el-table :data="queriedData" show-summary :summary-method="getSummaries" v-loading="$apollo.queries.getTopCaAnnonceurOrEditeur.loading" @selection-change="handleSelectionChangeEdit" v-if="law != 'ma'" class="customtable_cmd">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="Societe"
                prop="societe_client"
                show-overflow-tooltip
                sortable
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="Login"
                prop="login_client"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
              </el-table-column>
              <el-table-column
                :label="'Ca (' + this.months[this.month2] + ')'"
                prop="ca2"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca2, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="'Ca (' + this.months[this.month] + ')'"
                prop="ca"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="Platforme"
                minWidth="150"
              >
                <template scope="scope">
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_myaffile">
                    <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO PERF</title>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_mycoreg">
                    <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO COREG</title>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_leadmarket">
                    <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO MARKET</title>
                    </svg>
                  </button>
                  <template v-if="scope.row.client_mongustave">
                    <img src="img/ico-mongustave.png" width="20" height="20" title="MON GUSTAVE"/>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="Date Creation Client"
                prop="datecreation_client"
                show-overflow-tooltip
                sortable
                min-width="150"
              >
              <template scope="scope">
                {{ custom_date(scope.row.datecreation_client.split(" ")[0]) }}
              </template>
              </el-table-column>
              <el-table-column
                label="Solde"
                prop="solde"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
              <template scope="scope">
                {{ scope.row.solde }}
              </template>
              </el-table-column>
              <el-table-column
                label="action"
                show-overflow-tooltip
                min-width="100"
              >
                <template scope="scope">
                    <base-button @click.native="EditItemF(scope.row)" class="btn-link btn-warning" type="success" size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button> 
                </template>
              </el-table-column>
              <el-table-column
                label="Date Mise à jour"
                prop="solde_updated_at"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  <p v-if="scope.row.solde_updated_at !== null"> {{ custom_date(scope.row.solde_updated_at) }} </p>
                  <p v-else> {{ "--" }} </p>
                </template>
              </el-table-column>
              <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
            </div>
            </el-table>

            <el-table :data="queriedData" show-summary :summary-method="getSummaries" v-loading="$apollo.queries.getTopCaAnnonceurOrEditeur.loading" v-else class="customtable_cmd">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="Societe"
                prop="societe_client"
                show-overflow-tooltip
                sortable
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="Login"
                prop="login_client"
                show-overflow-tooltip
                sortable
                min-width="125"
              >
              </el-table-column>
              <el-table-column
                :label="'Ca (' + this.months[this.month2] + ')'"
                prop="ca"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca2, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="'Ca (' + this.months[this.month] + ')'"
                prop="ca"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="Platforme"
                minWidth="150"
              >
                <template scope="scope">
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_myaffile">
                    <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO PERF</title>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_mycoreg">
                    <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO COREG</title>
                    </svg>
                  </button>
                  <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_client_leadmarket">
                    <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                      <g>
                        <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                        <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                          c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                          h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                      </g>
                      <title>WEEDO MARKET</title>
                    </svg>
                  </button>
                  <template v-if="scope.row.client_mongustave">
                    <img src="img/ico-mongustave.png" width="20" height="20" title="MON GUSTAVE"/>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="Date Creation Client"
                prop="datecreation_client"
                show-overflow-tooltip
                sortable
                min-width="150"
              >
              <template scope="scope">
                {{ custom_date(scope.row.datecreation_client.split(" ")[0]) }}
              </template>
              </el-table-column>
              <el-table-column
                label="Solde"
                prop="solde"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
              <template scope="scope">
                {{ scope.row.solde }}
              </template>
              </el-table-column>
              <el-table-column
                label="action"
                show-overflow-tooltip
                min-width="100"
              >
                <template scope="scope">
                    <base-button @click.native="EditItemF(scope.row)" class="btn-link btn-warning" type="success" size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button> 
                </template>
              </el-table-column>
              <el-table-column
                label="Date Mise à jour"
                prop="solde_updated_at"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  <p v-if="scope.row.solde_updated_at !== null"> {{ custom_date(scope.row.solde_updated_at) }} </p>
                  <p v-else> {{ "--" }} </p>
                </template>
              </el-table-column>
              <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
              </div>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <fab
      v-if='MiltipleRowEdit.length && !$apollo.queries.getTopCaAnnonceurOrEditeur.loading'
      @click.native="EditCadeauF"
      position="bottom-rignt"
      main-tooltip="modifier"
      main-icon="edit"
      icon-size="small"
      bg-color="#004F84"
    ></fab>
    <!-- Modal Edit HMTL VISUEL -->
    <modal_selfEvent :show.sync="ShowEditCadeau" body-classes="p-0" modal-classes="modal-dialog-centered ">
      <card type="secondary" header-classes="pb-5"  class="border-0 mb-0  update-solde-table" style="width: 40vw;">
        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="closepop"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>
        <template v-if="ShowEditCadeau">
         <table class="table">
            <thead>
              <tr>
                <th>SOCIETE</th>
                <th>CA</th>
                <th class="text-center pl-3">POINT ACTUEL</th>
                <th class="text-center pl-3">POINT A AJOUTER</th>
                <th class="text-center pl-3">TOTAL DES POINT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(edit, i) in MiltipleRowEdit" :key="i">
                <th scope="row" style="color: #fff">
                  {{ edit.societe_client }}
                </th>
                <td style='width: 6.5em;'>{{ formatThounsends(edit.ca, true) }}  € </td>
                <td class="text-center pl-12 ">{{ edit.solde }}</td>
                <td class="text-center pl-12">
                  <span style="color: green"
                    >+{{ calcule_point(edit.ca) }}</span
                  >
                </td>
                <td class="text-center pl-12">
                  {{ edit.solde + calcule_point(edit.ca) }}
                </td>
              </tr>
            </tbody>
          </table>
          <center>
          <base-button
            v-if="update_loader"
            loading 
            class="btn btn-round btn-just-icon btn-info mt-0 d-block"
            >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
            <base-button
              disabled
              class="btn btn-round btn-just-icon btn-info mt-0 d-block"
              v-else-if="update_end"
            >POINTS AJOUTES</base-button>
            <base-button
              class="btn btn-round btn-just-icon btn-info mt-0 d-block"
              @click.native="updatePoints"
              v-else
            >AJOUTER DES POINTS</base-button>
          </center>
        </template>
      </card>
    </modal_selfEvent>
    <!-- Modal Edit HMTL VISUEL -->
    <!-- Modal Edit HMTL VISUEL -->
    <modal_selfEvent :show.sync="ShowItemCadeau" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
        <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
          <base-button
            @click.native="closepopItem"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>
        <h3 class="card-title">Points : {{ this.globaleSolde}}</h3>
        <template v-if="ShowItemCadeau">
          <form role="form">
            <base-input label="Points À Ajouter :" v-model="soldeEdit">
            </base-input>
            <div class="text-center">
              <base-button type="info" @click="EditSolde" class="my-4">{{ $t("buttons.BtnAdd").toUpperCase() }}</base-button>
            </div>
          </form>
        </template>
      </card>
    </modal_selfEvent>
    <!-- Modal Edit HMTL VISUEL -->
  </div>
</template>

<script>
  import { Table, TableColumn, DatePicker, Select, Option } from 'element-ui';
  import { BaseRadio } from 'src/components/index'
  import gql from 'graphql-tag'
  import moment from 'moment'
  import Modal_selfEvent from 'src/components/Modal_selfEvent'
  import fab from 'vue-fab'
  import XLSX from 'xlsx'

  export default {
    apollo: {
      getTopCaAnnonceurOrEditeur: {
        client: 'AUTH',
        query: gql`
          query($droit: String) {
            getTopCaAnnonceurOrEditeur (droit: $droit){
              societe_client
              login_client
              ca
              ca2
              datecreation_client
              solde
              solde_updated_at
              mois
              annee
              user_id
              droit
              code_client
              solde_created_at
              id_client_myaffile
              id_client_mycoreg
              id_client_leadmarket
              client_mongustave
            }
          }
        `,
        skip() {
          return this.skipQuery
        },
        variables() {
          return {
            droit: this.law,
          };
        },
        result({ data, loader, networkStatus }) {
          this.ListClient = []
          data.getTopCaAnnonceurOrEditeur.forEach(datag => {
            this.ListClient.push({
              user_id: datag.user_id,
              societe_client: datag.societe_client
            })
          });
          console.log(this.ListClient)
          this.skipQuery = true
        },
        fetchPolicy: 'network-only'
      }
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      BaseRadio,
      fab,
      Modal_selfEvent
    },
    data() {
      return {
        init_num:0,
        months:[
          'Janvier', 
          'Février', 
          'Mars', 
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre', 
          'Octobre', 
          'Novembre',
          'Décembre'
        ],
        MiltipleRowEdit: [],
        fabActions: [
              {
                  name: 'add',
                  icon: 'add',
                  tooltip: 'ajout des points'
              },

        ],
        disableinput: false,
        month: moment().subtract(1,'months').month(),
        month2:moment().subtract(2,'months').month(), 
        solde:0,
        soldeEdit:0,
        droitUser:null,
        globaleSolde:0,
        ShowEditCadeau: false,
        ShowItemCadeau: false,
        skipQuery: true,
        total: 0,
        update_loader:false,
        update_end:false,
        getTopCaAnnonceurOrEditeur: [],
        law: 'af',
        ListClient: [],
        societySelected: ''
      }
    },
    computed: {
      queriedData() {
          this.total = (this.getTopCaAnnonceurOrEditeur) ? this.getTopCaAnnonceurOrEditeur.length : 0
          return (this.societySelected === '') ? this.getTopCaAnnonceurOrEditeur.slice(this.from, this.to) : this.getTopCaAnnonceurOrEditeur.slice(this.from, this.to).filter(x => x.societe_client === this.societySelected)
      },
    },
    methods: {
      ExportTable() {
        if (this.getTopCaAnnonceurOrEditeur.length) {
          let getTopCaAnnonceurOrEditeur = JSON.parse(JSON.stringify(this.getTopCaAnnonceurOrEditeur));
          getTopCaAnnonceurOrEditeur.forEach(function(data){ 
            delete data.mois
            delete data.annee
            delete data.user_id
            delete data.droit
            delete data.code_client
            delete data.solde_created_at
            delete data.id_client_myaffile
            delete data.id_client_mycoreg
            delete data.id_client_leadmarket
            delete data.client_mongustave
            delete data.__typename

            data.ca2 = data.ca2.toFixed(2).replace(".", ",")
            data.ca = data.ca.toFixed(2).replace(".", ",")

          });
          const fileName = 'SOLDE_CADEAUX.csv'
          var Stats = XLSX.utils.json_to_sheet(getTopCaAnnonceurOrEditeur) 
          Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

          Stats = Stats.replace("societe_client","Societe")
          Stats = Stats.replace("login_client","Login")
          Stats = Stats.replace("ca2","Ca (" + this.months[this.month2] + ")")
          Stats = Stats.replace("ca","Ca (" + this.months[this.month] + ")")
          Stats = Stats.replace("datecreation_client","Date Creation Client")
          Stats = Stats.replace("solde","Solde")
          Stats = Stats.replace("solde_updated_at","Date Mise à jour")

          var blob = new Blob(["\ufeff", Stats]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      },
      custom_date(date){
        return moment(new Date(date)).format("DD-MM-YYYY")
      },
      calcule_point(ca){
        return parseInt(((2*ca)/100) * 8)
      },
      closepop(){
        this.ShowEditCadeau = false
        this.update_end = false
      },
      closepopItem(){
        this.ShowItemCadeau = false
      },
      updatePoints() {
        let selected = [];
        this.update_loader = true;
        let formData = new FormData();
        this.MiltipleRowEdit.forEach(function (item, key) {
          formData.append(`users[${key}][user_id]`, item.user_id);
          formData.append(`users[${key}][ca]`, item.ca);
          formData.append(
            `users[${key}][point]`,
            parseInt(((2 * item.ca) / 100) * 8)
          );
          formData.append(`users[${key}][droit]`, item.droit);
        });
        const url_api =
          "https://api-auth-plf.wee-do-it.net/api/v1/update_sold_cadeaux";

        let accessToken = localStorage.getItem("token_jwt");

        this.axios
          .post(url_api, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            this.$notify({
              type: "success",
              message: "Point ajouté avec succes",
            });
            this.update_loader = false;
            this.update_end = true;
          })
          .catch(() => {
            console.log("error occured");
          });

        for (const m in this.MiltipleRowEdit) {
          for (let i in this.getTopCaAnnonceurOrEditeur) {
            if (
              this.getTopCaAnnonceurOrEditeur[i].user_id ===
              this.MiltipleRowEdit[m].user_id
            ) {
              this.getTopCaAnnonceurOrEditeur[i].solde = parseInt(
                ((2 * this.MiltipleRowEdit[m].ca) / 100) * 8
              );
              break; //Stop this loop, we found it!
            }
          }
        }
      },
      async EditSolde(row) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: gql`mutation (
              $solde:Int!
              $user_id: Int!
              $droit: String
            ) {
              set_solde_cadeau
               (
                 solde:$solde, 
                 user_id:$user_id
                 droit:$droit
                ) 
              {
                message
            }
          }`,
          variables: {
            solde: this.soldeEdit,
            user_id: this.user_id,
            droit: this.droitUser,
          },
        }).then(response => {
          let respMutation = response.data.set_solde_cadeau.message
          if(respMutation.includes('erreur')){
            this.ShowItemCadeau = false
            this.$notify({type: 'warning', message:  'erreur serveur'})
          } else {
            this.$notify({
              type: "success",
              message: "Point ajouté avec succes",
            });
            this.ShowItemCadeau = false
            for (var i in this.getTopCaAnnonceurOrEditeur) {
              if (this.getTopCaAnnonceurOrEditeur[i].user_id === this.user_id) {
                  this.getTopCaAnnonceurOrEditeur[i].solde = this.globaleSolde;
                  break; //Stop this loop, we found it!
              }
            }
          }
        }).catch(error => {
          this.ShowItemCadeau = false
          this.$notify({type: 'warning', message: this.$t("apiresponse.restorationerror") })
        })
      },
      async RefreshSolde(row) {
        const result = await this.$apollo.query({
          client: "AUTH",
          query: gql`
          query($user_id: Int!) {
          refreshCompteCadeaux (user_id: $user_id){
            solde
            message
            }
          }
          `,
          variables: {
            user_id: row.user_id,
          },
        }).then(response => {
          let resp = response.data.refreshCompteCadeaux.solde
          for (var i in this.getTopCaAnnonceurOrEditeur) {
            if (this.getTopCaAnnonceurOrEditeur[i].user_id === row.user_id) {
                this.getTopCaAnnonceurOrEditeur[i].solde = resp;
                break; //Stop this loop, we found it!
            }
          }
         this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Solde actualisé' });
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.restorationerror") })
        })
      },
      EditCadeauF(row) {
        this.ShowEditCadeau = true
      },
      EditItemF(row) {
        this.ShowItemCadeau = true
        this.solde = (row.solde !== null) ? row.solde : 0
        this.soldeEdit = 0
        this.globaleSolde = this.solde
        this.user_id = row.user_id
        this.droitUser = row.droit
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        let sumCa_m1 = 0
        let sumCa_m2 = 0
        let sumSolde = 0
        data.forEach(element => {
          sumCa_m1 += element.ca
          sumCa_m2 += element.ca2
          sumSolde += element.solde
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
             sums[index] = '';
            return;
          }
          else if( index === 2) {
             sums[index] = '';
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sumCa_m2,true,0) + ' €';
            return;
          }
          else if( index === 4) {
            sums[index] = this.formatThounsends(sumCa_m1,true,0) + ' €';
            return;
          }
          else if( index === 7) {
            sums[index] = sumSolde;
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async send() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        this.skipQuery = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value : value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      handleSelectionChangeEdit(row) {
        console.log(row)
        if (row.length) {
          this.MiltipleRowEdit = row
        }else {
          this.MiltipleRowEdit = []
        }
        console.log(this.MiltipleRowEdit)
      },
    },
    watch : {
      soldeEdit(value){
        let v = 0;
        if(value !== ""){
          v = value
        }
        this.globaleSolde = parseInt(this.solde) + parseInt(v)
      },
      societySelected(value){
        console.log('selected value is / ',value)
      },
      law() {
        if(this.MiltipleRowEdit.length > 0){
          this.MiltipleRowEdit = []
        }
        this.getTopCaAnnonceurOrEditeur = [];
      }
    }
  }
</script>

<style>
  .update-solde-table
  {
    max-height: 500px;
    display: block;
    overflow-y:scroll;
  }
  .booking-table-rows {
    text-transform: uppercase;
  }
  .modal-content {
    background-color: transparent;
  }
  .modal.show .modal-dialog {
    transform: translate(0, 0%);
  }
  /* .customtable_statsCampEdit .cell{
    word-break: break-word;
    font-size: 11.5px;
  } */
  .el-table__row .form-control {
    background-color: transparent;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
</style>