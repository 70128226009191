<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 text-left">
                        <h2 class="card-title">Suivi des inscription des tokens FCM</h2>
                    </div>
                  <div class="col-12">
                      <div class="row">
                          <div class="col-lg-4 col-md-4">
                              <base-input>
                              <el-select class="select-primary"
                                  :disabled="$apollo.queries.getFCMLogCount.loading"
                                  :placeholder="$t('table.columns.Period')"
                                  v-model="PeriodeSelects.Period">
                                  <el-option
                                  v-for="option in PeriodeSelects.ListPeriod"
                                  class="select-primary"
                                  :value="option.value"
                                  :label="option.label.toUpperCase()"
                                  :key="option.value">
                                  </el-option>
                              </el-select>
                              </base-input>
                          </div>
                          <div class="col-lg-4  col-md-4">
                              <base-input>
                                  <el-date-picker
                                  :disabled="$apollo.queries.getFCMLogCount.loading"
                                  v-model="StartDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="DATE DE DÉBUT"
                                  :picker-options="pickerOptions"
                                  @input="send"
                                  ></el-date-picker>
                              </base-input>
                          </div>
                          <div class="col-lg-4  col-md-4">
                              <base-input>
                                  <el-date-picker
                                  :disabled="$apollo.queries.getFCMLogCount.loading"
                                  v-model="EndDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="DATE DE FIN"
                                  :picker-options="pickerOptions"
                                  @input="send"
                                  ></el-date-picker>
                              </base-input>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12 float-right">
                              <div class="btn-group btn-group-toggle float-right" >
                                  <base-button v-if="$apollo.queries.getFCMLogCount.loading"
                                      loading
                                      class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                                      >{{ $t("buttons.BtnLoad").toUpperCase() }}...
                                  </base-button>
                                  <base-button
                                      v-else
                                      class="btn btn-round btn-just-icon btn-primary"
                                      @click="send"
                                      >{{ $t("buttons.BtnSearch").toUpperCase() }}
                                  </base-button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card card-chart mb-0 pb-0" >
              <div class="card-header">
                 <div class="row">
                  <div class="col-md-6 offset-md-6">
                    <!-- Right Column Content with buttons -->
                    <div class="column-content text-right">
                      <button class="btn btn-sm btn-info btn-simple" :class="{ active: displayStatistic }" @click="displayStatistique">STATISTIQUES</button>
                      <button class="btn btn-sm btn-info btn-simple" :class="{ active: displayLog }" @click="displayLogs">LOGS</button>
                      <!-- <button class="btn btn-sm btn-info btn-simple" :class="{ active: displayDetail }" @click="displayDetails">TOKENS</button> -->
                    </div>
                  </div>
                </div>
              </div>

          <!--card -->
          <div class="card-body pb-4" data="black" v-if="displayStatistic">
            <el-table ref="topTM_Table" :data="getFCMLogCount" >
              <el-table-column
                min-width="280"
                sortable
                label="DOMAINE"
                prop="domain"
              >
                <template scope="scope">
                  <!-- <div v-if="scope.row.domain"> {{ scope.row.domain.replace('https://','')  }} </div> -->
                  <div v-if="scope.row.domain"> {{ formatDomain(scope.row.domain)  }} </div>
                  <div v-else class="text-danger"> non définie </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100"
                sortable
                label="NOMBRE TOKEN"
                prop="nb_token"
              >
                <template scope="scope">
                  <div class="text-center"> {{ scope.row.nb_token }} </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100"
                label="NOMBRE ERREUR"
                prop="nb_error"
                sortable
              >
                <template scope="scope">
                  <div class="text-center"> {{ scope.row.nb_error }} </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="250"
                label="Date"
                align="center"

              >
                <template>
                  <div v-if="StartDate && EndDate"> {{formatDate(StartDate)}} -- {{formatDate(EndDate)}}</div>
                  <div v-if="StartDate && !EndDate"> {{formatDate(StartDate)}} </div>
                  <div v-if="!StartDate && EndDate"> {{formatDate(EndDate)}} </div>

                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-if="displayLog">
          <div class="card-body" data="black">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="SÉLECTIONNER LES CRITÈRES"
                    v-model="CriteresSelects.Criteres"
                    @change="sendDetails"
                     >
                    <el-option
                      v-for="option in CriteresSelects.ListCriteres"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="SÉLECTIONNER LES CRITÈRES"
                    v-model="CriteresSelectsBrowser.Criteres"
                    @change="sendDetails"
                     >
                    <el-option
                      v-for="option in CriteresSelectsBrowser.ListCriteres"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
              <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="SÉLECTIONNER LES CRITÈRES"
                    v-model="CriteresSelectsPlatform.Criteres"
                    @change="sendDetails"
                     >
                    <el-option
                      v-for="option in CriteresSelectsPlatform.ListCriteres"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6 col-md-4 col-sm-12">
                <base-input type="text" v-model="keyword" @keyup.enter="sendDetails" placeholder="MOTS CLÉS POUR CHERCHER MESSAGE"/>
              </div>
            </div>
              <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <button
                  @click="resetFilter"
                  class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                <base-button
                  v-if="$apollo.queries.getErrorsFcm.loading"
                  loading
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                <button
                  v-else @click="sendDetails"
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
              </div>
            </div>

            </div>

            <div class="row">
              <div class="col-4">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <el-table ref="topTM_Table" :data="filterRequest" >
              <el-table-column
                label="ID"
                prop="id"
                width="80"
                show-overflow-tooltip
                sortable
              >
               <template scope="scope"> {{ scope.row.id }} </template>
              </el-table-column>
              <el-table-column
                label="DOMAIN"
                prop="domain"
                width="250"
                show-overflow-tooltip
                align="center"
                sortable
              >
                <template scope="scope">
                  <div v-if="scope.row.domain"> {{ formatDomain(scope.row.domain)  }} </div>
                  <div v-else class="text-danger"> non définie </div>
                </template>
              </el-table-column>
              <el-table-column
                label="IP ADDRESS"
                prop="ip_address"
                width="200"
                show-overflow-tooltip
                align="center"
                sortable
              >
                <template scope="scope"> {{ scope.row.ip_address === null ? '--' : scope.row.ip_address }} </template>
              </el-table-column>
              <el-table-column
                label="BROWSER"
                prop="browser"
                width="200"
                show-overflow-tooltip
                align="center"
                sortable
              >
                <template scope="scope"> {{ scope.row.browser === null ? '--' : scope.row.browser }} </template>
              </el-table-column>
              <el-table-column
                label="PLATFORM"
                prop="platform"
                width="200"
                show-overflow-tooltip
                align="center"
                sortable
              >
                <template scope="scope"> {{ scope.row.platform === null ? '--' : scope.row.platform }} </template>
              </el-table-column>
              <el-table-column
                label="MESSAGE"
                prop="message"
                width="500"
                show-overflow-tooltip
                sortable
              >
               <template scope="scope"> {{ scope.row.message }} </template>
              </el-table-column>
              <el-table-column
                label="DATE"
                prop="created_at"
                width="200"
                show-overflow-tooltip
                align="center"
                sortable
              >
                <template scope="scope"> {{ formatDate(scope.row.created_at,true) }} </template>
              </el-table-column>
            </el-table>

            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
               <div>
                  <p class="card-category">{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
               </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
          <!-- card end -->
           <hr>


          </div>
        </div>
      </div>
      </div>
      </div>
      <fab
        @click.native="sendFCMNotification"
        position="bottom-rignt"
        main-tooltip="ENVOYER FCM NOTIFICATION"
        main-icon="send"
        icon-size="medium"
        bg-color="#004F84"
      ></fab>

      <!--- modal formulaire fcm -->
        <Modal_selfEvent
          :show.sync="showModal"
          body-classes="p-0"
          modalClasses="modal-dialog modal-dialog-centered"
          data-keyboard="false" data-backdrop="static"
          class="modal-listbase"
          >
                  <card type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0 fixed-height">
                    <base-button style="float: right;" @click.native="closeModal()" class="like btn-link" type="danger" size="sm" icon>
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                    <div class="card">
                      <div class="card-body">
                        <div class="row" v-if="!showResponse">
                          <div class="col">
                            <form @submit.prevent="validate">
                                <div class="form-row">
                                  <div class="col">
                                    <base-input label="Topic"
                                     :error="getError('selectTopic')"
                                      v-validate="'required'"
                                      data-vv-as="topic"
                                      name='selectTopic'
                                      v-model="model.topic"
                                    >
                                      <el-select class="select-primary"
                                          placeholder="Topic"
                                          v-model="model.topic"
                                          @change="getDomains"

                                          >
                                          <el-option
                                          v-for="option in topicSelect.listTopic"
                                          class="select-primary"
                                          :value="option.value"
                                          :label="option.label.toUpperCase()"
                                          :key="option.value"
                                          >
                                          </el-option>
                                      </el-select>
                                    </base-input>
                                  </div>
                                </div>
                               <!--  <div class="form-row">
                                  <div class="col">
                                      <p class="category">test</p>

                                  </div>
                                </div> -->
                                <div class="form-row">
                                  <div class="col">
                                    <base-input label="DOMAINE"
                                    :error="getError('selectDomain')"
                                    v-model="model.domain"
                                    data-vv-as="domain"
                                    name='selectDomain'
                                    >
                                      <base-switch
                                        v-model="allDomain"
                                        type="primary"
                                        on-text="TOUS"
                                        off-text=""
                                        @input="switchDomaines"
                                      ></base-switch>
                                      <!-- <span class="mx-2 label-switch label-left text-white">Tous</span> -->
                                      <el-select class="select-primary"
                                          v-if="!allDomain"
                                          placeholder="SELECTIONNER UN DOMAINE"
                                          v-model="model.domain"
                                          :disabled="$apollo.queries.getDomainByTopic.loading"
                                          >
                                          <el-option
                                          v-for="option in domainSelect.listDomain"
                                          class="select-primary"
                                          :value="option.value"
                                          :label="option.label.toUpperCase()"
                                          :key="option.value">
                                          </el-option>
                                      </el-select>
                                    </base-input>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="col">
                                    <base-input
                                    name="title"
                                    label="Titre"
                                    placeholder="Titre"
                                    v-model="model.title"
                                    :error="getError('title')"
                                    v-validate="'required'"
                                    data-vv-as="titre"
                                  ></base-input>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="col">
                                    <base-input
                                    label="Contenu"
                                    name="body"
                                    placeholder="Contenu"
                                    v-model="model.body"
                                  ></base-input>
                                  </div>
                                </div>
                                <!-- <div class="form-row  d-flex align-items-center justify-content-center bg-dark"> -->
                                <div class="form-row  d-flex align-items-stretch">
                                  <div class="col-11 align-middle">
                                   <base-input
                                   label="Icon"
                                    name="icon"
                                    placeholder="Icon"
                                    v-model="model.icon"
                                    :error="getError('icon')"
                                    v-validate="'required'"
                                    data-vv-as="icon"
                                  ></base-input>
                                  </div>
                                  <div class="col-1  d-flex align-items-center justify-content-center">
                                    <img :src="model.icon" alt="Icon Description" height="50" width="50" >
                                  </div>
                                </div>
                               <!--  <div class="form-row">
                                  <div class="col">
                                    <div class="card text-center">
                                      <img :src="model.icon" alt="Icon Description" class="mx-auto" height="60" >
                                    </div>
                                    <base-input
                                    name="icon"
                                    placeholder="Icon"
                                    v-model="model.icon"
                                    :error="getError('icon')"
                                    v-validate="'required'"
                                    data-vv-as="icon"
                                  ></base-input>
                                  </div>
                                </div> -->
                                <div class="form-row">
                                  <div class="col">
                                    <label for="image">IMAGE</label>
                                    <div class="card text-center" v-if="model.image">
                                      <img :src="model.image" alt="Icon Description" class="mx-auto" style="max-height:200px;max-width:300px;">
                                    </div>
                                    <base-input
                                    name="image"
                                    placeholder="image"
                                    v-model="model.image"
                                    :error="getError('image')"
                                    v-validate="'required'"
                                    data-vv-as="image"
                                  ></base-input>
                                  </div>
                                </div>
                                 <div class="form-row">
                                  <div class="col">
                                    <base-input
                                    label="titre action"
                                    name="actionTitle"
                                    placeholder="titre action"
                                    v-model="model.data.title"
                                    :error="getError('actionTitle')"
                                    v-validate="'required'"
                                    data-vv-as="titre action"
                                  ></base-input>
                                  </div>
                                </div>
                                 <div class="form-row">
                                  <div class="col">
                                    <base-input
                                    label="url action"
                                    name="actionURL"
                                    placeholder="url action"
                                    v-model="model.data.url"
                                    :error="getError('actionURL')"
                                    v-validate="'required'"
                                    data-vv-as="url action"
                                  ></base-input>
                                  </div>
                                </div>
                                <center>
                                  <base-button
                                    v-if='!loading'
                                    native-type="submit"
                                    type="info"
                                    >ENVOYER
                                  </base-button>
                                  <base-button v-else type="info" loading>ENVOYER</base-button>
                              </center>
                            </form>
                          </div>
                        </div>
                        <div class="row" v-else>

                              <div class="col-7 pr-3 border-right border-dark">
                              <p class="text-light"> nombre de tokens  </p>
                              <p class="text-light"> nombre de tokens reussis   </p>
                              <p class="text-light"> nombre de tokens échoués   </p>
                              <p class="text-light"> nombre de tokens à modifier  </p>
                              <p class="text-light"> nombre de tokens à supprimer  </p>
                              <p class="text-light"> nombre de tokens à réessayer  </p>
                          </div>
                          <div class="col-4 text-center ">
                              <p class="text-danger"> {{response.numberTokens}} </p>
                              <p class="text-danger"> {{response.numberTokensSuccess}} </p>
                              <p class="text-danger"> {{response.numberTokensFailure}} </p>
                              <p class="text-danger"> {{response.numberTokenModify}} </p>
                              <p class="text-danger"> {{response.numberTokenToDelete}} </p>
                              <p class="text-danger"> {{response.numberTokenToRetry}} </p>
                          </div>

                        </div>
                      </div>
                    </div>

                </card>
        </Modal_selfEvent>
    </div>

  </div>

</template>

<script>
import {Select, Option, DatePicker} from 'element-ui'
import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import { Table, TableColumn } from 'element-ui';
import moment from 'moment';
import { BasePagination, BaseSwitch } from "src/components";
import fab from 'vue-fab'
import {Modal} from 'src/components'
import Modal_selfEvent from 'src/components/Modal_selfEvent'






let CurentDate = new Date()

export default {
  apollo: {
    getDomainByTopic: {
      client: "AUTH",
      query: gql`query ($topic: String)
            {
              getDomainByTopic (
                              topic: $topic
                            )
              {
                domain
              }
            }`,
      fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
         //console.log('getDomainByTopic', data.getDomainByTopic)

        let domains = []
        data.getDomainByTopic.map(obj => {
          if(obj.domain){
            domains.push({
              'label' : obj.domain.replace('https://',''),
              'value' : obj.domain,
            });
          }

        })

        this.domainSelect.listDomain = domains
      },
      skip() {
        return this.SkipDomainQuery
      },
      variables() {
        return {
          topic: this.model.topic,
        }
      }
    },
    getAllTopic: {
      client: "AUTH",
      query: gql`query
            {
              getAllTopic
              {
                topic
              }
            }`,
      fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        data.getAllTopic.map(obj => {
          if(obj.topic){
            this.topicSelect.listTopic.push({
              'label' : obj.topic.toUpperCase(),
              'value' : obj.topic,
            });
          }

        })
      },
      skip() {
        return this.SkipTopicQuery
      }
    },
    getFCMLogCount: {
      client: "AUTH",
      query: gql`query ($startDate: Date, $endDate: Date)
            {
              getFCMLogCount (
                              startDate: $startDate
                              endDate: $endDate
                            )
              {
                domain
                nb_token
                nb_error
              }
            }`,
      fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        this.dataLogfcm = data.getFCMLogCount
        //console.log('getFCMLogCount',data.getFCMLogCount)
      },
      skip() {
        return this.SkipQueryTest
      },
      variables() {
        return {
          startDate: this.StartDate,
          endDate: this.EndDate,
        }
      }
    },
    getFilters: {
      client: "AUTH",
      query: gql`query
            {
              getFilters
              {
                domains{
                    domain
                }
                browsers{
                    browser
                }
                platforms{
                    platform
                }
              }
            }`,
      fetchPolicy: 'network-only',
      // update: data => data,
      result({ data, loader, networkStatus }) {
        let domaines = [{
              'label' : 'Tous les domaines',
              'value' : '',
              }]
        let browsers = [{
              'label' : 'Tous les navigateurs',
              'value' : '',
              }]
        let platforms = [{
              'label' : 'Tous les platformes',
              'value' : '',
              }]
        let dataDomaines = data.getFilters[0].domains
        let dataBrowsers = data.getFilters[0].browsers
        let dataPlatforms = data.getFilters[0].platforms
       dataDomaines.map(obj => {
          if(obj.domain === null || obj.domain === ""){
            const isDomainUndefined = domaines.some(item => item.value === 'undefined');
            if (!isDomainUndefined) {
              // Add the 'non définie' item only if it's not already in the array
              domaines.push({
                label: 'non définie',
                value: 'undefined',
              });
            }
          }else{
            domaines.push({
              'label' : obj.domain.replace('https://',''),
              'value' : obj.domain,
            });
          }
        });
        //console.log(domaines);
        dataBrowsers.map(obj => {
          if(!obj.browser){
            browsers.push({
              'label' : 'non définie',
              'value' : 'undefined',
              })
          }else{
            browsers.push({
              'label' : obj.browser.toUpperCase(),
              'value' : obj.browser,
            });
          }
        });
        dataPlatforms.map(obj => {
          if(!obj.platform){
            platforms.push({
              'label' : 'non définie',
              'value' : 'undefined',
              })
          }else{
            platforms.push({
              'label' : obj.platform.toUpperCase(),
              'value' : obj.platform,
            });
          }
        });
        this.CriteresSelects.ListCriteres = domaines
        this.CriteresSelectsBrowser.ListCriteres = browsers
        this.CriteresSelectsPlatform.ListCriteres = platforms;
      },
    },
    getErrorsFcm: {
      client: "AUTH",
      query: gql`query (
                        $startDate: Date,
                        $endDate: Date,
                        $domain: String,
                        $browser: String,
                        $platform: String,
                        $message: String,
                        )
            {
              getErrorsFcm (
                              startDate: $startDate
                              endDate: $endDate
                              domain: $domain
                              browser: $browser
                              platform: $platform
                              message: $message
                            )
              {
                id
                message
                domain
                ip_address
                browser
                platform
                created_at
              }
            }`,
      fetchPolicy: 'network-only',

      skip() {
        return this.SkipQueryTest
      },
      variables() {
        return {
          startDate: this.StartDate,
          endDate: this.EndDate,
          domain: this.domain,
          browser: this.browser,
          platform: this.platform,
          message: this.message,
        }
      },
      update: data => data,
      result({ data, loader, networkStatus }) {
        this.logData = data.getErrorsFcm
         this.SkipQueryTest = true
      },
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    BarChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    fab,
    BaseSwitch,
    Modal,
    Modal_selfEvent

  },
  watch: {
    'PeriodeSelects.Period'(value){
      if(value == "YESTERDAY") {
        this.StartDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
        this.EndDate = ""
      } else if(value == "CURRENTDAY") {
        this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
        this.EndDate = ""
      } else if(value == "CURRENTMONTH") {
        this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ 1
        this.EndDate   = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
      } else if(value == "LASTMONTH") {
        if (CurentDate.getMonth()+1 == 1) {
          this.StartDate = (CurentDate.getFullYear() - 1) +"-"+ 12 +"-"+ 1
          this.EndDate   = (CurentDate.getFullYear() - 1) +"-"+ 12 +"-"+ 31
        }
        else {
          this.StartDate = CurentDate.getFullYear() +"-"+ CurentDate.getMonth() +"-"+ 1
          this.EndDate   = CurentDate.getFullYear() +"-"+ CurentDate.getMonth() +"-"+ new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate()
        }
      }
      this.SkipQuery = false
      this.SkipQueryTest = false
    },
  },
  data() {
    return {
      response:{},
      ereurs:[],
      showResponse:false,
      loading:false,
      SkipDomainQuery:true,
      allDomain: true,
      model: {
        topic : "",
        title : "",
        body : "",
        icon : "https://www.mongustave.fr/wp-content/uploads/2023/09/mongustave-circle.png",
        image : "",
        data:{
          title : "",
          action : "open",
          url : ""
        },
      },
      showModal:false,
      token:'',
      message:"",
      SkipQueryTest:true,
      SkipTopicQuery:true,
      keyword:'',
      CriteresSelects: {
        Criteres: "",
        ListCriteres: [
        ]
      },
      CriteresSelectsBrowser: {
        Criteres: "",
        ListCriteres: [
        ]
      },
      CriteresSelectsPlatform: {
        Criteres: "",
        ListCriteres: [
        ]
      },
      domain:'',
      platform:null,
      browser:null,
      isDisbaled: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [2, 10, 25, 50, 100, 500, 1000],
        total: 0
      },
      total: 0,
      displayStatistic:true,
      displayLog:false,
      dataLogfcm : {},
      logData : [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      SkipQuery: true,
      SkipQueryLog: true,
      StartDate: "",
      EndDate:  "",
      PeriodeSelects: {
        Period: '',
        ListPeriod: [
          { value: "YESTERDAY" , label: "Hier"},
          { value: "CURRENTDAY" , label: "Aujourd'hui"},
          { value: "CURRENTMONTH" , label: "Mois en cours"},
          { value: "LASTMONTH" , label: "Mois précédent"}
        ]
      },
      topicSelect: {
        topic: '',
        listTopic: [
          { value: "all" , label: "Tous"},
        ]
      },
      domainSelect: {
        domain: null,
        listDomain: [
        ]
      },
    }
  },
  methods: {
    validate(){
      this.$validator.validateAll().then(isValid => {
        if(isValid){
          this.sendNotif()
        }/* else{
          if(this.$validator.validate('selectDomain') && allDomain){
            this.sendNotif();
          }
        } */
      });
    },
    getDomains(){
      if(this.model.topic){
        this.SkipDomainQuery = false;
      }

    },
    switchDomaines(){
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    sendFCMNotification(){
      this.showModal = true;
      this.showResponse = false
      this.model.image = ""
      this.model.topic = ''
      this.model.title = ''
      this.model.body = ''
      this.model.data.title = ''
      this.model.data.url = ''
      this.allDomain = true;
      this.domainSelect.listDomain = {}
    },
    closeModal() {
        this.showModal = false
    },
    sendNotif(){
       this.loading = true
        this.showResponse = false

        this.ereurs = []
      console.log('send notif ...')
      this.axios
           // .post("http://127.0.0.1:8000/api/v1/firebase/send-notification-by-token",this.model,{
            .post("https://api-auth-plf.wee-do-it.net/api/v1/firebase/send-notification-by-token",this.model,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            //console.log(response.data)
            this.response = response.data.response;
             this.loading = false
             this.showResponse = true
          })
          .catch(error => {
            console.log(error.response.data)
            this.loading = false
            this.ereurs.push(this.translatemsg(error.response.data.message))
          });
    },
    formatDate(dateString, dateTime=false) {
      if(dateTime){
        return moment(dateString).format('DD-MM-YYYY HH:mm:ss');
      }
      //return moment(dateString).format('DD-MM-YYYY');
      return moment(dateString).format('dddd LL');
    },
    formatDomain(value){
      return value.replace("https://",'')
    },
    displayStatistique(){
      this.displayStatistic = true
      this.displayLog = false
    },
    displayLogs(){
      this.displayStatistic = false
      this.displayLog = true
    },
    send() {
      this.PeriodeSelects.Period = ''
      var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if(Difference_In_Days<0) {
        if(Difference_In_Days < 0) this.$notify({type: 'danger', message: "Période invalide"})
      } else {
        this.SkipQuery = false
        this.SkipQueryTest = false
      }
    },
    getDateInFrench(dateToConvert){
      moment.locale('fr');
      let date = moment(dateToConvert);
      return date.format('MMMM YYYY');

    },
    sendDetails() {
      this.domain = this.CriteresSelects.Criteres
      this.browser = this.CriteresSelectsBrowser.Criteres
      this.platform = this.CriteresSelectsPlatform.Criteres
      this.message = this.keyword
      this.SkipQuery = true
      this.SkipQueryTest = false
    },
    resetFilter(){
      this.domain = ""
      this.browser = ""
      this.platform = ""
      this.message = ""
      this.keyword = ""
      this.CriteresSelects.Criteres = ""
      this.CriteresSelectsBrowser.Criteres = ""
      this.CriteresSelectsPlatform.Criteres = ""
      this.SkipQuery = true
      this.SkipQueryTest = false

    },
    sendLog(){

      if(this.CriteresSelects.Criteres == 'browser'){
        this.browser = this.keyword
      }
      if(this.CriteresSelects.Criteres == 'platform'){
        this.platform = this.keyword
      }
      this.SkipQueryLog = false
      this.$apollo.queries.getErrorsFcm.refetch({
          startDate: this.StartDate,
          endDate: this.EndDate,
          domain: this.domain,
          browser: this.browser,
          platform: this.platform,
        })
    },
  },
  created() {
    this.token = localStorage.getItem("token_jwt")
    this.SkipQuery = false
    this.SkipQueryTest = false
    this.SkipTopicQuery = false
    this.StartDate = CurentDate.getFullYear() +"-"+ (CurentDate.getMonth()+1) +"-"+ CurentDate.getDate()
    this.EndDate = ""

  },
  computed: {
    filterRequest(){
      let list = this.logData;
      this.total = list.length;
      return list.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  }
}
</script>
