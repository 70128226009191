<template>
  <div>
    <card>
      <div class="col-md-12 text-left">
        <h2 class="card-title">{{ $t("labels.UploadImgIntegratedKit") }}</h2>
      </div>
      <div class="col-md-6 mx-auto">
        <h4>{{ $t("labels.Step") }} 1 : IMAGES</h4>
        <center>
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            drag
            :auto-upload="false"
            list-type="picture"
            accept="image/*"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{ $t("inputs.DropImgHere") }} </div>
          </el-upload>
        </center>
      </div><br>
      <div class="col-md-6 mx-auto">
        <h4>{{ $t("labels.Step") }} 2 : VISUEL HTM(L)</h4>
        <center>
          <image-upload
            @change="onFileChange"
            :select-text="$t('inputs.SelectFile')">
          </image-upload>
        </center>
      </div><br>
      <div class="col-md-3 mx-auto">
        <div class="btn-group btn-group-toggle " data-toggle="buttons">
          <base-button v-if="Loading" 
            loading 
            class="btn btn-round btn-just-icon btn-default mt-0 d-block"
            >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <base-button 
            class="btn btn-round btn-just-icon btn-primary"
            v-else
            @click="send"
            >UPLODER</base-button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
  import { upload } from 'element-ui'
  import { ImageUpload } from 'src/components';

  export default {
    components: {
      [upload.name]: upload,
      ImageUpload
    },
    data() {
      return {
        tags_file: null,
        Loading: false,
      }
    },
    methods: {
      onFileChange(file) {
        this.tags_file = file;
      },
      async send() {
        if (!this.$refs.upload.uploadFiles.length) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné les images !" })
          return
        }
        
        if (!this.tags_file) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné le fichier html !" })
          return
        }

        let images = this.$refs.upload.uploadFiles.map(a => a.raw);

        this.Loading = true
        var formData = new FormData();
        for (let i = 0; i < images.length; i++) {
          formData.append("images["+i+"]", images[i]);
        }
        formData.append("file", this.tags_file);
        const url_api = "https://apimyaffil.wee-do-it.net/api/upload-file-with-image"
        let accessToken = localStorage.getItem("token_jwt")
        this.axios.post(url_api, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
          }).then(response => {
            const filename = "kit.html";
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.Loading = false
          }).catch(error => {
            console.log("error occured")
            this.Loading = false
          });
      }
    },
  }
</script>