<template>
  <div class="container-fluid">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($route.name == 'Ajouter un Programme') ? 'active' : ''"
        id="0"
        data-toggle="collapse"
        href="#collapseone"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{name: 'Ajouter un Programme'}"
      >
        <input type="radio" name="options" checked />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">AJOUTER UN PROGRAMME</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-simple-add"></i>
        </span>
      </router-link>
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($router.name == 'Ajouter un Visuel') ? 'active' : ''"
        id="1"
        data-toggle="collapse"
        href="#collapsetwo"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{ name: 'Ajouter un Visuel' }"
      >
        <input type="radio" class="d-none d-sm-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">AJOUTER UN VISUEL</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-simple-add"></i>
        </span>
      </router-link>
      <router-link
        class="btn btn-sm btn-info btn-simple"
        :class="($route.name == 'Dupliquer un Programme') ? 'active' : ''"
        id="2"
        data-toggle="collapse"
        href="#collapsethree"
        aria-expanded="false"
        aria-controls="collapseExample"
        :to="{name: 'Dupliquer un Programme'}"
      >
        <input type="radio" class="d-none" name="options" />
        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">DUPLIQUER UN PROGRAMME</span>
        <span class="d-block d-sm-none">
          <i class="tim-icons icon-single-copy-04"></i>
        </span>
      </router-link>
    </div>
    <p>&nbsp;</p>
    <zoom-center-transition :duration="200" mode="out-in">
      <router-view></router-view>
    </zoom-center-transition>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    ZoomCenterTransition
  }
};
</script>
