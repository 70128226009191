<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">LEADS DU JOUR</h2>
              </div>
            </div>
            <template v-if="Info">
              <template v-if="Info.manager"><label> MANAGER: {{ Info.manager }}</label><br></template>
              <template v-if="Info.title_programme"><label> PROGRAMME : {{ Info.title_programme }}</label><br></template>
              <label v-if="Info.editeur"> EDITEUR : {{ Info.editeur }}</label>
            </template>
            <center>
              <base-button
                @click.native="Daybefore"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-double-left"></i>
              </base-button>

              <label> Statistiques  {{ new Date(Start_Date).toLocaleDateString() || '' }}</label>

              <base-button
                @click.native="Dayafter"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-double-right"></i>
              </base-button>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: hideImp }"
                  >
                    <input
                      type="radio"
                      @click="hideImp=true;"
                      name="options"
                      autocomplete="off"
                      :checked="hideImp"
                    />
                    <span class="d-none d-sm-block">IMP</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        content="IMP"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i class="tim-icons icon-coins"></i>
                      </el-tooltip>
                    </span>
                  </label>
                  <label
                    style="font-size: 0.75rem"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: hideclics }"
                  >
                    <input
                      type="radio"
                      @click="hideclics=true;"
                      name="options"
                      autocomplete="off"
                      :checked="hideclics"
                    />
                    <span class="d-none d-sm-block">CLICS</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        content="CLICS"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i class="tim-icons icon-badge"></i>
                      </el-tooltip>
                    </span>
                  </label>
                  <label
                    style="font-size: 0.75rem"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: hideleads }"
                  >
                    <input
                      type="radio"
                      @click="hideleads=true"
                      name="options"
                      autocomplete="off"
                      :checked="hideleads"
                    />
                    <span class="d-none d-sm-block">LEADS</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        content="LEADS"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i class="tim-icons icon-badge"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <line-chart
              :labels="lineChart2.labels"
              :chart-data="lineChart2.chartData"
              :extra-options="lineChart2.extraOptions"
              :height="200"
              v-if="hidechart"
            >
            </line-chart>
          </div>
          <div class="p-2" data="black">
            <div class="table-responsive">
              <el-table :data="tableCampagne" :default-sort = "{prop: 'ca', order: 'descending'}" class="customtable" show-summary :summary-method="getSummaries">
                <el-table-column
                  width="420"
                  sortable
                  label="CAMPAGNE"
                  prop="campagne"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  width="120"
                  sortable
                  label="IMP"
                  prop="impression.impression_old"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.impression.impression_old) ? formatThounsends(scope.row.impression.impression_old) : 0 }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="120"
                  sortable
                  label="NEW IMP"
                  prop="impression.impression_track"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.impression.impression_track) ? formatThounsends(scope.row.impression.impression_track) : 0 }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="120"
                  sortable
                  label="CLICS"
                  prop="clics.clics_old"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.clics.clics_old) ? formatThounsends(scope.row.clics.clics_old) : 0 }}
                  </template>
                </el-table-column>
                 <el-table-column
                  width="125"
                  sortable
                  label="NEW CLICS"
                  prop="clics.clics_track"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.clics.clics_track) ? formatThounsends(scope.row.clics.clics_track) : 0 }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="120"
                  sortable
                  label="LEADS"
                  prop="leads.leads_old"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.leads.leads_old) ? formatThounsends(scope.row.leads.leads_old) : 0 }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="130"
                  sortable
                  label="NEW LEADS"
                  prop="leads.leads_track"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ (scope.row.leads.leads_track) ? formatThounsends(scope.row.leads.leads_track) : 0 }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <center v-if="$apollo.queries.detailLeadsEditeurTracking.loading">
            <base-button
              loading 
              class="btn btn-round btn-just-icon btn-default mt-0 d-block"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import gql from 'graphql-tag'
  import {Modal} from 'src/components'
  import { Table, TableColumn } from 'element-ui';
  import LineChart from "@/components/Charts/LineChart";
  import * as chartConfigs from "@/components/Charts/config";
  import config from "@/config";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LineChart
    },
    watch: {
      async hideImp(value) {
        if(value) {
          this.hidechart = false
          this.hideclics = false
          this.hideleads = false
          this.lineChart2.chartData.datasets[0].data = this.dataImp
          this.lineChart2.chartData.datasets[0].label = "IMP"
          this.lineChart2.chartData.datasets[1].data = this.dataNEWImp
          this.lineChart2.chartData.datasets[1].label = "NEW IMP"
          await this.sleep(100);
          this.hidechart = true
        }
      },
      async hideclics(value) {
        if(value) {
          this.hidechart = false
          this.hideImp = false
          this.hideleads = false
          this.lineChart2.chartData.datasets[0].data = this.dataClics
          this.lineChart2.chartData.datasets[0].label = "CLICS"
          this.lineChart2.chartData.datasets[1].data = this.dataNEWClics
          this.lineChart2.chartData.datasets[1].label = "NEW CLICS"
          await this.sleep(100);
          this.hidechart = true
        }
      },
      async hideleads(value) {
        if(value) {
          this.hidechart = false
          this.hideImp = false
          this.hideclics = false
          this.lineChart2.chartData.datasets[0].data = this.dataLeads
          this.lineChart2.chartData.datasets[0].label = "LEADS"
          this.lineChart2.chartData.datasets[1].data = this.dataNEWLeads
          this.lineChart2.chartData.datasets[1].label = "NEW LEADS"
          await this.sleep(100);
          this.hidechart = true
        }
      }
    },
    data() {
        return {
          hideImp: false,
          hideclics: false,
          hideleads: false,
          hidechart: false,
          dataImp: null,
          dataNEWImp: null,
          dataClics: null,
          dataNEWClics: null,
          dataLeads: null,
          dataNEWLeads: null,
          lineChart2: {
            chartData: {
              labels: [],
              datasets: [
                {
                  label: 'IMP',
                  fill: true,
                  borderColor: config.colors.info,
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: config.colors.info,
                  pointBorderColor: 'rgba(255,255,255,0)',
                  pointHoverBackgroundColor: '#2380f7',
                  pointBorderWidth: 20,
                  pointHoverRadius: 2,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: []
                },
                {
                  label: 'NEW IMP',
                  fill: true,
                  borderColor: config.colors.primary,
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: config.colors.primary,
                  pointBorderColor: 'rgba(255,255,255,0)',
                  pointHoverBackgroundColor: '#2380f7',
                  pointBorderWidth: 20,
                  pointHoverRadius: 2,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: []
                }
              ]
            },
            extraOptions: {
              ...chartConfigs.lineChartOptionsBlue,
              legend: {
                display: true
              },
            }
          },
          Info: {
            manager: "",
            title_programme: "",
            editeur: ""
          },
          Flag: false,
          SkipQuery: true,
          tableCampagne: [],
          totales: 0,
          totale: {
            impression: 0,
            impression_track: 0,
            clics: 0,
            clics_track: 0,
            leads: 0,
            leads_track: 0
          },
          Start_Date: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).jour : '',
          End_Date: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).jour : '',
          Droit: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).droit : null,
          Id_client: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).id_client : null,
          Id_programme: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).id_programme : null,
          title_programme: (JSON.parse(localStorage.getItem('Details'))) ? JSON.parse(localStorage.getItem('Details')).title_programme : null
        };
    },
    apollo: {
      detailLeadsEditeurTracking: {
        query : gql` query (
          $date_start: Date!, 
          $date_end: Date!,
          $droit: String!,
          $id_client: Int,
          $id_programme: Int,
          $title_programme: String,
          $StartFrom: Int!,
          $LimitRows: Int!
        ) 
        {
          detailLeadsEditeurTracking(
            date_start:$date_start,
            date_end:$date_end,
            droit:$droit
            id_client:$id_client
            id_programme: $id_programme
            title_programme: $title_programme,
            StartFrom: $StartFrom
            LimitRows: $LimitRows
            ) {
                campagne
                type
                impression{
                    impression_track
                    impression_old
                }
                clics{
                    clics_track
                    clics_old
                }
                leads{
                    leads_track
                    leads_old
                }
              }
        }`,
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) 
        {
          this.tableCampagne = [...this.tableCampagne,...data.detailLeadsEditeurTracking]
          this.Flag = data.detailLeadsEditeurTracking.length != 500
          if (!this.Flag) {
            this.totales += 500 
          } else {
            let impression = 0
            let clics = 0
            let leads = 0
            let impression_track = 0
            let clics_track = 0
            let leads_track = 0
            this.tableCampagne.forEach(element => {
              impression += element.impression.impression_old,
              impression_track += element.impression.impression_track,
              clics += element.clics.clics_old,
              clics_track += element.clics.clics_track,
              leads += element.leads.leads_old,
              leads_track += element.leads.leads_track
            });
            this.totale.impression = impression
            this.totale.impression_track = impression_track
            this.totale.clics = clics
            this.totale.clics_track = clics_track
            this.totale.leads = leads
            this.totale.leads_track = leads_track

            let labels = this.tableCampagne.map(({ campagne }) => campagne.slice(0,10))
            this.dataImp = this.tableCampagne.map(a => a.impression.impression_old)
            this.dataNEWImp = this.tableCampagne.map(a => a.impression.impression_track)
            this.dataClics = this.tableCampagne.map(a => a.clics.clics_old)
            this.dataNEWClics = this.tableCampagne.map(a => a.clics.clics_track)
            this.dataLeads = this.tableCampagne.map(a => a.leads.leads_old)
            this.dataNEWLeads = this.tableCampagne.map(a => a.leads.leads_track)
            this.lineChart2.chartData.labels = labels
            this.hideImp = true
          }
        },
        skip() {
          return JSON.parse(localStorage.getItem('Details')) == null;
        },
        variables() {
          return {
            date_start: this.Start_Date.replace(new RegExp('/', 'g'), '-'), 
            date_end: this.Start_Date.replace(new RegExp('/', 'g'), '-'),
            droit: this.Droit,
            id_client: this.Id_client,
            id_programme: this.Id_programme,
            title_programme: this.title_programme,
            StartFrom: this.totales,
            LimitRows: 500
          }
        }
      }
    },
    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      Daybefore() {
        this.tableCampagne = []
        this.totale.clics = 0
        this.totale.clics_track = 0
        this.totale.leads = 0
        this.totale.leads_track = 0
        var myDate = new Date(this.Start_Date);
        myDate.setDate(myDate.getDate() - 1)
        this.Start_Date = myDate.getFullYear()+'/'+(myDate.getMonth()+1)+'/'+myDate.getDate();
        this.totales = 0
      },
      Dayafter() {
        this.tableCampagne = []
        this.totale.clics = 0
        this.totale.clics_track = 0
        this.totale.leads = 0
        this.totale.leads_track = 0
        var myDate = new Date(this.Start_Date);
        myDate.setDate(myDate.getDate() + 1)
        this.Start_Date  = myDate.getFullYear()+'/'+(myDate.getMonth()+1)+'/'+myDate.getDate();
        this.totales = 0
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(this.totale.impression);
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(this.totale.impression_track);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(this.totale.clics);
            return;
          }
          else if( index === 4) {
            sums[index] = this.formatThounsends(this.totale.clics_track);
            return;
          }
          else if( index === 5) {
            sums[index] = this.formatThounsends(this.totale.leads);
            return;
          }
          else if( index === 6) {
            sums[index] = this.formatThounsends(this.totale.leads_track);
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
    mounted() {
      this.Info = JSON.parse(localStorage.getItem('Details'))
      if(this.Info == null)
        this.$router.push({ name: "Stats Globales" })
      localStorage.removeItem("Details")

      this.$root.$on('Details', (Details) => {
        this.Info =  Commande_Object
        if(this.Info == null) 
          this.$router.push({ name: "Stats Globales" })
      })

    },
  };
</script>

<style>
  .customtable .cell{
    word-break: break-word;
    font-size: 14px;
  }
</style>