<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4 text-left">
                <h2 class="card-title">Statistique campagnes</h2>
              </div>
              <div class="col-sm-8">
                <div class="col-lg-8 col-md-4 col-sm-6">
                  <div class="btn-group">
                    <button class="btn btn-primary">Par jour</button>
                    <button class="btn btn-primary">Par mois</button>
                    <button class="btn btn-primary">Par année</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="row">
              <div class="col-lg-4">
                <div class="card card-chart">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-12 text-left">
                      </div>
                    </div>
                  </div>
                  <div class="p-2" data="black">
                    <div class="table-responsive">
                      <table id="datatable" class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Client</th>
                            <th class="text-center">Nbr progs</th>
                            <th class="text-center">Leads</th>
                            <th class="text-center">ECPM</th>
                            <th class="text-center">CA</th>
                            <th class="text-center">Marge</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                          <tr>
                            <td>ECA</td>
                            <td class="text-center">447</td>
                            <td class="text-center">9077</td>
                            <td class="text-center">104.24€</td>
                            <td class="text-center">60280.5€</td>
                            <td class="text-center">19098,19€</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-chart">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-12 text-left">
                      </div>
                    </div>
                  </div>
                  <div class="p-2" data="black">
                    <div class="table-responsive">
                      <table id="datatable" class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Catégorie</th>
                            <th class="text-center">Nbr progs</th>
                            <th class="text-center">Leads</th>
                            <th class="text-center">CA</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Assurance</td>
                            <td class="text-center">717</td>
                            <td class="text-center">11137</td>
                            <td class="text-center">72152.5€</td>
                          </tr>
                          <tr>
                            <td>Assurance</td>
                            <td class="text-center">717</td>
                            <td class="text-center">11137</td>
                            <td class="text-center">72152.5€</td>
                          </tr>
                          <tr>
                            <td>Assurance</td>
                            <td class="text-center">717</td>
                            <td class="text-center">11137</td>
                            <td class="text-center">72152.5€</td>
                          </tr>
                          <tr>
                            <td>Assurance</td>
                            <td class="text-center">717</td>
                            <td class="text-center">11137</td>
                            <td class="text-center">72152.5€</td>
                          </tr>
                          <tr>
                            <td>Assurance</td>
                            <td class="text-center">717</td>
                            <td class="text-center">11137</td>
                            <td class="text-center">72152.5€</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-chart">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-12 text-left">
                      </div>
                    </div>
                  </div>
                  <div class="p-2" data="black">
                    <div class="table-responsive">
                      <table id="datatable" class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Langue</th>
                            <th class="text-center">Nbr progs</th>
                            <th class="text-center">Leads</th>
                            <th class="text-center">CA</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>FR</td>
                            <td class="text-center">868</td>
                            <td class="text-center">12796</td>
                            <td class="text-center">82053.5€</td>
                          </tr>
                          <tr>
                            <td>FR</td>
                            <td class="text-center">868</td>
                            <td class="text-center">12796</td>
                            <td class="text-center">82053.5€</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {

};
</script>