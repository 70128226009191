<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("buttons.BtnStatsGlobal").toUpperCase() }}</h2>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <base-input>
                      <el-select
                        class="select-primary"
                        :placeholder="$t('table.columns.Period')"
                        v-model="PeriodeSelects.Periode"
                        :disabled="!hideChart"
                      >
                        <el-option
                          v-for="option in PeriodeSelects.ListPeriode"
                          class="select-primary"
                          v-if='option.annee != 1970 && option.annee != 1999'
                          :value="option.numero_mois+ ' '+option.annee"
                          :label="mounth[option.numero_mois-1].toUpperCase() +' '+ option.annee"
                          :key="option.value"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <!-- <div class="col-md-8">
                    <button
                      class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                      @click="send()"
                      v-if="Flag"
                    >RÉINITIALISER</button>
                    <base-button v-else loading type="primary" class="mb-2 pull-right"></base-button>
                  </div> -->
                </div>
              </div>
              <div class="col-sm-2">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: ChartStatGlobal.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="ChartStatGlobal.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <line-chart
              v-if='hideChart'
              :labels="ChartStatGlobal.labels"
              :chart-data="ChartStatGlobal.chartData"
              :extra-options="ChartStatGlobal.extraOptions"
              :height="200"
            >
            </line-chart>
            <center> 
              <base-button v-if='!hideChart' loading type="primary">{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 offset-md-8">
            <div class="btn-group float-right mb-3">
              <button simple size="sm" class="btn btn-sm btn-info btn-simple"  :class="{ active: this.switchTrack === 'Lead' }" @click="switchGraphTrack()">Leads</button>
              <button simple size="sm" class="btn btn-sm btn-info btn-simple" :class="{ active: this.switchTrack === 'Clique' }" @click="switchGraphTrack()">{{ $t('table.columns.clicks') }}</button>
            </div>
          </div>
        </div>
        <span v-show='this.switchTrack === "Lead"'>
        <div class="row">
          
          <div class="col-md-4">
          <div class="card">
            <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
              {{ $t('table.columns.SourceMachine') }}
            </span>
            <br/>
            <pie-chart
              :chart-data="dataChartAffichage_by_machine_lead"
              :extra-options="pieChart.extraOptions"
              :height="200"
              v-if="renderChart_Affichage_by_machine && !$apollo.queries.statsGlobales.loading"
            >
            </pie-chart>
          </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceOs') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_os_lead"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_os && !$apollo.queries.statsGlobales.loading"
              >
              </pie-chart>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceBrowser') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_nav_lead"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_nav && !$apollo.queries.statsGlobales.loading"
              >
              </pie-chart>
            </div>
          </div>

        </div>
        
      </span>
      <span v-show='this.switchTrack === "Clique"'>
        <div class="row">
          
          <div class="col-md-4">
          <div class="card">
            <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
              {{ $t('table.columns.SourceMachine') }}
            </span>
            <br/>
            <pie-chart
              :chart-data="dataChartAffichage_by_machine_clique"
              :extra-options="pieChart.extraOptions"
              :height="200"
              v-if="renderChart_Affichage_by_machine && !$apollo.queries.statsGlobales.loading"
            >
            </pie-chart>
          </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceOs') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_os_clique"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_os && !$apollo.queries.statsGlobales.loading"
              >
              </pie-chart>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
                {{ $t('table.columns.SourceBrowser') }}
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_nav_clique"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_nav && !$apollo.queries.statsGlobales.loading"
              >
              </pie-chart>
            </div>
          </div>

        </div>
      </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="AllstatsGlobales" show-summary :summary-method="getSummaries">
              <el-table-column
                :label="$t('table.columns.Day')"
                prop="jour"
                width="235"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    {{ moment(scope.row.year+"-"+scope.row.month+"-"+scope.row.numero_jour).format('dddd LL').toUpperCase() }}
                    <!-- {{ (scope.row.jour).toUpperCase() }} -->
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="Impressions"
                prop="impressions"
                min-width="140"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.clicks')"
                prop="clics"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Leads"
                prop="leads"
                min-width="100"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    <u>
                      {{ formatThounsends(scope.row.leads) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Sales')"
                prop="ventes"
                min-width="105"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ventes) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Pages.dashboard.ca')"
                prop="ca"
                min-width="100"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CTR"
                prop="CTR"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ECPC"
                prop="ECPC"
                min-width="95"
                sortable
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ECPC, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <center>
            <base-button v-if='!Flag' loading type="primary"></base-button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from 'element-ui';
import LineChart from 'src/components/Charts/LineChart'
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import PieChart from 'src/components/Charts/PieChart';
import {BaseButton} from 'src/components'
import moment from 'moment'
moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr");

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.info,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.info,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: '#2380f7',
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']

let bigChartData = [
  [],
  []
]

export default {
  apollo: {
    listPeriode: {
      query: gql`query {
        listPeriode{
          numero_mois
          mois
          annee
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.PeriodeSelects.ListPeriode = data.listPeriode
      }
    },
    statsGlobales: {
      query : gql`query ($day: Int,$month: String!,$year: String!) {
        statsGlobales (
          day: $day
          month: $month
          year: $year,
          droit: "ad"
        )
        {
          flag
          returnDay
          jour
          impressions
          clics
          leads
          ventes
          ECPC
          CTR
          ca
          month
          year
          numero_jour
        }        
      }`,
      fetchPolicy: 'no-cache',
      skip(){
        return this.SkipQuery 
      },
      variables() {
        return {
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""   
        }
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobales[0].returnDay
        this.Flag = data.statsGlobales[0].flag
        this.AllstatsGlobales = [...this.AllstatsGlobales,...data.statsGlobales]       
      },
    },
    statsTrackingDetails: {
        query: gql `query ($year: Int!, $month: Int!) {
            statsTrackingDetails(year: $year, month: $month) {
              clique {
                Mobile
                Desctop
                Tab
                os {
                    Android
                    Other
                    iOS
                    Linux
                    Windows
                    MacOS
                }
                navigateur {
                    Chrome
                    Other
                    Edg
                    Safari
                    Firefox
                    MSIE
                    Opera
                }
              }
              lead {
                Mobile
                Desctop
                Tab
                os {
                    Android
                    Other
                    iOS
                    Linux
                    Windows
                    MacOS
                }
                navigateur {
                    Chrome
                    Other
                    Edg
                    Safari
                    Firefox
                    MSIE
                    Opera
                }
              }
            }
          }`,
        variables () {
          return {
            month: (new Date().getUTCMonth() + 1) + "",
            year: new Date().getUTCFullYear() + ""
          }
        },
        fetchPolicy: 'network-only',
        skip() {
          return this.SkipQueryStatsTrackingDetails
        },
        result({ data, loader, networkStatus }) {
          let AllLeadOld = this.SUMlead - (data.statsTrackingDetails.lead.Mobile + data.statsTrackingDetails.lead.Desctop + data.statsTrackingDetails.lead.Tab)
          if(AllLeadOld < 0){
            AllLeadOld = 0;
          }
          let AllClicOld = this.SUMclics - (data.statsTrackingDetails.clique.Mobile + data.statsTrackingDetails.clique.Desctop + data.statsTrackingDetails.clique.Tab)
          if(AllClicOld < 0){
            AllClicOld = 0;
          }
          this.dataChartAffichage_by_machine_lead = {
            labels: ['Mobile','Desctop','Tab','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#00B7C3', '#1B61F7','#FFCD46', '#C626F7'],
                borderWidth: 0,
                data: [data.statsTrackingDetails.lead.Mobile, data.statsTrackingDetails.lead.Desctop, data.statsTrackingDetails.lead.Tab,AllLeadOld]
              }
            ]
          }
          this.dataChartAffichage_by_machine_clique = {
            labels: ['Mobile','Desctop','Tab','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#00B7C3', '#1B61F7','#FFCD46', '#C626F7'],
                borderWidth: 0,
                data: [data.statsTrackingDetails.clique.Mobile, data.statsTrackingDetails.clique.Desctop, data.statsTrackingDetails.clique.Tab,AllClicOld]
              }
            ]
          }
          this.renderChart_Affichage_by_machine = true 

          this.dataChartAffichage_by_os_lead = {
            labels: ['Android','Linux','MacOS','Windows','iOS','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#a4c639', '#3F51B5', '#A3AAAE', '#01A6F0', '#5FC9F8', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetails.lead.os.Android, 
                  data.statsTrackingDetails.lead.os.Linux,
                  data.statsTrackingDetails.lead.os.MacOS,
                  data.statsTrackingDetails.lead.os.Windows,
                  data.statsTrackingDetails.lead.os.iOS,
                  data.statsTrackingDetails.lead.os.Other,
                  AllLeadOld
                  ]
              }
            ]
          }
          this.dataChartAffichage_by_os_clique = {
            labels: ['Android','Linux','MacOS','Windows','iOS','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#a4c639', '#3F51B5', '#A3AAAE', '#01A6F0', '#5FC9F8', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetails.clique.os.Android, 
                  data.statsTrackingDetails.clique.os.Linux,
                  data.statsTrackingDetails.clique.os.MacOS,
                  data.statsTrackingDetails.clique.os.Windows,
                  data.statsTrackingDetails.clique.os.iOS,
                  data.statsTrackingDetails.clique.os.Other,
                  AllClicOld
                  ]
              }
            ]
          }
          this.renderChart_Affichage_by_os = true 

          this.dataChartAffichage_by_nav_lead = {
            labels: ['Chrome','Edg','Firefox','MSIE','Opera','Safari','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#FFCD46', '#0078d7', '#FF6611', '#009999', '#FF1B2D', '#006CFF', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetails.lead.navigateur.Chrome, 
                  data.statsTrackingDetails.lead.navigateur.Edg,
                  data.statsTrackingDetails.lead.navigateur.Firefox,
                  data.statsTrackingDetails.lead.navigateur.MSIE,
                  data.statsTrackingDetails.lead.navigateur.Opera,
                  data.statsTrackingDetails.lead.navigateur.Safari,
                  data.statsTrackingDetails.lead.navigateur.Other,
                  AllLeadOld
                  ]
              }
            ]
          }
          this.dataChartAffichage_by_nav_clique = {
            labels: ['Chrome','Edg','Firefox','MSIE','Opera','Safari','Other','Ancien Tracking'],
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#FFCD46', '#0078d7', '#FF6611', '#009999', '#FF1B2D', '#006CFF', '#FC3D39', '#C626F7'],
                borderWidth: 0,
                data: [
                  data.statsTrackingDetails.clique.navigateur.Chrome, 
                  data.statsTrackingDetails.clique.navigateur.Edg,
                  data.statsTrackingDetails.clique.navigateur.Firefox,
                  data.statsTrackingDetails.clique.navigateur.MSIE,
                  data.statsTrackingDetails.clique.navigateur.Opera,
                  data.statsTrackingDetails.clique.navigateur.Safari,
                  data.statsTrackingDetails.clique.navigateur.Other,
                  AllClicOld
                  ]
              }
            ]
          }
          this.renderChart_Affichage_by_nav = true
        },
      }
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: this.$t('Pages.dashboard.ca'), icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LineChart, 
    PieChart,
    BaseButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      mounth: this.$t('labels.Mounths'),
      AllstatsGlobales: [],
      returnDay: null,
      SkipQuery: true,
      SkipQueryStatsTrackingDetails: true,
      Flag: false,
      hideChart: false,
      switchTrack : 'Lead',
      SUMimpressions: 0,
      SUMclics: 0,
      SUMlead: 0,
      SUMventes: 0,
      SUMECPC: 0,
      SUMca: 0,
      renderChart_Affichage_by_machine: false,
      renderChart_Affichage_by_os: false,
      renderChart_Affichage_by_nav: false,
      dataChartAffichage_by_machine_lead: null,
      dataChartAffichage_by_machine_clique: null,
      dataChartAffichage_by_os_lead: null,
      dataChartAffichage_by_nav_lead: null,
      dataChartAffichage_by_os_clique: null,
      dataChartAffichage_by_nav_clique: null,
      PeriodeSelects: {
        Periode: "",
        ListPeriode: []
      },
      pieChart: {
        chartData: {},
        extraOptions: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 15,
              fontSize: 12
            }
          },
          cutoutPercentage: 60,
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
              },
              label: function(tooltipItem, data) {
                return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              },
              afterLabel: function(tooltipItem, data) {
                var dataset = data['datasets'][0];
                
                var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
                return '(' + percent + '%)';
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 16,
            titleFontColor: '#0000',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: false
          },
        }
      },
      ChartStatGlobal: {
        activeIndex: 0,
        chartData: {
          labels: bigChartLabels,
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[0]
            }
          ],
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 150,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        }
      },
    };
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value : value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.ChartStatGlobal.chartData = chartData;
      this.ChartStatGlobal.activeIndex = index;
    },
    switchGraphTrack(){
      if(this.switchTrack === 'Lead'){
        this.switchTrack = "Clique"
      } else {
        this.switchTrack = 'Lead'
      }
    },
    async send() {
      this.hideChart = false
      this.AllstatsGlobales = []
      this.returnDay = null
      this.SkipQuery = false
      this.renderChart_Affichage_by_machine= false
      this.renderChart_Affichage_by_os= false
      this.renderChart_Affichage_by_nav= false
      this.Flag = false
      while(!this.Flag){
        await this.$apollo.queries.statsGlobales.refetch({
          day: this.returnDay,
          month: this.PeriodeSelects.Periode.split(" ")[0],
          year: this.PeriodeSelects.Periode.split(" ")[1]
        })
      }
      let label = []
      let datalead = []
      let dataCA = []
      let SUMimpressions = 0
      let SUMclics = 0
      let SUMlead = 0
      let SUMventes = 0
      let SUMECPC = 0
      let SUMca = 0
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        label.push(index+1 + "/" + this.mounth[this.AllstatsGlobales[index].month-1] )
        datalead.push(this.AllstatsGlobales[index].leads)
        dataCA.push(this.AllstatsGlobales[index].ca)
        SUMimpressions += this.AllstatsGlobales[index].impressions
        SUMlead += this.AllstatsGlobales[index].leads
        SUMventes += this.AllstatsGlobales[index].ventes
        SUMECPC += this.AllstatsGlobales[index].ECPC
        SUMca += this.AllstatsGlobales[index].ca
        SUMclics += this.AllstatsGlobales[index].clics
      }
      this.SUMimpressions = SUMimpressions
      this.SUMclics = SUMclics
      this.SUMlead = SUMlead
      this.SUMventes = SUMventes
      this.SUMECPC = SUMECPC
      this.SUMca = SUMca
      await this.$apollo.queries.statsTrackingDetails.refetch({
        month: this.PeriodeSelects.Periode.split(" ")[0],
        year: this.PeriodeSelects.Periode.split(" ")[1]
      })
      bigChartData[0] = dataCA 
      bigChartData[1] = datalead
      bigChartLabels = label
      this.initBigChart(0)
      this.hideChart = true
      this.SkipQuery = true
      this.SkipQueryStatsTrackingDetails = false
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMlead)
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMventes) + " €"
          return;
        }
        else if( index === 7) {
          sums[index] = this.formatThounsends(this.SUMECPC, true, 2) + ' €'
          return;
        }
        else if( index === 6) {
          sums[index] = ((this.SUMclics == 0) ? 0 : this.formatThounsends((this.SUMlead/this.SUMclics)*100, true, 2)) + ' %'
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMca, true) + ' €'
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    Details(row) {
      let Details = {}
      Details.jour = row.year + '/' + row.month+ '/' + row.numero_jour
      Details.droit = "ad"
      Details.id_client = null
      Details.id_programme = null
      localStorage.setItem("Details", JSON.stringify(Details))
      let routeData = this.$router.resolve({name: 'stats Detail Leads day'});
      window.open(routeData.href, '_blank');   
    },
    moment(date) {
      return moment(date);
    }
  },
  async created() {
    this.SkipQuery = false
    while(!this.Flag){
      await this.$apollo.queries.statsGlobales.refetch({
        day: this.returnDay,
        month: (new Date().getUTCMonth() + 1) + "",
        year: new Date().getUTCFullYear() + "",
        droit: 'ad'
      })
    }
    await this.$apollo.queries.statsTrackingDetails.refetch({
        month: (new Date().getUTCMonth() + 1) + "",
        year: new Date().getUTCFullYear() + "",
      })
    let label = []
    let datalead = []
    let dataCA = []
    let SUMimpressions = 0
    let SUMclics = 0
    let SUMlead = 0
    let SUMventes = 0
    let SUMECPC = 0
    let SUMca = 0

    for (let index = 0; index < this.AllstatsGlobales.length; index++) {
      label.push(index+1 + "/" + this.mounth[this.AllstatsGlobales[index].month-1])
      datalead.push(this.AllstatsGlobales[index].leads)
      dataCA.push(this.AllstatsGlobales[index].ca)
      SUMimpressions += this.AllstatsGlobales[index].impressions
      SUMlead += this.AllstatsGlobales[index].leads
      SUMventes += this.AllstatsGlobales[index].ventes
      SUMECPC += this.AllstatsGlobales[index].ECPC
      SUMca += this.AllstatsGlobales[index].ca
      SUMclics += this.AllstatsGlobales[index].clics
    }
    this.SkipQueryStatsTrackingDetails = false
    this.SUMimpressions = SUMimpressions
    this.SUMclics = SUMclics
    this.SUMlead = SUMlead
    this.SUMventes = SUMventes
    this.SUMECPC = SUMECPC
    this.SUMca = SUMca
    bigChartData[0] = dataCA 
    bigChartData[1] = datalead
    bigChartLabels = label
    this.initBigChart(0)
    this.hideChart = true
    this.SkipQuery = true
  },
  watch: {
    "PeriodeSelects.Periode"() {
      this.send()
    }
  }
};
</script>