<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">Statistique CA par editeur</h2>
              </div>
              <div class="col-md-8">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >RÉINITIALISER</button>
                  <button class="btn btn-round btn-just-icon btn-primary mb-2">ACTUALISER</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-3 col-sm-6">
                <base-input>
                  <el-date-picker
                    v-model="StartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="DATE DE DÉBUT"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-3 col-sm-6">
                <base-input>
                  <el-date-picker
                    v-model="EndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="DATE DE FIN"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-3 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="TRAFFIC MANAGER"
                    v-model="ManagerSelects.Manager"
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="row">
              <div class="col-12">
                <div class="card card-chart">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-12 text-left"></div>
                    </div>
                  </div>
                  <div class="p-2" data="black">
                    <div class="table-responsive">
                      <table id="datatable" class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Editeur</th>
                            <th class="text-center">Impressions</th>
                            <th class="text-center">Clics</th>
                            <th class="text-center">Leads</th>
                            <th class="text-center">Ventes</th>
                            <th class="text-center">ECPM</th>
                            <th class="text-center">ECPC</th>
                            <th class="text-center">CTR</th>
                            <th class="text-center">CA</th>
                            <th class="text-center">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                          <tr>
                            <td>Caloga</td>
                            <td class="text-center">732994</td>
                            <td class="text-center">8880</td>
                            <td class="text-center">1962</td>
                            <td class="text-center">0</td>
                            <td class="text-center">11,65€</td>
                            <td class="text-center">0,96€</td>
                            <td class="text-center">22,09%</td>
                            <td class="text-center">8536,30€</td>
                            <td class="text-center">3.82</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      StartDate: '',
      EndDate: '',
      ManagerSelects: {
        Manager: "",
        ListManager: [
          { value: "1", label: "Team Casa" },
          { value: "2", label: "Team Lyon" },
          { value: "3", label: "Sara LOUBIZ" }
        ]
      }
    };
  }
};
</script>